import { useEffect, useState } from 'react';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { CountryFlag } from '@/components/CountryFlag';
import { formatDistanceToNow } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';
import { MemberPublicData, MovementStats } from '@/types/movement';
import { Card } from '@/components/ui/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface FirestoreMemberData extends Omit<MemberPublicData, 'timestamp'> {
  timestamp: Timestamp;
}

interface MembersListDocument {
  members: FirestoreMemberData[];
  updateTimestamp: Timestamp;
}

export function MembersList({ limit: memberLimit = 10 }) {
  const [members, setMembers] = useState<MemberPublicData[]>([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<MovementStats | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch stats
        const statsDoc = await getDoc(doc(db, 'movement/stats'));
        if (statsDoc.exists()) {
          const statsData = statsDoc.data() as MovementStats & { lastUpdateTimestamp: Timestamp };
          setStats({
            totalMembers: statsData.totalMembers || 0,
            totalCountries: statsData.totalCountries || [],
            operationalFundsCollected: statsData.operationalFundsCollected || 0,
            treasuryFundsCollected: statsData.treasuryFundsCollected || 0,
            lastMemberNumber: statsData.lastMemberNumber || 0,
            lastUpdateTimestamp: statsData.lastUpdateTimestamp?.toDate() || new Date(),
          });
        }

        // Calculate which document to fetch based on total members
        const totalMembers = statsDoc.exists() ? statsDoc.data().totalMembers || 0 : 0;
        const currentListNumber = Math.floor((totalMembers - 1) / 1000);
        const listNumberStr = currentListNumber.toString().padStart(4, '0');
        
        // Updated path for members document
        const membersDoc = await getDoc(doc(db, 'members', listNumberStr));
        
        if (membersDoc.exists()) {
          const data = membersDoc.data() as MembersListDocument;
          if (data?.members && Array.isArray(data.members)) {
            const convertedMembers = data.members
              .map((member: FirestoreMemberData) => ({
                ...member,
                timestamp: member.timestamp.toDate(),
              }))
              .slice(-memberLimit)
              .reverse();
            
            setMembers(convertedMembers);
          }
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        setError(error instanceof Error ? error : new Error('Failed to fetch members'));
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [memberLimit]);

  if (error) {
    return (
      <div className="text-center text-red-500">
        <p>Failed to load members. Please try again later.</p>
      </div>
    );
  }

  if (loading) {
    return <MembersListSkeleton count={memberLimit} />;
  }

  return (
    <div className="space-y-8">
      {stats && (
        <div className="grid grid-cols-2 gap-6">
          <Card className="p-6 text-center">
            <div className="text-4xl font-bold text-primary mb-2">
              {stats.totalMembers.toLocaleString()}
            </div>
            <div className="text-sm font-medium text-muted-foreground">
              {t('members.stats.membersLabel')}
            </div>
          </Card>
          <Card className="p-6 text-center">
            <div className="text-4xl font-bold text-primary mb-2">
              {stats.totalCountries.length.toLocaleString()}
            </div>
            <div className="text-sm font-medium text-muted-foreground">
              {t('members.stats.countriesLabel')}
            </div>
          </Card>
        </div>
      )}

      <div className="space-y-3">
        {members.map((member) => (
          <Link 
            key={member.memberNumber}
            to={member.userId ? `/profile/${member.userId}` : '#'}
            className="block"
          >
            <Card className="p-4 hover:bg-accent/50 transition-colors">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-6">
                  {member.userId && (
                    <img
                      src={`/profile-photos/${member.userId}/thumbnail.jpg`}
                      alt={member.displayName}
                      className="w-10 h-10 rounded-full object-cover"
                      onError={(e) => {
                        (e.target as HTMLImageElement).style.display = 'none';
                      }}
                    />
                  )}
                  <div className="flex items-center gap-2">
                    <CountryFlag countryId={member.displayCountry || ''} />
                    <span className="font-medium">
                      {member.displayName || `Member #${member.memberNumber}`}
                    </span>
                    {(member.displayCity || member.displayCountry) && (
                      <span className="text-muted-foreground">
                        {[member.displayCity, member.displayCountry]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-sm text-muted-foreground">
                  {formatDistanceToNow(member.timestamp, { addSuffix: true })}
                </div>
              </div>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}

function MembersListSkeleton({ count }: { count: number }) {
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-6">
        <Card className="p-6">
          <Skeleton className="h-10 w-full mb-2" />
          <Skeleton className="h-4 w-2/3 mx-auto" />
        </Card>
        <Card className="p-6">
          <Skeleton className="h-10 w-full mb-2" />
          <Skeleton className="h-4 w-2/3 mx-auto" />
        </Card>
      </div>
      <div className="space-y-3">
        {Array.from({ length: count }).map((_, i) => (
          <Card key={i} className="p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-6">
                <Skeleton className="h-4 w-12" />
                <Skeleton className="h-4 w-32" />
              </div>
              <Skeleton className="h-4 w-24" />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
} 