import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useUserStore } from '@/stores/useUserStore';
import { useStripePayment } from '@/hooks/useStripePayment';
import { RecentJoins } from '@/features/chain/components/RecentJoins';
import { ChainStatus } from '@/features/chain/components/ChainStatus';
import { InfoIcon } from "lucide-react";
import { useTranslation } from 'react-i18next';

const cardVariants = {
  hidden: { 
    opacity: 0, 
    y: 20 
  },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.3
    }
  }
};

export function Dashboard() {
  const { t } = useTranslation();
  const { profile } = useUserStore();
  const { handleVerificationPayment, loading } = useStripePayment();

  const handleJoinClick = async () => {
    if (!profile) return;
    
    try {
      await handleVerificationPayment(profile.email, {
        userId: profile.id,
        email: profile.email,
        fullName: profile.fullName || ''
      });
    } catch (error) {
      console.error('Error starting verification:', error);
    }
  };

  return (
    <div className="space-y-8 px-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Content - Recent Joins */}
        <div className="lg:col-span-2">
          <RecentJoins />
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Show ChainStatus for verified users */}
          {profile?.verified && (
            <motion.div variants={cardVariants} initial="hidden" animate="visible">
              <ChainStatus />
            </motion.div>
          )}

          {/* Verification Card for unverified users */}
          {profile && !profile.verified && (
            <motion.div variants={cardVariants} initial="hidden" animate="visible">
              <Card className="border-primary/10">
                <CardHeader>
                  <CardTitle>{t('dashboard.verification.title')}</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="flex items-start gap-3">
                      <InfoIcon className="h-5 w-5 text-primary shrink-0 mt-0.5" />
                      <div>
                        <p className="text-sm text-muted-foreground">
                          {t('dashboard.verification.subtitle')}
                        </p>
                      </div>
                    </div>
                    <Button 
                      onClick={handleJoinClick}
                      className="w-full"
                      disabled={loading}
                    >
                      {loading ? t('common.processing') : t('chain.join.button')}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
}