import { useState } from 'react';
import { db } from '@/config/firebase';
import { query, collection, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useUserStore } from '@/stores/useUserStore';

export function VerificationRecovery() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { profile } = useUserStore();
  
  const handleRecovery = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      // Find pending verification by email
      const q = query(
        collection(db, 'pendingVerifications'),
        where('email', '==', email),
        where('status', '==', 'paid')
      );
      
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const verificationDoc = querySnapshot.docs[0];
        
        // If user is logged in, update the verification with current user data
        if (profile) {
          await updateDoc(doc(db, 'pendingVerifications', verificationDoc.id), {
            userId: profile.id,
            fullName: profile.fullName
          });
        }

        setSuccess(true);
        // Redirect to success page with verification data
        window.location.href = `/verification/success?verification_id=${verificationDoc.id}&email=${email}`;
      } else {
        setError('No pending verification found for this email');
      }
    } catch (error: any) {
      console.error('Recovery error:', error);
      setError(error.message || 'Failed to recover verification');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full px-4 md:px-8 py-24">
      <Card className="mx-auto max-w-md w-full">
        <CardHeader>
          <CardTitle>Recover Verification</CardTitle>
          <p className="text-muted-foreground">
            Already paid but haven't completed signup? Recover your verification here.
          </p>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleRecovery} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="recovery-email">Email Used for Payment</Label>
              <Input
                id="recovery-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your payment email"
                required
              />
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {success && (
              <Alert>
                <AlertDescription>
                  Verification found! Redirecting to complete your signup...
                </AlertDescription>
              </Alert>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Checking...' : 'Recover Verification'}
            </Button>

            <p className="text-sm text-center text-muted-foreground">
              Haven't verified yet?{' '}
              <Button
                variant="link"
                className="p-0 h-auto"
                onClick={() => window.location.href = '/dashboard'}
              >
                Start verification
              </Button>
            </p>
          </form>
        </CardContent>
      </Card>
    </div>
  );
} 