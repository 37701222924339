export const es = {

  hero: {
    title: "OpenAI Ya No Es Abierta.",
    subtitle: "Este Es el Único Movimiento Que Pone a la Humanidad Primero.",
    chainCall: "Control por las Personas, No por las Corporaciones."
  },

  problem: {
    category: "Los Hechos",
    title: "ChatGPT: 300M+ Usuarios",
    subtitle: "$157B+ Valoración",
    subheadline: "0% Propiedad de los Usuarios"
  },

  problem2: {
    title: "Estamos Construyendo Ctrl AI",
    subtitle: "99% en Manos de los Usuarios. Eso Significa Tú."
  },

  opportunity: {
    points: {
      control: "Empiezas con 300 Puntos de Control",
      bring: "Conecta UNA Persona en 24 Horas",
      fail: "Fallar = Perder 200 Puntos para Siempre"
    },
    note: "Esta Vez, Realmente Posees Lo Que Ayudas a Construir."
  },

  howItWorks: {
    title: "La Alternativa Real",
    points: [
      {
        title: "Fase 1 / Unir 1M de Humanos",
        bullets: [
          "Sin capital de riesgo, sin control corporativo",
          "Poder del pueblo, eslabón por eslabón",
          "Cada usuario es un verdadero propietario"
        ]
      },
      {
        title: "Fase 2 / Financiar 100 Equipos",
        bullets: [
          "Reconstruir las aplicaciones que usamos a diario. Aplicaciones que extraen nuestros datos y los venden al mejor postor",
          "Construir herramientas de IA que realmente poseemos",
          "Control democrático de las funciones",
          "Tus datos siguen siendo tuyos"
        ]
      },
      {
        title: "Fase 3 / Crecer a 1B de Humanos",
        bullets: [
          "Verdadera alternativa a la IA corporativa",
          "Voz global en el desarrollo de IA",
          "Poder real a través de la propiedad real"
        ]
      }
    ]
  },

  cta: {
    category: "La Elección",
    title: "Abierto vs Control",
    subtitle: "OpenAI nos mostró lo que sucede cuando 'abierto' se convierte en cerrado. Ctrl AI garantiza que eso no vuelva a pasar al dar el 99% de la propiedad a los usuarios desde el primer día.",
    subheadline: "¿Tomarás el control?",
    citation: "Soy Rado. Construí Ctrl AI porque vi la transformación de OpenAI de una organización sin fines de lucro a un gigante de $157 mil millones. Esta vez, construyamos algo que realmente poseamos juntos.",
    buttons: {
      primary: "Toma el Control ($1)",
      secondary: "Seguir sin poder"
    },
    note: "$1 prueba que eres humano. Sin bots, sin inversionistas—solo personas unidas."
  },


  aspiringFounders: {
    intro: {
      category: "Fundadores y Creadores",
      title: "Construye tu Visión Bajo Ctrl AI",
      subtitle: "Convierte tu idea de IA o aplicación en realidad con el apoyo de una comunidad global. Sin inversores depredadores, sin perseguir ingresos publicitarios—solo innovación con propósito, plenamente alineada con el bienestar del usuario.",
      citation: "“¿Por qué buscar capital de riesgo cuando puedes aprovechar una base de usuarios copropietarios desde el primer día?”",
      pointsTitle: "¿Por Qué Construir con Ctrl AI?",
      points: [
        {
          headline: "Centrado en el Usuario, No en los Anuncios",
          text: "Olvida los algoritmos manipuladores—nuestra Constitución exige transparencia y utilidad genuina."
        },
        {
          headline: "90% Propiedad Comunitaria",
          text: "Conserva hasta un 10% para tu equipo fundador mientras la mayor parte pertenece a quienes realmente impulsan el proyecto."
        },
        {
          headline: "Evangelistas Incorporados",
          text: "Desde el primer día, tendrás miles de embajadores que son copropietarios, listos para compartir y apoyar tu producto."
        }
      ],
      conclusion: "Si te motiva crear de manera ética y abierta, Ctrl AI ofrece la plataforma, el financiamiento y la base de usuarios que necesitas—todo bajo un marco que prioriza el bienestar humano."
    },

    pitchProcess: {
      category: "Propuesta y Financiamiento",
      title: "Tu Camino hacia el Éxito Respaldado por la Comunidad",
      subtitle: "Una vez que Ctrl AI alcance los primeros 100k Miembros Fundadores, podrás presentar tu proyecto a nuestra comunidad global. Si es aprobado, recibirás financiamiento mensual, retroalimentación activa de los usuarios y una red de socios dispuesta a verte triunfar.",
      citation: "“Obtén hasta $10k/mes por fundador una vez que tu idea sea aprobada por la comunidad—sin ataduras más allá de cumplir con la Constitución de Ctrl AI.”",
      pointsTitle: "Cómo Funciona:",
      points: [
        {
          headline: "Propón tu Idea",
          text: "Envía una propuesta breve detallando tu visión, equipo y cómo cumplirás con el modelo 90%/10% (al menos el 20% destinado al núcleo de Ctrl AI y el 70% a los seguidores directos)."
        },
        {
          headline: "Votación Comunitaria",
          text: "Los miembros de Ctrl AI revisan y votan tu proyecto. Si alcanzas el umbral de apoyo necesario, obtendrás financiamiento."
        },
        {
          headline: "Salario Mensual",
          text: "Cada fundador aprobado puede recibir $10k/mes, financiado por los fondos aportados por los miembros—para que puedas enfocarte en construir, no en recaudar fondos."
        }
      ],
      conclusion: "Sin rondas interminables de capital de riesgo. Sin muros de pago. Solo apoyo tangible y respaldado por los usuarios. Cuando tu proyecto crezca, tus copropietarios crecerán contigo."
    },

    buildingInPublic: {
      category: "Construcción Pública",
      title: "Comparte Hitos, Éxitos y Lecciones Semanales",
      subtitle: "Las actualizaciones regulares mantienen a tus seguidores y copropietarios informados—fomentando la transparencia, la responsabilidad y un sentido de entusiasmo colectivo.",
      citation: "“Cuando la comunidad tiene algo en juego, los éxitos—y desafíos—de tu proyecto son experiencias compartidas genuinamente.”",
      pointsTitle: "Principios Básicos:",
      points: [
        {
          headline: "Rutas Abiertas",
          text: "Publica tu cronograma de desarrollo, características principales y objetivos a largo plazo para que todos sepan qué se viene."
        },
        {
          headline: "Pruebas y Retroalimentación de la Comunidad",
          text: "Integra bucles de retroalimentación temprana y frecuente. Tus copropietarios pueden detectar problemas, perfeccionar funciones y promover tu crecimiento."
        },
        {
          headline: "Informes de Progreso Transparentes",
          text: "Comparte instantáneas semanales o mensuales—destacando logros, desafíos y próximos pasos—para mantener a los interesados comprometidos."
        }
      ],
      conclusion: "Es más que solo actualizaciones de software; es una invitación para que tu comunidad realmente moldee y celebre tu viaje. El compromiso auténtico fomenta la lealtad y mejores productos."
    },

    callToAction: {
      category: "Comienza",
      title: "¿Listo para Lanzar tu Proyecto de IA?",
      subtitle: "Únete a una comunidad comprometida con la innovación ética y de propiedad de los usuarios. Propón tu idea, asegura financiamiento y construye con el respaldo de miles—sin ataduras corporativas.",
      pointsTitle: "Próximos Pasos:",
      points: [
        {
          headline: "Alcanza los 100k Miembros Fundadores",
          text: "Una vez que consolidemos nuestra primera ola de miembros, se abrirá la plataforma de propuestas—comparte Ctrl AI para alcanzar ese hito más rápido."
        },
        {
          headline: "Prepara tu Propuesta",
          text: "Comienza a redactar tu concepto, reúne a tu equipo (o trabaja solo) y finaliza cómo cumplirás con la estructura de propiedad 90%/10%."
        },
        {
          headline: "Involúcrate Temprano y Frecuentemente",
          text: "Únete a nuestros foros comunitarios para recibir retroalimentación inicial sobre tu idea. Cuantas más conexiones construyas ahora, más fluido será el proceso de propuesta."
        }
      ],
      conclusion: "**Ctrl AI es donde se forman las aplicaciones transformadoras del mañana—respaldadas por una comunidad global, financiamiento transparente y una ética centrada en el usuario.**"
    }
  },


  foundingMembers: {
    intro: {
      category: "Únete al Movimiento",
      title: "Conviértete en Miembro Fundador de Ctrl AI",
      subtitle: "Asegura tu lugar en una coalición global que está dando forma al futuro de la IA—sin entregar todo el poder a un puñado de gigantes tecnológicos.",
      citation: "Los Miembros Fundadores son el corazón de Ctrl AI. Dan forma a nuestra Constitución, votan en decisiones importantes y marcan el tono de una revolución de IA impulsada por las personas.",
      pointsTitle: "¿Por Qué Convertirse en Miembro Fundador?",
      points: [
        {
          headline: "Gobernanza Exclusiva Temprana",
          text: "Los Miembros Fundadores ganan Puntos de Control diarios, lo que les permite influir en proyectos clave de IA antes que nadie."
        },
        {
          headline: "Cupos Globales",
          text: "Asignamos lugares según el tamaño del país, asegurando que la voz de cada nación se escuche desde el primer día."
        },
        {
          headline: "Sin Anuncios, Sin VC, Pura Comunidad",
          text: "Tus cuotas de membresía financian nuestra plataforma—sin accionistas corporativos. Es verdadera democracia para la IA."
        }
      ],
      conclusion: "¿Listo para ayudar a construir un futuro de IA justo y de propiedad comunitaria? Únete a nuestra lista de espera y comienza a ganar tu lugar como Miembro Fundador hoy mismo."
    },

    waitlistMechanics: {
      category: "Lista de Espera y Puntos",
      title: "Cómo Funciona la Lista de Espera",
      subtitle: "Creemos en construir un movimiento de verdaderos creyentes—no de espectadores pasivos. Por eso invitamos a los principales contribuyentes de la lista de espera a convertirse en Miembros Fundadores.",
      citation: "“Gana tu lugar compartiendo, colaborando y demostrando un compromiso genuino con la visión de Ctrl AI.”",
      pointsTitle: "Cómo Ganar Puntos:",
      points: [
        {
          headline: "Completa tu Perfil",
          text: "Proporciona información básica para que sepamos quién eres y qué región representas."
        },
        {
          headline: "Invita a Amigos y Comparte en Redes Sociales",
          text: "Ofrecemos publicaciones preescritas que puedes personalizar, luego pega el enlace de prueba en tu panel. Nuestro equipo lo verificará manualmente y te otorgará puntos."
        },
        {
          headline: "Participa en la Comunidad",
          text: "Pronto podrás participar en preguntas y respuestas, agregar comentarios a propuestas o ayudar a responder preguntas de nuevos miembros. Cada acción significativa puede mejorar tu rango."
        }
      ],
      conclusion: "Una vez que alcances un cierto umbral de puntos o estés entre los principales contribuyentes, te invitaremos a activar tu estado de Miembro Fundador."
    },

    callToAction: {
      category: "Actúa",
      title: "Reclama tu Lugar y Da Forma al Futuro",
      subtitle: "Revisamos regularmente la lista de espera y seleccionamos nuevos Miembros Fundadores. Demuestra tu compromiso y ayuda a difundir el mensaje para escalar posiciones más rápido.",
      pointsTitle: "¿Qué Sucede Después de ser Elegido?",
      points: [
        {
          headline: "Cuota de Membresía: $1",
          text: "Comienza tu membresía oficial. Esta pequeña cuota mantiene a Ctrl AI independiente—sin anuncios ni vínculos con capital de riesgo."
        },
        {
          headline: "Puntos de Control Diarios",
          text: "Gana poder de gobernanza por cada día de membresía. Influye en todo, desde las características de las aplicaciones hasta las directrices éticas."
        },
        {
          headline: "Votación y Ratificación de la Constitución",
          text: "Ayuda a finalizar y hacer cumplir nuestra Constitución vinculante. Si alguna vez nos desviamos, tienes el derecho (y la responsabilidad) de corregir el rumbo."
        }
      ],
      conclusion: "**Construyamos una plataforma de IA verdaderamente propiedad de sus usuarios.** Únete a la lista de espera hoy, participa para ganar puntos y conviértete en uno de los primeros en tomar Ctrl de la IA."
    }
  },

  ownership: {
    intro: {
      category: "Propiedad",
      title: "El Poder de la Propiedad Colectiva",
      subtitle: "Ctrl AI es 99% propiedad de los usuarios—asegurando que ninguna corporación, inversor o país pueda dominar nuestra plataforma. Al distribuir la propiedad entre millones (eventualmente miles de millones) de miembros, creamos un ecosistema de IA genuinamente democrático.",
      citation: "“La propiedad en Ctrl AI no es solo una palabra de moda—es tu derecho a dirigir nuestro futuro, dar forma a nuestras políticas y compartir el valor que creamos juntos.”",
      pointsTitle: "¿Por Qué Esto Importa?",
      points: [
        {
          headline: "Pesos y Contrapesos",
          text: "Cuando el 99% de la propiedad está distribuida entre los usuarios, ninguna minoría puede imponer decisiones que perjudiquen a la comunidad."
        },
        {
          headline: "Responsabilidad Real",
          text: "Los usuarios tienen poder de veto a través de votos y pueden exigir transparencia, asegurando un desarrollo ético y justo."
        },
        {
          headline: "Estabilidad a Largo Plazo",
          text: "Las plataformas impulsadas por la comunidad son más resistentes a los caprichos del mercado y a los accionistas ávidos de beneficios."
        }
      ],
      conclusion: "Como miembro, no solo usas Ctrl AI—también lo posees y lo guías. Así prevenimos el Colonialismo de IA y aseguramos que la IA siga siendo impulsada por las personas."
    },
  
    controlPoints: {
      category: "Puntos de Control",
      title: "Propiedad Continua y Poder de Gobernanza",
      subtitle: "Cada miembro activo gana 1 Punto de Control por día—acumulándose continuamente mientras mantengas tu suscripción activa. Estos Puntos de Control te otorgan influencia directa sobre las decisiones importantes de la plataforma.",
      citation: "“Únete temprano y mantente activo: cuanto más tiempo seas miembro, más Puntos de Control acumularás y mayor será tu influencia en la evolución de Ctrl AI.”",
      pointsTitle: "Cómo Funciona:",
      points: [
        {
          headline: "Acumulación Diaria",
          text: "Tus Puntos de Control crecen en 1 cada día que tu membresía esté activa. No hay un límite máximo—los miembros de larga data naturalmente obtienen más influencia."
        },
        {
          headline: "Votación y Propuestas",
          text: "Usa los Puntos de Control para votar sobre nuevas propuestas de funciones, directrices éticas, asignaciones de presupuesto y más."
        },
        {
          headline: "Conversión Opcional a On-Chain",
          text: "Después de 2 años, puedes convertir tus Puntos de Control en tokens, pero esto es completamente opcional. Si lo prefieres, puedes mantenerte fuera de la cadena de bloques."
        }
      ],
      conclusion: "Los Puntos de Control están diseñados principalmente para la gobernanza, no para la especulación. Reflejan tu compromiso continuo y te otorgan una verdadera voz en cómo Ctrl AI evoluciona."
    },

    communityModel: {
      category: "Modelo Centrado en el Usuario",
      title: "99% Propiedad Comunitaria",
      subtitle: "Dividimos la propiedad de una manera que empodera a los usuarios sin perjudicar el desarrollo. Las decisiones importantes—desde lanzamientos de aplicaciones hasta la privacidad de los datos—siempre estarán en manos de la mayoría.",
      citation: "“Imagina un futuro donde tus herramientas de IA realmente te pertenezcan, no a conglomerados impulsados por anuncios o capitalistas de riesgo.”",
      pointsTitle: "Características Clave de Nuestro Modelo:",
      points: [
        {
          headline: "Proyectos de Fundadores (90/10)",
          text: "Los subproyectos se construyen bajo Ctrl AI con un mínimo del 90% de propiedad comunitaria y un 10% para el equipo fundador—manteniendo los intereses de los usuarios en el centro."
        },
        {
          headline: "Gobernanza Directa",
          text: "Vota en decisiones importantes, propone cambios y da forma al futuro de Ctrl AI."
        },
        {
          headline: "Representación Global",
          text: "Los cupos de membresía por país aseguran que los votos de una sola región no puedan eclipsar a otros. La propiedad se distribuye a nivel mundial."
        }
      ],
      conclusion: "Al limitar las participaciones individuales y distribuir el resto entre millones de usuarios, aseguramos que Ctrl AI siga siendo un ecosistema global, sin fronteras y centrado en los usuarios."
    },

    callToAction: {
      category: "Toma Acción",
      title: "Sé Dueño de tu Futuro con Ctrl AI",
      subtitle: "No te conformes con un mundo donde la IA está monopolizada. Únete a Ctrl AI, gana Puntos de Control diariamente y mantén el desarrollo de la IA honesto, centrado en los humanos y transparente.",
      pointsTitle: "Próximos Pasos:",
      points: [
        {
          headline: "Únete a la Lista de Espera",
          text: "Asegura tu lugar como miembro y comienza a acumular Puntos de Control tan pronto como seas invitado."
        },
        {
          headline: "Comparte y Gana Puntos de la Lista de Espera",
          text: "Mejora tu rango invitando a amigos y difundiendo el mensaje—ayúdanos a alcanzar más rápido nuestros objetivos de membresía global."
        },
        {
          headline: "Da Forma a la IA del Mañana",
          text: "Una vez dentro, usa tus Puntos de Control acumulados para votar, proponer cambios y mantener nuestra plataforma fiel a su misión."
        }
      ],
      conclusion: "**La propiedad es poder**—y en Ctrl AI, ese poder te pertenece. Regístrate, mantente activo y ayúdanos a reescribir las reglas de la propiedad de la IA para todos."
    }
  },


  constitutionPage: {
    hero: {
      title: "La Constitución de Ctrl AI",
      preamble: "En una era donde la tecnología cada vez más moldea el futuro de la humanidad, elegimos un camino diferente. **No solo mejores productos, sino mejores principios. No solo innovación, sino progreso ético. No solo participación de usuarios, sino bienestar humano.**"
    },
    principles: {
      title: "Nuestros Principios Vinculantes",
      intro: "Estos no son solo lineamientos o sugerencias—son compromisos vinculantes que cada proyecto bajo Ctrl AI debe seguir, respaldados por la supervisión de la comunidad y auditorías regulares:",
      principles: [
        {
          title: "Primero el Bienestar Humano",
          items: [
            "Diseñar para el verdadero bienestar humano, no métricas de participación",
            "Tolerancia cero a los patrones oscuros o la manipulación psicológica",
            "Tratar la atención del usuario como un recurso valioso que debe respetarse",
            "Construir puntos naturales de parada y patrones de uso saludables",
            "Priorizar la conexión significativa sobre la participación adictiva"
          ]
        },
        {
          title: "Derechos Absolutos de los Datos",
          items: [
            "Los usuarios son dueños completos de sus datos—sin excepciones",
            "Prohibida la venta de datos o el intercambio no autorizado",
            "Procesamiento local siempre que sea posible",
            "Cifrado de grado militar como estándar",
            "Derecho garantizado al olvido—completo y permanente"
          ]
        },
        {
          title: "Poder Real de la Comunidad",
          items: [
            "Propiedad comunitaria mínima del 90%—garantizada constitucionalmente",
            "Gobernanza democrática directa en decisiones importantes",
            "Votos vinculantes de la comunidad sobre la dirección del producto",
            "Distribución justa y matemática de tokens",
            "Igualdad de derechos sin importar el tamaño de la contribución"
          ]
        },
        {
          title: "Límites Éticos para la IA",
          items: [
            "La IA debe servir a la agencia humana—nunca disminuirla",
            "Transparencia completa en los procesos de decisión de la IA",
            "Monitoreo y eliminación continua de sesgos",
            "Capacidad de anulación humana en todas las funciones de IA",
            "Desarrollo de IA enfocado en el empoderamiento humano"
          ]
        },
        {
          title: "Bienestar del Equipo",
          items: [
            "Semana laboral de cuatro días como derecho constitucional",
            "Apoyo integral a la salud mental",
            "Financiamiento continuo para el aprendizaje de todos",
            "Prohibida la explotación en tiempos de crisis",
            "Distribución equitativa de beneficios",
            "Trabajo remoto como opción principal",
            "Límites estrictos entre trabajo y vida personal",
            "Eventos regulares de conexión del equipo"
          ]
        },
        {
          title: "Primero la Educación",
          items: [
            "Valor educativo integrado en todos los productos",
            "Diseño y seguridad adecuados a la edad",
            "Promoción activa de la alfabetización digital",
            "Enfoque educativo de múltiples partes interesadas",
            "Prohibida la explotación de usuarios jóvenes",
            "Prohibición de la manipulación dirigida a menores"
          ]
        },
        {
          title: "Transparencia Radical",
          items: [
            "Actualizaciones de desarrollo semanales públicas",
            "Transparencia financiera completa",
            "Comunicación directa sobre desafíos",
            "Código abierto por defecto",
            "Sesiones regulares de participación comunitaria"
          ]
        },
        {
          title: "Desarrollo Sostenible",
          items: [
            "Crecimiento natural sobre viralidad artificial",
            "Expansión solo impulsada por la comunidad",
            "Prohibida la comercialización explotadora",
            "Consideración del impacto ambiental",
            "Sostenibilidad a largo plazo sobre ganancias a corto plazo"
          ]
        },
        {
          title: "Ingresos Justos",
          items: [
            "Cero ingresos basados en publicidad",
            "Precios transparentes basados en el valor",
            "Comunicación clara de costos y beneficios",
            "Distribución directa de beneficios a la comunidad",
            "Precios alineados con los costos únicamente"
          ]
        },
        {
          title: "Innovación con Propósito",
          items: [
            "Enfocarse en las verdaderas necesidades humanas",
            "Innovación solo al servicio de la comunidad",
            "Principios de colaboración abierta",
            "Tecnología al servicio de la humanidad",
            "Consideración del impacto social en todas las decisiones"
          ]
        }
      ]
    },
    enforcement: {
      title: "Aplicación Real, Responsabilidad Real",
      content: "**Estos principios no son promesas—son compromisos vinculantes con consecuencias reales:**",
      measures: [
        {
          title: "Supervisión Comunitaria",
          items: [
            "• Auditorías públicas trimestrales",
            "• Informes en tiempo real de violaciones",
            "• Sistemas de retroalimentación directa de la comunidad",
            "• Junta independiente de revisión ética"
          ]
        },
        {
          title: "Consecuencias Claras",
          items: [
            "• Respuesta inmediata a violaciones",
            "• Correcciones impulsadas por la comunidad",
            "• Remediación obligatoria",
            "• Informes públicos de responsabilidad"
          ]
        },
        {
          title: "Documento Vivo",
          items: [
            "• Revisión anual comunitaria",
            "• Actualizaciones adaptativas a la tecnología",
            "• Principios fundamentales inmutables",
            "• Proceso democrático de enmiendas"
          ]
        }
      ]
    },
    cta: {
      title: "Únete a la Revolución Tecnológica Ética",
      content: "**Esto no es solo la declaración de misión de otra empresa tecnológica. Es una constitución vinculante para construir tecnología que sirva a la humanidad.** Ya seas un fundador dispuesto a construir de manera diferente o un usuario listo para ser dueño de lo que usa, el futuro de la tecnología ética comienza aquí.",
      button: "Únete como Miembro Fundador ($1)"
    }
  },

  footer: {
    companyDescription: "Construyendo el futuro de la IA con propiedad comunitaria y principios éticos.",
    navigation: "Navegación",
    legal: "Legal",
    connect: "Otros",
    privacy: "Política de Privacidad",
    terms: "Términos del Servicio",
    cookies: "Política de Cookies",
    rights: "Todos los derechos reservados."
  },

  nav: {
    community: "Miembros Fundadores",
    founders: "Fundadores",
    ownership: "Propiedad",
    constitution: "Constitución",
    pricing: "Precios",
    dashboard: "Panel",
    myProfile: "Mi Perfil",
    settings: "Configuración",
    home: "Inicio",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Postúlate como Fundador",
    about: "Acerca de Ctrl AI",
    logout: "Cerrar sesión"
  },


  auth: {
    login: {
      title: "Bienvenido de Nuevo",
      subtitle: "Continúa tu camino para construir el futuro",
      emailPlaceholder: "Ingresa tu correo electrónico",
      passwordPlaceholder: "Ingresa tu contraseña",
      submitButton: "Iniciar Sesión",
      noAccount: "¿No tienes una cuenta?",
      signupLink: "Regístrate como Fundador",
      error: "Correo electrónico o contraseña inválidos"
    },
    signup: {
      title: "Únete como Fundador",
      subtitle: "Comienza a construir el futuro de la tecnología",
      namePlaceholder: "Ingresa tu nombre completo",
      emailPlaceholder: "Ingresa tu correo electrónico",
      passwordPlaceholder: "Elige una contraseña",
      submitButton: "Crear Cuenta",
      hasAccount: "¿Ya tienes una cuenta?",
      loginLink: "Inicia sesión",
      error: "Error al crear la cuenta"
    },
    errors: {
      passwordLength: "La contraseña debe tener al menos 6 caracteres",
      invalidEmail: "Por favor, ingresa una dirección de correo válida",
      emailInUse: "Este correo ya está en uso"
    },
    passwordRequirements: "La contraseña debe tener al menos 6 caracteres"
  },

  pricing: {
    title: "Únete al Movimiento",
    subtitle: "Únete al movimiento para construir y poseer el futuro de la tecnología",
    tiers: {
      founding: {
        title: "Miembro Fundador",
        price: "$1",
        description: "Para quienes quieren impulsar el cambio",
        features: [
          "1 Punto de Control diario",
          "Acceso prioritario a nuevos proyectos",
          "Da forma al futuro de la tecnología",
          "Insignia exclusiva de miembro fundador",
          "3 códigos de invitación para amigos"
        ]
      }
    },
    footer: {
      quote: "En veinte años, la gente se preguntará cómo alguien pudo perder esta oportunidad. No seas quien explique por qué esperaste."
    }
  },

  modals: {
    inviteCode: {
      title: "Canjea tu Código de Invitación",
      description: "Ingresa tu código de invitación para asegurar tu lugar como Miembro Fundador. Cada código solo puede ser utilizado una vez.",
      placeholder: "Ingresa el código de invitación",
      submit: "Únete como Miembro Fundador",
      invalidCode: "Código de invitación inválido o caducado. Por favor, inténtalo de nuevo.",
      success: "¡Bienvenido a Ctrl AI! Completando tu registro...",
      validating: "Validando...",
      alreadyUsed: "Este código ya ha sido usado",
      expired: "Este código ha caducado",
      validated: "¡Código validado! Redirigiendo al registro..."
    },
    waitlist: {
      title: "Únete a la Lista de Espera",
      description: "Únete a nuestra exclusiva lista de espera para obtener acceso anticipado a Ctrl AI.",
      namePlaceholder: "Tu nombre completo",
      emailPlaceholder: "Tu dirección de correo",
      passwordPlaceholder: "Crea una contraseña",
      submit: "Únete a la Lista de Espera",
      submitting: "Uniéndose...",
      error: "Algo salió mal. Por favor, inténtalo de nuevo.",
      success: "¡Bienvenido! Estás en la lista."
    }
  },

  waitlist: {
    status: {
      title: "Tu Estado en la Lista de Espera",
      totalInWaitlist: "De un total de {{total}} personas en la fila",
      referralCode: "Tu Código de Referencia",
      copyLink: "Copiar Enlace",
      referralDescription: "Comparte tu código de referencia con amigos para avanzar en la lista de espera. ¡Cada referencia te otorga 50 puntos!",
      points: "Progreso de Puntos"
    },
    actions: {
      twitter: "Compartir en Twitter",
      linkedin: "Compartir en LinkedIn",
      discord: "Unirse a Discord",
      profile: "Completa tu Perfil"
    }
  },

  onboarding: {
    title: "Bienvenido a Ctrl AI",
    subtitle: "Vamos a configurarte para el éxito",
    points: "puntos",
    continue: "Continuar",
    finish: "Completar Configuración",
    steps: {
      profile: {
        title: "Completa tu Perfil",
        description: "Agrega una foto de perfil y una biografía para que otros te conozcan."
      },
      social: {
        title: "Comparte tu Viaje",
        description: "Comparte tu entusiasmo por unirte a Ctrl AI con tu red."
      },
      community: {
        title: "Únete a la Comunidad",
        description: "Conéctate con otros miembros en nuestra comunidad de Discord."
      }
    }
  },

  dashboard: {
    welcome: "Bienvenido de nuevo, {{name}}",
    membershipTiers: {
      founding: "Miembro Fundador",
      collective: "Miembro Colectivo"
    },
    tabs: {
      projects: "Proyectos",
      community: "Comunidad",
      rewards: "Recompensas"
    },
    community: {
      title: "Actividad de la Comunidad",
      discussions: {
        title: "Discusiones Recientes",
        empty: "Aún no hay discusiones",
        start: "Iniciar una Discusión"
      },
      activity: {
        title: "Actividad Reciente",
        empty: "Sin actividad reciente"
      },
      stats: {
        members: "Miembros Activos",
        discussions: "Discusiones",
        contributions: "Contribuciones"
      }
    },
    projects: {
      title: "Proyectos Activos",
      empty: "Sin proyectos activos",
      explore: "Explorar Proyectos",
      create: "Crear Proyecto"
    },
    rewards: {
      title: "Tus Recompensas",
      points: "Puntos de Control",
      achievements: "Logros",
      empty: "Aún no tienes recompensas"
    },
    quickActions: {
      title: "Acciones Rápidas",
      createProject: "Crear Proyecto",
      startDiscussion: "Iniciar Discusión",
      updateProfile: "Actualizar Perfil"
    }
  },

  global: {
    title: "Observa Crecer Nuestra Lista de Espera Globalmente",
    subtitle: "Crecimiento Comunitario en Tiempo Real",
    content: "Cada punto es alguien compitiendo por un lugar como Miembro Fundador. ¿Aún no tienes código de invitación? Únete a la lista de espera y mejora tu rango completando desafíos.",
    stats: {
      totalMembers: "Miembros Totales",
      activeToday: "Activos Hoy",
      averagePoints: "Puntos Promedio"
    },
    controls: {
      pause: "Pausar e Interactuar",
      resume: "Reanudar Recorrido",
      next: "Siguiente Continente"
    }
  },

  quotaMap: {
    stats: {
      totalQuota: "Cupo Global Total",
      totalRegistered: "Total Registrado",
      totalPopulation: "Población Total",
      countryCount: "Países Representados",
      fulfillment: "Cumplimiento Global"
    },
    tooltip: {
      country: "País",
      population: "Población",
      quota: "Cupo de Miembros",
      registered: "Miembros Registrados",
      fulfillment: "Cumplimiento del Cupo"
    },
    legend: {
      title: "Cumplimiento del Cupo",
      low: "Bajo",
      target: "Objetivo",
      exceeded: "Excedido"
    },
    controls: {
      reset: "Restablecer Vista",
      zoom: "Acercar",
      pan: "Mover"
    },
    noData: "No hay datos disponibles para este país",
    loading: "Cargando datos del mapa..."
  },
  signupForm: {
    title: {
      waitlist: "Unirse a la lista de espera",
      verified: "Crear cuenta"
    },
    subtitle: {
      waitlist: "Únete a nuestra lista de espera exclusiva",
      verified: "Has sido invitado como miembro fundador"
    },
    fields: {
      fullName: "Nombre completo",
      email: "Correo electrónico",
      password: "Contraseña"
    },
    buttons: {
      submit: "Continuar",
      submitting: "Creando cuenta...",
      google: "Continuar con Google"
    },
    errors: {
      emailExists: "Este correo ya está en la lista de espera. Por favor, revisa tu correo para las instrucciones de verificación.",
      emailInUse: "Este correo ya está registrado. Por favor, inicia sesión.",
      waitlistCheck: "No se pudo verificar el estado de la lista de espera. Por favor, inténtalo de nuevo.",
      createAccount: "No se pudo crear la cuenta. Por favor, inténtalo de nuevo."
    },
    inviteCode: {
      prompt: "¿Tienes un código de invitación?",
      button: "Ingresar código de invitación"
    }
  },
  waitlistDashboard: {
    title: "Estado de tu Lista de Espera",
    position: "Posición {{position}}",
    total: "De {{total}} personas",
    stats: {
      referrals: "Referencias",
      points: "Puntos",
      status: "Estado",
      active: "Activo",
      position: "Posición",
      completion: "Completado",
      earnMore: "Gana más puntos",
      completeProfile: "Completa tu perfil para subir de posición",
      total: "De {{total}} personas en total"
    },
    breadcrumbs: {
      waitlist: "Lista de Espera",
      dashboard: "Panel"
    },
    welcome: {
      title: "Bienvenido",
      location: "{{city}}, {{country}}"
    },
    overview: {
      journey: "Tu Camino hacia la Membresía Fundadora",
      profileCompletion: "Completado del Perfil",
      socialSharing: "Compartir en Redes",
      tasks: {
        title: "Tareas Disponibles",
        completeProfile: "Completa tu Perfil",
        completeProfileDesc: "Añade tu información básica para que podamos conocerte mejor",
        addLocation: "Añade tu Ubicación",
        addLocationDesc: "Indícanos dónde te encuentras"
      }
    },
    tabs: {
      overview: "Resumen",
      tasks: "Ganar Puntos",
      social: "Compartir y Ganar"
    },
    socialShare: {
      title: "Comparte y Gana Puntos",
      description: "Comparte Ctrl AI en tus redes sociales para ganar puntos y mejorar tu posición",
      verificationPending: "Tu publicación en {{platform}} ha sido enviada para verificación"
    },
    progress: {
      items: {
        photo: "Foto de Perfil",
        basicInfo: "Información Básica",
        location: "Ubicación",
        skills: "Habilidades e Intereses"
      }
    }
  },

  userProfile: {
    title: "Tu Perfil",
    breadcrumbs: {
      waitlist: "Lista de espera",
      dashboard: "Panel",
      profile: "Perfil"
    },
    fields: {
      fullName: "Nombre completo",
      location: "Ubicación",
      bio: "Cuéntanos sobre ti..."
    },
    buttons: {
      save: "Guardar perfil",
      saving: "Guardando..."
    },
    completion: {
      title: "Completado del perfil",
      status: {
        complete: "¡Tu perfil está completo! Gracias por proporcionar toda la información.",
        incomplete: "¡Completa tu perfil para ganar puntos adicionales y mejorar tu posición en la lista de espera!"
      }
    },
    toast: {
      success: {
        withPoints: "¡Perfil actualizado! ¡Has ganado {{points}} puntos!",
        default: "Perfil actualizado con éxito"
      },
      error: "No se pudo actualizar el perfil. Por favor, inténtalo de nuevo."
    }
  },

  progressSection: {
    complete: "Completado",
    points: "+{{points}} puntos",
    viewDetails: "Ver Detalles",
    completeTasks: "Completar Tareas",
    percentComplete: "{{percent}}% Completado"
  }
};