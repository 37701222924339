import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export interface PaymentMetadata {
  userId: string;
  email: string;
  fullName?: string;
  coverOperationalCosts: boolean;
  type: 'verification';
}

export interface VerificationPaymentOptions {
  userId: string;
  email: string;
  fullName?: string;
  coverOperationalCosts: boolean;
}

export function useStripePayment() {
  const [loading, setLoading] = useState(false);

  const handleVerificationPayment = async (email: string, options: VerificationPaymentOptions) => {
    try {
      setLoading(true);
      console.log('Starting verification payment process...', { email, options });

      const functionUrl = `${import.meta.env.VITE_FIREBASE_FUNCTIONS_URL}/createCheckout`;
      
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email,
          metadata: {
            userId: options.userId,
            email: options.email,
            fullName: options.fullName,
            coverOperationalCosts: options.coverOperationalCosts,
            type: 'verification'
          }
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', errorText);
        throw new Error(`Failed to create checkout session: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.sessionId) {
        throw new Error('No session ID in response');
      }

      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error('Stripe failed to load');
      }

      const { error } = await stripe.redirectToCheckout({ 
        sessionId: data.sessionId 
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Payment Error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { handleVerificationPayment, loading };
} 