export const ja = {
  hero: {
    title: "AIに支配される前にAIをコントロールしましょう",
    subtitle: "“AI植民地主義”を止めるために世界中の何百万もの人々と団結し、未来のすべての仕事、産業、イノベーションを形作る技術を共同所有しましょう。",
  },

  problem: {
    category: "緊急性",
    title: "AI植民地主義が私たち全員に脅威を与える理由",
    subtitle: "いくつかの巨大テクノロジー企業は、すでに私たちのデータ、注意、そしてイノベーションを利用して前例のない権力を手にしています。AIが人間の労働を置き換え続ける中で、富と支配は一部の巨大企業に集中します—私たちが今行動しない限り。",
    citation: "もし私たちがAIを共同所有するために世界的に団結しなければ、一握りの巨大企業が労働、富、イノベーションの未来を独占してしまいます。",
    pointsTitle: "これが重要な理由:",
    points: [
      {
        headline: "急増する格差",
        text: "AIが人間の労働者を上回ると、AIの所有者は利益を得る一方で、何十億もの人々は生き残りをかけて苦しむことになります。"
      },
      {
        headline: "主権の喪失",
        text: "多くの国がデジタル植民地化され、シリコンバレーのAI拠点に影を落とされるリスクがあります。"
      },
      {
        headline: "政府だけでは解決できない",
        text: "テクノロジー企業は規制の遅れをつく迅速な行動と積極的なロビー活動を行っています。"
      }
    ],
    conclusion: "解決策: 8億人がAIの未来を共有する市民主導のムーブメント—Ctrl AI—を立ち上げましょう。"
  },

  opportunity: {
    title: "Ctrl AIの核心的使命",
    subtitle: "AI植民地主義を阻止し、世界中の8億人に高度なAIの共同所有権を提供することで、大手テック企業の独占を終わらせます。",
    benefitsTitle: "私たちが掲げる価値",
    benefits: [
      {
        headline: "グローバルな包括性",
        text: "人口に基づいて席を割り当てます。すべての国に発言権があります。"
      },
      {
        headline: "コミュニティ所有",
        text: "プラットフォームの99%はメンバーのもの—少数のエリートが支配することはありません。"
      },
      {
        headline: "倫理的で透明なAI",
        text: "ブラックボックスによる操作やプライバシー侵害を一切行いません。"
      },
      {
        headline: "広告なし、ベンチャーキャピタルなし",
        text: "メンバーの支援だけで運営するため、投資家や広告主ではなく、あなたに応えます。"
      }
    ],
  },

  howItWorks: {
    title: "仕組み: 簡単な概要",
    points: [
      {
        title: "創設メンバーとして参加 ($1/月)",
        bullets: [
          "コントロールポイントをロックイン—投票権と所有権を獲得。",
          "AI憲法と将来のアプリの設計に貢献。"
        ]
      },
      {
        title: "ムーブメントを広げる",
        bullets: [
          "各地域に公正な割合で席が割り当てられています。自分の席を失わないようにしましょう。",
          "紹介リンクを使って友人や家族、同僚を招待しましょう。新しい登録ごとに追加特典を獲得できます。"
        ]
      },
      {
        title: "倫理的AIアプリを支援・構築",
        bullets: [
          "プライベートメッセージングツールからAI駆動の生産性向上ツールまで、すべてのプロジェクトが99/1%または90/10%モデルに準拠。",
          "資金提供するプロジェクトに投票できます。"
        ]
      },
      {
        title: "メンバー数を10億人に拡大",
        bullets: [
          "臨界質量に達することで、どの企業や政府も私たちの集合的な声を無視できなくなります。",
          "AIにおける真の民主主義が可能になります。"
        ]
      }
    ]
  },
  
  whyNow: {
    title: "なぜ今が決定的な瞬間なのか",
    benefits: [
      {
        headline: "AIは新たな資本",
        text: "未来の富はAIを所有する人々のもとに流れます。それをすべての人が所有できるようにしなければなりません。"
      },
      {
        headline: "デジタル封建制を防ぐ",
        text: "グローバルな行動がなければ、企業のテックプラットフォームに「賃借人」として住むことになり、AIの大富豪が支配する世界が到来します。"
      },
      {
        headline: "歴史的な機会",
        text: "現在、私たちはAIの進路を決定する力をまだ持っています。AIがすべての分野で人間の進歩を上回ると、その力は失われるかもしれません。"
      }
    ]
  },

  joinUs: {
    title: "私たちに参加して歴史を作りましょう",
    actions: [
      {
        headline: "創設メンバーになる",
        text: [
          "年間$1/月であなたの席を確保。",
          "日々コントロールポイントを獲得—ガバナンスと所有権を共有。"
        ]
      },
      {
        headline: "情報を広める",
        text: [
          "ソーシャルメディア、メール、フォーラム—あなたが繋がる場所で私たちのミッションを共有してください。",
          "このメッセージを聞くべき3人以上を招待してください。すべての紹介が私たちの集合的な力を強化します。"
        ]
      },
      {
        headline: "私たちと共に構築する",
        text: [
          "開発者ですか？オープンソースAIに貢献してください。",
          "マーケターですか？地域別のキャンペーンの作成を支援してください。",
          "誰でもボランティアになれます: ミートアップを主催し、コンテンツを作成し、新しいプロジェクトを提案してください。"
        ]
      }
    ]
  },

  constitution: {
    title: "原則に基づいて構築し、あなたによって施行される",
    subtitle: "Ctrl AI憲法",
    content:
      "Ctrl AI内のすべてのプロジェクトは、私たちの拘束力のある憲法を順守しなければなりません—操作的な「ダークパターン」、利益優先の拡張、ユーザーデータの販売は禁止されています。" +
      "私たちの原則は、オープンガバナンスと定期監査を通じて**あなた**によって守られます:",
    principles: [
      {
        headline: "人間の福祉を最優先",
        text: "中毒性のあるループや隠れた意図は排除し、本当に役立つものだけを提供します。"
      },
      {
        headline: "倫理的AI",
        text: "人間の可能性を引き出すためにAIを使用し、決して悪用や欺瞞を行いません。"
      },
      {
        headline: "財務およびコードの透明性",
        text: "予算、収益、製品の方向性についてコミュニティによる監視を行います。"
      },
      {
        headline: "永久的なユーザー所有権",
        text: "途中で方針を変更することはありません。すべてのプロジェクトはユーザーのものです。"
      }
    ],
    cta: "憲法全文を読む",
    conclusion:
      "**この文書は単なる宣言ではありません**—私たちを監視するための手段です。もし私たちが道を踏み外したときには、あなたが正しい方向に導く力を持っています。"
  },

  cta: {
    title: "最後の呼びかけ",
    subtitle: "AIは人類全体を力づけるものにも、企業エリートによる支配の道具にもなり得ます。それをすべての人のものとするために行動しなければなりません。\n\n**政府や大手テック企業があなたの代わりに決定するのを待たないでください。** 私たちには**あなた**の声が必要です。AIは特権を持つ少数の人々だけでなく、すべての人のものになるべきだという信念が必要です。",
    subheadline: "AIをコントロールしますか、それともコントロールされますか？",
    buttons: {
      primary: "あなたの席を確保",
      secondary: "メンバーシップについて詳しく知る"
    }
  },


  aspiringFounders: {
    intro: {
      category: "創設者 & クリエイター",
      title: "Ctrl AIであなたのビジョンを実現する",
      subtitle: "あなたのAIやアプリのアイデアを、グローバルコミュニティの支援を受けて現実のものにしましょう。捕食的なベンチャーキャピタルや広告収入に頼ることなく、ユーザーの福祉に完全に沿った目的主導のイノベーションを追求できます。",
      citation: "「なぜベンチャーキャピタルを追いかけるのか？最初の日から共同所有者であるユーザー基盤を活用できるのに。」",
      pointsTitle: "なぜCtrl AIで構築するのか？",
      points: [
        {
          headline: "ユーザー中心、広告中心ではない",
          text: "操作的なアルゴリズムは不要—憲法が透明性と真の利便性を求めています。"
        },
        {
          headline: "90% コミュニティ所有",
          text: "創設チームのために最大10%を確保しつつ、プロジェクトを真に支える人々が大多数の所有権を持ちます。"
        },
        {
          headline: "エバンジェリストがすでに存在",
          text: "最初の日から、共同所有者として何千人ものアンバサダーがあなたの製品を共有し、支援する準備ができています。"
        }
      ],
      conclusion: "倫理的かつオープンに創造する意欲があるなら、Ctrl AIはプラットフォーム、資金、ユーザー基盤を提供します—すべて人間の福祉を最優先する枠組みの中で。"
    },

    pitchProcess: {
      category: "提案と資金調達",
      title: "コミュニティ支援による成功への道",
      subtitle: "Ctrl AIの最初の10万人の創設メンバーが集まった後、あなたのプロジェクトをグローバルコミュニティに提案できます。承認されると、月々の資金提供、積極的なユーザーフィードバック、成功を支援するパートナーネットワークを利用できます。",
      citation: "「コミュニティの承認を得たアイデアには、毎月最大$10k/創設者を提供—Ctrl AI憲法の遵守を条件に。」",
      pointsTitle: "仕組み:",
      points: [
        {
          headline: "アイデアを提案",
          text: "ビジョン、チーム、90%/10%モデルの順守方法を詳細に記載した短い提案を提出します（Ctrl AIのコアに少なくとも20%、直接支援者に70%を割り当てる必要があります）。"
        },
        {
          headline: "コミュニティ投票",
          text: "Ctrl AIのメンバーがプロジェクトをレビューし、投票します。必要な支持率を達成すれば、資金提供の資格を得ます。"
        },
        {
          headline: "毎月の給料",
          text: "承認された創設者は、メンバーシップ資金プールから$10k/月を受け取ることができ、資金調達ではなく構築に集中できます。"
        }
      ],
      conclusion: "無限のVCラウンドやペイウォールはありません。具体的な、ユーザー主導の支援のみです。プロジェクトが成長すれば、共同所有者も一緒に成長します。"
    },

    buildingInPublic: {
      category: "公開開発",
      title: "週ごとにマイルストーン、成果、教訓を共有",
      subtitle: "定期的なアップデートにより、支援者や共同所有者との透明性、責任、そして共有の興奮を高めます。",
      citation: "「コミュニティが利害関係を持つ場合、プロジェクトの成功と課題は真の共有体験となります。」",
      pointsTitle: "基本原則:",
      points: [
        {
          headline: "オープンなロードマップ",
          text: "開発のタイムライン、主要機能、長期目標を公開し、次に何が来るのかを明確にします。"
        },
        {
          headline: "コミュニティのテストとフィードバック",
          text: "ユーザーフィードバックループを早期かつ頻繁に統合します。共同所有者は問題を発見し、機能を改善し、成長を促進します。"
        },
        {
          headline: "透明な進捗報告",
          text: "毎週または毎月のスナップショットを共有し—成果、課題、次のステップを強調し、ステークホルダーを引きつけます。"
        }
      ],
      conclusion: "単なるソフトウェアアップデート以上のものです。それは、コミュニティがあなたの旅を形作り、祝う真の招待状です。誠実な関与は忠誠を育み、より良い製品を生み出します。"
    },

    callToAction: {
      category: "スタートする",
      title: "あなたのAIプロジェクトを立ち上げる準備はできていますか？",
      subtitle: "倫理的でユーザー所有のイノベーションにコミットするコミュニティに参加しましょう。アイデアを提案し、資金を確保し、何千人もの支援を受けて構築しましょう—企業の紐付きは一切ありません。",
      pointsTitle: "次のステップ:",
      points: [
        {
          headline: "10万人の創設メンバーに到達",
          text: "最初のメンバーシップの波を確定させたら、提案プラットフォームが開放されます—Ctrl AIを共有してこのマイルストーンを早く達成しましょう。"
        },
        {
          headline: "提案を準備",
          text: "コンセプトを作成し、チームを集め（または単独で行動し）、90%/10%の所有権構造をどのように維持するかを最終化します。"
        },
        {
          headline: "早期かつ頻繁に関与",
          text: "私たちのコミュニティフォーラムに参加し、アイデアに対する初期のフィードバックを得ましょう。今つながりを作ることで、提案プロセスがスムーズになります。"
        }
      ],
      conclusion: "**Ctrl AIは明日の革新的なアプリを形成する場所です—グローバルコミュニティ、透明な資金、ユーザーファーストの倫理が支える。**"
    }
  },


  foundingMembers: {
    intro: {
      category: "ムーブメントに参加する",
      title: "Ctrl AIの創設メンバーになりましょう",
      subtitle: "少数のテック巨人にすべての力を与えることなく、AIの未来を形作るグローバル連合の一員になりましょう。",
      citation: "創設メンバーはCtrl AIの生命線です。憲法を形作り、主要な決定事項に投票し、市民主導のAI革命の基盤を築きます。",
      pointsTitle: "なぜ創設メンバーになるのか？",
      points: [
        {
          headline: "独占的な初期ガバナンス",
          text: "創設メンバーは日々コントロールポイントを獲得し、他の誰よりも先に主要なAIプロジェクトを指揮できます。"
        },
        {
          headline: "グローバルクォータ",
          text: "国の規模に応じて席を割り当てることで、すべての国の声が最初の日から反映されます。"
        },
        {
          headline: "広告なし、VCなし、純粋なコミュニティ",
          text: "メンバーシップ料金がプラットフォームを支えます—企業株主は存在しません。本物のAIの民主主義です。"
        }
      ],
      conclusion: "公平でユーザーが所有するAIの未来を構築する準備はできていますか？今すぐウェイトリストに登録して、創設メンバーの資格を獲得しましょう。"
    },

    waitlistMechanics: {
      category: "ウェイトリスト & ポイント",
      title: "ウェイトリストの仕組み",
      subtitle: "私たちはただの観客ではなく、真の信奉者で構成されるムーブメントを作りたいと考えています。そのため、ウェイトリストから最も貢献した人々を創設メンバーとして招待します。",
      citation: "「Ctrl AIのビジョンに真のコミットメントを示し、共有と協力を通じて自分の席を獲得してください。」",
      pointsTitle: "ポイント獲得方法:",
      points: [
        {
          headline: "プロフィールを完成させる",
          text: "基本情報を提供することで、あなたが誰であり、どの地域を代表しているかを把握します。"
        },
        {
          headline: "友人を紹介し、SNSで共有する",
          text: "事前に用意された投稿をカスタマイズし、それをダッシュボードに証拠リンクとして貼り付けてください。私たちのチームが手動で検証し、ポイントを付与します。"
        },
        {
          headline: "コミュニティとの交流",
          text: "今後、Q&Aへの参加や提案へのフィードバックの追加、新規メンバーの質問への対応が可能になります。すべての意義ある行動があなたの順位を上げる可能性があります。"
        }
      ],
      conclusion: "一定のポイント閾値に到達するか、上位の貢献者にランクインすると、創設メンバーの資格をアクティブ化する招待をお送りします。"
    },

    callToAction: {
      category: "行動を起こす",
      title: "あなたの席を確保し、未来を形作る",
      subtitle: "私たちは定期的にウェイトリストを確認し、新しい創設メンバーを選びます。コミットメントを示し、情報を広めることで、ランクを早く上げましょう。",
      pointsTitle: "選ばれた後の流れ:",
      points: [
        {
          headline: "メンバーシップ料金: $1/月 (年間)",
          text: "公式メンバーシップを開始します。この小額の料金がCtrl AIを独立させ、広告やベンチャーキャピタルからの制約をなくします。"
        },
        {
          headline: "日々のコントロールポイント",
          text: "メンバーシップの日数に応じてガバナンス権を獲得します。アプリの機能から倫理指針まで、すべてに影響を与えることができます。"
        },
        {
          headline: "投票と憲法の批准",
          text: "拘束力のある憲法を最終化し、施行する手助けをします。私たちが道を誤った場合には、あなたが正しい方向に導く権利（そして責任）を持っています。"
        }
      ],
      conclusion: "**AIプラットフォームを真にユーザーが所有するものにしましょう。**今すぐウェイトリストに登録し、ポイントを獲得してアクティブになり、AIのコントロールを手に入れましょう。"
    }
  },

  ownership: {
    intro: {
      category: "所有権",
      title: "集合的所有権の力",
      subtitle: "Ctrl AIは99%がユーザー所有です—これにより、単一の企業、投資家、国がプラットフォームを支配することを防ぎます。所有権を数百万（最終的には数十億）のメンバーに分散させることで、真に民主的なAIエコシステムを構築します。",
      citation: "「Ctrl AIにおける所有権は単なる流行語ではありません—それは私たちの未来を導き、政策を形作り、共に生み出した価値を共有する権利です。」",
      pointsTitle: "これが重要な理由:",
      points: [
        {
          headline: "抑制と均衡",
          text: "所有権の99%がユーザーに分散されているため、少数派がコミュニティを害する決定を強いることはできません。"
        },
        {
          headline: "真の説明責任",
          text: "ユーザーは投票を通じて拒否権を持ち、透明性を要求することで倫理的で公正な開発を保証します。"
        },
        {
          headline: "長期的な安定性",
          text: "コミュニティ主導のプラットフォームは、市場の気まぐれや利益重視の株主に対してより強靭です。"
        }
      ],
      conclusion: "メンバーとして、あなたはCtrl AIを使用するだけでなく、所有し、導くことができます。これによりAI植民地主義を防ぎ、AIが人々の力であることを保証します。"
    },
  
    controlPoints: {
      category: "コントロールポイント",
      title: "継続的な所有権とガバナンス権",
      subtitle: "すべてのアクティブメンバーは1日ごとに1コントロールポイントを獲得し、メンバーシップが継続している限りポイントを蓄積できます。これらのコントロールポイントにより、主要なプラットフォームの意思決定に直接影響を与えることができます。",
      citation: "「早く参加し、アクティブであり続けましょう: メンバーである期間が長いほどコントロールポイントが蓄積し、Ctrl AIを形作る力が増します。」",
      pointsTitle: "仕組み:",
      points: [
        {
          headline: "毎日の蓄積",
          text: "メンバーシップがアクティブである1日ごとに1コントロールポイントが増加します。上限はなく、長期間メンバーでいるほど影響力が増します。"
        },
        {
          headline: "投票と提案",
          text: "コントロールポイントを使って新機能の提案、倫理指針、予算配分などに投票できます。"
        },
        {
          headline: "オプションのオンチェーン変換",
          text: "2年後には、コントロールポイントをトークンに変換するオプションが提供されます—ただし、これは完全に任意です。シンプルさを維持したい場合はオフチェーンのままにできます。"
        }
      ],
      conclusion: "コントロールポイントは、投機目的ではなく、主にガバナンスのために設計されています。それはあなたの継続的なコミットメントを反映し、Ctrl AIの進化における真の発言権を与えます。"
    },
  
    communityModel: {
      category: "ユーザー中心モデル",
      title: "99% コミュニティ所有",
      subtitle: "所有権を分割することで、ユーザーを強化しつつ、開発を阻害しないようにしています。主要な決定事項—アプリのリリースからデータプライバシーに至るまで—常に大多数の手に委ねられます。",
      citation: "「自分のAIツールが広告主主導の巨大企業やベンチャーキャピタリストのものではなく、本当に自分のものである未来を想像してください。」",
      pointsTitle: "モデルの主な特徴:",
      points: [
        {
          headline: "創設者プロジェクト (90/10)",
          text: "Ctrl AIの下で構築されるサブプロジェクトは、最低90%がコミュニティ所有、10%が創設チームに割り当てられます—常にユーザーの利益を最優先にします。"
        },
        {
          headline: "直接ガバナンス",
          text: "主要な決定事項に投票し、変更を提案し、Ctrl AIの未来を形作ります。"
        },
        {
          headline: "グローバルな代表性",
          text: "国ごとのメンバーシップクォータにより、特定の地域が他を圧倒することはできません。所有権は世界中に分散されています。"
        }
      ],
      conclusion: "個人の持ち分を制限し、残りを何百万ものユーザーに分配することで、Ctrl AIはグローバルで国境を越えた、ユーザー中心のエコシステムとして維持されます。"
    },
  
    callToAction: {
      category: "行動を起こす",
      title: "Ctrl AIで未来を所有する",
      subtitle: "AIが独占される世界を受け入れる必要はありません。Ctrl AIに参加し、日々コントロールポイントを獲得し、AI開発を誠実で人間中心、そして透明性のあるものに保ちましょう。",
      pointsTitle: "次のステップ:",
      points: [
        {
          headline: "ウェイトリストに登録",
          text: "メンバーシップの枠を確保し、招待されたときからコントロールポイントの蓄積を開始しましょう。"
        },
        {
          headline: "共有してウェイトリストポイントを獲得",
          text: "友人を紹介し、情報を広めることでランクを上げ、グローバルメンバーシップ目標を早く達成できるよう支援してください。"
        },
        {
          headline: "明日のAIを形作る",
          text: "内部に入ったら、増え続けるコントロールポイントを使って投票し、変更を提案し、私たちのプラットフォームを使命に忠実に保ちましょう。"
        }
      ],
      conclusion: "**所有権は力です**—Ctrl AIでは、その力はあなたのものです。登録してアクティブになり、AI所有のルールをすべての人のために書き換えましょう。"
    }
  },


  constitutionPage: {
    hero: {
      title: "Ctrl AI憲法",
      preamble: "テクノロジーが人類の未来を形作る時代において、私たちは異なる道を選びます。**単により良い製品ではなく、より良い原則を。単なる革新ではなく、倫理的進歩を。単なるユーザーエンゲージメントではなく、人間の繁栄を。**"
    },
    principles: {
      title: "私たちの拘束力のある原則",
      intro: "これらはガイドラインや提案ではありません—Ctrl AIのすべてのプロジェクトが順守しなければならない拘束力のある約束であり、コミュニティの監視と定期監査によって施行されます。",
      principles: [
        {
          title: "人間の福祉を最優先",
          items: [
            "真の人間の繁栄を目指して設計されること",
            "エンゲージメント指標を目的としたダークパターンの完全禁止",
            "ユーザーの注意を尊重する資源として扱うこと",
            "自然な停止ポイントと健全な使用パターンを優先すること",
            "中毒性のあるエンゲージメントではなく、意味のある接続を優先すること"
          ]
        },
        {
          title: "絶対的なデータ権",
          items: [
            "ユーザーが完全にデータを所有—例外なし",
            "データ販売や無許可の共有を一切禁止",
            "可能な限りローカルファーストでの処理",
            "標準としての軍事レベルの暗号化",
            "完全かつ永久的に忘れられる権利を保証"
          ]
        },
        {
          title: "真のコミュニティパワー",
          items: [
            "最低90%のコミュニティ所有—憲法によって保証",
            "主要な意思決定における直接民主的ガバナンス",
            "製品の方向性に関する拘束力のあるコミュニティ投票",
            "数学的に公平なトークン分配",
            "貢献度の大小に関係なく平等な権利"
          ]
        },
        {
          title: "倫理的AIの境界",
          items: [
            "AIは人間の主体性を支えるものであり、それを弱めるものではない",
            "AIの意思決定プロセスの完全な透明性",
            "継続的なバイアスの監視と排除",
            "すべてのAI機能における明確な人間によるオーバーライド",
            "人間のエンパワーメントを目的としたAI開発"
          ]
        },
        {
          title: "チームの福祉",
          items: [
            "4日間労働週を憲法上の権利として保証",
            "包括的なメンタルヘルスサポート",
            "すべてのメンバーに継続的な学習機会を提供",
            "過度な締め切りによる搾取を禁止",
            "公平な利益分配",
            "リモートファーストで、希望者にはコラボレーションの選択肢を提供",
            "厳格なワークライフバランス",
            "定期的なチーム連携イベントの実施"
          ]
        },
        {
          title: "教育の優先",
          items: [
            "すべての製品に組み込まれた教育的価値",
            "年齢に適した設計と安全性",
            "積極的なデジタルリテラシーの促進",
            "複数のステークホルダーを巻き込んだ教育的アプローチ",
            "若年ユーザーの搾取を完全禁止",
            "若者をターゲットにした操作的行動の禁止"
          ]
        },
        {
          title: "徹底した透明性",
          items: [
            "毎週の開発進捗報告を公開",
            "完全な財務透明性",
            "課題に関する直接的なコミュニケーション",
            "デフォルトでオープンソース",
            "定期的なコミュニティ参加セッション"
          ]
        },
        {
          title: "持続可能な開発",
          items: [
            "人工的なバイラル性ではなく自然な成長",
            "コミュニティ主導の拡大のみ",
            "搾取的なマーケティングを禁止",
            "環境への影響を考慮",
            "短期的利益よりも長期的な持続可能性を重視"
          ]
        },
        {
          title: "公正な収益",
          items: [
            "広告ベースの収益ゼロ",
            "透明性のある価値ベースの価格設定",
            "コストと利益を明確に説明",
            "直接的なコミュニティ利益共有",
            "コストに見合った価格設定のみ"
          ]
        },
        {
          title: "目的ある革新",
          items: [
            "実際の人間のニーズに焦点を当てる",
            "コミュニティの利益を最優先にした革新のみ",
            "オープンな協力の原則",
            "人類に貢献するテクノロジー",
            "すべての意思決定における社会的影響の考慮"
          ]
        }
      ]
    },
    enforcement: {
      title: "実効的な施行と責任の確保",
      content: "**これらの原則は単なる約束ではなく、現実的な結果を伴う拘束力のある約束です:**",
      measures: [
        {
          title: "コミュニティの監視",
          items: [
            "• 四半期ごとの公開監査",
            "• リアルタイムでの違反報告",
            "• 直接的なコミュニティフィードバックシステム",
            "• 独立した倫理レビュー委員会"
          ]
        },
        {
          title: "明確な対応措置",
          items: [
            "• 違反への即時対応",
            "• コミュニティ主導の是正",
            "• 強制的な改善",
            "• 公開による説明責任報告"
          ]
        },
        {
          title: "生きた文書",
          items: [
            "• 年次コミュニティレビュー",
            "• 技術に適応した更新",
            "• 変更不可能な基本原則",
            "• 民主的な改正プロセス"
          ]
        }
      ]
    },
    cta: {
      title: "倫理的テクノロジー革命に参加する",
      content: "**これは単なるテック企業のミッションステートメントではありません。これは、人類に奉仕するテクノロジーを構築するための拘束力のある憲法です。** 倫理的に構築する準備ができている創設者、または使用する製品を所有する準備ができているユーザーは、ここから倫理的テクノロジーの未来を始めましょう。",
      button: "創設メンバーに参加 ($1/月)"
    }
  },


  footer: {
    companyDescription: "コミュニティ所有と倫理的原則でAIの未来を構築します。",
    navigation: "ナビゲーション",
    legal: "法的情報",
    connect: "その他",
    privacy: "プライバシーポリシー",
    terms: "利用規約",
    cookies: "クッキーポリシー",
    rights: "全著作権所有。"
  },

  nav: {
    community: "創設メンバー",
    founders: "創設者",
    ownership: "所有権",
    constitution: "憲法",
    pricing: "料金",
    dashboard: "ダッシュボード",
    myProfile: "マイプロフィール",
    settings: "設定",
    home: "ホーム",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "創設者として申し込む",
    about: "Ctrl AIについて",
    logout: "ログアウト"
  },

  auth: {
    login: {
      title: "おかえりなさい",
      subtitle: "未来を築く旅を続けましょう",
      emailPlaceholder: "メールアドレスを入力",
      passwordPlaceholder: "パスワードを入力",
      submitButton: "サインイン",
      noAccount: "アカウントをお持ちでない場合",
      signupLink: "創設者としてサインアップ",
      error: "無効なメールアドレスまたはパスワードです"
    },
    signup: {
      title: "創設者として参加",
      subtitle: "未来のテクノロジーを構築し始めましょう",
      namePlaceholder: "氏名を入力",
      emailPlaceholder: "メールアドレスを入力",
      passwordPlaceholder: "パスワードを選択",
      submitButton: "アカウントを作成",
      hasAccount: "すでにアカウントをお持ちですか？",
      loginLink: "サインイン",
      error: "アカウントの作成中にエラーが発生しました"
    },
    errors: {
      passwordLength: "パスワードは6文字以上である必要があります",
      invalidEmail: "有効なメールアドレスを入力してください",
      emailInUse: "このメールアドレスはすでに使用されています"
    },
    passwordRequirements: "パスワードは6文字以上である必要があります"
  },

  pricing: {
    title: "ムーブメントに参加",
    subtitle: "テクノロジーの未来を構築し、所有するためのムーブメントに参加しましょう",
    tiers: {
      founding: {
        title: "創設メンバー",
        price: "$1/月",
        description: "変革を推進したい方へ",
        features: [
          "1日ごとに1コントロールポイント",
          "新しいプロジェクトへの優先アクセス",
          "テクノロジーの未来を形作る",
          "限定の創設メンバーバッジ",
          "友人用招待コード3つ"
        ]
      }
    },
    footer: {
      quote: "20年後、人々はこのチャンスを逃した理由を問うでしょう。待っていた理由を説明する側にはならないでください。"
    }
  },

  modals: {
    inviteCode: {
      title: "招待コードを引き換え",
      description: "創設メンバーの席を確保するために招待コードを入力してください。各コードは一度のみ使用可能です。",
      placeholder: "招待コードを入力",
      submit: "創設メンバーとして参加",
      invalidCode: "無効または期限切れの招待コードです。もう一度お試しください。",
      success: "Ctrl AIへようこそ！登録を完了しています…",
      validating: "検証中…",
      alreadyUsed: "このコードはすでに使用されています",
      expired: "このコードは期限切れです",
      validated: "コードが検証されました！サインアップにリダイレクト中…"
    },
    waitlist: {
      title: "ウェイトリストに参加",
      description: "Ctrl AIへの早期アクセスを得るための限定ウェイトリストに参加しましょう。",
      namePlaceholder: "氏名",
      emailPlaceholder: "メールアドレス",
      passwordPlaceholder: "パスワードを作成",
      submit: "ウェイトリストに参加",
      submitting: "参加中…",
      error: "エラーが発生しました。もう一度お試しください。",
      success: "ようこそ！リストに登録されました。"
    }
  },

  waitlist: {
    status: {
      title: "ウェイトリストのステータス",
      totalInWaitlist: "{{total}}人中",
      referralCode: "あなたの紹介コード",
      copyLink: "リンクをコピー",
      referralDescription: "紹介コードを友人と共有して、ウェイトリストを上位に進めましょう。紹介1件ごとに50ポイントを獲得できます！",
      points: "ポイントの進捗"
    },
    actions: {
      twitter: "Twitterで共有",
      linkedin: "LinkedInで共有",
      discord: "Discordに参加",
      profile: "プロフィールを完成"
    }
  },

  onboarding: {
    title: "Ctrl AIへようこそ",
    subtitle: "成功への準備を整えましょう",
    points: "ポイント",
    continue: "続ける",
    finish: "セットアップを完了",
    steps: {
      profile: {
        title: "プロフィールを完成",
        description: "プロフィール画像と自己紹介を追加して、他のメンバーにあなたを知ってもらいましょう。"
      },
      social: {
        title: "あなたの旅を共有",
        description: "Ctrl AIに参加したことをネットワークで共有し、興奮を伝えましょう。"
      },
      community: {
        title: "コミュニティに参加",
        description: "Discordコミュニティで他のメンバーとつながりましょう。"
      }
    }
  },

  dashboard: {
    welcome: "おかえりなさい、{{name}}",
    membershipTiers: {
      founding: "創設メンバー",
      collective: "コレクティブメンバー"
    },
    tabs: {
      projects: "プロジェクト",
      community: "コミュニティ",
      rewards: "報酬"
    },
    community: {
      title: "コミュニティの活動",
      discussions: {
        title: "最近のディスカッション",
        empty: "まだディスカッションはありません",
        start: "ディスカッションを開始"
      },
      activity: {
        title: "最近の活動",
        empty: "最近の活動はありません"
      },
      stats: {
        members: "アクティブメンバー",
        discussions: "ディスカッション",
        contributions: "貢献"
      }
    },
    projects: {
      title: "アクティブなプロジェクト",
      empty: "アクティブなプロジェクトはありません",
      explore: "プロジェクトを探索",
      create: "プロジェクトを作成"
    },
    rewards: {
      title: "あなたの報酬",
      points: "コントロールポイント",
      achievements: "実績",
      empty: "まだ報酬はありません"
    },
    quickActions: {
      title: "クイックアクション",
      createProject: "プロジェクトを作成",
      startDiscussion: "ディスカッションを開始",
      updateProfile: "プロフィールを更新"
    }
  },

  global: {
    title: "ウェイトリストの世界的な成長を見よう",
    subtitle: "リアルタイムのコミュニティ成長",
    content: "各ドットは創設メンバーの席を競っている人を表しています。まだ招待コードがありませんか？ウェイトリストに参加し、チャレンジを完了してランクを上げましょう。",
    stats: {
      totalMembers: "総メンバー数",
      activeToday: "今日のアクティブメンバー",
      averagePoints: "平均ポイント"
    },
    controls: {
      pause: "一時停止して操作",
      resume: "ツアーを再開",
      next: "次の大陸"
    }
  },

  quotaMap: {
    stats: {
      totalQuota: "全世界の総クォータ",
      totalRegistered: "登録総数",
      totalPopulation: "総人口",
      countryCount: "代表されている国数",
      fulfillment: "グローバル達成度"
    },
    tooltip: {
      country: "国",
      population: "人口",
      quota: "メンバークォータ",
      registered: "登録メンバー",
      fulfillment: "クォータ達成度"
    },
    legend: {
      title: "クォータ達成度",
      low: "低い",
      target: "目標達成",
      exceeded: "超過"
    },
    controls: {
      reset: "ビューをリセット",
      zoom: "ズーム",
      pan: "移動"
    },
    noData: "この国のデータは利用できません",
    loading: "地図データを読み込み中…"
  },
  signupForm: {
    title: {
      waitlist: "ウェイトリストに参加",
      verified: "アカウント作成"
    },
    subtitle: {
      waitlist: "限定ウェイトリストに参加して早期アクセスを獲得",
      verified: "創設メンバーとして招待されました"
    },
    fields: {
      fullName: "氏名",
      email: "メールアドレス",
      password: "パスワード"
    },
    buttons: {
      submit: "続ける",
      submitting: "アカウント作成中...",
      google: "Googleで続ける"
    },
    errors: {
      emailExists: "このメールアドレスは既にウェイトリストに登録されています。確認手順をメールでご確認ください。",
      emailInUse: "このメールアドレスは既に登録されています。ログインしてください。",
      waitlistCheck: "ウェイトリストの状態を確認できませんでした。もう一度お試しください。",
      createAccount: "アカウントを作成できませんでした。もう一度お試しください。"
    },
    inviteCode: {
      prompt: "招待コードをお持ちですか？",
      button: "招待コードを入力"
    }
  },

  waitlistDashboard: {
    title: "ウェイトリストステータス",
    position: "順位 {{position}}",
    total: "総数 {{total}}人中",
    stats: {
      referrals: "紹介数",
      points: "ポイント",
      status: "ステータス",
      active: "アクティブ",
      position: "順位",
      completion: "完了度",
      earnMore: "ポイントを獲得",
      completeProfile: "プロフィールを完成させて順位を上げる",
      total: "総数 {{total}}人中"
    },
    share: {
      title: "シェアしてポイント獲得",
      description: "友達に紹介リンクをシェアしてウェイトリストの順位を上げましょう。紹介ごとにポイントが獲得できます！",
      copy: "リンクをコピー",
      copied: "コピーしました！",
      buttons: {
        twitter: "Twitterでシェア",
        linkedin: "LinkedInでシェア"
      }
    },
    earnPoints: {
      title: "ポイント獲得方法",
      items: [
        "プロフィールを完成させる",
        "SNSでシェアする",
        "友達を招待する",
        "Discordコミュニティに参加する"
      ]
    },
    breadcrumbs: {
      waitlist: "ウェイトリスト",
      dashboard: "ダッシュボード"
    },
    welcome: {
      title: "ようこそ",
      location: "{{city}}、{{country}}"
    },
    overview: {
      journey: "創設メンバーへの道のり",
      profileCompletion: "プロフィール完成度",
      socialSharing: "SNSシェア",
      tasks: {
        title: "利用可能なタスク",
        completeProfile: "プロフィールを完成させる",
        completeProfileDesc: "基本情報を追加して、あなたのことをより良く知らせてください",
        addLocation: "場所を追加",
        addLocationDesc: "あなたの所在地を教えてください"
      }
    },
    tabs: {
      overview: "概要",
      tasks: "ポイント獲得",
      social: "シェア＆獲得"
    },
    socialShare: {
      title: "シェアしてポイント獲得",
      description: "SNSでCtrl AIについてシェアしてポイントを獲得し、順位を上げましょう",
      verificationPending: "{{platform}}での共有が確認待ちです"
    },
    progress: {
      items: {
        photo: "プロフィール写真",
        basicInfo: "基本情報",
        location: "場所",
        skills: "スキル・興味"
      }
    }
  },

  progressSection: {
    complete: "完了",
    points: "+{{points}}ポイント",
    viewDetails: "詳細を見る",
    completeTasks: "タスクを完了する",
    percentComplete: "{{percent}}％完了"
  },

  social: {
    templates: {
      twitter: [
        {
          text: "#CtrlAIムーブメントに参加しました！🚀 巨大テック企業がAIを独占できない、ユーザー主導の未来を作ります。\n\n各国の枠に限りがあります。今すぐ参加：{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ユーザー所有", "#AI革命"]
        },
        {
          text: "AIで真の発言力を持つには、オーガニックな成長が必要です！@CtrlAIの10万創設メンバー達成を支援中。\n\nあなたの国の枠が埋まる前に参加を：{{REFERRAL_LINK}}",
          hashtags: ["#コントロールを取り戻そう", "#デジタルの自由", "#テクノロジーの未来"]
        }
      ],
      linkedin: [
        {
          text: "Ctrl AIのウェイトリストに参加しました — 巨大テック企業のAI独占に挑戦する、人々主導のプラットフォームです。\n\n重要なポイント：\n• 99%がユーザー所有（企業役員による支配なし）\n• デイリーコントロールポイント = 実質的な運営権限\n• 世界的な代表性を確保する国別割当制\n\n創設メンバーの枠は10万人限定。口コミだけで（広告やVCなし）AIの未来を共に作りましょう！\n\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#テック革命", "#デジタルの自由", "#AI"]
        },
        {
          text: "明日のテクノロジーを共同所有するため、Ctrl AIのウェイトリストに参加しました！株主ではなく、人々に対して責任を持つAIを想像してください。\n\n• 99%ユーザー所有\n• 透明で倫理的なAI\n• 巨額の広告予算なし—私たち自身で広める\n\n創設メンバーの枠は国ごとに限定されています。詳細はこちら：{{REFERRAL_LINK}}",
          hashtags: ["#AI革命", "#ユーザー所有", "#倫理的テック", "#テクノロジーの未来"]
        }
      ],
      instagram: [
        {
          text: "AIのコントロールを取り戻す時が来ました！⚡\n\n巨大テック企業の支配から自由な、ユーザー所有のアプリを作るためCtrl AIのウェイトリストに参加。\n\n成長は口コミのみ。あなたの国の創設メンバー枠をチェック！\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#テック革命", "#ユーザー所有", "#デジタルの自由", "#AI"]
        },
        {
          text: "10万人の創設メンバー募集中！\n\nCtrl AI = 99%ユーザー所有、テクノロジーの進化に実質的な発言権。広告なし、VCなし、データ収集なし。\n\nあなたの国の枠には限りがあります—今すぐ参加を：{{REFERRAL_LINK}}",
          hashtags: ["#AI革命", "#コントロールを取り戻そう", "#巨大テックNoMore", "#テクノロジーの未来"]
        }
      ],
      facebook: [
        {
          text: "AIが本当にユーザーのものとなり、少数の巨大企業に支配されない世界を作るため、Ctrl AIのウェイトリストに参加しています。\n\n99%を実際の人々が所有することで、倫理的で透明性のあるAIを確保できます。成長は純粋に口コミで—広告費やVCに頼りません。\n\n創設メンバーの枠は10万人限定で、公平性のため国別に配分されています。テクノロジーを変革する準備はできましたか？こちらから参加：{{REFERRAL_LINK}}",
          hashtags: ["#コントロールを取り戻そう", "#デジタルの自由", "#ユーザー所有", "#AI"]
        },
        {
          text: "使用しているアプリを実際に所有したいと思ったことはありませんか？Ctrl AIがそれを実現します！私はウェイトリストに参加しています。あなたもぜひ参加を。\n\n広告に頼ることはできません—これは人々主導の運動です。あなたの国の枠には限りがあります。今すぐ枠を確保：{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#テクノロジーの未来", "#AI革命"]
        }
      ],
      youtube: [
        {
          text: "AIの作り方を変えましょう！Ctrl AIは99%がユーザー所有の人々主導プラットフォーム。広告なし、企業支配なし、テクノロジーにおける真の民主主義を。\n\n私のリンクから参加：{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#AI", "#イノベーション", "#テック革命"]
        },
        {
          text: "AIは一握りの巨大企業のものであってはなりません。私はCtrl AIのメンバーです。あなたもなれます！成長は口コミに依存、世界で10万枠限定です。\n\nこちらから参加：{{REFERRAL_LINK}}",
          hashtags: ["#デジタルの自由", "#ユーザー所有", "#AI", "#テクノロジーの未来"]
        }
      ],
      tiktok: [
        {
          text: "POV：AIの未来をあなたが共同所有できる運動を発見 🤖\n\nCtrl AIは99%ユーザー所有、巨大テック企業や怪しい投資家なし。成長は私たち自身の手で。このリンクから参加：{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#テックトーク", "#AI革命", "#ユーザー所有"]
        },
        {
          text: "巨大テック企業の支配から解放されたい？Ctrl AIに参加を！🚀 創設メンバーは世界で10万枠限定。あなたの国の枠がまだ空いているうちに、参加して広めてください：{{REFERRAL_LINK}}",
          hashtags: ["#テック革命", "#AI", "#デジタルの自由", "#コントロールを取り戻そう"]
        }
      ],
      github: [
        {
          text: "倫理的なAIアプリを共同所有・共同開発するため、Ctrl AIのウェイトリストに参加しました！広告なし、VCなし、完全オープンソースのコミュニティ。\n\nあなたの国の創設メンバー枠には限りがあるかも—こちらから参加：{{REFERRAL_LINK}}",
          hashtags: ["#AI", "#オープンソース", "#ユーザー所有", "#倫理的テック"]
        },
        {
          text: "開発者の皆さん：巨大テック企業の独占にうんざりしていませんか？Ctrl AIはユーザー所有のAIの新時代を構築中。成長はコミュニティのみで実現。オープンな協力に情熱をお持ちなら、今すぐ創設メンバーシップを確保を：{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#イノベーション", "#オープンソース", "#テクノロジーの未来"]
        }
      ]
    }
  }
};