import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores/useUserStore';

export function ReferralRedirect() {
  const { referralCode } = useParams();
  const navigate = useNavigate();
  const { profile } = useUserStore();

  useEffect(() => {
    // Store referral code in localStorage
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }

    // If user is already logged in, redirect to dashboard
    if (profile) {
      navigate('/dashboard');
    } else {
      // Redirect to home page with referral code
      navigate('/', { state: { referralCode } });
    }
  }, [referralCode, navigate, profile]);

  return null; // or loading spinner
} 