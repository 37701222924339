export const cs = {
  hero: {
    title: "OpenAI už není Otevřená.",
    subtitle: "Jsme hnutí, které staví lidstvo na první místo.",
    chainCall: "Kontrola lidmi, ne korporacemi."
  },

  problem: {
    category: "Fakta",
    title: "ChatGPT: 300M+ Uživatelů",
    subtitle: "$157B+ Valuace",
    subheadline: "0% vlastněno uživateli"
  },

  problem2: {
    title: "Budujeme Ctrl AI",
    subtitle: "99% vlastněno uživateli. To znamená vámi."
  },

  opportunity: {
    points: {
      control: "Začínáte s 300 Control Point",
      bring: "Připojte JEDNU osobu za 24 hodin",
      fail: "Pokud to nestihnete = Ztratíte 200 Bodů"
    },
    note: "Tentokrát skutečně vlastníte to, co pomáháte budovat."
  },

  howItWorks: {
    title: "Skutečná Alternativa",
    points: [
      {
        title: "Fáze 1 / Spojit 1M Lidí",
        bullets: [
          "Žádný rizikový kapitál, žádná korporátní kontrola",
          "Síla lidí, článek po článku",
          "Každý uživatel je skutečným vlastníkem"
        ]
      },
      {
        title: "Fáze 2 / Financovat 100 Týmů",
        bullets: [
          "Přebudovat aplikace, které denně používáme, ty které těží naše data a prodávají je nejvyšší nabídce",
          "Vytvořit AI nástroje, které skutečně vlastníme",
          "Demokratická kontrola funkcí",
          "Vaše data zůstávají vašimi"
        ]
      },
      {
        title: "Fáze 3 / Růst na 1B Lidí",
        bullets: [
          "Skutečná alternativa ke korporátní AI",
          "Globální hlas ve vývoji AI",
          "Skutečná moc skrze skutečné vlastnictví"
        ]
      }
    ]
  },

  cta: {
    category: "Volba",
    title: "Otevřenost vs Kontrola",
    subtitle: "OpenAI nám ukázala, co se stane, když se 'otevřené' stane uzavřeným. Ctrl AI zajišťuje, že se to už nikdy nestane tím, že dává 99 % vlastnictví uživatelům od prvního dne.",
    subheadline: "Převezmete kontrolu?",
    citation: "Jsem Rado. Vytvořil jsem Ctrl AI, protože jsem viděl transformaci OpenAI z neziskové organizace na giganta za 157 miliard dolarů. Tentokrát pojďme vybudovat něco, co skutečně vlastníme společně.",
    buttons: {
      primary: "Převzít kontrolu (1$)",
      secondary: "Zůstat bezmocný"
    },
    note: "1$ dokazuje, že jste člověk. Žádní boti, žádní investoři—jen spojení lidé."
  },


  aspiringFounders: {
    intro: {
      category: "Zakladatelé & Tvůrci",
      title: "Budujte svou vizi pod Ctrl AI",
      subtitle:
        "Proměňte svůj nápad na AI nebo aplikaci v realitu s podporou globální komunity. Žádní draví investoři, žádné honění reklamních příjmů—jen smysluplná inovace v souladu s uživatelským blahem.",
      citation: "Proč honit rizikový kapitál, když můžete od prvního dne čerpat z vestavěné uživatelské základny spoluvlastníků?",
      pointsTitle: "Proč budovat s Ctrl AI?",
      points: [
        {
          headline: "Uživatelé na prvním místě, ne reklamy",
          text: "Zapomeňte na manipulativní algoritmy—naše ústava vyžaduje transparentnost, soukromí a skutečný užitek."
        },
        {
          headline: "95% vlastněno komunitou",
          text: "Ponechte si až 5 % pro svůj zakládající tým, zatímco drtivá většina zůstane těm, kteří váš projekt skutečně pohánějí."
        },
        {
          headline: "Vestavění ambasadoři",
          text: "Od prvního dne máte tisíce spoluvlastníků, kteří jsou připraveni sdílet a podporovat váš produkt."
        }
      ],
      conclusion:
        "Pokud chcete tvořit eticky a otevřeně, Ctrl AI vám nabízí platformu, financování a uživatelskou základnu—all pod rámcem, který klade důraz na lidské blaho."
    },
  
    pitchProcess: {
      category: "Pitch & Financování",
      title: "Vaše cesta k úspěchu podpořenému komunitou",
      subtitle:
        "Jakmile zajistíme našich prvních 1M zakládajících členů, můžete svůj projekt představit komunitě Ctrl AI. Pokud bude schválen, získáte měsíční financování, okamžitou zpětnou vazbu a vestavěné publikum, které chce vidět váš úspěch.",
      citation:
        "Zajistěte si až **$10k/měsíc na zakladatele**, jakmile váš nápad projde schválením komunity—bez podmínek kromě dodržování ústavy Ctrl AI.",
      pointsTitle: "Jak to funguje:",
      points: [
        {
          headline: "Navrhněte svůj nápad",
          text: "Odešlete krátký pitch s vaší vizí, týmem (pokud nějaký máte) a tím, jak budete dodržovat poměr 95%/5%—95 % pro komunitu, 5 % pro zakládající skupinu."
        },
        {
          headline: "Hlasování komunity",
          text: "Všichni členové Ctrl AI váš návrh posoudí a hlasují. Pokud dosáhnete požadovaného prahu podpory, máte zelenou k financování."
        },
        {
          headline: "Měsíční plat",
          text: "Schválení zakladatelé mohou každý získat až **$10k/měsíc** z komunitního fondu—takže se můžete soustředit na budování, ne na nekonečné shánění financí."
        }
      ],
      conclusion:
        "Žádné nekonečné pitchování investorům. Žádné paywally. Jen skutečná podpora poháněná uživateli. Jak váš projekt roste, roste i vaše komunita—a tato synergie pohání udržitelný růst."
    },
  
    buildingInPublic: {
      category: "Budování na veřejnosti",
      title: "Sdílejte milníky, úspěchy & ponaučení každý týden",
      subtitle:
        "Pravidelné, transparentní aktualizace podporují odpovědnost a kolektivní nadšení. Vaši spoluvlastníci investovali do vašeho úspěchu a chtějí vidět, jak se vám daří.",
      citation:
        "Když má komunita skutečný podíl na věci, vaše triumfy—a výzvy—se stávají hluboce sdílenými zkušenostmi.",
      pointsTitle: "Základní principy:",
      points: [
        {
          headline: "Otevřené roadmapy",
          text: "Zveřejněte svůj vývojový plán, hlavní funkce a dlouhodobé cíle, aby každý věděl, co přijde dál."
        },
        {
          headline: "Testování a zpětná vazba od komunity",
          text: "Zapojte uživatele brzy a často. Spoluvlastníci mohou odhalit problémy, vylepšit funkce a stát se vašimi největšími ambasadory."
        },
        {
          headline: "Transparentní zprávy o pokroku",
          text: "Poskytujte týdenní nebo měsíční přehledy—zdůrazňující úspěchy, překážky a nadcházející milníky—aby zůstali zainteresovaní."
        }
      ],
      conclusion:
        "Nejde jen o vydávání softwaru; je to pozvání pro celou komunitu, aby se podílela na každém kroku. Autentická angažovanost buduje loajalitu—a vede k lepším produktům."
    },
  
    callToAction: {
      category: "Začněte",
      title: "Připraveni spustit svůj AI projekt?",
      subtitle:
        "Připojte se ke komunitě zavázané k etické, uživatelsky vlastněné inovaci. Představte svůj nápad, zajistěte financování a budujte s podporou tisíců—bez firemních podmínek.",
      pointsTitle: "Další kroky:",
      points: [
        {
          headline: "Pomozte nám dosáhnout 1M zakládajících členů",
          text: "Pitchování projektů otevřeme po dosažení prvního 1M uživatelů. Čím rychleji tohoto milníku dosáhneme, tím dříve můžete spustit."
        },
        {
          headline: "Připravte svůj pitch",
          text: "Návrh konceptu, definování, jak zajistíte **95% komunitní vlastnictví**, a sestavení zakládajícího týmu."
        },
        {
          headline: "Zapojte se včas a často",
          text: "Začněte se spojovat s členy na našich fórech. Čím více důvěry vybudujete nyní, tím plynulejší bude váš pitch proces."
        }
      ],
      conclusion:
        "**Ctrl AI je místem, kde se rodí transformační aplikace zítřka—poháněné globální, transparentní, uživatelsky orientovanou vizí.**"
    }
  },


  foundingMembers: {
    intro: {
      category: "Připojte se k hnutí",
      title: "Staňte se zakládajícím členem Ctrl AI",
      subtitle:
        "Zajistěte si své místo mezi prvními průkopníky, kteří formují budoucnost AI—dříve než si monopol upevní velké technologické společnosti.",
      citation:
        "Zakládající členové jsou životní silou Ctrl AI. Ratifikují naši ústavu, hlasují o klíčových rozhodnutích a nastavují tón pro revoluci v komunitní AI.",
      pointsTitle: "Proč se stát zakládajícím členem?",
      points: [
        {
          headline: "Exkluzivní raný vliv",
          text: "Buďte mezi prvními 1M články v našem **Řetězci vlastnictví**, získáte nejvyšší Control Points a trvalý podíl na etické AI."
        },
        {
          headline: "Globální zastoupení",
          text: "Kvóty podle zemí zajišťují, že žádný region nebude dominovat."
        },
        {
          headline: "Žádné reklamy, žádní investoři—jen komunita",
          text: "Fungujeme na principu **jednorázového ověření za $1**—bez vlivu investorů a komerčních reklam."
        }
      ],
      conclusion:
        "Jste připraveni pomoci vybudovat spravedlivou, uživatelsky vlastněnou AI budoucnost? Zajistěte si svůj článek řetězu, připojte se k zakladatelům a veďte Ctrl AI od prvního dne."
    },
  
    callToAction: {
      category: "Podnikněte akci",
      title: "Získejte své místo a formujte budoucnost",
      subtitle:
        "Připojení je okamžité—bez čekací listiny nebo skrytých kroků. Ověřte svou identitu za $1, získejte svůj článek řetězu a pomozte vést AI revoluci.",
      pointsTitle: "Jak to probíhá:",
      points: [
        {
          headline: "Jednorázové ověření za $1",
          text: "Ověřte, že jste skutečný člověk, chraňte naši komunitu před boty a zajistěte si své unikátní číslo v řetězu."
        },
        {
          headline: "Významná rozhodovací moc",
          text: "Získejte rané **Control Points**, pokud jste mezi prvními vlnami, což vám dává větší vliv na klíčová AI rozhodnutí."
        },
        {
          headline: "Ústava & hlasování",
          text: "Pomozte finalizovat naši závaznou ústavu. Pokud by se Ctrl AI někdy odklonilo od své mise, máte moc nasměrovat ho zpět."
        }
      ],
      conclusion:
        "**Vybudujme AI platformu skutečně vlastněnou jejími uživateli.** Připojte se nyní, zajistěte si status zakládajícího člena a formujte budoucnost Ctrl AI zevnitř."
    }
  },
  
  ownership: {
    intro: {
      category: "Vlastnictví",
      title: "Síla kolektivního vlastnictví",
      subtitle:
        "Ctrl AI je **99% vlastněné uživateli**—zajišťuje, že žádná korporace nebo investor nemůže dominovat naší platformě. Rozdělením vlastnictví mezi miliony lidí vytváříme skutečně demokratický AI ekosystém.",
      citation:
        "**Vlastnictví v Ctrl AI není jen fráze**—je to vaše skutečné právo řídit naši budoucnost, utvářet naše zásady a podílet se na hodnotě, kterou společně vytváříme.",
      pointsTitle: "Proč na tom záleží:",
      points: [
        {
          headline: "Kontrola & rovnováha",
          text: "Když je 99 % vlastnictví rozděleno mezi uživatele, žádná malá elita nemůže vnutit rozhodnutí, která škodí komunitě."
        },
        {
          headline: "Skutečná odpovědnost",
          text: "Uživatelé mají právo veta prostřednictvím hlasování a mohou vyžadovat transparentnost, což zajišťuje etický a spravedlivý vývoj."
        },
        {
          headline: "Dlouhodobá stabilita",
          text: "Komunitně řízené platformy jsou odolnější vůči tržním výkyvům a investorskému tlaku na krátkodobé zisky."
        }
      ],
      conclusion:
        "**Jako člen Ctrl AI nejen využíváte platformu—spoluvlastníte a řídíte ji.** To zabraňuje AI kolonialismu a udržuje moc v rukou obyčejných lidí."
    },
  
    controlPoints: {
      category: "Control Points",
      title: "Vlastnictví & řízení na dosah ruky",
      subtitle:
        "Když se připojíte k Ctrl AI získáním svého článku řetězu, obdržíte blok **Control Points** podle vlny, ve které se připojíte. Tyto body vám dávají přímý vliv na klíčová rozhodnutí platformy.",
      citation:
        "**Připojte se brzy a zajistěte si vyšší Control Points**—garantující větší hlas v tom, jak se Ctrl AI vyvíjí.",
      pointsTitle: "Klíčové detaily:",
      points: [
        {
          headline: "Přidělení bodů podle vlny",
          text: "**Prvních 1M článků řetězu** získá **100 Control Points**. Pozdější členové mají stále hlas, ale méně bodů na začátku."
        },
        {
          headline: "Hlasování & návrhy",
          text: "**Použijte Control Points k hlasování** o nových funkcích, rozpočtech a etických zásadách. Máte skutečnou moc formovat platformu."
        },
        {
          headline: "Navrženo pro řízení, ne spekulaci",
          text: "Žádné denní přibývání bodů ani kryptoměnové spekulace—jen jasný systém založený na vlnách, který podporuje spolupráci před ziskem."
        }
      ],
      conclusion:
        "**Control Points jsou vaším klíčem k skutečné AI demokracii**—umožňují vám podílet se na rozhodnutích, která mají význam, aniž by se Ctrl AI stalo spekulativním trhem."
    }
  },
  
  callToAction: {
    category: "Podnikněte akci",
    title: "Vlastněte svou budoucnost s Ctrl AI",
    subtitle:
      "Nespokojte se se světem, kde AI monopolizuje hrstka korporací. Připojte se k Ctrl AI, získejte svůj článek řetězu a pomozte vytvořit transparentní budoucnost pro všechny.",
    pointsTitle: "Další kroky:",
    points: [
      {
        headline: "Získejte svůj článek řetězu",
        text: "Ověřte se za **$1**, získejte své trvalé číslo v řetězu a okamžitě si zajistěte svůj podíl na vlastnictví—bez čekací listiny."
      },
      {
        headline: "Pozvěte ostatní",
        text: "Řetěz je silnější s každým novým článkem. Sdílejte Ctrl AI a pomozte vytvořit etický AI ekosystém."
      },
      {
        headline: "Formujte budoucí AI",
        text: "Jakmile jste uvnitř, **použijte své Control Points k hlasování**, předkládání návrhů a ujištění, že Ctrl AI zůstane skutečně řízené uživateli."
      }
    ],
    conclusion:
      "**Vlastnictví je síla.** V Ctrl AI tato síla patří vám. Připojte se nyní, zůstaňte aktivní a pomozte nám přepsat pravidla AI pro všechny."
  },



  constitutionPage: {
    hero: {
      title: "Ústava Ctrl AI",
      preamble:
        "V době, kdy technologie stále více formuje budoucnost lidstva, volíme jinou cestu. **Nejen lepší produkty, ale lepší principy. Nejen inovace, ale etický pokrok. Nejen uživatelské zapojení, ale lidské blaho.**"
    },
    principles: {
      title: "Naše závazné principy",
      intro:
        "Nejde o pouhé směrnice nebo doporučení—jsou to **závazné závazky**, které musí dodržovat každý projekt pod Ctrl AI. Jsou vynucovány dohledem komunity a pravidelnými audity:",
      principles: [
        {
          title: "Lidské blaho na prvním místě",
          items: [
            "Navrhování pro skutečný lidský rozvoj, ne pro metriky zapojení",
            "Nulová tolerance k manipulativním praktikám",
            "Zacházení s uživatelskou pozorností jako s cenným zdrojem",
            "Podpora přirozených bodů zastavení a zdravých vzorců používání",
            "Upřednostňování smysluplného propojení před návykovým zapojením"
          ]
        },
        {
          title: "Absolutní práva na data",
          items: [
            "Uživatelé plně vlastní svá data—bez výjimek",
            "Žádný prodej dat nebo neoprávněné sdílení",
            "Zpracování dat **lokálně**, kdykoli je to možné",
            "Šifrování vojenské úrovně jako standard",
            "Zaručené **právo být zapomenut**—zcela a trvale"
          ]
        },
        {
          title: "Skutečná moc komunity",
          items: [
            "Minimálně **95% vlastnictví komunitou**—garantováno ústavou",
            "Přímé demokratické řízení klíčových rozhodnutí",
            "Závazná komunitní hlasování o směru vývoje",
            "Spravedlivé a transparentní rozdělování Control Points",
            "Rovná práva pro všechny členy, bez ohledu na jejich přínos"
          ]
        },
        {
          title: "Etické hranice AI",
          items: [
            "AI **slouží lidské autonomii**—nikdy ji neomezuje",
            "Úplná transparentnost v rozhodovacích procesech AI",
            "Nepřetržité monitorování a minimalizace zaujatosti",
            "Jasná možnost lidského zásahu do všech AI funkcí",
            "Vývoj AI zaměřený na posilování lidských schopností"
          ]
        },
        {
          title: "Blaho týmu",
          items: [
            "**Čtyřdenní pracovní týden** jako ústavní právo",
            "Komplexní podpora duševního zdraví",
            "Financované neustálé vzdělávání pro všechny",
            "Žádné **vykořisťování pracovní doby**",
            "Spravedlivé rozdělení zisku",
            "Práce na dálku s možností osobní spolupráce",
            "Striktní hranice mezi prací a osobním životem",
            "Pravidelné týmové akce"
          ]
        },
        {
          title: "Vzdělání na prvním místě",
          items: [
            "Vestavěná vzdělávací hodnota ve všech produktech",
            "Návrh odpovídající věku uživatele",
            "Aktivní podpora digitální gramotnosti",
            "Zapojení více stran do vzdělávacího procesu",
            "Nulová tolerance vůči vykořisťování mladých uživatelů",
            "**Zákaz manipulace** zaměřené na mladé uživatele"
          ]
        },
        {
          title: "Radikální transparentnost",
          items: [
            "**Týdenní veřejné aktualizace** vývoje",
            "Úplná finanční transparentnost",
            "Otevřená komunikace o výzvách",
            "**Open-source** přístup jako výchozí stav",
            "Pravidelná zasedání pro zapojení komunity"
          ]
        },
        {
          title: "Udržitelný vývoj",
          items: [
            "Přirozený růst místo umělé virality",
            "Expanze řízená komunitou",
            "**Zákaz vykořisťovatelského marketingu**",
            "Důraz na ekologický dopad",
            "Dlouhodobá udržitelnost místo krátkodobých zisků"
          ]
        },
        {
          title: "Spravedlivý příjem",
          items: [
            "**Žádný příjem z reklam**",
            "Transparentní cenové modely odpovídající hodnotě",
            "Jasná komunikace o nákladech a přínosech",
            "**Přímé sdílení zisků s komunitou**",
            "Modely přizpůsobené nákladům nebo **pay-what-you-can**"
          ]
        },
        {
          title: "Inovace s cílem",
          items: [
            "Zaměření na **skutečné lidské potřeby**",
            "Inovace sloužící **komunitě**",
            "Otevřená spolupráce jako standard",
            "Technologie ve službách **lidstva**",
            "Zohlednění **společenského dopadu** v každém rozhodnutí"
          ]
        }
      ]
    },
    enforcement: {
      title: "Skutečná vynutitelnost, skutečná odpovědnost",
      content:
        "**Tyto principy nejsou pouhé sliby—jsou to závazná pravidla s reálnými důsledky:**",
      measures: [
        {
          title: "Dohled komunity",
          items: [
            "• **Čtvrtletní veřejné audity**",
            "• Systém hlášení porušení v reálném čase",
            "• Přímá zpětná vazba od komunity",
            "• **Nezávislá etická kontrolní rada**"
          ]
        },
        {
          title: "Jasné důsledky",
          items: [
            "• **Okamžitá reakce na porušení**",
            "• Náprava vedená komunitou",
            "• Povinná opatření k nápravě",
            "• **Veřejné zprávy o odpovědnosti**"
          ]
        },
        {
          title: "Živý dokument",
          items: [
            "• **Každoroční revize komunity**",
            "• Aktualizace přizpůsobené technologii",
            "• **Neměnné základní principy**",
            "• **Demokratický proces úprav**"
          ]
        }
      ]
    },
    cta: {
      title: "Připojte se k revoluci etické technologie",
      content:
        "**Tohle není jen další prohlášení technologické společnosti. Toto je závazná ústava pro tvorbu technologií, které slouží lidstvu.**\n\nAť už jste zakladatel připravený tvořit pod těmito principy, nebo uživatel připravený vlastnit to, co používáte, budoucnost etické technologie začíná zde.",
      button: "Získejte svůj článek řetězu ($1)"
    }
  },


  footer: {
    companyDescription: "Budujeme budoucnost AI s komunitním vlastnictvím a etickými principy.",
    navigation: "Navigace",
    legal: {
      title: "Právní informace",
      privacy: "Zásady ochrany osobních údajů",
      terms: "Podmínky služby",
      cookies: "Zásady používání cookies"
    },
    connect: "Další",
    privacy: "Zásady ochrany osobních údajů",
    terms: "Podmínky služby",
    cookies: "Zásady používání cookies",
    rights: "Všechna práva vyhrazena."
  },
  
  nav: {
    // Hlavní navigace
    chain: "Řetězec důvěry",
    community: "Komunita",
    founders: "Zakladatelé",
    ownership: "Vlastnictví",
    constitution: "Ústava",
  
    // Uživatelské menu
    dashboard: "Nástěnka",
    signOut: "Odhlásit se",
    login: "Přihlásit se",
    getVerified: "Ověřit účet",
  
    // Dialog
    joinDialog: {
      title: "Připojit se k Ctrl AI"
    }
  },
  
  auth: {
    login: {
      title: "Vítejte zpět",
      continueWithGoogle: "Pokračovat přes Google",
      orDivider: "Nebo pokračujte s e-mailem",
      email: "E-mail",
      password: "Heslo",
      signIn: "Přihlásit se",
      processing: "Probíhá zpracování...",
      noAccount: "Nemáte účet?",
      signUp: "Zaregistrovat se",
      errors: {
        loginFailed: "Přihlášení se nezdařilo. Zkontrolujte své údaje."
      }
    },
    signup: {
      title: "Připojte se k řetězu",
      titleAdditional: "Získat další článek řetězu",
      
      fullName: "Celé jméno",
      email: "E-mail",
      
      continue: "Pokračovat k platbě",
      processing: "Probíhá zpracování...",
      
      existingLinks: "Již máte {{count}} článek řetězu",
      existingLinksPlural: "Již máte {{count}} články řetězu",
      earliestLink: "Váš nejstarší článek je #{{number}}",
      
      errors: {
        required: "Všechna pole jsou povinná",
        invalidEmail: "Neplatný formát e-mailu",
        checkoutFailed: "Nepodařilo se vytvořit platební sezení",
        generic: "Nepodařilo se zahájit platbu. Zkuste to znovu."
      }
    },
    errors: {
      passwordLength: "Heslo musí mít alespoň 6 znaků",
      invalidEmail: "Zadejte platnou e-mailovou adresu",
      emailInUse: "Tento e-mail je již používán"
    },
    passwordRequirements: "Heslo musí mít alespoň 6 znaků",
    
    joinForm: {
      // Tlačítka
      continueWithGoogle: "Pokračovat přes Google",
      signUpWithEmail: "Zaregistrovat se e-mailem",
      processing: "Probíhá zpracování...",
      
      // Popisky formuláře
      email: "E-mail",
      fullName: "Celé jméno",
      password: "Heslo",
      referralCode: "Referral kód",
      
      // Placeholdery
      emailPlaceholder: "Zadejte svůj e-mail",
      fullNamePlaceholder: "Zadejte své celé jméno",
      passwordPlaceholder: "Vytvořte heslo",
      referralCodePlaceholder: "Zadejte referral kód (volitelné)",
      
      // Oddělovače a odkazy
      orDivider: "Nebo se zaregistrujte e-mailem",
      alreadyHaveAccount: "Již máte účet?",
      signIn: "Přihlásit se",
      termsAgreement: "Souhlasím s",
      termsLink: "Podmínkami služby",
      
      // Chyby
      errors: {
        allFieldsRequired: "Všechna pole jsou povinná",
        acceptTerms: "Musíte souhlasit s Podmínkami služby",
        passwordLength: "Heslo musí mít alespoň 6 znaků",
        paymentFailed: "Nepodařilo se zahájit platbu. Zkuste to znovu."
      },
      referralCodeProvided: "Doporučeno uživatelem"
    }
  },
  
  quotaMap: {
    stats: {
      totalQuota: "Celková globální kvóta",
      totalRegistered: "Celkový počet registrovaných",
      totalPopulation: "Celková populace",
      countryCount: "Zastoupené země",
      fulfillment: "Globální naplnění kvóty"
    },
    tooltip: {
      country: "Země",
      population: "Populace",
      quota: "Kvóta členů",
      registered: "Registrovaní členové",
      fulfillment: "Naplnění kvóty"
    },
    legend: {
      title: "Naplnění kvóty",
      low: "Nízké",
      target: "Cíl",
      exceeded: "Překročeno"
    },
    controls: {
      reset: "Obnovit pohled",
      zoom: "Přiblížení",
      pan: "Posun"
    },
    noData: "Pro tuto zemi nejsou k dispozici žádná data",
    loading: "Načítání dat mapy..."
  },
  
  social: {
    share: {
      title: "Sdílet",
      twitter: "Sdílet na X",
      linkedin: "Sdílet na LinkedIn",
      copyText: "Kopírovat text",
      copied: "Zkopírováno!",
      messages: {
        twitter: "🚀 Právě jsem se připojil k Ctrl AI – hnutí, které vrací AI do rukou lidí! Připojte se přes můj odkaz {{url}} a budujme společně to, co skutečně vlastníme.",
        linkedin: "Připojil jsem se k Ctrl AI – hnutí, které demokratizuje vývoj AI. Budujeme alternativu ke korporátní AI, kterou vlastní uživatelé. Připojte se přes můj odkaz {{url}} a buďte součástí této změny.",
        general: "Připojte se k Ctrl AI přes můj odkaz {{url}} a pomozte budovat AI, kterou vlastní uživatelé, ne korporace."
      }
    }
  },
  
  error: {
    verification: {
      failed: "Ověření selhalo. Zkuste to prosím znovu.",
      alreadyVerified: "Účet je již ověřen.",
      paymentFailed: "Nepodařilo se zahájit platbu. Zkuste to prosím znovu."
    }
  }
}
