import { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { auth } from '@/config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

interface EmailSignupFormProps {
  verificationData: any;
  onComplete: (userId: string) => Promise<void>;
  onError: (error: string) => void;
}

export function EmailSignupForm({ verificationData, onComplete, onError }: EmailSignupFormProps) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!verificationData.email) {
        throw new Error('Email is required');
      }

      // Create Firebase account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        verificationData.email,
        password
      );

      // Complete verification process
      await onComplete(userCredential.user.uid);
    } catch (error: any) {
      console.error('Email signup error:', error);
      onError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={verificationData?.email || ''}
          disabled
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="password">Password</Label>
        <Input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={8}
          placeholder="Create a password"
        />
      </div>

      <Button
        type="submit"
        className="w-full"
        disabled={loading}
      >
        {loading ? 'Creating Account...' : 'Create Account'}
      </Button>
    </form>
  );
} 