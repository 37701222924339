import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { useUserStore } from '@/stores/useUserStore';
import { useStripePayment } from '@/hooks/useStripePayment';
import { RecentJoins } from '@/features/chain/components/RecentJoins';
import { motion } from 'framer-motion';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { JoinForm } from '@/features/dashboard/components/JoinForm';
import { useState } from 'react';

export default function ChainPage() {
  const { t } = useTranslation();
  const { profile, user } = useUserStore();
  const { handleVerificationPayment, loading } = useStripePayment();
  const [showJoinDialog, setShowJoinDialog] = useState(false);

  const handleJoinChain = async () => {
    if (!user) {
      setShowJoinDialog(true);
      return;
    }
    
    try {
      await handleVerificationPayment(user.email || '', {
        userId: user.uid,
        email: user.email || '',
        fullName: user.displayName || ''
      });
    } catch (error) {
      console.error('Error starting verification:', error);
    }
  };
  
  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-background to-background/95 py-32">
        <motion.div 
          className="max-w-3xl mx-auto px-4 md:px-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-8 flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
            <div>
              <h1 className="text-4xl font-semibold tracking-tight">
                {t('nav.chain')}
              </h1>
              <p className="text-sm text-muted-foreground mt-1">
                {t('chain.description')}
              </p>
            </div>

            {!profile?.verified && (
              <Button 
                size="lg"
                onClick={handleJoinChain}
                disabled={loading}
                className="animate-pulse hover:animate-none rounded-full"
              >
                {loading ? t('common.processing') : t('chain.join.button')}
              </Button>
            )}
          </div>

          <RecentJoins limit={100} showLoadMore={true} />
        </motion.div>
      </div>

      <Dialog open={showJoinDialog} onOpenChange={setShowJoinDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('nav.joinDialog.title')}</DialogTitle>
          </DialogHeader>
          <JoinForm onClose={() => setShowJoinDialog(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
} 