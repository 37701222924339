export const pt = {

hero: {
  title: "A OpenAI Não É Mais Aberta.",
  subtitle: "Este É o Único Movimento Que Coloca a Humanidade em Primeiro Lugar.",
  chainCall: "Controle Pelas Pessoas, Não Pelas Corporações."
},

problem: {
  category: "Os Fatos",
  title: "ChatGPT: 300M+ Usuários",
  subtitle: "$157B+ em Avaliação",
  subheadline: "0% de Propriedade dos Usuários"
},

problem2: {
  title: "Estamos Construindo o Ctrl AI",
  subtitle: "99% Pertencente aos Usuários. Isso Significa Você."
},

opportunity: {
  points: {
    control: "Você Começa com 300 Pontos de Controle",
    bring: "Conecte UMA Pessoa em 24 Horas",
    fail: "Falhar = Perder 200 Pontos Para Sempre"
  },
  note: "Desta Vez, Você Realmente Possui o Que Ajuda a Construir."
},

howItWorks: {
  title: "A Verdadeira Alternativa",
  points: [
    {
      title: "Fase 1 / Unir 1M de Humanos",
      bullets: [
        "Sem capital de risco, sem controle corporativo",
        "Poder do povo, elo por elo",
        "Cada usuário é um verdadeiro proprietário"
      ]
    },
    {
      title: "Fase 2 / Financiar 100 Equipes",
      bullets: [
        "Reconstruir aplicativos que usamos diariamente, aqueles que extraem nossos dados e os vendem ao maior lance",
        "Criar ferramentas de IA que realmente possuímos",
        "Controle democrático das funcionalidades",
        "Seus dados continuam sendo seus"
      ]
    },
    {
      title: "Fase 3 / Crescer para 1B de Humanos",
      bullets: [
        "Verdadeira alternativa à IA corporativa",
        "Voz global no desenvolvimento de IA",
        "Poder real através de propriedade real"
      ]
    }
  ]
},

  cta: {
    category: "A Escolha",
    title: "Aberto vs Controle",
    subtitle: "A OpenAI nos mostrou o que acontece quando 'aberto' se torna fechado. A Ctrl AI garante que isso nunca mais aconteça, dando 99% da propriedade aos usuários desde o primeiro dia.",
    subheadline: "Você vai assumir o controle?",
    citation: "Sou Rado. Criei a Ctrl AI porque vi a transformação da OpenAI de uma organização sem fins lucrativos para um gigante de $157 bilhões. Desta vez, vamos construir algo que realmente possuímos juntos.",
    buttons: {
      primary: "Assuma o Controle ($1)",
      secondary: "Permanecer Impotente"
    },
    note: "$1 prova que você é humano. Sem bots, sem investidores—apenas pessoas unidas."
  },


  aspiringFounders: {
    intro: {
      category: "Fundadores & Criadores",
      title: "Construa Sua Visão com o Ctrl AI",
      subtitle: "Transforme sua ideia de IA ou aplicativo em realidade com o apoio de uma comunidade global. Sem investidores predatórios, sem perseguir dólares de anúncios — apenas inovação com propósito, totalmente alinhada com o bem-estar dos usuários.",
      citation: "“Por que buscar capital de risco quando você pode acessar uma base de usuários co-proprietários desde o primeiro dia?”",
      pointsTitle: "Por Que Construir com o Ctrl AI?",
      points: [
        {
          headline: "Centrado no Usuário, Não em Anúncios",
          text: "Esqueça algoritmos manipulativos — nossa Constituição exige transparência e utilidade genuína."
        },
        {
          headline: "90% de Propriedade da Comunidade",
          text: "Mantenha até 10% para sua equipe fundadora, enquanto a maior parte permanece com aqueles que realmente impulsionam o projeto."
        },
        {
          headline: "Evangelistas Integrados",
          text: "Desde o primeiro dia, você conta com milhares de embaixadores que são co-proprietários, prontos para compartilhar e apoiar seu produto."
        }
      ],
      conclusion: "Se você está motivado a criar de forma ética e aberta, o Ctrl AI oferece a plataforma, o financiamento e a base de usuários de que você precisa — tudo sob um framework que prioriza o bem-estar humano."
    },

    pitchProcess: {
      category: "Proposta & Financiamento",
      title: "Seu Caminho para o Sucesso com Apoio da Comunidade",
      subtitle: "Assim que os primeiros 100 mil Membros Fundadores do Ctrl AI estiverem ativos, você poderá apresentar seu projeto à nossa comunidade global. Se aprovado, você receberá financiamento mensal, feedback ativo dos usuários e uma rede de parceiros pronta para ajudar você a vencer.",
      citation: "“Garanta até $10 mil por fundador assim que sua ideia for aprovada pela comunidade — sem amarras além de respeitar a Constituição do Ctrl AI.”",
      pointsTitle: "Como Funciona:",
      points: [
        {
          headline: "Proponha Sua Ideia",
          text: "Envie uma proposta curta detalhando sua visão, equipe e como você seguirá o modelo 90%/10% (com pelo menos 20% indo para o núcleo do Ctrl AI e 70% para apoiadores diretos)."
        },
        {
          headline: "Votação da Comunidade",
          text: "Os membros do Ctrl AI revisam e votam em seu projeto. Se você atingir o nível de suporte necessário, estará apto a receber financiamento."
        },
        {
          headline: "Salário Mensal",
          text: "Cada fundador aprovado pode receber até $10 mil/mês, retirados de fundos financiados pelos membros — para que você possa se concentrar em construir, não em arrecadar fundos."
        }
      ],
      conclusion: "Sem rodadas intermináveis de capital de risco. Sem paywalls. Apenas apoio tangível e impulsionado pelos usuários. Quando seu projeto cresce, seus co-proprietários crescem com você."
    },

    buildingInPublic: {
      category: "Construção Aberta",
      title: "Compartilhe Marcos, Conquistas e Lições Semanalmente",
      subtitle: "Atualizações regulares mantêm seus apoiadores e co-proprietários informados — promovendo transparência, responsabilidade e uma sensação de empolgação coletiva.",
      citation: "“Quando a comunidade tem participação direta, o sucesso — e os desafios — do seu projeto tornam-se experiências verdadeiramente compartilhadas.”",
      pointsTitle: "Princípios Fundamentais:",
      points: [
        {
          headline: "Roteiros Abertos",
          text: "Publique sua linha do tempo de desenvolvimento, recursos principais e metas de longo prazo, para que todos saibam o que vem a seguir."
        },
        {
          headline: "Teste e Feedback da Comunidade",
          text: "Integre loops de feedback dos usuários cedo e com frequência. Seus co-proprietários podem identificar problemas, aprimorar recursos e impulsionar seu crescimento."
        },
        {
          headline: "Relatórios de Progresso Transparentes",
          text: "Compartilhe instantâneos semanais ou mensais — destacando conquistas, desafios e próximos passos — para manter os stakeholders engajados."
        }
      ],
      conclusion: "É mais do que apenas atualizações de software; é um convite para que sua comunidade realmente molde e celebre sua jornada. O engajamento autêntico gera lealdade — e produtos melhores."
    },

    callToAction: {
      category: "Comece Agora",
      title: "Pronto para Lançar Seu Projeto de IA?",
      subtitle: "Junte-se a uma comunidade comprometida com a inovação ética e de propriedade do usuário. Apresente sua ideia, garanta financiamento e construa com o apoio de milhares — sem amarras corporativas.",
      pointsTitle: "Próximos Passos:",
      points: [
        {
          headline: "Alcançar 100 mil Membros Fundadores",
          text: "Assim que garantirmos nossa onda inicial de membros, a plataforma de propostas será aberta — então compartilhe o Ctrl AI para acelerar esse marco."
        },
        {
          headline: "Prepare Sua Proposta",
          text: "Comece a esboçar seu conceito, reúna sua equipe (ou vá solo) e finalize como você seguirá a estrutura de propriedade 90%/10%."
        },
        {
          headline: "Engaje-se Cedo e Frequentemente",
          text: "Participe de nossos fóruns comunitários para obter feedback inicial sobre sua ideia. Quanto mais conexões você construir agora, mais suave será o processo de proposta."
        }
      ],
      conclusion: "**O Ctrl AI é onde os aplicativos transformadores de amanhã ganham forma — com o apoio de uma comunidade global, financiamento transparente e uma ética centrada no usuário.**"
    }
  },


  foundingMembers: {
    intro: {
      category: "Junte-se ao Movimento",
      title: "Torne-se um Membro Fundador do Ctrl AI",
      subtitle: "Garanta seu lugar em uma coalizão global que está moldando o futuro da IA — sem entregar todo o poder a um punhado de gigantes da tecnologia.",
      citation: "Os Membros Fundadores são a essência do Ctrl AI. Eles definem nossa Constituição, votam em decisões importantes e estabelecem o tom para uma revolução de IA impulsionada pelas pessoas.",
      pointsTitle: "Por Que Ser um Membro Fundador?",
      points: [
        {
          headline: "Governança Exclusiva Antecipada",
          text: "Os Membros Fundadores ganham Pontos de Controle diários, permitindo que você oriente os principais projetos de IA antes de qualquer outra pessoa."
        },
        {
          headline: "Cotas Globais",
          text: "Alocamos lugares com base no tamanho do país, garantindo que a voz de cada nação seja ouvida desde o primeiro dia."
        },
        {
          headline: "Sem Anúncios, Sem Investidores, Apenas Comunidade",
          text: "As taxas de sua associação financiam nossa plataforma — sem acionistas corporativos. É a verdadeira democracia aplicada à IA."
        }
      ],
      conclusion: "Pronto para ajudar a construir um futuro justo e de propriedade do usuário para a IA? Junte-se à nossa lista de espera e comece a garantir sua vaga de Membro Fundador hoje."
    },

    waitlistMechanics: {
      category: "Lista de Espera e Pontos",
      title: "Como Funciona a Lista de Espera",
      subtitle: "Acreditamos em construir um movimento de verdadeiros crentes — não de espectadores passivos. Por isso, convidamos os principais contribuintes da lista de espera para se tornarem Membros Fundadores.",
      citation: "“Garanta sua vaga compartilhando, colaborando e demonstrando um compromisso genuíno com a visão do Ctrl AI.”",
      pointsTitle: "Ganhando Pontos:",
      points: [
        {
          headline: "Complete Seu Perfil",
          text: "Forneça informações básicas para que saibamos quem você é e qual região você representa."
        },
        {
          headline: "Convide Amigos e Compartilhe nas Redes Sociais",
          text: "Oferecemos publicações pré-escritas que você pode personalizar e, em seguida, colar o link de prova no seu painel. Nossa equipe verifica manualmente e atribui os pontos."
        },
        {
          headline: "Engaje-se com a Comunidade",
          text: "Em breve, você poderá participar de perguntas e respostas, adicionar feedback a propostas ou ajudar a responder perguntas de novos membros. Cada ação significativa pode aumentar sua classificação."
        }
      ],
      conclusion: "Assim que você atingir um determinado limite de pontos ou estiver entre os principais contribuintes, convidaremos você a ativar seu status de Membro Fundador."
    },

    callToAction: {
      category: "Tome uma Ação",
      title: "Garanta Seu Lugar e Molde o Futuro",
      subtitle: "Revisamos regularmente a lista de espera e selecionamos novos Membros Fundadores. Mostre-nos seu compromisso e ajude a espalhar a palavra para subir na classificação mais rapidamente.",
      pointsTitle: "O Que Acontece Após Ser Escolhido:",
      points: [
        {
          headline: "Taxa de Associação: $1 (anual)",
          text: "Inicie sua associação oficial. Esta pequena taxa mantém o Ctrl AI independente — sem anúncios ou investidores de risco."
        },
        {
          headline: "Pontos de Controle Diários",
          text: "Ganhe poder de governança a cada dia de associação. Influencie tudo, desde recursos de aplicativos até diretrizes éticas."
        },
        {
          headline: "Votação e Ratificação da Constituição",
          text: "Ajude a finalizar e aplicar nossa Constituição vinculativa. Se algum dia nos desviarmos, você terá o direito (e a responsabilidade) de nos colocar de volta no caminho certo."
        }
      ],
      conclusion: "**Vamos construir uma plataforma de IA verdadeiramente pertencente aos seus usuários.** Junte-se à lista de espera hoje, participe para ganhar pontos e seja um dos primeiros a assumir o Controle da IA."
    }
  },
   
  
  ownership: {
    intro: {
      category: "Propriedade",
      title: "O Poder da Propriedade Coletiva",
      subtitle: "Ctrl AI é 99% de propriedade dos usuários — garantindo que nenhuma corporação, investidor ou país possa dominar nossa plataforma. Ao distribuir a propriedade entre milhões (eventualmente bilhões) de membros, criamos um ecossistema de IA genuinamente democrático.",
      citation: "“Propriedade no Ctrl AI não é apenas uma palavra da moda — é seu direito de direcionar nosso futuro, moldar nossas políticas e compartilhar o valor que criamos juntos.”",
      pointsTitle: "Por Que Isso Importa:",
      points: [
        {
          headline: "Freios e Contrapesos",
          text: "Quando 99% da propriedade é distribuída entre os usuários, nenhuma minoria pode impor decisões que prejudiquem a comunidade."
        },
        {
          headline: "Responsabilidade Real",
          text: "Os usuários têm poder de veto por meio de votos e podem exigir transparência, garantindo um desenvolvimento ético e justo."
        },
        {
          headline: "Estabilidade de Longo Prazo",
          text: "Plataformas impulsionadas pela comunidade são mais resilientes às oscilações do mercado e aos acionistas focados no lucro."
        }
      ],
      conclusion: "Como membro, você não apenas usa o Ctrl AI — você o possui e o direciona. É assim que evitamos o Colonialismo de IA e garantimos que a IA permaneça impulsionada pelas pessoas."
    },

    controlPoints: {
      category: "Pontos de Controle",
      title: "Propriedade Contínua e Poder de Governança",
      subtitle: "Cada membro ativo ganha 1 Ponto de Controle por dia — acumulando continuamente enquanto sua assinatura permanecer ativa. Esses Pontos de Controle dão a você influência direta sobre as principais decisões da plataforma.",
      citation: "“Junte-se cedo e permaneça ativo: quanto mais tempo você for membro, mais Pontos de Controle acumulará e maior será sua influência na evolução do Ctrl AI.”",
      pointsTitle: "Como Funciona:",
      points: [
        {
          headline: "Acumulação Diária",
          text: "Seus Pontos de Controle crescem em 1 a cada dia que sua assinatura estiver ativa. Não há limite — membros de longa data naturalmente ganham mais influência."
        },
        {
          headline: "Votação e Propostas",
          text: "Use os Pontos de Controle para votar em novas propostas de recursos, diretrizes éticas, alocação de orçamento e muito mais."
        },
        {
          headline: "Conversão Opcional para On-Chain",
          text: "Após 2 anos, você pode converter seus Pontos de Controle em tokens — mas isso é totalmente opcional. Permaneça off-chain se preferir manter as coisas simples."
        }
      ],
      conclusion: "Os Pontos de Controle são projetados para governança, não para especulação. Eles refletem seu compromisso contínuo e concedem a você uma verdadeira voz em como o Ctrl AI evolui."
    },

    communityModel: {
      category: "Modelo Centrado no Usuário",
      title: "99% de Propriedade da Comunidade",
      subtitle: "Dividimos a propriedade de uma forma que empodera os usuários sem prejudicar o desenvolvimento. Grandes decisões — desde lançamentos de aplicativos até privacidade de dados — sempre permanecem nas mãos da maioria.",
      citation: "“Imagine um futuro onde suas ferramentas de IA realmente pertencem a você, não a conglomerados impulsionados por anúncios ou investidores de risco.”",
      pointsTitle: "Características Principais do Nosso Modelo:",
      points: [
        {
          headline: "Projetos de Fundadores (90/10)",
          text: "Subprojetos são construídos sob o Ctrl AI com no mínimo 90% de propriedade da comunidade e 10% para a equipe fundadora — mantendo os interesses dos usuários no centro."
        },
        {
          headline: "Governança Direta",
          text: "Vote em grandes decisões, proponha mudanças e molde o futuro do Ctrl AI."
        },
        {
          headline: "Representação Global",
          text: "Cotas de membros por país garantem que os votos de nenhuma região possam sobrepujar outros. A propriedade é distribuída mundialmente."
        }
      ],
      conclusion: "Ao limitar a participação individual e distribuir o restante entre milhões de usuários, garantimos que o Ctrl AI permaneça um ecossistema global, sem fronteiras e focado no usuário."
    },

    callToAction: {
      category: "Tome uma Ação",
      title: "Assuma o Controle do Seu Futuro com o Ctrl AI",
      subtitle: "Não se contente com um mundo onde a IA é monopolizada. Junte-se ao Ctrl AI, ganhe Pontos de Controle diariamente e mantenha o desenvolvimento da IA honesto, centrado no ser humano e transparente.",
      pointsTitle: "Próximos Passos:",
      points: [
        {
          headline: "Junte-se à Lista de Espera",
          text: "Garanta sua vaga de membro e comece a acumular Pontos de Controle assim que for convidado."
        },
        {
          headline: "Compartilhe e Ganhe Pontos na Lista de Espera",
          text: "Aumente sua classificação referindo amigos e espalhando a palavra — ajude-nos a atingir nossas metas globais de adesão mais rapidamente."
        },
        {
          headline: "Molde a IA do Amanhã",
          text: "Uma vez dentro, use seus Pontos de Controle crescentes para votar, propor mudanças e manter nossa plataforma fiel à sua missão."
        }
      ],
      conclusion: "**Propriedade é poder** — e no Ctrl AI, esse poder pertence a você. Inscreva-se, mantenha-se ativo e nos ajude a reescrever as regras da propriedade de IA para todos."
    }
  },


  constitutionPage: {
    hero: {
      title: "A Constituição do Ctrl AI",
      preamble: "Em uma era em que a tecnologia molda cada vez mais o futuro da humanidade, escolhemos um caminho diferente. **Não apenas melhores produtos, mas melhores princípios. Não apenas inovação, mas progresso ético. Não apenas engajamento do usuário, mas o florescimento humano.**"
    },
    principles: {
      title: "Nossos Princípios Vinculantes",
      intro: "Esses não são apenas diretrizes ou sugestões — são compromissos obrigatórios que todo projeto sob o Ctrl AI deve seguir, aplicados por meio de supervisão comunitária e auditorias regulares:",
      principles: [
        {
          title: "Bem-Estar Humano em Primeiro Lugar",
          items: [
            "Projetar para o verdadeiro florescimento humano, não para métricas de engajamento",
            "Tolerância zero a padrões obscuros ou manipulação psicológica",
            "Tratar a atenção do usuário como um recurso precioso a ser respeitado",
            "Construir pontos de parada naturais e padrões de uso saudáveis",
            "Priorizar conexões significativas em vez de engajamento viciante"
          ]
        },
        {
          title: "Direitos Absolutos sobre Dados",
          items: [
            "Os usuários possuem completamente seus dados — sem exceções",
            "Zero venda de dados ou compartilhamento não autorizado",
            "Processamento local sempre que possível",
            "Criptografia de nível militar como padrão",
            "Direito garantido ao esquecimento — completa e permanentemente"
          ]
        },
        {
          title: "Poder Real da Comunidade",
          items: [
            "Mínimo de 90% de propriedade da comunidade — garantido pela constituição",
            "Governança democrática direta sobre decisões importantes",
            "Votos vinculativos da comunidade sobre a direção do produto",
            "Distribuição justa e matemática de tokens",
            "Direitos iguais, independentemente do tamanho da contribuição"
          ]
        },
        {
          title: "Limites Éticos da IA",
          items: [
            "IA serve à agência humana — nunca a diminui",
            "Transparência total nos processos de decisão da IA",
            "Monitoramento contínuo de vieses e sua eliminação",
            "Clara possibilidade de intervenção humana em todos os recursos de IA",
            "Desenvolvimento de IA focado no empoderamento humano"
          ]
        },
        {
          title: "Bem-Estar da Equipe",
          items: [
            "Semana de trabalho de quatro dias como um direito constitucional",
            "Suporte abrangente à saúde mental",
            "Aprendizado contínuo financiado para todos",
            "Zero exploração em períodos de alta demanda",
            "Compartilhamento equitativo de lucros",
            "Prioridade no trabalho remoto com colaboração opcional",
            "Limites rigorosos entre trabalho e vida pessoal",
            "Eventos regulares de conexão da equipe"
          ]
        },
        {
          title: "Educação em Primeiro Lugar",
          items: [
            "Valor educacional embutido em todos os produtos",
            "Design e segurança apropriados para a idade",
            "Promoção ativa da alfabetização digital",
            "Abordagem educacional com múltiplos stakeholders",
            "Zero exploração de jovens usuários",
            "Proibição de manipulação direcionada a jovens"
          ]
        },
        {
          title: "Transparência Radical",
          items: [
            "Atualizações públicas de desenvolvimento semanais",
            "Transparência financeira completa",
            "Comunicação direta sobre desafios",
            "Código aberto como padrão",
            "Sessões regulares de engajamento da comunidade"
          ]
        },
        {
          title: "Desenvolvimento Sustentável",
          items: [
            "Crescimento natural em vez de viralidade artificial",
            "Expansão impulsionada pela comunidade apenas",
            "Proibição de marketing exploratório",
            "Consideração do impacto ambiental",
            "Sustentabilidade de longo prazo sobre ganhos de curto prazo"
          ]
        },
        {
          title: "Receita Justa",
          items: [
            "Zero receita baseada em anúncios",
            "Preço transparente baseado em valor",
            "Comunicação clara de custo-benefício",
            "Distribuição direta de lucros para a comunidade",
            "Preço alinhado ao custo apenas"
          ]
        },
        {
          title: "Inovação com Propósito",
          items: [
            "Foco nas reais necessidades humanas",
            "Inovação a serviço da comunidade apenas",
            "Princípios de colaboração aberta",
            "Tecnologia a serviço da humanidade",
            "Consideração do impacto social em todas as decisões"
          ]
        }
      ]
    },
    enforcement: {
      title: "Aplicação Real, Responsabilidade Real",
      content: "**Estes princípios não são promessas — são compromissos vinculantes com consequências reais:**",
      measures: [
        {
          title: "Supervisão Comunitária",
          items: [
            "• Auditorias públicas trimestrais",
            "• Relatórios em tempo real de violações",
            "• Sistemas diretos de feedback da comunidade",
            "• Conselho independente de revisão ética"
          ]
        },
        {
          title: "Consequências Claras",
          items: [
            "• Resposta imediata a violações",
            "• Correções impulsionadas pela comunidade",
            "• Remediação obrigatória",
            "• Relatórios públicos de responsabilidade"
          ]
        },
        {
          title: "Documento Vivo",
          items: [
            "• Revisão anual pela comunidade",
            "• Atualizações adaptativas à tecnologia",
            "• Princípios centrais imutáveis",
            "• Processo democrático de emendas"
          ]
        }
      ]
    },
    cta: {
      title: "Junte-se à Revolução da Tecnologia Ética",
      content: "**Esta não é apenas mais uma declaração de missão de uma empresa de tecnologia. Esta é uma constituição vinculante para construir tecnologia que serve à humanidade.** Se você é um fundador pronto para construir de forma diferente ou um usuário pronto para possuir o que usa, o futuro da tecnologia ética começa aqui.",
      button: "Junte-se aos Membros Fundadores ($1)"
    }
  },


  footer: {
    companyDescription: "Construindo o futuro da IA com propriedade comunitária e princípios éticos.",
    navigation: "Navegação",
    legal: "Legal",
    connect: "Outros",
    privacy: "Política de Privacidade",
    terms: "Termos de Serviço",
    cookies: "Política de Cookies",
    rights: "Todos os direitos reservados."
  },


  nav: {
    community: "Membros Fundadores",
    founders: "Fundadores",
    ownership: "Propriedade",
    constitution: "Constituição",
    pricing: "Preços",
    dashboard: "Painel",
    myProfile: "Meu Perfil",
    settings: "Configurações",
    home: "Início",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Candidate-se como Fundador",
    about: "Sobre o Ctrl AI",
    logout: "Sair"
  },


  auth: {
    login: {
      title: "Bem-vindo de Volta",
      subtitle: "Continue sua jornada na construção do futuro",
      emailPlaceholder: "Digite seu e-mail",
      passwordPlaceholder: "Digite sua senha",
      submitButton: "Entrar",
      noAccount: "Não tem uma conta?",
      signupLink: "Inscreva-se como Fundador",
      error: "E-mail ou senha inválidos"
    },
    signup: {
      title: "Inscreva-se como Fundador",
      subtitle: "Comece a construir o futuro da tecnologia",
      namePlaceholder: "Digite seu nome completo",
      emailPlaceholder: "Digite seu e-mail",
      passwordPlaceholder: "Escolha uma senha",
      submitButton: "Criar Conta",
      hasAccount: "Já tem uma conta?",
      loginLink: "Entrar",
      error: "Erro ao criar conta"
    },
    errors: {
      passwordLength: "A senha deve ter pelo menos 6 caracteres",
      invalidEmail: "Por favor, insira um endereço de e-mail válido",
      emailInUse: "Este e-mail já está em uso"
    },
    passwordRequirements: "A senha deve ter pelo menos 6 caracteres"
  },


  pricing: {
    title: "Junte-se ao Movimento",
    subtitle: "Participe do movimento para construir e possuir o futuro da tecnologia",
    tiers: {
      founding: {
        title: "Membro Fundador",
        price: "$1",
        description: "Para aqueles que querem impulsionar a mudança",
        features: [
          "1 Ponto de Controle diário",
          "Acesso prioritário a novos projetos",
          "Molde o futuro da tecnologia",
          "Distintivo exclusivo de membro fundador",
          "3 códigos de convite para amigos"
        ]
      }
    },
    footer: {
      quote: "Em vinte anos, as pessoas vão se perguntar como alguém perdeu essa oportunidade. Não seja a pessoa que explica por que esperou.",
    }
  },
 
      
  modals: {
    inviteCode: {
      title: "Resgate Seu Código de Convite",
      description: "Insira seu código de convite para garantir sua vaga como Membro Fundador. Cada código pode ser usado apenas uma vez.",
      placeholder: "Digite o código de convite",
      submit: "Junte-se como Membro Fundador",
      invalidCode: "Código de convite inválido ou expirado. Por favor, tente novamente.",
      success: "Bem-vindo ao Ctrl AI! Concluindo seu registro...",
      validating: "Validando...",
      alreadyUsed: "Este código já foi usado",
      expired: "Este código expirou",
      validated: "Código validado! Redirecionando para a inscrição..."
    },
    waitlist: {
      title: "Junte-se à Lista de Espera",
      description: "Junte-se à nossa lista de espera exclusiva para obter acesso antecipado ao Ctrl AI.",
      namePlaceholder: "Seu nome completo",
      emailPlaceholder: "Seu endereço de e-mail",
      passwordPlaceholder: "Crie uma senha",
      submit: "Entrar na Lista de Espera",
      submitting: "Entrando...",
      error: "Algo deu errado. Por favor, tente novamente.",
      success: "Bem-vindo! Você está na lista."
    }
  },
  waitlist: {
    status: {
      title: "Seu Status na Lista de Espera",
      totalInWaitlist: "De um total de {{total}} pessoas na fila",
      referralCode: "Seu Código de Referência",
      copyLink: "Copiar Link",
      referralDescription: "Compartilhe seu código de referência com amigos para subir na lista de espera. Cada indicação vale 50 pontos!",
      points: "Progresso dos Pontos",
    },
    actions: {
      twitter: "Compartilhar no Twitter",
      linkedin: "Compartilhar no LinkedIn",
      discord: "Entrar no Discord",
      profile: "Completar Perfil"
    }
  },
  onboarding: {
    title: "Bem-vindo ao Ctrl AI",
    subtitle: "Vamos configurar tudo para seu sucesso",
    points: "pontos",
    continue: "Continuar",
    finish: "Concluir Configuração",
    steps: {
      profile: {
        title: "Complete Seu Perfil",
        description: "Adicione uma foto de perfil e uma bio para que outros possam conhecê-lo melhor."
      },
      social: {
        title: "Compartilhe Sua Jornada",
        description: "Compartilhe sua empolgação por fazer parte do Ctrl AI com sua rede."
      },
      community: {
        title: "Junte-se à Comunidade",
        description: "Conecte-se com outros membros na nossa comunidade do Discord."
      }
    }
  },

  dashboard: {
    welcome: "Bem-vindo de volta, {{name}}",
    membershipTiers: {
      founding: "Membro Fundador",
      collective: "Membro Coletivo"
    },
    tabs: {
      projects: "Projetos",
      community: "Comunidade",
      rewards: "Recompensas"
    },
    community: {
      title: "Atividade da Comunidade",
      discussions: {
        title: "Discussões Recentes",
        empty: "Nenhuma discussão ainda",
        start: "Iniciar uma Discussão"
      },
      activity: {
        title: "Atividade Recente",
        empty: "Sem atividade recente"
      },
      stats: {
        members: "Membros Ativos",
        discussions: "Discussões",
        contributions: "Contribuições"
      }
    },
    projects: {
      title: "Projetos Ativos",
      empty: "Nenhum projeto ativo",
      explore: "Explorar Projetos",
      create: "Criar Projeto"
    },
    rewards: {
      title: "Suas Recompensas",
      points: "Pontos de Controle",
      achievements: "Conquistas",
      empty: "Nenhuma recompensa ainda"
    },
    quickActions: {
      title: "Ações Rápidas",
      createProject: "Criar Projeto",
      startDiscussion: "Iniciar Discussão",
      updateProfile: "Atualizar Perfil"
    }
  },

       global: {
    title: "Acompanhe o Crescimento Global da Nossa Lista de Espera",
    subtitle: "Crescimento da Comunidade em Tempo Real",
    content: "Cada ponto representa alguém competindo por uma vaga como Membro Fundador. Ainda não tem um código de convite? Entre na lista de espera e melhore sua posição completando desafios.",
    stats: {
      totalMembers: "Total de Membros",
      activeToday: "Ativos Hoje",
      averagePoints: "Pontos Médios"
    },
    controls: {
      pause: "Pausar e Interagir",
      resume: "Continuar Tour",
      next: "Próximo Continente"
    }
  },

  quotaMap: {
    stats: {
      totalQuota: "Cota Global Total",
      totalRegistered: "Total Registrado",
      totalPopulation: "População Total",
      countryCount: "Países Representados",
      fulfillment: "Cumprimento Global"
    },
    tooltip: {
      country: "País",
      population: "População",
      quota: "Cota de Membros",
      registered: "Membros Registrados",
      fulfillment: "Cumprimento da Cota"
    },
    legend: {
      title: "Cumprimento da Cota",
      low: "Baixo",
      target: "Alvo",
      exceeded: "Excedido"
    },
    controls: {
      reset: "Redefinir Visualização",
      zoom: "Zoom",
      pan: "Mover"
    },
    noData: "Nenhum dado disponível para este país",
    loading: "Carregando dados do mapa..."
  },

  signupForm: {
    title: {
      waitlist: "Junte-se à Lista de Espera",
      verified: "Crie Sua Conta"
    },
    subtitle: {
      waitlist: "Entre para nossa lista de espera exclusiva e obtenha acesso antecipado",
      verified: "Você foi convidado a ser um membro fundador"
    },
    fields: {
      fullName: "Nome Completo",
      email: "E-mail",
      password: "Senha"
    },
    buttons: {
      submit: "Continuar",
      submitting: "Criando Conta...",
      google: "Continuar com Google"
    },
    errors: {
      emailExists: "Este e-mail já está na lista de espera. Por favor, verifique seu e-mail para instruções de verificação.",
      emailInUse: "Este e-mail já está registrado. Tente fazer login.",
      waitlistCheck: "Não foi possível verificar o status da lista de espera. Por favor, tente novamente.",
      createAccount: "Falha ao criar conta. Por favor, tente novamente."
    },
    inviteCode: {
      prompt: "Tem um código de convite?",
      button: "Inserir Código de Convite"
    }
  },
      
  waitlistDashboard: {
    title: "Seu Status na Lista de Espera",
    position: "Posição {{position}}",
    total: "De um total de {{total}} pessoas",
    stats: {
      referrals: "Indicações",
      points: "Pontos",
      status: "Status",
      active: "Ativo",
      position: "Posição",
      completion: "Conclusão",
      earnMore: "Ganhe mais pontos",
      completeProfile: "Complete seu perfil para melhorar sua posição",
      total: "De um total de {{total}} pessoas"
    },
    share: {
      title: "Compartilhe & Ganhe Pontos",
      description: "Compartilhe seu link de referência com amigos para subir na lista de espera. Cada indicação vale pontos!",
      copy: "Copiar Link",
      copied: "Link Copiado!",
      buttons: {
        twitter: "Compartilhar no Twitter",
        linkedin: "Compartilhar no LinkedIn"
      }
    },
    earnPoints: {
      title: "Maneiras de Ganhar Pontos",
      items: [
        "Complete seu perfil",
        "Compartilhe nas redes sociais",
        "Indique amigos",
        "Participe da nossa comunidade no Discord"
      ]
    },
    overview: {
      journey: "Sua Jornada para se Tornar Membro Fundador",
      profileCompletion: "Conclusão do Perfil",
      socialSharing: "Compartilhamento nas Redes Sociais",
      tasks: {
        title: "Tarefas Disponíveis",
        completeProfile: "Complete Seu Perfil",
        completeProfileDesc: "Adicione suas informações básicas para que possamos conhecê-lo melhor",
        addLocation: "Adicione Sua Localização",
        addLocationDesc: "Informe onde você está localizado"
      }
    },
    tabs: {
      overview: "Visão Geral",
      tasks: "Ganhar Pontos",
      social: "Compartilhar & Ganhar"
    },
    socialShare: {
      title: "Compartilhe & Ganhe Pontos",
      description: "Compartilhe sobre o Ctrl AI em suas redes sociais para ganhar pontos e melhorar sua posição",
      verificationPending: "Seu compartilhamento no {{platform}} foi enviado para verificação"
    },
    progress: {
      items: {
        photo: "Foto de Perfil",
        basicInfo: "Informações Básicas",
        location: "Localização",
        skills: "Habilidades & Interesses"
      }
    },
    welcome: {
      title: "Bem-vindo",
      location: "{{city}}, {{country}}"
    },
    breadcrumbs: {
      waitlist: "Lista de Espera",
      dashboard: "Painel"
    }
  },
      
  userProfile: {
    title: "Seu Perfil",
    breadcrumbs: {
      waitlist: "Lista de Espera",
      dashboard: "Painel",
      profile: "Perfil"
    },
    fields: {
      fullName: "Nome Completo",
      location: "Localização",
      bio: "Fale um pouco sobre você..."
    },
    buttons: {
      save: "Salvar Perfil",
      saving: "Salvando..."
    },
    completion: {
      title: "Conclusão do Perfil",
      status: {
        complete: "Seu perfil está completo! Obrigado por fornecer todas as informações.",
        incomplete: "Complete seu perfil para ganhar pontos adicionais e melhorar sua posição na lista de espera!"
      }
    },
    toast: {
      success: {
        withPoints: "Perfil atualizado! Você ganhou {{points}} pontos!",
        default: "Perfil atualizado com sucesso"
      },
      error: "Falha ao atualizar o perfil. Por favor, tente novamente."
    }
  },

  progressSection: {
    complete: "Concluído",
    points: "+{{points}} pontos",
    viewDetails: "Ver Detalhes",
    completeTasks: "Concluir Tarefas",
    percentComplete: "{{percent}}% Concluído"
  },

  
  social: {
    templates: {
      twitter: [
        {
          text: "Acabei de me juntar ao movimento #CtrlAI! 🚀 Construindo um futuro de IA de propriedade dos usuários, onde nenhum gigante da tecnologia pode monopolizar.\n\nAs vagas são limitadas por país — garanta a sua agora: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#UserOwned", "#AIRevolution"]
        },
        {
          text: "Se queremos uma voz real na IA, precisamos crescer organicamente! Estou ajudando @CtrlAI a alcançar 100 mil Membros Fundadores.\n\nJunte-se a mim e garanta sua vaga antes que a cota do seu país se esgote: {{REFERRAL_LINK}}",
          hashtags: ["#TakeControl", "#DigitalFreedom", "#FutureOfTech"]
        }
      ],
      linkedin: [
        {
          text: "Estou animado para compartilhar que entrei na lista de espera do Ctrl AI — uma plataforma impulsionada pelas pessoas que desafia o monopólio da IA pelas grandes empresas de tecnologia.\n\nPor que isso importa:\n• 99% de propriedade dos usuários (sem mais conselhos corporativos tomando as decisões)\n• Pontos de Controle diários = verdadeiro poder de governança\n• Cotas por país para garantir representação global\n\nExistem apenas 100.000 vagas para Membros Fundadores. Junte-se a mim na reformulação do futuro da IA pelo boca a boca (sem anúncios, sem investidores de risco)!\n\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechRevolution", "#DigitalFreedom", "#AI"]
        },
        {
          text: "Garanti minha vaga na lista de espera do Ctrl AI para co-possuirmos a tecnologia do amanhã! Imagine uma IA que presta contas às pessoas, não aos acionistas.\n\n• 99% de propriedade dos usuários\n• IA transparente e ética\n• Sem orçamentos massivos de anúncios — apenas nós, espalhando a palavra\n\nCorra, cada país tem um limite de vagas para Membros Fundadores. Saiba mais: {{REFERRAL_LINK}}",
          hashtags: ["#AIRevolution", "#UserOwned", "#EthicalTech", "#FutureOfTech"]
        }
      ],
      instagram: [
        {
          text: "Assumir o controle da IA começa agora! ⚡\n\nEntrei na lista de espera do Ctrl AI para ajudar a construir aplicativos de propriedade dos usuários — sem envolvimento de grandes empresas de tecnologia.\n\nNós só crescemos pelo boca a boca, então confira se a cota de Membro Fundador do seu país ainda está aberta!\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechRevolution", "#UserOwned", "#DigitalFreedom", "#AI"]
        },
        {
          text: "A corrida por 100.000 Membros Fundadores já começou!\n\nCtrl AI = 99% de propriedade dos usuários, voz real em como a tecnologia evolui. Sem anúncios, sem investidores de risco, sem coleta de dados.\n\nAs vagas no seu país são limitadas — junte-se agora: {{REFERRAL_LINK}}",
          hashtags: ["#AIRevolution", "#TakeControl", "#NoMoreBigTech", "#FutureOfTech"]
        }
      ],
      facebook: [
        {
          text: "Estou entrando na lista de espera do Ctrl AI para ajudar a criar um mundo onde a IA seja realmente de propriedade dos usuários, não dominada por algumas megacorporações.\n\nCom 99% da propriedade nas mãos de pessoas reais, podemos garantir uma IA ética e transparente. Mas estamos crescendo exclusivamente pelo boca a boca — sem gastos com anúncios, sem investidores de risco.\n\nExistem apenas 100.000 vagas para Membros Fundadores, divididas por país para manter a justiça. Pronto para transformar a tecnologia? Junte-se aqui: {{REFERRAL_LINK}}",
          hashtags: ["#TakeControl", "#DigitalFreedom", "#UserOwned", "#AI"]
        },
        {
          text: "Já imaginou possuir de verdade os aplicativos que você usa? Ctrl AI está tornando isso realidade! Estou na lista de espera e quero que VOCÊ também se junte a mim.\n\nNão podemos depender de anúncios para espalhar a palavra — este tem que ser um movimento impulsionado pelas pessoas. Seu país tem uma cota limitada, então garanta sua vaga agora: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#FutureOfTech", "#AIRevolution"]
        }
      ],
      youtube: [
        {
          text: "Junte-se a mim na mudança de como a IA é construída! Ctrl AI é uma plataforma impulsionada pelas pessoas, 99% de propriedade de seus usuários. Sem anúncios, sem corporações controladoras, apenas democracia real na tecnologia.\n\nJunte-se pelo meu link: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#AI", "#Innovation", "#TechRevolution"]
        },
        {
          text: "A IA não deve ser reservada a um punhado de gigantes. Eu faço parte do Ctrl AI, e você também pode! Contamos apenas com o boca a boca para crescer, então espalhe a mensagem — existem apenas 100 mil vagas no mundo.\n\nJunte-se aqui: {{REFERRAL_LINK}}",
          hashtags: ["#DigitalFreedom", "#UserOwned", "#AI", "#FutureOfTech"]
        }
      ],
      tiktok: [
        {
          text: "POV: Você acabou de descobrir um movimento onde VOCÊ pode co-possuír o futuro da IA 🤖\n\nCtrl AI é 99% de propriedade dos usuários, sem grandes corporações ou investidores obscuros. Contamos com as pessoas para crescer. Junte-se pelo meu link: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechTok", "#AIRevolution", "#UserOwned"]
        },
        {
          text: "Quer se libertar do domínio das grandes empresas de tecnologia? Junte-se ao Ctrl AI! 🚀 Só temos 100 mil vagas para Membros Fundadores globalmente. Se a cota do seu país ainda estiver aberta, garanta sua vaga e ajude a espalhar a palavra: {{REFERRAL_LINK}}",
          hashtags: ["#TechRevolution", "#AI", "#DigitalFreedom", "#TakeControl"]
        }
      ],
      github: [
        {
          text: "Acabei de entrar na lista de espera do Ctrl AI para co-possuír e co-desenvolver aplicativos de IA éticos! Sem anúncios, sem investidores de risco, tudo código aberto e comunidade.\n\nAs vagas de Membro Fundador do seu país podem ser limitadas — junte-se aqui: {{REFERRAL_LINK}}",
          hashtags: ["#AI", "#OpenSource", "#UserOwned", "#EthicalTech"]
        },
        {
          text: "Amigos desenvolvedores: Cansados de monopólios das grandes empresas de tecnologia? Ctrl AI está construindo uma nova era de IA de propriedade dos usuários. Crescemos apenas por meio da comunidade, então se você é apaixonado por colaboração aberta, garanta seu Membro Fundador agora: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#Innovation", "#OpenSource", "#FutureOfTech"]
        }
      ]
    }
  }
      }
