import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signOut,
  User
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  serverTimestamp, 
  FieldValue,
  getDoc
} from 'firebase/firestore';
import { auth, db } from '@/config/firebase';
import { UserRole, MembershipStatus, MembershipTier, CreateUserProfileData } from '@/types/user';
import { UserType } from '@/features/onboarding/types';

interface SignupResult {
  user: User;
  redirectTo: string;
}

interface UserProfile {
  email: string;
  fullName: string;
  role: UserRole;
  membershipTier: MembershipTier;
  membershipStatus: MembershipStatus;
  membershipStartDate?: FieldValue | null;
  waitlistJoinDate?: FieldValue | null;
}

export interface SignupData {
  email: string;
  password: string;
  name: string;
  type?: 'subscription' | 'invite' | 'waitlist' | 'founder';
  metadata?: {
    subscriptionTier?: string;
    intentId?: string;
    inviteCode?: string;
    referralCode?: string;
  };
  userType?: UserType;
}

export class AuthService {
  // Helper method to create user profile
  private async createUserProfile(userId: string, data: Partial<CreateUserProfileData>) {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      ...data,
      createdAt: serverTimestamp(),
      membershipStartDate: data.membershipStartDate ?? null,
      waitlistJoinDate: data.waitlistJoinDate ?? null
    } as CreateUserProfileData);
  }

  // Helper method to create waitlist entry
  private async createWaitlistEntry(
    userId: string,
    email: string,
    name: string,
    referralCode?: string
  ) {
    try {
      // 1. Create main waitlist document
      const waitlistEntry = {
        userId,
        email,
        fullName: name,
        joinDate: serverTimestamp(),
        status: 'pending',
        referredBy: referralCode || null,
        referralCount: 0,
        completedActions: []
      };

      // 2. Create the user profile first
      await this.createUserProfile(userId, {
        email,
        fullName: name,
        role: UserRole.WAITLIST,
        membershipTier: MembershipTier.WAITLIST,
        membershipStatus: MembershipStatus.PENDING,
        membershipStartDate: null,
        waitlistJoinDate: serverTimestamp()
      });

      // 3. Create the waitlist entry
      await setDoc(doc(db, 'waitlist', userId), waitlistEntry);

      // 4. Initialize the actions subcollection with an empty document
      await setDoc(
        doc(db, 'waitlist', userId, 'actions', 'initial'), 
        {
          type: 'signup',
          timestamp: serverTimestamp(),
          verified: true
        }
      );

      return waitlistEntry;
    } catch (error) {
      console.error('Error creating waitlist entry:', error);
      throw error;
    }
  }

  // Main signup method with different flows
  async signup(data: SignupData): Promise<void> {
    // Implementation
  }

  async signInWithGoogle(params?: {
    type?: 'subscription' | 'invite' | 'waitlist' | 'founder';
    metadata?: {
      subscriptionTier?: string;
      intentId?: string;
      inviteCode?: string;
      referralCode?: string;
    };
  }) {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    
    // Check if this is a new user
    const isNewUser = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
    
    if (isNewUser) {
      const type = params?.type || 'waitlist';
      let role = UserRole.WAITLIST;
      let status = MembershipStatus.PENDING;
      let membershipTier = MembershipTier.WAITLIST;
      let redirectTo = '/waitlist/status';

      switch (type) {
        case 'subscription':
          role = params?.metadata?.subscriptionTier === 'founding' ? 
            UserRole.FOUNDING_MEMBER : UserRole.COLLECTIVE_MEMBER;
          status = MembershipStatus.ACTIVE;
          membershipTier = params?.metadata?.subscriptionTier === 'founding' ? 
            MembershipTier.FOUNDING : MembershipTier.COLLECTIVE;
          redirectTo = '/onboarding';
          break;
        case 'invite':
          role = UserRole.FOUNDING_MEMBER;
          status = MembershipStatus.ACTIVE;
          membershipTier = MembershipTier.FOUNDING;
          redirectTo = '/onboarding';
          break;
      }

      await this.createUserProfile(result.user.uid, {
        email: result.user.email!,
        fullName: result.user.displayName!,
        role,
        membershipTier,
        membershipStatus: status,
        membershipStartDate: type === 'waitlist' ? null : serverTimestamp(),
        waitlistJoinDate: type === 'waitlist' ? serverTimestamp() : null
      });

      if (type === 'waitlist') {
        await this.createWaitlistEntry(
          result.user.uid,
          result.user.email!,
          result.user.displayName!,
          params?.metadata?.referralCode
        );
      }

      return { user: result.user, redirectTo };
    }

    // Existing user - get their profile and return appropriate redirect
    const userDoc = await getDoc(doc(db, 'users', result.user.uid));
    const userData = userDoc.data() as UserProfile;
    
    let redirectTo = '/dashboard';
    if (userData.membershipStatus === MembershipStatus.PENDING) {
      redirectTo = '/waitlist/status';
    }

    return { user: result.user, redirectTo };
  }

  async login(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Get user profile
      const userRef = doc(db, 'users', userCredential.user.uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User profile not found');
      }

      return userCredential;
    } catch (error) {
      console.error('Login error:', error);
      if (error instanceof Error) {
        switch (error.message) {
          case 'auth/user-not-found':
          case 'auth/wrong-password':
            throw new Error('Invalid email or password');
          case 'auth/too-many-requests':
            throw new Error('Too many failed attempts. Please try again later');
          default:
            throw new Error('Failed to log in. Please try again.');
        }
      }
      throw error;
    }
  }

  async logout() {
    return signOut(auth);
  }

  async signupWithInvite(
    email: string,
    password: string,
    name: string,
    inviteCode: string,
    membershipType: 'founding' | 'collective'
  ) {
    return this.signup({
      email,
      password,
      name,
      type: 'invite',
      metadata: {
        inviteCode,
        subscriptionTier: membershipType
      }
    });
  }

  async signupWithSubscription(
    email: string,
    password: string,
    name: string,
    subscription: {
      tier: string;
      intentId: string;
    }
  ) {
    return this.signup({
      email,
      password,
      name,
      type: 'subscription',
      metadata: {
        subscriptionTier: subscription.tier,
        intentId: subscription.intentId
      }
    });
  }

  async signupForWaitlist(
    email: string,
    password: string,
    name: string,
    referralCode?: string
  ): Promise<SignupResult> {
    try {
      // 1. First create the Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // 2. Update the user's display name
      await updateProfile(userCredential.user, {
        displayName: name
      });

      // 3. Create the user profile document
      await this.createUserProfile(userCredential.user.uid, {
        email,
        fullName: name,
        role: UserRole.WAITLIST,
        membershipTier: MembershipTier.WAITLIST,
        membershipStatus: MembershipStatus.PENDING,
        membershipStartDate: null,
        waitlistJoinDate: serverTimestamp(),
        controlPoints: 0,
        projectsContributed: 0
      });

      // 4. Create the waitlist entry
      const waitlistEntry = {
        userId: userCredential.user.uid,
        email,
        fullName: name,
        joinDate: serverTimestamp(),
        status: 'pending',
        referredBy: referralCode || null,
        referralCount: 0,
        completedActions: []
      };

      await setDoc(doc(db, 'waitlist', userCredential.user.uid), waitlistEntry);

      return {
        user: userCredential.user,
        redirectTo: '/waitlist/status'
      };
    } catch (error) {
      console.error('Waitlist signup error:', error);
      
      // If we failed after creating the user, try to clean up
      if (auth.currentUser) {
        try {
          await auth.currentUser.delete();
        } catch (deleteError) {
          console.error('Failed to clean up user after failed signup:', deleteError);
        }
      }
      
      throw error;
    }
  }
}

export const authService = new AuthService(); 