import { ReactNode } from 'react';
import { HeaderNavigation } from './HeaderNavigation';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';

interface RootLayoutProps {
  children?: ReactNode;
}

export function RootLayout({ children }: RootLayoutProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <HeaderNavigation />
      <main className="flex-grow">
        {children || <Outlet />}
      </main>
      <Footer />
    </div>
  );
}