import getUnicodeFlag from 'country-flag-icons/unicode'

// Map between topology IDs and ISO 3166-1 alpha-2 country codes
export const countryCodeMap: { [key: string]: string } = {
  '004': 'AF', // Afghanistan
  '008': 'AL', // Albania
  '012': 'DZ', // Algeria
  '024': 'AO', // Angola
  '032': 'AR', // Argentina
  '036': 'AU', // Australia
  '040': 'AT', // Austria
  '031': 'AZ', // Azerbaijan
  '050': 'BD', // Bangladesh
  '051': 'AM', // Armenia
  '056': 'BE', // Belgium
  '064': 'BT', // Bhutan
  '068': 'BO', // Bolivia
  '070': 'BA', // Bosnia and Herzegovina
  '072': 'BW', // Botswana
  '076': 'BR', // Brazil
  '096': 'BN', // Brunei
  '100': 'BG', // Bulgaria
  '104': 'MM', // Myanmar
  '108': 'BI', // Burundi
  '112': 'BY', // Belarus
  '116': 'KH', // Cambodia
  '120': 'CM', // Cameroon
  '124': 'CA', // Canada
  '140': 'CF', // Central African Republic
  '144': 'LK', // Sri Lanka
  '148': 'TD', // Chad
  '152': 'CL', // Chile
  '156': 'CN', // China
  '170': 'CO', // Colombia
  '178': 'CG', // Republic of the Congo
  '180': 'CD', // DR Congo
  '188': 'CR', // Costa Rica
  '191': 'HR', // Croatia
  '192': 'CU', // Cuba
  '203': 'CZ', // Czech Republic
  '208': 'DK', // Denmark
  '214': 'DO', // Dominican Republic
  '218': 'EC', // Ecuador
  '222': 'SV', // El Salvador
  '226': 'GQ', // Equatorial Guinea
  '231': 'ET', // Ethiopia
  '233': 'EE', // Estonia
  '246': 'FI', // Finland
  '250': 'FR', // France
  '268': 'GE', // Georgia
  '276': 'DE', // Germany
  '288': 'GH', // Ghana
  '300': 'GR', // Greece
  '320': 'GT', // Guatemala
  '324': 'GN', // Guinea
  '328': 'GY', // Guyana
  '332': 'HT', // Haiti
  '340': 'HN', // Honduras
  '348': 'HU', // Hungary
  '352': 'IS', // Iceland
  '356': 'IN', // India
  '360': 'ID', // Indonesia
  '364': 'IR', // Iran
  '368': 'IQ', // Iraq
  '372': 'IE', // Ireland
  '376': 'IL', // Israel
  '380': 'IT', // Italy
  '384': 'CI', // Côte d'Ivoire
  '388': 'JM', // Jamaica
  '392': 'JP', // Japan
  '398': 'KZ', // Kazakhstan
  '400': 'JO', // Jordan
  '404': 'KE', // Kenya
  '408': 'KP', // North Korea
  '410': 'KR', // South Korea
  '414': 'KW', // Kuwait
  '417': 'KG', // Kyrgyzstan
  '418': 'LA', // Laos
  '422': 'LB', // Lebanon
  '426': 'LS', // Lesotho
  '428': 'LV', // Latvia
  '430': 'LR', // Liberia
  '434': 'LY', // Libya
  '440': 'LT', // Lithuania
  '450': 'MG', // Madagascar
  '454': 'MW', // Malawi
  '458': 'MY', // Malaysia
  '462': 'MV', // Maldives
  '466': 'ML', // Mali
  '470': 'MT', // Malta
  '478': 'MR', // Mauritania
  '480': 'MU', // Mauritius
  '484': 'MX', // Mexico
  '496': 'MN', // Mongolia
  '498': 'MD', // Moldova
  '499': 'ME', // Montenegro
  '504': 'MA', // Morocco
  '508': 'MZ', // Mozambique
  '512': 'OM', // Oman
  '516': 'NA', // Namibia
  '524': 'NP', // Nepal
  '528': 'NL', // Netherlands
  '548': 'VU', // Vanuatu
  '554': 'NZ', // New Zealand
  '558': 'NI', // Nicaragua
  '562': 'NE', // Niger
  '566': 'NG', // Nigeria
  '578': 'NO', // Norway
  '586': 'PK', // Pakistan
  '591': 'PA', // Panama
  '598': 'PG', // Papua New Guinea
  '600': 'PY', // Paraguay
  '604': 'PE', // Peru
  '608': 'PH', // Philippines
  '616': 'PL', // Poland
  '620': 'PT', // Portugal
  '624': 'GW', // Guinea-Bissau
  '626': 'TL', // Timor-Leste
  '630': 'PR', // Puerto Rico
  '634': 'QA', // Qatar
  '642': 'RO', // Romania
  '643': 'RU', // Russia
  '646': 'RW', // Rwanda
  '652': 'BL', // Saint Barthélemy
  '654': 'SH', // Saint Helena
  '659': 'KN', // Saint Kitts and Nevis
  '660': 'AI', // Anguilla
  '662': 'LC', // Saint Lucia
  '666': 'PM', // Saint Pierre and Miquelon
  '670': 'VC', // Saint Vincent and the Grenadines
  '674': 'SM', // San Marino
  '678': 'ST', // São Tomé and Príncipe
  '682': 'SA', // Saudi Arabia
  '686': 'SN', // Senegal
  '688': 'RS', // Serbia
  '690': 'SC', // Seychelles
  '694': 'SL', // Sierra Leone
  '702': 'SG', // Singapore
  '703': 'SK', // Slovakia
  '704': 'VN', // Vietnam
  '705': 'SI', // Slovenia
  '706': 'SO', // Somalia
  '710': 'ZA', // South Africa
  '716': 'ZW', // Zimbabwe
  '724': 'ES', // Spain
  '728': 'SS', // South Sudan
  '729': 'SD', // Sudan
  '732': 'EH', // Western Sahara
  '740': 'SR', // Suriname
  '744': 'SJ', // Svalbard and Jan Mayen
  '748': 'SZ', // Eswatini
  '752': 'SE', // Sweden
  '756': 'CH', // Switzerland
  '760': 'SY', // Syria
  '762': 'TJ', // Tajikistan
  '764': 'TH', // Thailand
  '768': 'TG', // Togo
  '776': 'TO', // Tonga
  '780': 'TT', // Trinidad and Tobago
  '784': 'AE', // United Arab Emirates
  '788': 'TN', // Tunisia
  '792': 'TR', // Turkey
  '795': 'TM', // Turkmenistan
  '796': 'TC', // Turks and Caicos Islands
  '798': 'TV', // Tuvalu
  '800': 'UG', // Uganda
  '804': 'UA', // Ukraine
  '807': 'MK', // North Macedonia
  '818': 'EG', // Egypt
  '826': 'GB', // United Kingdom
  '831': 'GG', // Guernsey
  '832': 'JE', // Jersey
  '833': 'IM', // Isle of Man
  '834': 'TZ', // Tanzania
  '840': 'US', // United States
  '850': 'VI', // U.S. Virgin Islands
  '854': 'BF', // Burkina Faso
  '858': 'UY', // Uruguay
  '860': 'UZ', // Uzbekistan
  '862': 'VE', // Venezuela
  '876': 'WF', // Wallis and Futuna
  '882': 'WS', // Samoa
  '887': 'YE', // Yemen
  '894': 'ZM', // Zambia
};

export const getCountryFlag = (topologyId: string): string => {
  const isoCode = countryCodeMap[topologyId];
  return isoCode ? getUnicodeFlag(isoCode) : '';
};

export const getCountryName = (topologyId: string): string => {
  const names: { [key: string]: string } = {
    '004': 'Afghanistan',
    '008': 'Albania',
    '012': 'Algeria',
    // ... matching names for all countries in countryCodeMap
  };
  return names[topologyId] || 'Unknown Country';
}; 