import React, { useEffect } from 'react';
import { useThemeStore } from '../stores/themeStore';
import { Button } from './ui/button';
import { Sun, Moon } from 'lucide-react';

export const ThemeToggle: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useThemeStore();

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(isDarkMode ? 'dark' : 'light');
    
  }, [isDarkMode]);

  return (
    <Button 
      onClick={toggleDarkMode} 
      variant="ghost" 
      size="sm"
      title={`Current theme: ${isDarkMode ? 'dark' : 'light'}`}
    >
      {isDarkMode ? <Sun size={16} /> : <Moon size={16} />}
    </Button>
  );
};
