import { Dashboard } from '@/features/dashboard/components/Dashboard';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '@/stores/useUserStore';

export default function DashboardPage() {
  const { t } = useTranslation();
  const { loading } = useUserStore();
  
  if (loading) {
    return <div>Loading...</div>; // Or better loading component
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-background/95 py-32">
      <div className="max-w-5xl mx-auto">
        {/* Header Section */}
        <div className="mb-8 px-4">
          <h1 className="text-3xl font-bold tracking-tight">
            {t('dashboard.title')}
          </h1>
          <p className="text-sm text-muted-foreground mt-1">
            {t('dashboard.subtitle')}
          </p>
        </div>

        {/* Main Dashboard */}
        <Dashboard />

        {/* Timezone Info */}
        <div className="mt-8 text-center text-sm text-muted-foreground">
          {t('dashboard.timezone')}
        </div>
      </div>
    </div>
  );
} 