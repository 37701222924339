import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserStore } from '@/stores/useUserStore';
import { LoadingSpinner } from './LoadingSpinner';

interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles?: string[];
  requiresOnboarding?: boolean;
  isAdminRoute?: boolean;
}

export function ProtectedRoute({ 
  children, 
  allowedRoles = [], 
  requiresOnboarding = true,
  isAdminRoute = false
}: ProtectedRouteProps) {
  const { user, loading, initialized } = useUserStore();
  const location = useLocation();

  if (!initialized || loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!user) {
    if (isAdminRoute) {
      return <Navigate to="/admin/login" state={{ from: location.pathname }} replace />;
    }
    // Redirect to home instead of login
    return <Navigate to="/" state={{ from: location.pathname }} replace />;
  }

  // Render the protected content
  return <>{children}</>;
} 