import { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useStripePayment } from '@/hooks/useStripePayment';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { auth } from '@/config/firebase';
import { 
  GoogleAuthProvider, 
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile 
} from 'firebase/auth';
import { Loader2 } from 'lucide-react';
import { Label } from '@/components/ui/label';
import { Checkbox } from "@/components/ui/checkbox";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Google icon with official colors
const GoogleIcon = () => (
  <svg className="mr-2 h-4 w-4" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
  </svg>
);

interface SignupFormData {
  email: string;
  password: string;
  fullName: string;
  acceptedTerms: boolean;
  referralCode?: string;
}

interface LocationState {
  referralCode?: string;
}

export function JoinForm({ onClose }: { onClose?: () => void }) {
  const location = useLocation();
  const { t } = useTranslation();
  
  // Get referral code from URL state or localStorage
  const getReferralCode = () => {
    const stateReferralCode = (location.state as LocationState)?.referralCode;
    const storedReferralCode = localStorage.getItem('referralCode');
    return stateReferralCode || storedReferralCode || '';
  };

  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    password: '',
    fullName: '',
    acceptedTerms: false,
    referralCode: getReferralCode()
  });

  // Clear referral code from localStorage after using it
  useEffect(() => {
    if (formData.referralCode) {
      localStorage.removeItem('referralCode');
    }
  }, [formData.referralCode]);

  const [showFullForm, setShowFullForm] = useState(false);
  const [error, setError] = useState('');
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const { handleVerificationPayment } = useStripePayment();

  const proceedToPayment = async (userId: string, email: string, fullName: string) => {
    try {
      await handleVerificationPayment(email, {
        userId,
        email,
        fullName,
        referralCode: formData.referralCode
      });
    } catch (error: any) {
      console.error('Payment error:', error);
      setError(t('auth.joinForm.errors.paymentFailed'));
    }
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleLoading(true);
    setError('');

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      await proceedToPayment(
        result.user.uid,
        result.user.email || '',
        result.user.displayName || ''
      );
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const handleEmailSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsEmailLoading(true);
    setError('');

    try {
      // Validate form data
      if (!formData.email || !formData.password || !formData.fullName) {
        throw new Error(t('auth.joinForm.errors.allFieldsRequired'));
      }

      if (!formData.acceptedTerms) {
        throw new Error(t('auth.joinForm.errors.acceptTerms'));
      }

      if (formData.password.length < 6) {
        throw new Error(t('auth.joinForm.errors.passwordLength'));
      }

      // Create user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Update profile with full name
      await updateProfile(userCredential.user, {
        displayName: formData.fullName
      });

      // Proceed to payment
      await proceedToPayment(
        userCredential.user.uid,
        formData.email,
        formData.fullName
      );
    } catch (error: any) {
      console.error('Email signup error:', error);
      setError(error.message);
    } finally {
      setIsEmailLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <Button 
        onClick={handleGoogleSignIn}
        className="w-full"
        disabled={isGoogleLoading || isEmailLoading}
        variant="outline"
      >
        {isGoogleLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <GoogleIcon />
        )}
        {t('auth.joinForm.continueWithGoogle')}
      </Button>

      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            {t('auth.joinForm.orDivider')}
          </span>
        </div>
      </div>

      <form onSubmit={handleEmailSignup} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="email">{t('auth.joinForm.email')}</Label>
          <Input
            id="email"
            type="email"
            value={formData.email}
            onChange={(e) => {
              setFormData(prev => ({ ...prev, email: e.target.value }));
              if (!showFullForm && e.target.value.includes('@')) {
                setShowFullForm(true);
              }
            }}
            placeholder={t('auth.joinForm.emailPlaceholder')}
            required
            disabled={isGoogleLoading || isEmailLoading}
          />
        </div>

        {showFullForm && (
          <>
            <div className="space-y-2">
              <Label htmlFor="fullName">{t('auth.joinForm.fullName')}</Label>
              <Input
                id="fullName"
                value={formData.fullName}
                onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                placeholder={t('auth.joinForm.fullNamePlaceholder')}
                required
                disabled={isGoogleLoading || isEmailLoading}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="password">{t('auth.joinForm.password')}</Label>
              <Input
                id="password"
                type="password"
                value={formData.password}
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                placeholder={t('auth.joinForm.passwordPlaceholder')}
                required
                disabled={isGoogleLoading || isEmailLoading}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="terms"
                checked={formData.acceptedTerms}
                onCheckedChange={(checked) => 
                  setFormData(prev => ({ ...prev, acceptedTerms: checked as boolean }))
                }
              />
              <label
                htmlFor="terms"
                className="text-sm text-muted-foreground"
              >
                {t('auth.joinForm.termsAgreement')}{" "}
                <Link
                  to="/terms"
                  className="text-primary hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('auth.joinForm.termsLink')}
                </Link>
              </label>
            </div>
          </>
        )}

        {!formData.referralCode && (
          <div className="space-y-2">
            <Label htmlFor="referralCode">{t('auth.joinForm.referralCode')}</Label>
            <Input
              id="referralCode"
              value={formData.referralCode}
              onChange={(e) => setFormData(prev => ({ ...prev, referralCode: e.target.value }))}
              placeholder={t('auth.joinForm.referralCodePlaceholder')}
              disabled={isGoogleLoading || isEmailLoading}
            />
          </div>
        )}

        {formData.referralCode && (
          <div className="space-y-2">
            <Label htmlFor="referralCode">{t('auth.joinForm.referralCode')}</Label>
            <div className="flex items-center gap-2">
              <Input
                id="referralCode"
                value={formData.referralCode}
                readOnly
                className="bg-muted"
              />
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setFormData(prev => ({ ...prev, referralCode: '' }))}
              >
                {t('common.change')}
              </Button>
            </div>
          </div>
        )}

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <Button 
          type="submit" 
          className="w-full" 
          disabled={isGoogleLoading || isEmailLoading}
        >
          {isEmailLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('auth.joinForm.processing')}
            </>
          ) : (
            t('auth.joinForm.signUpWithEmail')
          )}
        </Button>

        <p className="text-sm text-center text-muted-foreground">
          {t('auth.joinForm.alreadyHaveAccount')}{" "}
          <Link to="/login" className="text-primary hover:underline">
            {t('auth.joinForm.signIn')}
          </Link>
        </p>
      </form>
    </div>
  );
} 