import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useEffect } from 'react';
import { useUserStore } from '@/stores/useUserStore';

export function VerificationCancel() {
  const navigate = useNavigate();
  const { profile } = useUserStore();

  // If user is logged in, redirect to dashboard after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      if (profile) {
        navigate('/dashboard');
      }
    }, 3000); // 3 second delay

    return () => clearTimeout(timer);
  }, [profile, navigate]);

  return (
    <div className="w-full px-4 md:px-8 py-24">
      <Card className="mx-auto max-w-md w-full">
        <CardHeader>
          <CardTitle className="text-center">Verification Cancelled</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <p className="text-center text-muted-foreground">
            Your verification process was cancelled. You can try again when you're ready.
          </p>
          <div className="space-y-2">
            <Button
              className="w-full"
              onClick={() => navigate('/dashboard')}
            >
              Return to Dashboard
            </Button>
            <p className="text-sm text-center text-muted-foreground">
              You'll be redirected automatically in a few seconds...
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 