export const zhHans = {
hero: {
  title: "OpenAI 不再开放。",
  subtitle: "这是唯一一个将人类放在首位的运动。",
  chainCall: "由人民控制，而非企业。"
},

problem: {
  category: "事实",
  title: "ChatGPT：3亿+用户",
  subtitle: "$1570亿+估值",
  subheadline: "0%用户所有权"
},

problem2: {
  title: "我们正在构建 Ctrl AI",
  subtitle: "99%由用户所有。这意味着由您所有。"
},

opportunity: {
  points: {
    control: "从300控制点开始",
    bring: "24小时内邀请一个人",
    fail: "失败 = 永久失去200点"
  },
  note: "这一次，您真正拥有您帮助构建的东西。"
},

howItWorks: {
  title: "真正的替代方案",
  points: [
    {
      title: "第1阶段 / 团结100万人",
      bullets: [
        "没有风险资本，没有企业控制",
        "人民的力量，一环接一环",
        "每个用户都是真正的所有者"
      ]
    },
    {
      title: "第2阶段 / 资助100个团队",
      bullets: [
        "重建我们每天使用的应用程序，那些挖掘我们数据并出售给出价最高者的应用",
        "创建我们真正拥有的AI工具",
        "功能的民主控制",
        "您的数据仍然是您的"
      ]
    },
    {
      title: "第3阶段 / 发展到10亿人",
      bullets: [
        "企业AI的真正替代方案",
        "AI发展的全球发言权",
        "通过真正的所有权获得真正的力量"
      ]
    }
  ]
},

cta: {
  category: "选择",
  title: "开放 vs 控制",
  subtitle: "OpenAI向我们展示了当'开放'变得封闭时会发生什么。Ctrl AI通过从第一天起就将99%的所有权给予用户来确保这永远不会再次发生。",
  subheadline: "您要掌控吗？",
  citation: "我是Rado。我创建了Ctrl AI，因为我见证了OpenAI从一个非营利组织转变为一个价值1570亿美元的巨头。这一次，让我们一起建立一些我们真正共同拥有的东西。",
  buttons: {
    primary: "掌控（$1）",
    secondary: "保持无力"
  },
  note: "$1证明您是人类。没有机器人，没有投资者——只有团结的人。"
},


  aspiringFounders: {
    intro: {
      category: "创始人和创造者",
      title: "在 Ctrl AI 下构建您的愿景",
      subtitle: "在全球社区的支持下，将您的 AI 或应用创意变为现实。无需与掠夺性风投周旋，无需追求广告收入——只专注于真正有意义的创新，完全以用户福祉为核心。",
      citation: "“为什么要追求风险投资，当您可以从第一天起就获得共同所有者的内置用户群？”",
      pointsTitle: "为什么要与 Ctrl AI 一起构建？",
      points: [
        {
          headline: "以用户为中心，而非以广告为中心",
          text: "抛弃操控性算法——我们的宪章要求透明和真正的实用性。"
        },
        {
          headline: "90% 社区所有权",
          text: "您的创始团队最多保留 10%，其余股份归真正推动项目的用户所有。"
        },
        {
          headline: "内置推广大使",
          text: "从第一天起，您就拥有成千上万的共同所有者，他们准备分享和支持您的产品。"
        }
      ],
      conclusion: "如果您有意以道德和开放的方式进行创造，Ctrl AI 提供平台、资金和用户基础——所有这些都在优先考虑人类福祉的框架下进行。"
    },

    pitchProcess: {
      category: "提案与资金支持",
      title: "通往社区支持的成功之路",
      subtitle: "一旦 Ctrl AI 拥有首批 10 万名创始会员，您可以向我们的全球社区提交项目提案。如果获得批准，您将获得每月资金支持、活跃用户反馈以及一个致力于您成功的合作伙伴网络。",
      citation: "“一旦您的创意通过社区批准，每位创始人每月最多可获得 $10,000，无附加条件，只需遵守 Ctrl AI 宪章。”",
      pointsTitle: "操作流程：",
      points: [
        {
          headline: "提出您的想法",
          text: "提交简短提案，说明您的愿景、团队以及您如何遵循 90%/10% 模式（至少 20% 用于 Ctrl AI 核心，70% 直接支持者）。"
        },
        {
          headline: "社区投票",
          text: "Ctrl AI 会员将审核并投票决定您的项目。如果达到必要的支持门槛，您将获得资金支持。"
        },
        {
          headline: "每月薪资",
          text: "每位获批的创始人可获得每月 $10,000，由会员资助池提供——让您专注于构建，而非筹资。"
        }
      ],
      conclusion: "无需无休止的风投轮次，无需收费墙，只有实实在在的用户支持。当您的项目成长时，您的共同所有者也将随之成长。"
    },

    buildingInPublic: {
      category: "公开构建",
      title: "每周分享里程碑、成果与经验",
      subtitle: "定期更新让您的支持者和共同所有者掌握最新进展——促进透明度、责任感和集体的兴奋感。",
      citation: "“当社区拥有权益时，您的项目的成功和挑战将成为真正的共同经历。”",
      pointsTitle: "核心原则：",
      points: [
        {
          headline: "公开路线图",
          text: "发布您的开发时间表、主要功能和长期目标，让每个人都知道接下来的计划。"
        },
        {
          headline: "社区测试与反馈",
          text: "尽早并频繁地整合用户反馈。您的共同所有者可以帮助发现问题、改进功能并推动项目成长。"
        },
        {
          headline: "透明的进度报告",
          text: "每周或每月分享快照——突出成就、挑战和下一步计划，让利益相关者保持参与感。"
        }
      ],
      conclusion: "这不仅仅是软件更新，更是一个邀请，邀请您的社区真正参与和庆祝您的旅程。真正的参与会促进忠诚度——以及更好的产品。"
    },

    callToAction: {
      category: "开始行动",
      title: "准备好启动您的 AI 项目了吗？",
      subtitle: "加入一个致力于道德、用户所有创新的社区。提出您的创意、获得资金支持，并在成千上万人的支持下共同构建——没有企业附加条件。",
      pointsTitle: "下一步：",
      points: [
        {
          headline: "达到 10 万名创始会员",
          text: "一旦我们锁定首批会员，提案平台将开放——因此请分享 Ctrl AI 以更快达成此目标。"
        },
        {
          headline: "准备您的提案",
          text: "开始起草您的概念，组建您的团队（或单打独斗），并最终确定您将如何遵守 90%/10% 所有权结构。"
        },
        {
          headline: "早期且频繁地参与",
          text: "加入我们的社区论坛，获取对您想法的初步反馈。现在建立的联系越多，提案过程就越顺畅。"
        }
      ],
      conclusion: "**Ctrl AI 是塑造未来变革性应用的地方——由全球社区支持、透明资金和以用户为先的伦理驱动。**"
    }
  },


  foundingMembers: {
    intro: {
      category: "加入运动",
      title: "成为 Ctrl AI 的创始会员",
      subtitle: "在塑造 AI 未来的全球联盟中占据一席之地——不再将所有权力交给少数科技巨头。",
      citation: "创始会员是 Ctrl AI 的核心力量。他们制定我们的宪章，对重大决策进行投票，并为以人为本的 AI 革命奠定基调。",
      pointsTitle: "为什么要成为创始会员？",
      points: [
        {
          headline: "独家早期治理权",
          text: "创始会员每天获取控制点，让您在其他人之前对关键 AI 项目进行决策。"
        },
        {
          headline: "全球配额",
          text: "我们按国家规模分配席位，确保从第一天起每个国家的声音都能被听到。"
        },
        {
          headline: "无广告、无风投，纯粹的社区",
          text: "您的会员费用为我们的平台提供资金支持——没有企业股东。这是真正的 AI 民主。"
        }
      ],
      conclusion: "准备好帮助构建一个公平、用户拥有的 AI 未来？加入我们的候补名单，开始赢得您的创始会员资格。"
    },

    waitlistMechanics: {
      category: "候补名单与积分",
      title: "候补名单如何运作",
      subtitle: "我们致力于打造一支由真正的信仰者组成的运动，而不是被动的旁观者。因此，我们从候补名单中挑选顶级贡献者成为创始会员。",
      citation: "“通过分享、协作并展示您对 Ctrl AI 愿景的真正承诺来赢得您的席位。”",
      pointsTitle: "获取积分：",
      points: [
        {
          headline: "完成您的个人资料",
          text: "提供基本信息，让我们知道您是谁以及您代表哪个地区。"
        },
        {
          headline: "推荐朋友并在社交平台分享",
          text: "我们提供预编写的帖子，您可以进行调整，然后将证明链接粘贴回您的仪表板。我们的团队会手动验证并授予积分。"
        },
        {
          headline: "与社区互动",
          text: "很快，您将可以参与问答、为提案提供反馈或帮助解答新会员的问题。每一个有意义的行动都可以提升您的排名。"
        }
      ],
      conclusion: "一旦您达到一定的积分门槛或在顶级贡献者中排名，我们将邀请您激活您的创始会员身份。"
    },

    callToAction: {
      category: "采取行动",
      title: "占据您的席位，塑造未来",
      subtitle: "我们会定期审查候补名单，并挑选新的创始会员。展示您的承诺并帮助传播，以更快地提高排名。",
      pointsTitle: "被选中后会发生什么：",
      points: [
        {
          headline: "会员费用：$1（按年缴费）",
          text: "开始您的正式会员资格。这笔少量费用确保 Ctrl AI 独立运营——无广告或风险投资束缚。"
        },
        {
          headline: "每日控制点",
          text: "每个会员日获取治理权力，影响从应用功能到伦理准则的所有内容。"
        },
        {
          headline: "投票与宪章批准",
          text: "帮助最终确定并执行我们的约束性宪章。如果我们有偏离方向，您有权利（也有责任）引导我们回归正轨。"
        }
      ],
      conclusion: "**让我们共同构建一个真正属于用户的 AI 平台。** 立即加入候补名单，参与赢取积分，并成为首批掌控 AI 的人之一。"
    }
  },

  ownership: {
    intro: {
      category: "所有权",
      title: "集体所有权的力量",
      subtitle: "Ctrl AI 99% 由用户拥有——确保没有单一公司、投资者或国家可以主导我们的平台。通过将所有权分散到数百万（最终将达数十亿）成员之间，我们创建了一个真正的民主 AI 生态系统。",
      citation: "“在 Ctrl AI 中，所有权不仅仅是一个流行词——它是您引导我们的未来、塑造我们的政策以及分享我们共同创造价值的权利。”",
      pointsTitle: "为什么这很重要：",
      points: [
        {
          headline: "制衡机制",
          text: "当 99% 的所有权分配给用户时，没有少数派可以强迫做出损害社区的决定。"
        },
        {
          headline: "真正的问责制",
          text: "用户通过投票拥有否决权，并可以要求透明度，确保公平与道德开发。"
        },
        {
          headline: "长期稳定性",
          text: "社区驱动的平台对市场波动和逐利股东更具弹性。"
        }
      ],
      conclusion: "作为成员，您不仅使用 Ctrl AI——您还拥有并引导它。这就是我们防止 AI 殖民主义并确保 AI 以人为本的方式。"
    },

    controlPoints: {
      category: "控制点",
      title: "持续所有权与治理权",
      subtitle: "每位活跃会员每天获取 1 个控制点——只要您保持订阅，这些控制点将持续累积。这些控制点使您能够直接影响重大平台决策。",
      citation: "“尽早加入并保持活跃：您成为会员的时间越长，累积的控制点越多，对 Ctrl AI 的影响力就越大。”",
      pointsTitle: "如何运作：",
      points: [
        {
          headline: "每日累积",
          text: "只要您的会员资格保持活跃，每天累积 1 个控制点。没有上限——资深会员自然拥有更大的影响力。"
        },
        {
          headline: "投票与提案",
          text: "使用控制点对新功能提案、伦理准则、预算分配等进行投票。"
        },
        {
          headline: "可选链上转换",
          text: "两年后，您可以将控制点转换为代币——但这完全是可选的。如果您希望保持简单，可以选择不参与链上操作。"
        }
      ],
      conclusion: "控制点旨在优先用于治理，而非投机。它们反映了您的持续承诺，并赋予您真正的发言权，决定 Ctrl AI 的发展方向。"
    },
  
    communityModel: {
      category: "以用户为中心的模式",
      title: "99% 社区所有",
      subtitle: "我们以一种赋权用户而不会阻碍发展的方式进行所有权分配。重大决策——从应用发布到数据隐私——始终由大多数人掌控。",
      citation: "“想象一个未来，您的 AI 工具真正属于您，而不是由依赖广告的企业集团或风险资本家控制。”",
      pointsTitle: "我们模式的关键特性：",
      points: [
        {
          headline: "创始人项目（90/10）",
          text: "Ctrl AI 下的子项目至少保证 90% 的社区所有权，创始团队保留 10%——始终以用户利益为中心。"
        },
        {
          headline: "直接治理",
          text: "对重大决策进行投票、提出变更建议并塑造 Ctrl AI 的未来。"
        },
        {
          headline: "全球代表性",
          text: "通过按国家分配会员配额，确保没有单一地区的投票能够压倒其他地区。所有权在全球范围内分配。"
        }
      ],
      conclusion: "通过限制个人股份并将其余股份分散到数百万用户之间，我们确保 Ctrl AI 仍然是一个全球化、无边界且以用户为中心的生态系统。"
    },
  
    callToAction: {
      category: "采取行动",
      title: "掌控未来，与 Ctrl AI 一起",
      subtitle: "不要接受一个 AI 被垄断的世界。加入 Ctrl AI，每天累积控制点，并确保 AI 开发保持诚实、人性化和透明。",
      pointsTitle: "下一步：",
      points: [
        {
          headline: "加入候补名单",
          text: "锁定您的会员资格，并在收到邀请后立即开始累积控制点。"
        },
        {
          headline: "分享并赚取候补名单积分",
          text: "通过推荐朋友和传播消息提高您的排名——帮助我们更快实现全球会员目标。"
        },
        {
          headline: "塑造未来的 AI",
          text: "加入后，使用您不断增长的控制点进行投票、提出变更建议，并确保我们的平台忠实于其使命。"
        }
      ],
      conclusion: "**所有权就是力量**——在 Ctrl AI，这种力量属于您。注册、保持活跃，并帮助我们为所有人改写 AI 所有权的规则。"
    },
  },
  
    constitutionPage: {
      hero: {
        title: "Ctrl AI 宪章",
        preamble: "在一个技术日益决定人类未来的时代，我们选择一条不同的道路。**不仅是更好的产品，更是更好的原则；不仅是创新，更是道德进步；不仅是用户参与，更是人类福祉。**"
      },
      principles: {
        title: "我们的约束性原则",
        intro: "这些不是指南或建议——它们是每个 Ctrl AI 项目必须遵循的约束性承诺，由社区监督和定期审计执行：",
        principles: [
          {
            title: "以人类福祉为首要目标",
            items: [
              "以真正的人类福祉为目标，而非参与度指标",
              "零容忍黑暗模式或心理操控",
              "将用户注意力视为宝贵资源并予以尊重",
              "构建自然的停止点和健康的使用模式",
              "优先考虑有意义的连接，而非上瘾的参与"
            ]
          },
          {
            title: "绝对数据权利",
            items: [
              "用户完全拥有其数据——无例外",
              "不出售数据或未经授权共享数据",
              "尽可能采用本地优先处理",
              "采用军用级别加密作为标准",
              "确保彻底且永久的被遗忘权"
            ]
          },
          {
            title: "真正的社区力量",
            items: [
              "最低 90% 的社区所有权——由宪章保障",
              "在重大决策中实行直接民主治理",
              "对产品方向进行约束性社区投票",
              "以数学方式公平分配代币",
              "无论贡献大小，权利平等"
            ]
          },
          {
            title: "道德 AI 边界",
            items: [
              "AI 服务于人类自主权——永不削弱它",
              "AI 决策过程完全透明",
              "持续进行偏见监测与消除",
              "所有 AI 功能均可由人类明确覆盖",
              "AI 开发以人类赋权为重点"
            ]
          },
          {
            title: "团队福祉",
            items: [
              "四天工作周作为宪法权利",
              "全面的心理健康支持",
              "为所有人提供持续学习资金",
              "零加班剥削",
              "公平的利润分享",
              "以远程优先，提供协作选项",
              "严格的工作与生活界限",
              "定期团队联络活动"
            ]
          },
          {
            title: "教育优先",
            items: [
              "在所有产品中内置教育价值",
              "设计符合年龄的内容和安全措施",
              "积极促进数字素养",
              "采取多方利益相关者的教育方式",
              "禁止对年轻用户的剥削",
              "禁止针对青少年的操控行为"
            ]
          },
          {
            title: "激进透明",
            items: [
              "每周公开开发更新",
              "全面的财务透明度",
              "直接沟通挑战",
              "默认开源",
              "定期的社区参与会议"
            ]
          },
          {
            title: "可持续发展",
            items: [
              "自然增长而非人为病毒式传播",
              "仅在社区驱动下扩展",
              "禁止剥削性营销",
              "考虑环境影响",
              "长期可持续性优先于短期收益"
            ]
          },
          {
            title: "公平收入",
            items: [
              "零基于广告的收入",
              "透明的基于价值的定价",
              "明确的成本效益沟通",
              "直接的社区利润分享",
              "仅基于成本的定价"
            ]
          },
          {
            title: "有目的的创新",
            items: [
              "专注于真实的人类需求",
              "仅进行服务于社区的创新",
              "倡导开放协作原则",
              "以服务人类为目的的技术",
              "所有决策中考虑社会影响"
            ]
          }
        ]
      },
      enforcement: {
        title: "真正的执行力与真正的问责制",
        content: "**这些原则不是承诺——它们是具有实际后果的约束性承诺：**",
        measures: [
          {
            title: "社区监督",
            items: [
              "• 每季度公开审计",
              "• 实时违规报告",
              "• 直接的社区反馈系统",
              "• 独立的道德审查委员会"
            ]
          },
          {
            title: "明确的后果",
            items: [
              "• 即时违规响应",
              "• 由社区主导的纠正措施",
              "• 强制补救",
              "• 公开问责报告"
            ]
          },
          {
            title: "活文档",
            items: [
              "• 每年社区审核",
              "• 适应技术更新",
              "• 核心原则不可更改",
              "• 民主修订流程"
            ]
          }
        ]
      },
      cta: {
        title: "加入道德技术革命",
        content: "**这不仅仅是另一家科技公司的使命声明。这是一份为人类服务的技术建设的约束性宪章。** 无论您是准备以不同方式构建的创始人，还是准备拥有自己使用技术的用户，道德技术的未来从这里开始。",
        button: "加入创始会员（$1）"
      }
      },


      footer: {
        companyDescription: "以社区所有权和道德原则构建 AI 的未来。",
        navigation: "导航",
        legal: "法律",
        connect: "其他",
        privacy: "隐私政策",
        terms: "服务条款",
        cookies: "Cookie 政策",
        rights: "版权所有。"
      },
    
      nav: {
        community: "创始会员",
        founders: "创始人",
        ownership: "所有权",
        constitution: "宪章",
        pricing: "定价",
        dashboard: "仪表板",
        myProfile: "我的个人资料",
        settings: "设置",
        home: "主页",
        ctrlaiCom: "CtrlAI.com",
        ctrlaiAi: "CtrlAI.ai",
        apply: "申请成为创始人",
        about: "关于 Ctrl AI",
        logout: "退出登录"
      },
    
      auth: {
        login: {
          title: "欢迎回来",
          subtitle: "继续您的未来构建之旅",
          emailPlaceholder: "输入您的电子邮件",
          passwordPlaceholder: "输入您的密码",
          submitButton: "登录",
          noAccount: "还没有账号？",
          signupLink: "注册成为创始人",
          error: "电子邮件或密码无效"
        },
        signup: {
          title: "注册成为创始人",
          subtitle: "开始构建技术的未来",
          namePlaceholder: "输入您的全名",
          emailPlaceholder: "输入您的电子邮件",
          passwordPlaceholder: "选择一个密码",
          submitButton: "创建账号",
          hasAccount: "已有账号？",
          loginLink: "登录",
          error: "创建账号时出错"
        },
        errors: {
          passwordLength: "密码长度至少为 6 个字符",
          invalidEmail: "请输入有效的电子邮件地址",
          emailInUse: "该电子邮件已被使用"
        },
        passwordRequirements: "密码长度至少为 6 个字符"
      },
    
      pricing: {
        title: "加入运动",
        subtitle: "加入运动，共同构建并拥有技术的未来",
        tiers: {
          founding: {
            title: "创始会员",
            price: "$1",
            description: "适合希望推动变革的人",
            features: [
              "每天 1 个控制点",
              "优先访问新项目",
              "塑造技术的未来",
              "专属创始会员徽章",
              "3 个推荐码可供朋友使用"
            ]
          }
        },
        footer: {
          quote: "二十年后，人们会问，为什么有人会错过这个机会。不要成为那个解释为什么等待的人。",
        }
      },
    
      modals: {
        inviteCode: {
          title: "兑换您的邀请码",
          description: "输入您的邀请码以锁定创始会员席位。每个邀请码只能使用一次。",
          placeholder: "输入邀请码",
          submit: "加入成为创始会员",
          invalidCode: "无效或已过期的邀请码。请重试。",
          success: "欢迎加入 Ctrl AI！正在完成您的注册……",
          validating: "正在验证……",
          alreadyUsed: "该邀请码已被使用",
          expired: "该邀请码已过期",
          validated: "邀请码验证成功！正在跳转至注册页面……"
        },
        waitlist: {
          title: "加入候补名单",
          description: "加入我们的专属候补名单，抢先获得 Ctrl AI 的早期访问权。",
          namePlaceholder: "您的全名",
          emailPlaceholder: "您的电子邮件地址",
          passwordPlaceholder: "创建一个密码",
          submit: "加入候补名单",
          submitting: "正在加入……",
          error: "发生错误，请重试。",
          success: "欢迎！您已在候补名单中。"
        }
      },
    
      waitlist: {
        status: {
          title: "您的候补名单状态",
          totalInWaitlist: "在 {{total}} 人中排队",
          referralCode: "您的推荐码",
          copyLink: "复制链接",
          referralDescription: "通过分享您的推荐码邀请朋友，以提升候补名单排名。每个推荐可获得 50 积分！",
          points: "积分进度",
        },
        actions: {
          twitter: "分享到 Twitter",
          linkedin: "分享到 LinkedIn",
          discord: "加入 Discord",
          profile: "完善个人资料"
        }
      },
    
      onboarding: {
        title: "欢迎加入 Ctrl AI",
        subtitle: "让我们为您的成功做好准备",
        points: "积分",
        continue: "继续",
        finish: "完成设置",
        steps: {
          profile: {
            title: "完善您的个人资料",
            description: "添加个人头像和简介，帮助他人更好地了解您。"
          },
          social: {
            title: "分享您的旅程",
            description: "与您的社交网络分享您加入 Ctrl AI 的激动心情。"
          },
          community: {
            title: "加入社区",
            description: "在我们的 Discord 社区中与其他成员互动。"
          }
        }
      },
    
      dashboard: {
        welcome: "欢迎回来，{{name}}",
        membershipTiers: {
          founding: "创始会员",
          collective: "集体会员"
        },
        tabs: {
          projects: "项目",
          community: "社区",
          rewards: "奖励"
        },
        community: {
          title: "社区活动",
          discussions: {
            title: "最近讨论",
            empty: "尚无讨论",
            start: "发起讨论"
          },
          activity: {
            title: "最新动态",
            empty: "暂无最新动态"
          },
          stats: {
            members: "活跃会员",
            discussions: "讨论",
            contributions: "贡献"
          }
        },
        projects: {
          title: "活跃项目",
          empty: "暂无活跃项目",
          explore: "探索项目",
          create: "创建项目"
        },
        rewards: {
          title: "您的奖励",
          points: "控制点",
          achievements: "成就",
          empty: "暂无奖励"
        },
        quickActions: {
          title: "快速操作",
          createProject: "创建项目",
          startDiscussion: "发起讨论",
          updateProfile: "更新个人资料"
        }
      },
    
      global: {
        title: "观看我们的候补名单全球增长",
        subtitle: "实时社区增长",
        content: "每个点都代表正在争夺创始会员席位的某个人。还没有邀请码？加入候补名单，通过完成挑战提高排名。",
        stats: {
          totalMembers: "总会员数",
          activeToday: "今日活跃人数",
          averagePoints: "平均积分"
        },
        controls: {
          pause: "暂停与互动",
          resume: "继续巡演",
          next: "下一个大陆"
        }
      },
    
      quotaMap: {
        stats: {
          totalQuota: "全球总配额",
          totalRegistered: "总注册人数",
          totalPopulation: "总人口",
          countryCount: "代表国家数量",
          fulfillment: "全球配额完成度"
        },
        tooltip: {
          country: "国家",
          population: "人口",
          quota: "会员配额",
          registered: "注册会员",
          fulfillment: "配额完成度"
        },
        legend: {
          title: "配额完成度",
          low: "低",
          target: "目标",
          exceeded: "超额"
        },
        controls: {
          reset: "重置视图",
          zoom: "缩放",
          pan: "平移"
        },
        noData: "该国家暂无数据",
        loading: "正在加载地图数据……"
      },

      signupForm: {
        title: {
          waitlist: "加入候补名单",
          verified: "创建账户"
        },
        subtitle: {
          waitlist: "加入我们的专属候补名单，获得优先访问权",
          verified: "您已被邀请成为创始成员"
        },
        fields: {
          fullName: "姓名",
          email: "电子邮箱",
          password: "密码"
        },
        buttons: {
          submit: "继续",
          submitting: "正在创建账户...",
          google: "使用谷歌账户继续"
        },
        errors: {
          emailExists: "该邮箱已在候补名单中。请查收邮件获取验证说明。",
          emailInUse: "该邮箱已注册。请直接登录。",
          waitlistCheck: "无法验证候补状态。请重试。",
          createAccount: "创建账户失败。请重试。"
        },
        inviteCode: {
          prompt: "有邀请码？",
          button: "输入邀请码"
        }
      },


      waitlistDashboard: {
        title: "等待列表状态",
        position: "当前排名 {{position}}",
        total: "共 {{total}} 人",
        stats: {
          referrals: "邀请数",
          points: "积分",
          status: "状态",
          active: "活跃",
          position: "排名",
          completion: "完成度",
          earnMore: "获取更多积分",
          completeProfile: "完善个人资料以提升排名",
          total: "总计 {{total}} 人"
        },
        share: {
          title: "分享获取积分",
          description: "与好友分享您的邀请链接，提升等待列表排名。每次邀请都可获得积分！",
          copy: "复制链接",
          copied: "已复制！",
          buttons: {
            twitter: "分享到Twitter",
            linkedin: "分享到LinkedIn"
          }
        },
        earnPoints: {
          title: "获取积分方式",
          items: [
            "完善个人资料",
            "分享到社交媒体",
            "邀请好友",
            "加入Discord社区"
          ]
        },
        breadcrumbs: {
          waitlist: "等待列表",
          dashboard: "仪表盘"
        },
        welcome: {
          title: "欢迎",
          location: "{{city}}，{{country}}"
        },
        overview: {
          journey: "创始成员之路",
          profileCompletion: "资料完成度",
          socialSharing: "社交分享",
          tasks: {
            title: "可用任务",
            completeProfile: "完善个人资料",
            completeProfileDesc: "添加基本信息，让我们更好地了解您",
            addLocation: "添加位置",
            addLocationDesc: "告诉我们您所在的位置"
          }
        },
        tabs: {
          overview: "概览",
          tasks: "获取积分",
          social: "分享获积分"
        },
        socialShare: {
          title: "分享获取积分",
          description: "在社交媒体上分享Ctrl AI，获取积分并提升排名",
          verificationPending: "您的{{platform}}分享正在验证中"
        },
        progress: {
          items: {
            photo: "个人照片",
            basicInfo: "基本信息",
            location: "位置",
            skills: "技能与兴趣"
          }
        }
      },
      progressSection: {
        complete: "已完成",
        points: "+{{points}}积分",
        viewDetails: "查看详情",
        completeTasks: "完成任务",
        percentComplete: "已完成{{percent}}%"
      },
      social: {
        templates: {
          twitter: [
            {
              text: "刚刚加入 #CtrlAI 运动！🚀 共建用户所有的未来，不让科技巨头垄断人工智能。\n\n名额按国家限量—立即抢占你的位置：{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#用户所有权", "#AI革命"]
            },
            {
              text: "要在AI领域发出真正的声音，我们需要自然增长！正在帮助 @CtrlAI 达成10万创始成员目标。\n\n在你所在国家的配额用完前加入：{{REFERRAL_LINK}}",
              hashtags: ["#夺回控制权", "#数字自由", "#科技未来"]
            }
          ],
          linkedin: [
            {
              text: "很高兴分享我已加入Ctrl AI候补名单—这是一个由人民驱动的平台，正在挑战科技巨头的AI垄断。\n\n为什么这很重要：\n• 99%用户所有权（不再由企业董事会做主）\n• 每日控制点 = 真正的治理权\n• 国家配额确保全球代表性\n\n仅有10万个创始成员名额。通过口口相传（无广告，无风投）一起重塑AI的未来！\n\n{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#科技革命", "#数字自由", "#人工智能"]
            },
            {
              text: "我已获得Ctrl AI候补名单资格，共同拥有明日科技！想象一下对人民负责而不是对股东负责的AI。\n\n• 99%用户所有\n• 透明、道德的AI\n• 无巨额广告预算—仅靠我们口耳相传\n\n快行动，每个国家的创始成员名额有限。了解更多：{{REFERRAL_LINK}}",
              hashtags: ["#AI革命", "#用户所有权", "#道德科技", "#科技未来"]
            }
          ],
          instagram: [
            {
              text: "夺回AI控制权的时刻到了！⚡\n\n我加入了Ctrl AI候补名单，共建用户所有的应用—不受科技巨头控制。\n\n我们只通过口碑发展，快看看你所在国家的创始成员名额是否还有！\n{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#科技革命", "#用户所有权", "#数字自由", "#人工智能"]
            },
            {
              text: "10万创始成员招募进行中！\n\nCtrl AI = 99%用户所有权，真正发声掌控科技发展。无广告，无风投，无数据采集。\n\n你所在国家的名额有限—立即加入：{{REFERRAL_LINK}}",
              hashtags: ["#AI革命", "#夺回控制权", "#告别科技巨头", "#科技未来"]
            }
          ],
          facebook: [
            {
              text: "我正在加入Ctrl AI候补名单，共创一个AI真正由用户所有、而不是被少数巨头控制的世界。\n\n99%所有权掌握在真实用户手中，确保道德、透明的AI。我们只依靠口碑发展—无广告支出，无风投。\n\n仅有10万个创始成员名额，按国家公平分配。准备好改变科技了吗？点此加入：{{REFERRAL_LINK}}",
              hashtags: ["#夺回控制权", "#数字自由", "#用户所有权", "#人工智能"]
            },
            {
              text: "是否曾希望真正拥有你使用的应用？Ctrl AI让这成为现实！我已在候补名单，也想邀请你加入。\n\n我们不能依赖广告传播—这必须是一场人民驱动的运动。你所在国家的名额有限，立即确保你的位置：{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#科技未来", "#AI革命"]
            }
          ],
          youtube: [
            {
              text: "一起改变AI的构建方式！Ctrl AI是一个99%由用户所有的人民驱动平台。无广告，无企业霸主，实现科技领域的真正民主。\n\n通过我的链接加入：{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#人工智能", "#创新", "#科技革命"]
            },
            {
              text: "AI不应该被少数巨头垄断。我已是Ctrl AI的一员，你也可以加入！我们依靠口碑发展，快来传播—全球仅10万个名额。\n\n点此加入：{{REFERRAL_LINK}}",
              hashtags: ["#数字自由", "#用户所有权", "#人工智能", "#科技未来"]
            }
          ],
          tiktok: [
            {
              text: "POV：你发现了一个可以共同拥有AI未来的运动 🤖\n\nCtrl AI 99%由用户所有，没有科技巨头或可疑投资者。发展靠我们自己。通过我的链接加入：{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#科技说", "#AI革命", "#用户所有权"]
            },
            {
              text: "想摆脱科技巨头的控制？加入Ctrl AI！🚀 全球仅10万个创始成员名额。趁你所在国家的名额还在，赶快加入并帮助传播：{{REFERRAL_LINK}}",
              hashtags: ["#科技革命", "#人工智能", "#数字自由", "#夺回控制权"]
            }
          ],
          github: [
            {
              text: "刚刚加入Ctrl AI候补名单，共同拥有和开发道德AI应用！无广告，无风投，纯开源社区。\n\n你所在国家的创始成员名额可能有限—点此加入：{{REFERRAL_LINK}}",
              hashtags: ["#人工智能", "#开源", "#用户所有权", "#道德科技"]
            },
            {
              text: "开发者朋友们：厌倦了科技垄断？Ctrl AI正在建立用户所有的AI新时代。我们只通过社区发展，如果你热衷于开放协作，现在就确保你的创始成员资格：{{REFERRAL_LINK}}",
              hashtags: ["#CtrlAI", "#创新", "#开源", "#科技未来"]
            }
          ]
        }
      }

};
