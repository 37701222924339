import { ReactNode, useEffect, useState } from 'react';
import { ProtectedRoute } from './ProtectedRoute';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { useUserStore } from '@/stores/useUserStore';
import { LoadingSpinner } from './LoadingSpinner';
import { Navigate } from 'react-router-dom';

interface AdminRouteProps {
  children: ReactNode;
}

export function AdminRoute({ children }: AdminRouteProps) {
  const { user } = useUserStore();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        // Check admin collection
        const adminDoc = await getDocs(
          query(collection(db, 'adminUsers'), where('userId', '==', user.uid))
        );

        // Additional check for active status
        if (!adminDoc.empty) {
          const adminData = adminDoc.docs[0].data();
          setIsAdmin(adminData.active !== false); // Consider admin active unless explicitly set to false
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!isAdmin) {
    return <Navigate to="/admin/login" replace />;
  }

  return (
    <ProtectedRoute isAdminRoute={true}>
      {children}
    </ProtectedRoute>
  );
} 