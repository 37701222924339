import { useState } from 'react';
import { useUserStore } from '@/stores/useUserStore';
import { ImageUploader } from '@/components/ImageUploader';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card } from '@/components/ui/card';
import { functions } from '@/config/firebase';
import { httpsCallable } from 'firebase/functions';
import { UserProfileFormData } from '@/types/user';
import { toast } from 'sonner';

export function ProfileEditPage() {
  const { profile, refreshProfile } = useUserStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<UserProfileFormData>({
    displayName: profile?.displayName || profile?.fullName || '',
    displayCity: profile?.displayCity || '',
    displayCountry: profile?.displayCountry || '',
    bio: profile?.bio || '',
    links: {
      linkedin: profile?.links?.linkedin || '',
      twitter: profile?.links?.twitter || '',
      website: profile?.links?.website || ''
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!profile) return;

    try {
      setLoading(true);
      const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
      await updateUserProfile(formData);
      await refreshProfile();
      toast.success(t('profile.updateSuccess'));
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(t('profile.updateError'));
    } finally {
      setLoading(false);
    }
  };

  const handleImageUploaded = async (urls: { full: string; thumbnail: string }) => {
    // Handle profile picture update
    await refreshProfile();
  };

  if (!profile) return null;

  return (
    <div className="max-w-2xl mx-auto px-4 py-32">
      <Card className="p-6">
        <h1 className="text-2xl font-bold mb-6">{t('profile.editTitle')}</h1>
        
        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">{t('profile.photo')}</h2>
          <ImageUploader
            onImageUploaded={handleImageUploaded}
            currentImage={profile.avatar?.thumbnailUrl}
          />
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">
              {t('profile.displayName')}
            </label>
            <Input
              value={formData.displayName}
              onChange={(e) => setFormData({
                ...formData,
                displayName: e.target.value
              })}
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                {t('profile.city')}
              </label>
              <Input
                value={formData.displayCity}
                onChange={(e) => setFormData({
                  ...formData,
                  displayCity: e.target.value
                })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                {t('profile.country')}
              </label>
              <Input
                value={formData.displayCountry}
                onChange={(e) => setFormData({
                  ...formData,
                  displayCountry: e.target.value
                })}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              {t('profile.bio')}
            </label>
            <Textarea
              value={formData.bio}
              onChange={(e) => setFormData({
                ...formData,
                bio: e.target.value
              })}
              maxLength={400}
              rows={4}
            />
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">{t('profile.links')}</h3>
            <div>
              <label className="block text-sm font-medium mb-2">LinkedIn</label>
              <Input
                value={formData.links?.linkedin}
                onChange={(e) => setFormData({
                  ...formData,
                  links: { ...formData.links, linkedin: e.target.value }
                })}
                placeholder="https://linkedin.com/in/username"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">X (Twitter)</label>
              <Input
                value={formData.links?.twitter}
                onChange={(e) => setFormData({
                  ...formData,
                  links: { ...formData.links, twitter: e.target.value }
                })}
                placeholder="https://x.com/username"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Website</label>
              <Input
                value={formData.links?.website}
                onChange={(e) => setFormData({
                  ...formData,
                  links: { ...formData.links, website: e.target.value }
                })}
                placeholder="https://example.com"
              />
            </div>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={loading}
          >
            {loading ? t('common.saving') : t('common.save')}
          </Button>
        </form>
      </Card>
    </div>
  );
} 