import { MembersList } from '@/features/members/components/MembersList';
import { useUserStore } from '@/stores/useUserStore';
import { ShareInvite } from '@/features/members/components/ShareInvite';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Suspense } from 'react';
import { LoadingSpinner } from '@/components/LoadingSpinner';

export function MembersPage() {
  const { user } = useUserStore();
  const { t } = useTranslation();
  
  return (
    <div className="max-w-4xl mx-auto px-4 py-32">
      <div className="space-y-12">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">{t('members.title')}</h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            {t('members.subtitle')}
          </p>
        </div>
        
        {user && <ShareInvite />}
        <ErrorBoundary>
          <Suspense fallback={<LoadingSpinner />}>
            <MembersList limit={50} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
} 