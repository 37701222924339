import { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useStripePayment } from '@/hooks/useStripePayment';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { auth } from '@/config/firebase';
import { 
  GoogleAuthProvider, 
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile 
} from 'firebase/auth';
import { Loader2 } from 'lucide-react';
import { Label } from '@/components/ui/label';
import { Checkbox } from "@/components/ui/checkbox";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { VerificationPaymentOptions } from '@/hooks/useStripePayment';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

// Google icon with official colors
const GoogleIcon = () => (
  <svg className="mr-2 h-4 w-4" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
  </svg>
);

interface SignupFormData {
  email: string;
  password: string;
  fullName: string;
  acceptedTerms: boolean;
  coverOperationalCosts: boolean;
}

export function JoinForm({ onClose }: { onClose?: () => void }) {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    password: '',
    fullName: '',
    acceptedTerms: false,
    coverOperationalCosts: true
  });

  const [showFullForm, setShowFullForm] = useState(false);
  const [error, setError] = useState('');
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const { handleVerificationPayment } = useStripePayment();

  const proceedToPayment = async (userId: string, email: string, fullName: string) => {
    try {
      const paymentOptions: VerificationPaymentOptions = {
        userId,
        email,
        fullName,
        coverOperationalCosts: formData.coverOperationalCosts
      };
      
      await handleVerificationPayment(email, paymentOptions);
    } catch (error: any) {
      console.error('Payment error:', error);
      setError(t('auth.joinForm.errors.paymentFailed'));
    }
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleLoading(true);
    setError('');

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      await proceedToPayment(
        result.user.uid,
        result.user.email || '',
        result.user.displayName || ''
      );
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const handleEmailSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsEmailLoading(true);
    setError('');

    try {
      // Validate form data
      if (!formData.email || !formData.password || !formData.fullName) {
        throw new Error(t('auth.joinForm.errors.allFieldsRequired'));
      }

      if (!formData.acceptedTerms) {
        throw new Error(t('auth.joinForm.errors.acceptTerms'));
      }

      if (formData.password.length < 6) {
        throw new Error(t('auth.joinForm.errors.passwordLength'));
      }

      // Create user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Update profile with full name
      await updateProfile(userCredential.user, {
        displayName: formData.fullName
      });

      // Proceed to payment
      await proceedToPayment(
        userCredential.user.uid,
        formData.email,
        formData.fullName
      );
    } catch (error: any) {
      console.error('Email signup error:', error);
      setError(error.message);
    } finally {
      setIsEmailLoading(false);
    }
  };

  return (
    <div className="w-full px-4 md:px-8 py-24">
      <Card className="mx-auto max-w-md w-full">
        <CardHeader>
          <CardTitle className="text-center">{t('auth.joinForm.headline')}</CardTitle>
          <p className="text-center text-sm text-muted-foreground mt-2">
            {t('auth.joinForm.subtitle')}
          </p>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <Button 
              onClick={handleGoogleSignIn}
              className="w-full"
              disabled={isGoogleLoading || isEmailLoading}
              variant="outline"
            >
              {isGoogleLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <GoogleIcon />
              )}
              {t('auth.joinForm.continueWithGoogle')}
            </Button>

            <div className="space-y-4 border rounded-lg p-4 bg-gray-50 dark:bg-gray-900">
              <Label className="text-base font-medium">
                {t('auth.joinForm.costAllocationTitle')}
              </Label>
              <RadioGroup
                defaultValue="operational"
                onValueChange={(value) => 
                  setFormData(prev => ({ 
                    ...prev, 
                    coverOperationalCosts: value === 'operational' 
                  }))
                }
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="operational" id="operational" />
                  <Label htmlFor="operational" className="text-sm font-normal">
                    {t('auth.joinForm.operationalCosts')} 
                    <span className="block text-xs text-muted-foreground mt-1">
                      {t('auth.joinForm.operationalCostsDetail')}
                    </span>
                  </Label>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <RadioGroupItem value="treasury" id="treasury" />
                  <Label htmlFor="treasury" className="text-sm font-normal">
                    {t('auth.joinForm.treasuryCosts')}
                    <span className="block text-xs text-muted-foreground mt-1">
                      {t('auth.joinForm.treasuryCostsDetail')}
                    </span>
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  {t('auth.joinForm.orDivider')}
                </span>
              </div>
            </div>

            <form onSubmit={handleEmailSignup} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">{t('auth.joinForm.email')}</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, email: e.target.value }));
                    if (!showFullForm && e.target.value.includes('@')) {
                      setShowFullForm(true);
                    }
                  }}
                  placeholder={t('auth.joinForm.emailPlaceholder')}
                  required
                  disabled={isGoogleLoading || isEmailLoading}
                />
              </div>

              {showFullForm && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="fullName">{t('auth.joinForm.fullName')}</Label>
                    <Input
                      id="fullName"
                      value={formData.fullName}
                      onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                      placeholder={t('auth.joinForm.fullNamePlaceholder')}
                      required
                      disabled={isGoogleLoading || isEmailLoading}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="password">{t('auth.joinForm.password')}</Label>
                    <Input
                      id="password"
                      type="password"
                      value={formData.password}
                      onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                      placeholder={t('auth.joinForm.passwordPlaceholder')}
                      required
                      disabled={isGoogleLoading || isEmailLoading}
                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="terms"
                      checked={formData.acceptedTerms}
                      onCheckedChange={(checked) => 
                        setFormData(prev => ({ ...prev, acceptedTerms: checked as boolean }))
                      }
                    />
                    <label
                      htmlFor="terms"
                      className="text-sm text-muted-foreground"
                    >
                      {t('auth.joinForm.termsAgreement')}{" "}
                      <Link
                        to="/terms"
                        className="text-primary hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('auth.joinForm.termsLink')}
                      </Link>
                    </label>
                  </div>
                </>
              )}

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <Button 
                type="submit" 
                className="w-full" 
                disabled={isGoogleLoading || isEmailLoading}
              >
                {isEmailLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {t('auth.joinForm.processing')}
                  </>
                ) : (
                  t('auth.joinForm.signUpWithEmail')
                )}
              </Button>

              <p className="text-sm text-center text-muted-foreground">
                {t('auth.joinForm.alreadyHaveAccount')}{" "}
                <Link to="/login" className="text-primary hover:underline">
                  {t('auth.joinForm.signIn')}
                </Link>
              </p>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 