import { useUserStore } from '@/stores/useUserStore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import { Link2, Clock, Share2 } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { 
  Tooltip, 
  TooltipContent, 
  TooltipTrigger, 
  TooltipProvider 
} from '@/components/ui/tooltip';
import { SocialShare } from '@/components/SocialShare';

export function ChainStatus() {
  const { t } = useTranslation();
  const { profile } = useUserStore();

  // Get user's chain link data
  const chainLink = profile?.chainData?.chainLinks ? 
    Object.values(profile.chainData.chainLinks)[0] : 
    null;

  if (!chainLink) {
    return null;
  }

  const now = new Date();
  const referralDeadline = chainLink.referralDeadline.toDate();
  const timeLeft = referralDeadline.getTime() - now.getTime();
  const canRefer = !chainLink.hasReferred && timeLeft > 0;
  const timeLeftPercentage = Math.max(0, Math.min(100, (timeLeft / (24 * 60 * 60 * 1000)) * 100));


  return (
    <TooltipProvider>
      <Card className="border-primary/20">
        <CardHeader className="pb-2">
          <CardTitle className="flex items-center justify-between">
            <span>{t('chain.status.title')}</span>
            <Badge variant="outline" className="text-lg font-mono">
              #{chainLink.chainLinkNumber}
            </Badge>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Control Points */}
          <div className="bg-muted/50 p-4 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium">{t('chain.status.controlPoints')}</span>
              <Badge variant="secondary" className="text-lg">
                {chainLink.controlPoints} CP
              </Badge>
            </div>
            {chainLink.pendingPoints > 0 && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>+{chainLink.pendingPoints} {t('chain.status.pendingPoints')}</span>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge variant="outline">{t('chain.status.referralBonus')}</Badge>
                  </TooltipTrigger>
                  <TooltipContent>
                    {t('chain.status.referralInstructions')}
                  </TooltipContent>
                </Tooltip>
              </div>
            )}
          </div>

          {/* Referral Status */}
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">{t('chain.status.referralStatus')}</span>
              {chainLink.hasReferred ? (
                <Badge variant="success" className="flex items-center gap-1">
                  <Link2 className="w-4 h-4" />
                  {t('chain.status.referred')}
                </Badge>
              ) : canRefer ? (
                <Badge variant="secondary" className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  {formatDistanceToNow(referralDeadline, { addSuffix: true })}
                </Badge>
              ) : (
                <Badge variant="destructive">
                  {t('chain.status.expired')}
                </Badge>
              )}
            </div>

            {canRefer && (
              <div className="space-y-3">
                <div className="flex justify-between text-xs text-muted-foreground">
                  <span>{t('chain.status.timeLeft')}</span>
                  <span>{formatDistanceToNow(referralDeadline, { addSuffix: true })}</span>
                </div>
                <Progress value={timeLeftPercentage} className="h-1" />
                <SocialShare 
                  referralNumber={chainLink.chainLinkNumber}
                  fullWidth
                />
              </div>
            )}
          </div>

          {/* Referred Links */}
          {chainLink.hasReferred && chainLink.referredLinkNumbers?.length > 0 && (
            <div className="border-t pt-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium">{t('chain.status.referredLinks')}</span>
                <div className="flex gap-1">
                  {chainLink.referredLinkNumbers.map((num) => (
                    <Badge key={num} variant="outline" className="font-mono">
                      #{num}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </TooltipProvider>
  );
} 