import { Button } from '@/components/ui/button';
import { useUserStore } from '@/stores/useUserStore';
import { Twitter, Facebook, Link as LinkIcon } from 'lucide-react';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { Card } from '@/components/ui/card';

export function ShareInvite({ className = '' }: { className?: string }) {
  const { profile } = useUserStore();
  const { t } = useTranslation();
  
  // Make sure we get an array back by providing a default empty array
  const bulletPoints = t('members.shareInvite.bulletPoints', { returnObjects: true }) as string[] || [];

  const shareText = `${t('members.shareInvite.twitterText')} ${window.location.origin}`;
  
  const memberNumber = profile?.memberNumber?.toLocaleString() || '...';

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.origin);
    toast(t('members.shareInvite.shareButtons.linkCopied'));
  };

  return (
    <Card className={`p-8 ${className}`}>
      <div className="max-w-2xl">
        <h3 className="text-2xl font-semibold mb-6">
          {t('members.shareInvite.title')}
        </h3>
        
        {bulletPoints.length > 0 && (
          <ul className="space-y-3 mb-8">
            {bulletPoints.map((point, index) => (
              <li key={index} className="flex items-center gap-3 text-muted-foreground">
                <div className="w-2 h-2 rounded-full bg-primary/50" />
                {point}
              </li>
            ))}
          </ul>
        )}

        <div className="flex flex-wrap gap-4 mb-8">
          <Button
            variant="outline"
            onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`, '_blank')}
          >
            <Twitter className="w-5 h-5 mr-2" />
            {t('members.shareInvite.shareButtons.twitter')}
          </Button>
          <Button
            variant="outline"
            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.origin)}`, '_blank')}
          >
            <Facebook className="w-5 h-5 mr-2" />
            {t('members.shareInvite.shareButtons.facebook')}
          </Button>
          <Button
            variant="outline"
            onClick={handleCopyLink}
          >
            <LinkIcon className="w-5 h-5 mr-2" />
            {t('members.shareInvite.shareButtons.copyLink')}
          </Button>
        </div>

        <p className="text-sm text-muted-foreground border-t pt-6">
          {t('members.shareInvite.founderNote', { 
            memberNumber,
            interpolation: { escapeValue: false }
          })}
        </p>
      </div>
    </Card>
  );
} 