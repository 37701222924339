import { create } from 'zustand';
import { User } from 'firebase/auth';
import { auth, db } from '@/config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/config/firebase';

export interface ChainVerification {
  chainLinkNumber: number;
  verifiedAt: Date;
  stripePaymentId: string;
  bonuses?: {
    streak10?: boolean;
    streak50?: boolean;
    streak100?: boolean;
    oneOnOne?: boolean;
  };
}

export interface MemberData {
  memberNumber: number;
  timestamp: Date;
  city: string;
  country: string;
  allocationPreference: 'operational' | 'treasury';
}

export interface UserProfile {
  id: string;
  email: string;
  fullName: string;
  verified: boolean;
  memberNumber?: number;
  chainVerifications: ChainVerification[];
  primaryChainLinkNumber?: number; // The first/lowest chain link number
  latestChainLinkNumber?: number;  // The most recent chain link number
  controlPoints: number;
  pendingChainBonus?: boolean;
  memberData?: MemberData;
  stripeCustomerId?: string;
  avatar?: {
    url: string;
    thumbnailUrl: string;
  };
  createdAt?: Date;
  updatedAt?: Date;
}

interface UserState {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  initialized: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: UserProfile | null) => void;
  setLoading: (loading: boolean) => void;
  initialize: () => void;
  refreshProfile: () => Promise<void>;
}

let authInitialized = false;

async function fetchUserProfile(uid: string): Promise<UserProfile | null> {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      return {
        id: uid,
        email: data.email || '',
        fullName: data.fullName || '',
        verified: data.verified || false,
        chainVerifications: data.chainVerifications || [],
        primaryChainLinkNumber: data.primaryChainLinkNumber,
        latestChainLinkNumber: data.latestChainLinkNumber,
        controlPoints: data.controlPoints || 0,
        pendingChainBonus: data.pendingChainBonus || false,
        memberData: data.memberData,
        stripeCustomerId: data.stripeCustomerId,
        avatar: data.avatar ? {
          url: data.avatar.url,
          thumbnailUrl: data.avatar.thumbnailUrl
        } : undefined,
        createdAt: data.createdAt?.toDate(),
        updatedAt: data.updatedAt?.toDate()
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
}

export const useUserStore = create<UserState>((set, get) => ({
  user: null,
  profile: null,
  loading: true,
  initialized: false,

  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (loading) => set({ loading }),

  refreshProfile: async () => {
    const { user } = get();
    if (user?.uid) {
      try {
        set({ loading: true });
        const profile = await fetchUserProfile(user.uid);
        set({ profile, loading: false });
      } catch (error) {
        console.error('Error refreshing profile:', error);
        set({ loading: false });
      }
    }
  },

  initialize: () => {
    if (authInitialized) return;
    authInitialized = true;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      set({ user, loading: true });

      if (user) {
        try {
          let profile = await fetchUserProfile(user.uid);
          
          // If no profile exists, try to create it
          if (!profile) {
            const createUserProfile = httpsCallable(functions, 'createUserProfile');
            await createUserProfile({
              email: user.email,
              fullName: user.displayName
            });
            // Fetch the newly created profile
            profile = await fetchUserProfile(user.uid);
          }

          set({ 
            profile,
            loading: false,
            initialized: true
          });
        } catch (error) {
          console.error('Error initializing user profile:', error);
          set({ 
            profile: null,
            loading: false,
            initialized: true
          });
        }
      } else {
        set({ 
          profile: null,
          loading: false,
          initialized: true
        });
      }
    });

    return () => unsubscribe();
  }
}));

// Initialize the store
useUserStore.getState().initialize(); 