import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './translations/en';
import { es } from './translations/es';
import { de } from './translations/de';
import { fr } from './translations/fr';
import { zhHans } from './translations/zhHans';
import { ja } from './translations/ja';
import { ar } from './translations/ar';
import { cs } from './translations/cs';
import { pt } from './translations/pt';
const options: InitOptions = {
  resources: {
    'en-US': {
      translation: en,
    },
    'en-GB': {
      translation: en,
    },
    pt: {
      translation: pt,
    },
    es: {
      translation: es,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
    zhHans: {
      translation: zhHans,
    },
    ja: {
      translation: ja,
    },
    ar: {
      translation: ar,
    },
    cs: {
      translation: cs,
    }
  },
  fallbackLng: {
    'en-GB': ['en-US'],
    'en-US': ['en-GB'],
    default: ['en-US']
  },
  load: 'languageOnly',
  detection: {
    order: ['querystring', 'path', 'navigator', 'htmlTag', 'localStorage'],
    lookupQuerystring: 'lang',
    lookupFromPathIndex: 0,
    caches: ['localStorage'],
    cookieMinutes: 10080,
  },
  interpolation: {
    escapeValue: false,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18next; 