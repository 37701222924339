export const de = {

  hero: {
    title: "OpenAI ist nicht mehr offen.",
    subtitle: "Hier ist die einzige Bewegung, die die Menschheit an erste Stelle setzt.",
    chainCall: "Kontrolle durch Menschen, nicht durch Konzerne."
  },

  problem: {
    category: "Die Fakten",
    title: "ChatGPT: 300M+ Nutzer",
    subtitle: "$157Mrd+ Bewertung",
    subheadline: "0% Nutzerbesitz"
  },

  problem2: {
    title: "Wir bauen Ctrl AI",
    subtitle: "99% im Besitz der Nutzer. Das bedeutet dich."
  },

  opportunity: {
    points: {
      control: "Du startest mit 300 Kontrollpunkten",
      bring: "Verbinde **EINE** Person in 24 Stunden",
      fail: "Fehlschlag = Verliere dauerhaft 200 Punkte"
    },
    note: "Diesmal besitzt du wirklich, was du mit aufbaust."
  },

  howItWorks: {
    title: "Die echte Alternative",
    points: [
      {
        title: "Phase 1 / Vereine 1M Menschen",
        bullets: [
          "Kein Risikokapital, keine Konzernkontrolle",
          "Reine Menschenkraft, Glied für Glied",
          "Jeder Nutzer ist ein echter Mitbesitzer"
        ]
      },
      {
        title: "Phase 2 / Finanziere 100 Teams",
        bullets: [
          "Baue Apps neu, die wir täglich nutzen – Apps, die derzeit unsere Daten sammeln und an den Meistbietenden verkaufen",
          "Erschaffe KI-Tools, die **wir** tatsächlich besitzen",
          "Demokratische Kontrolle über Funktionen",
          "Deine Daten bleiben **deine**"
        ]
      },
      {
        title: "Phase 3 / Wachse auf 1 Milliarde Menschen",
        bullets: [
          "Die wahre Alternative zu Konzern-KI",
          "Globale Stimme in der KI-Entwicklung",
          "Echte Macht durch echten Besitz"
        ]
      }
    ]
  },

  cta: {
    category: "Die Entscheidung",
    title: "Offen vs Kontrolle",
    subtitle: "OpenAI hat uns gezeigt, was passiert, wenn 'offen' zu geschlossen wird. Ctrl AI stellt sicher, dass das nie wieder geschieht – indem 99% des Besitzes von Anfang an an die Nutzer gehen.",
    subheadline: "Wirst du die Kontrolle übernehmen?",
    citation: "Ich bin Rado. Ich habe Ctrl AI gegründet, weil ich miterlebt habe, wie OpenAI sich von einer gemeinnützigen Organisation zu einem Konzern mit 157 Milliarden Dollar Bewertung wandelte. Diesmal lasst uns etwas aufbauen, das wir wirklich gemeinsam besitzen.",
    buttons: {
      primary: "Übernimm die Kontrolle (1€)",
      secondary: "Machtlos bleiben"
    },
    note: "1€ beweist, dass du ein Mensch bist. Keine Bots, keine Investoren – nur vereinte Menschen."
  },

aspiringFounders: {
      intro: {
        category: "Gründer & Kreative",
        title: "Baue deine Vision unter Ctrl AI auf",
        subtitle:
          "Verwirkliche deine KI- oder App-Idee mit der Unterstützung einer globalen Community. Keine ausbeuterischen Investoren, keine Jagd nach Werbeeinnahmen – nur zielgerichtete Innovation im Interesse der Nutzer.",
        citation: "Warum um Risikokapital kämpfen, wenn du von Anfang an auf eine Basis von Mitbesitzern zugreifen kannst?",
        pointsTitle: "Warum mit Ctrl AI bauen?",
        points: [
          {
            headline: "Nutzerzentriert, nicht werbezentriert",
            text: "Vergiss manipulative Algorithmen – unsere Verfassung verlangt Transparenz, Datenschutz und echten Nutzen."
          },
          {
            headline: "95% Gemeinschaftsbesitz",
            text: "Behalte bis zu 5% für dein Gründerteam, während der Großteil derer gehört, die das Projekt tatsächlich unterstützen."
          },
          {
            headline: "Eingebaute Unterstützer",
            text: "Von Tag eins an hast du Tausende von Botschaftern, die als Mitbesitzer bereitstehen, dein Produkt zu teilen und zu fördern."
          }
        ],
        conclusion:
          "Wenn du ethisch und offen entwickeln willst, bietet Ctrl AI die Plattform, die Finanzierung und die Nutzerbasis, die du brauchst – alles unter einem Rahmen, der das Wohl der Menschen priorisiert."
      },

      pitchProcess: {
        category: "Pitch & Finanzierung",
        title: "Dein Weg zum gemeinschaftlich unterstützten Erfolg",
        subtitle:
          "Sobald unsere ersten 1M Gründungsmitglieder gesichert sind, kannst du dein Projekt der Ctrl AI Community präsentieren. Bei Genehmigung erhältst du monatliche Finanzierung, direktes Feedback und eine eingebaute Zielgruppe, die deinen Erfolg sehen möchte.",
        citation:
          "Sichere bis zu €10.000/Monat pro Gründer, sobald deine Idee die Zustimmung der Community erhält – ohne Bedingungen, außer der Einhaltung der Ctrl AI-Verfassung.",
        pointsTitle: "So funktioniert's:",
        points: [
          {
            headline: "Schlage deine Idee vor",
            text: "Reiche einen kurzen Pitch ein, in dem du deine Vision, dein Team (falls vorhanden) und deine Einhaltung der 95%/5%-Regel erläuterst – 95% für die Community, 5% für dein Gründerteam."
          },
          {
            headline: "Community-Abstimmung",
            text: "Alle Ctrl AI-Mitglieder prüfen und stimmen über deinen Vorschlag ab. Erreichst du die erforderliche Unterstützung, erhältst du die Finanzierung."
          },
          {
            headline: "Monatliches Gehalt",
            text: "Genehmigte Gründer können bis zu €10.000/Monat aus dem gemeinschaftlich finanzierten Pool erhalten – damit du dich auf den Aufbau konzentrieren kannst, anstatt auf endlose Fundraising-Runden."
          }
        ],
        conclusion:
          "Keine endlosen Pitches für Investoren. Keine Paywalls. Nur greifbare, nutzergetriebene Unterstützung. Wenn dein Projekt wächst, wachsen auch deine Mitbesitzer – und genau das treibt nachhaltiges Wachstum an."
      }
    },

    foundingMembers: {
      intro: {
        category: "Werde Teil der Bewegung",
        title: "Werde ein Gründungsmitglied von Ctrl AI",
        subtitle:
          "Sichere dir deinen Platz als einer der ersten Pioniere, die die Zukunft der KI gestalten – bevor Big Tech sein Monopol festigt.",
        citation:
          "Gründungsmitglieder sind das Herz von Ctrl AI. Sie ratifizieren unsere Verfassung, stimmen über wichtige Entscheidungen ab und geben den Ton für eine menschenzentrierte KI-Revolution vor.",
        pointsTitle: "Warum ein Gründungsmitglied werden?",
        points: [
          {
            headline: "Exklusiver Früh-Einfluss",
            text: "Sei einer der ersten 1M Glieder in unserer Eigentumskette – erhalte die meisten Kontrollpunkte und eine dauerhafte Beteiligung an ethischer KI."
          },
          {
            headline: "Globale Repräsentation",
            text: "Länderbasierte Quoten stellen sicher, dass keine einzelne Region dominiert."
          },
          {
            headline: "Keine Werbung, keine Investoren – nur Community",
            text: "Wir arbeiten mit einer einmaligen €1-Verifikation – frei von Investoreneinfluss und kommerzieller Werbung."
          }
        ],
        conclusion:
          "Bereit, eine faire, nutzerbesitzende KI-Zukunft mitzugestalten? Sichere dein Kettenglied, schließe dich den Gründern an und leite Ctrl AI von Tag eins an mit."
      },

    waitlistMechanics: {
      category: "Warteliste & Punkte",
      title: "Wie die Warteliste funktioniert",
      subtitle: "Wir glauben daran, eine Bewegung echter Überzeugungstäter zu schaffen—nicht passiver Zuschauer. Deshalb laden wir die besten Mitwirkenden der Warteliste ein, Gründungsmitglieder zu werden.",
      citation: "„Verdiene dir deinen Platz, indem du teilst, mitarbeitest und echtes Engagement für die Vision von Ctrl AI zeigst.“",
      pointsTitle: "Punkte verdienen:",
      points: [
        {
          headline: "Vervollständige dein Profil",
          text: "Gib grundlegende Informationen an, damit wir wissen, wer du bist und welche Region du vertrittst."
        },
        {
          headline: "Freunde werben & in sozialen Medien teilen",
          text: "Wir bieten vorgefertigte Posts an, die du anpassen kannst. Füge den Beweislink in dein Dashboard ein. Unser Team überprüft ihn manuell und vergibt Punkte."
        },
        {
          headline: "Engagiere dich in der Community",
          text: "Bald kannst du an Q&A teilnehmen, Feedback zu Vorschlägen geben oder neuen Mitgliedern helfen. Jede sinnvolle Aktion kann deinen Rang verbessern."
        }
      ],
      conclusion: "Sobald du eine bestimmte Punkteschwelle erreichst oder zu den besten Mitwirkenden gehörst, laden wir dich ein, deinen Status als Gründungsmitglied zu aktivieren."
    },
  
    callToAction: {
      category: "Werde aktiv",
      title: "Sichere dir deinen Platz & gestalte die Zukunft",
      subtitle: "Wir überprüfen die Warteliste regelmäßig und wählen neue Gründungsmitglieder aus. Zeige dein Engagement und hilf dabei, die Botschaft zu verbreiten, um schneller in den Rängen aufzusteigen.",
      pointsTitle: "Was passiert, nachdem du ausgewählt wurdest:",
      points: [
        {
          headline: "Mitgliedsbeitrag: 1 €",
          text: "Starte deine offizielle Mitgliedschaft. Dieser kleine Beitrag hält Ctrl AI unabhängig—keine Werbung oder Wagniskapitalbedingungen."
        },
        {
          headline: "Tägliche Kontrollpunkte",
          text: "Verdiene täglich Mitbestimmungspunkte für jeden Tag deiner Mitgliedschaft. Beeinflusse alles, von App-Funktionen bis hin zu ethischen Richtlinien."
        },
        {
          headline: "Abstimmungen & Verfassungsratifikation",
          text: "Hilf dabei, unsere verbindliche Verfassung zu finalisieren und durchzusetzen. Sollten wir jemals vom Kurs abweichen, hast du das Recht (und die Pflicht), uns zurückzubringen."
        }
      ],
      conclusion: "**Lass uns gemeinsam eine KI-Plattform aufbauen, die wirklich den Nutzern gehört.** Melde dich für die Warteliste an, engagiere dich, um Punkte zu sammeln, und werde einer der Ersten, die die Kontrolle über KI übernehmen."
    }
  },

  ownership: {
    intro: {
      category: "Eigentum",
      title: "Die Macht des kollektiven Eigentums",
      subtitle: "Ctrl AI gehört zu 99 % den Nutzern—das stellt sicher, dass keine einzelne Firma, kein Investor und kein Land unsere Plattform dominieren kann. Durch die Verteilung des Eigentums auf Millionen (später Milliarden) Mitglieder schaffen wir ein wirklich demokratisches KI-Ökosystem.",
      citation: "„Eigentum bei Ctrl AI ist nicht nur ein Schlagwort—es ist dein Recht, unsere Zukunft zu lenken, unsere Richtlinien zu gestalten und am geschaffenen Wert teilzuhaben.“",
      pointsTitle: "Warum das wichtig ist:",
      points: [
        {
          headline: "Kontrolle und Ausgewogenheit",
          text: "Wenn 99 % des Eigentums auf die Nutzer verteilt sind, kann keine Minderheit Entscheidungen durchsetzen, die der Gemeinschaft schaden."
        },
        {
          headline: "Echte Verantwortlichkeit",
          text: "Nutzer haben Vetorechte durch Abstimmungen und können Transparenz verlangen, um eine faire und ethische Entwicklung sicherzustellen."
        },
        {
          headline: "Langfristige Stabilität",
          text: "Gemeinschaftsgetriebene Plattformen sind widerstandsfähiger gegenüber Marktschwankungen und gewinnorientierten Aktionären."
        }
      ],
      conclusion: "Als Mitglied nutzt du nicht nur Ctrl AI—du besitzt und lenkst es mit. So verhindern wir den KI-Kolonialismus und stellen sicher, dass KI menschenorientiert bleibt."
    },

    controlPoints: {
      category: "Kontrollpunkte",
      title: "Kontinuierliches Eigentum & Mitbestimmungsmacht",
      subtitle: "Jedes aktive Mitglied verdient täglich 1 Kontrollpunkt—diese Punkte summieren sich kontinuierlich, solange du Mitglied bleibst. Diese Kontrollpunkte geben dir direkten Einfluss auf wichtige Plattformentscheidungen.",
      citation: "„Tritt früh bei und bleibe aktiv: Je länger du Mitglied bist, desto mehr Kontrollpunkte sammelst du und desto größer ist dein Einfluss auf die Gestaltung von Ctrl AI.“",
      pointsTitle: "Wie es funktioniert:",
      points: [
        {
          headline: "Täglicher Zuwachs",
          text: "Deine Kontrollpunkte wachsen jeden Tag um 1 Punkt, an dem deine Mitgliedschaft aktiv ist. Es gibt kein Limit—langjährige Mitglieder erhalten auf natürliche Weise mehr Einfluss."
        },
        {
          headline: "Abstimmungen & Vorschläge",
          text: "Nutze Kontrollpunkte, um über neue Funktionsvorschläge, ethische Richtlinien, Budgetzuweisungen und mehr abzustimmen."
        },
        {
          headline: "Optionale On-Chain-Umwandlung",
          text: "Nach 2 Jahren kannst du deine Kontrollpunkte in Token umwandeln—dies ist jedoch völlig optional. Bleibe Off-Chain, wenn dir das lieber ist."
        }
      ],
      conclusion: "Kontrollpunkte sind in erster Linie für Mitbestimmung gedacht, nicht für Spekulation. Sie spiegeln dein anhaltendes Engagement wider und geben dir eine echte Stimme bei der Weiterentwicklung von Ctrl AI."
    },

    communityModel: {
      category: "Nutzerzentriertes Modell",
      title: "99 % gemeinschaftlich im Besitz",
      subtitle: "Wir teilen das Eigentum so auf, dass die Nutzer gestärkt werden, ohne die Entwicklung zu behindern. Wichtige Entscheidungen—von App-Starts bis hin zum Datenschutz—bleiben immer in den Händen der Mehrheit.",
      citation: "„Stell dir eine Zukunft vor, in der dir deine KI-Tools wirklich gehören, nicht werbegetriebenen Konzernen oder Risikokapitalgebern.“",
      pointsTitle: "Wichtige Merkmale unseres Modells:",
      points: [
        {
          headline: "Gründerprojekte (90/10)",
          text: "Unterprojekte werden unter Ctrl AI mit mindestens 90 % Gemeinschaftseigentum und 10 % für das Gründerteam entwickelt—wobei die Interessen der Nutzer im Vordergrund stehen."
        },
        {
          headline: "Direkte Mitbestimmung",
          text: "Stimme über wichtige Entscheidungen ab, schlage Änderungen vor und gestalte die Zukunft von Ctrl AI aktiv mit."
        },
        {
          headline: "Globale Repräsentation",
          text: "Mitgliedsquoten nach Ländern sorgen dafür, dass die Stimmen keiner einzigen Region die anderer übertönen können. Das Eigentum wird weltweit verteilt."
        }
      ],
      conclusion: "Durch die Begrenzung individueller Anteile und die Verteilung des Rests auf Millionen von Nutzern stellen wir sicher, dass Ctrl AI eine globale, grenzenlose und nutzerzentrierte Plattform bleibt."
    },

    callToAction: {
      category: "Werde aktiv",
      title: "Besitze deine Zukunft mit Ctrl AI",
      subtitle: "Gib dich nicht mit einer Welt zufrieden, in der KI monopolisiert wird. Tritt Ctrl AI bei, verdiene täglich Kontrollpunkte und halte die KI-Entwicklung ehrlich, menschenzentriert und transparent.",
      pointsTitle: "Nächste Schritte:",
      points: [
        {
          headline: "Melde dich für die Warteliste an",
          text: "Sichere dir deinen Mitgliedsplatz und beginne, Kontrollpunkte zu sammeln, sobald du eingeladen wirst."
        },
        {
          headline: "Teile & verdiene Wartelistenpunkte",
          text: "Erhöhe deinen Rang, indem du Freunde wirbst und die Botschaft verbreitest—hilf uns, unsere globalen Mitgliederziele schneller zu erreichen."
        },
        {
          headline: "Gestalte die KI von morgen",
          text: "Nutze deine wachsenden Kontrollpunkte, um abzustimmen, Änderungen vorzuschlagen und unsere Plattform ihrem Auftrag treu zu halten."
        }
      ],
      conclusion: "**Eigentum bedeutet Macht**—und bei Ctrl AI gehört diese Macht dir. Melde dich an, bleibe aktiv und hilf uns, die Regeln des KI-Eigentums für alle neu zu schreiben."
    }
  },

  constitutionPage: {
    hero: {
      title: "Die Ctrl AI Verfassung",
      preamble: "In einer Zeit, in der Technologie zunehmend die Zukunft der Menschheit gestaltet, wählen wir einen anderen Weg. **Nicht nur bessere Produkte, sondern bessere Prinzipien. Nicht nur Innovation, sondern ethischer Fortschritt. Nicht nur Nutzerbindung, sondern menschliches Gedeihen.**"
    },
    principles: {
      title: "Unsere verbindlichen Prinzipien",
      intro: "Das sind keine Richtlinien oder Empfehlungen—es sind verbindliche Verpflichtungen, denen jedes Projekt unter Ctrl AI folgen muss, durch gemeinschaftliche Aufsicht und regelmäßige Prüfungen durchgesetzt:",
      principles: [
        {
          title: "Das Wohl der Menschen an erster Stelle",
          items: [
            "Gestaltung für echtes menschliches Gedeihen, nicht für Engagement-Metriken",
            "Null Toleranz gegenüber manipulativen Mustern oder psychologischer Beeinflussung",
            "Behandlung der Aufmerksamkeit der Nutzer als wertvolle Ressource, die respektiert werden muss",
            "Einbau natürlicher Pausenpunkte und gesunder Nutzungsmuster",
            "Priorisierung sinnvoller Verbindungen gegenüber süchtig machender Nutzung"
          ]
        },
        {
          title: "Absolute Datenrechte",
          items: [
            "Nutzer besitzen ihre Daten vollständig—keine Ausnahmen",
            "Kein Datenverkauf oder unautorisierte Weitergabe",
            "Wo immer möglich, lokale Verarbeitung zuerst",
            "Verschlüsselung auf militärischem Niveau als Standard",
            "Garantiertes Recht auf vollständige und dauerhafte Datenlöschung"
          ]
        },
        {
          title: "Echte Gemeinschaftsmacht",
          items: [
            "Mindestens 90 % Gemeinschaftseigentum—verfassungsmäßig garantiert",
            "Direkte demokratische Mitbestimmung bei wichtigen Entscheidungen",
            "Verbindliche Gemeinschaftsabstimmungen über die Produktentwicklung",
            "Mathematisch faire Token-Verteilung",
            "Gleiche Rechte unabhängig von der Größe des Beitrags"
          ]
        },
        {
          title: "Ethische KI-Grenzen",
          items: [
            "KI dient der menschlichen Entscheidungsfreiheit—niemals ihrer Einschränkung",
            "Vollständige Transparenz bei KI-Entscheidungsprozessen",
            "Kontinuierliche Überwachung und Beseitigung von Vorurteilen",
            "Klare menschliche Eingriffsmöglichkeiten bei allen KI-Funktionen",
            "KI-Entwicklung mit Fokus auf menschliche Ermächtigung"
          ]
        },
        {
          title: "Wohlbefinden des Teams",
          items: [
            "Vier-Tage-Woche als verfassungsmäßiges Recht",
            "Umfassende psychische Gesundheitsunterstützung",
            "Finanzierte kontinuierliche Weiterbildung für alle",
            "Keine Ausbeutung durch Überstunden",
            "Gerechte Gewinnbeteiligung",
            "Remote-first mit optionaler Zusammenarbeit",
            "Strikte Trennung zwischen Arbeit und Freizeit",
            "Regelmäßige Teamevents zur Verbindung"
          ]
        },
        {
          title: "Bildung an erster Stelle",
          items: [
            "Eingebauter Bildungswert in allen Produkten",
            "Altersgerechte Gestaltung und Sicherheit",
            "Aktive Förderung der digitalen Kompetenz",
            "Multistakeholder-Ansatz in der Bildung",
            "Keine Ausbeutung junger Nutzer",
            "Verbot von manipulativen Techniken, die auf Jugendliche abzielen"
          ]
        },
        {
          title: "Radikale Transparenz",
          items: [
            "Wöchentliche öffentliche Entwicklungsupdates",
            "Vollständige finanzielle Transparenz",
            "Direkte Kommunikation über Herausforderungen",
            "Standardmäßig Open-Source",
            "Regelmäßige Community-Veranstaltungen"
          ]
        },
        {
          title: "Nachhaltige Entwicklung",
          items: [
            "Natürliches Wachstum statt künstlicher Viralität",
            "Nur gemeinschaftsgetriebene Expansion",
            "Verbot von ausbeuterischem Marketing",
            "Berücksichtigung der Umweltbelastung",
            "Langfristige Nachhaltigkeit statt kurzfristiger Gewinne"
          ]
        },
        {
          title: "Faire Einnahmen",
          items: [
            "Keine werbebasierten Einnahmen",
            "Transparente, wertbasierte Preisgestaltung",
            "Klare Kommunikation über Kosten und Nutzen",
            "Direkte Gewinnbeteiligung der Gemeinschaft",
            "Preisgestaltung nur im Einklang mit den Kosten"
          ]
        },
        {
          title: "Innovation mit Zweck",
          items: [
            "Fokus auf echte menschliche Bedürfnisse",
            "Nur gemeinschaftsdienliche Innovation",
            "Offene Kollaborationsprinzipien",
            "Technologie im Dienste der Menschheit",
            "Berücksichtigung der sozialen Auswirkungen bei allen Entscheidungen"
          ]
        }
      ]
    },
    enforcement: {
      title: "Echte Durchsetzung, echte Verantwortlichkeit",
      content: "**Diese Prinzipien sind keine Versprechen—sie sind verbindliche Verpflichtungen mit echten Konsequenzen:**",
      measures: [
        {
          title: "Gemeinschaftliche Aufsicht",
          items: [
            "• Vierteljährliche öffentliche Prüfungen",
            "• Echtzeit-Berichtssysteme bei Verstößen",
            "• Direkte Rückmeldesysteme der Gemeinschaft",
            "• Unabhängiger Ethikrat"
          ]
        },
        {
          title: "Klare Konsequenzen",
          items: [
            "• Sofortige Reaktion auf Verstöße",
            "• Korrekturen durch die Gemeinschaft",
            "• Verbindliche Abhilfemaßnahmen",
            "• Öffentliche Rechenschaftsberichte"
          ]
        },
        {
          title: "Lebendiges Dokument",
          items: [
            "• Jährliche Überprüfung durch die Gemeinschaft",
            "• Technologieadaptive Aktualisierungen",
            "• Unveränderliche Kernprinzipien",
            "• Demokratischer Änderungsprozess"
          ]
        }
      ]
    },
    cta: {
      title: "Schließe dich der Revolution für ethische Technologie an",
      content: "**Das ist nicht nur die Mission eines weiteren Technologieunternehmens. Das ist eine verbindliche Verfassung für den Aufbau einer Technologie, die der Menschheit dient.** Ob du ein Gründer bist, der anders entwickeln möchte, oder ein Nutzer, der besitzen möchte, was er nutzt—die Zukunft der ethischen Technologie beginnt hier.",
      button: "Werde Gründungsmitglied (1 €)"
    }
  },


  footer: {
    companyDescription: "Die Zukunft der KI gestalten—mit Gemeinschaftseigentum und ethischen Prinzipien.",
    navigation: "Navigation",
    legal: "Rechtliches",
    connect: "Weiteres",
    privacy: "Datenschutzrichtlinie",
    terms: "Nutzungsbedingungen",
    cookies: "Cookie-Richtlinie",
    rights: "Alle Rechte vorbehalten."
  },

  nav: {
    community: "Gründungsmitglieder",
    founders: "Gründer",
    ownership: "Eigentum",
    constitution: "Verfassung",
    pricing: "Preise",
    dashboard: "Dashboard",
    myProfile: "Mein Profil",
    settings: "Einstellungen",
    home: "Startseite",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Als Gründer bewerben",
    about: "Über Ctrl AI",
    logout: "Abmelden"
  },

  auth: {
    login: {
      title: "Willkommen zurück",
      subtitle: "Setze deine Reise zur Gestaltung der Zukunft fort",
      emailPlaceholder: "E-Mail eingeben",
      passwordPlaceholder: "Passwort eingeben",
      submitButton: "Anmelden",
      noAccount: "Noch kein Konto?",
      signupLink: "Als Gründer registrieren",
      error: "Ungültige E-Mail oder ungültiges Passwort"
    },
    signup: {
      title: "Als Gründer registrieren",
      subtitle: "Beginne mit dem Aufbau der Technologie der Zukunft",
      namePlaceholder: "Vollständigen Namen eingeben",
      emailPlaceholder: "E-Mail eingeben",
      passwordPlaceholder: "Passwort auswählen",
      submitButton: "Konto erstellen",
      hasAccount: "Bereits ein Konto?",
      loginLink: "Anmelden",
      error: "Fehler bei der Kontoerstellung"
    },
    errors: {
      passwordLength: "Das Passwort muss mindestens 6 Zeichen lang sein",
      invalidEmail: "Bitte gib eine gültige E-Mail-Adresse ein",
      emailInUse: "Diese E-Mail wird bereits verwendet"
    },
    passwordRequirements: "Das Passwort muss mindestens 6 Zeichen lang sein"
  },

  pricing: {
    title: "Schließe dich der Bewegung an",
    subtitle: "Sei Teil der Bewegung, die die Zukunft der Technologie gestaltet und besitzt",
    tiers: {
      founding: {
        title: "Gründungsmitglied",
        price: "1 €",
        description: "Für alle, die Veränderungen vorantreiben wollen",
        features: [
          "1 Kontrollpunkt täglich",
          "Bevorzugter Zugang zu neuen Projekten",
          "Gestalte die Zukunft der Technologie mit",
          "Exklusives Abzeichen für Gründungsmitglieder",
          "3 Einladungscodes für Freunde"
        ]
      }
    },
    footer: {
      quote: "In zwanzig Jahren werden die Leute fragen, wie jemand diese Chance verpassen konnte. Sei nicht derjenige, der erklären muss, warum du gewartet hast."
    }
  },

  modals: {
    inviteCode: {
      title: "Einladungscode einlösen",
      description: "Gib deinen Einladungscode ein, um deinen Platz als Gründungsmitglied zu sichern. Jeder Code kann nur einmal verwendet werden.",
      placeholder: "Einladungscode eingeben",
      submit: "Als Gründungsmitglied beitreten",
      invalidCode: "Ungültiger oder abgelaufener Einladungscode. Bitte versuche es erneut.",
      success: "Willkommen bei Ctrl AI! Registrierungsprozess wird abgeschlossen...",
      validating: "Wird überprüft...",
      alreadyUsed: "Dieser Code wurde bereits verwendet",
      expired: "Dieser Code ist abgelaufen",
      validated: "Code validiert! Weiterleitung zur Anmeldung..."
    },
    waitlist: {
      title: "Auf die Warteliste eintragen",
      description: "Trage dich in unsere exklusive Warteliste ein, um frühzeitigen Zugang zu Ctrl AI zu erhalten.",
      namePlaceholder: "Vollständiger Name",
      emailPlaceholder: "E-Mail-Adresse",
      passwordPlaceholder: "Passwort erstellen",
      submit: "Warteliste beitreten",
      submitting: "Wird beigetreten...",
      error: "Etwas ist schiefgelaufen. Bitte versuche es erneut.",
      success: "Willkommen! Du bist auf der Liste."
    }
  },

  waitlist: {
    status: {
      title: "Dein Wartelistenstatus",
      totalInWaitlist: "Von insgesamt {{total}} Personen in der Warteschlange",
      referralCode: "Dein Empfehlungscode",
      copyLink: "Link kopieren",
      referralDescription: "Teile deinen Empfehlungscode mit Freunden, um in der Warteliste aufzusteigen. Jede Empfehlung bringt dir 50 Punkte!",
      points: "Punktestand"
    },
    actions: {
      twitter: "Auf Twitter teilen",
      linkedin: "Auf LinkedIn teilen",
      discord: "Discord beitreten",
      profile: "Profil vervollständigen"
    }
  },

  onboarding: {
    title: "Willkommen bei Ctrl AI",
    subtitle: "Lass uns alles für deinen Erfolg einrichten",
    points: "Punkte",
    continue: "Weiter",
    finish: "Setup abschließen",
    steps: {
      profile: {
        title: "Vervollständige dein Profil",
        description: "Füge ein Profilbild und eine Kurzbiografie hinzu, damit andere dich besser kennenlernen."
      },
      social: {
        title: "Teile deine Reise",
        description: "Teile deine Begeisterung über den Beitritt zu Ctrl AI mit deinem Netzwerk."
      },
      community: {
        title: "Community beitreten",
        description: "Vernetze dich mit anderen Mitgliedern in unserer Discord-Community."
      }
    },
  },

  dashboard: {
    welcome: "Willkommen zurück, {{name}}",
    membershipTiers: {
      founding: "Gründungsmitglied",
      collective: "Kollektivmitglied"
    },
    tabs: {
      projects: "Projekte",
      community: "Community",
      rewards: "Belohnungen"
    },
    community: {
      title: "Community-Aktivität",
      discussions: {
        title: "Neueste Diskussionen",
        empty: "Noch keine Diskussionen",
        start: "Diskussion starten"
      },
      activity: {
        title: "Neueste Aktivitäten",
        empty: "Keine aktuellen Aktivitäten"
      },
      stats: {
        members: "Aktive Mitglieder",
        discussions: "Diskussionen",
        contributions: "Beiträge"
      }
    },
    projects: {
      title: "Aktive Projekte",
      empty: "Keine aktiven Projekte",
      explore: "Projekte erkunden",
      create: "Projekt erstellen"
    },
    rewards: {
      title: "Deine Belohnungen",
      points: "Kontrollpunkte",
      achievements: "Erfolge",
      empty: "Noch keine Belohnungen"
    },
    quickActions: {
      title: "Schnellaktionen",
      createProject: "Projekt erstellen",
      startDiscussion: "Diskussion starten",
      updateProfile: "Profil aktualisieren"
    }
  },
  global: {
    title: "Beobachte, wie unsere Warteliste weltweit wächst",
    subtitle: "Echtzeit-Wachstum der Community",
    content: "Jeder Punkt steht für jemanden, der um einen Platz als Gründungsmitglied konkurriert. Noch keinen Einladungscode? Tritt der Warteliste bei und verbessere deinen Rang, indem du Herausforderungen abschließt.",
    stats: {
      totalMembers: "Gesamtmitglieder",
      activeToday: "Heute aktiv",
      averagePoints: "Durchschnittliche Punkte"
    },
    controls: {
      pause: "Pause & Interaktion",
      resume: "Tour fortsetzen",
      next: "Nächster Kontinent"
    }
  },

  quotaMap: {
    stats: {
      totalQuota: "Gesamtkontingent weltweit",
      totalRegistered: "Gesamtanzahl Registrierte",
      totalPopulation: "Gesamtbevölkerung",
      countryCount: "Vertretene Länder",
      fulfillment: "Globaler Kontingenterfüllung"
    },
    tooltip: {
      country: "Land",
      population: "Bevölkerung",
      quota: "Mitgliederkontingent",
      registered: "Registrierte Mitglieder",
      fulfillment: "Kontingenterfüllung"
    },
    legend: {
      title: "Kontingenterfüllung",
      low: "Niedrig",
      target: "Ziel",
      exceeded: "Überschritten"
    },
    controls: {
      reset: "Ansicht zurücksetzen",
      zoom: "Zoom",
      pan: "Verschieben"
    },
    noData: "Keine Daten für dieses Land verfügbar",
    loading: "Kartendaten werden geladen..."
  },
  signupForm: {
    title: {
      waitlist: "Warteliste beitreten",
      verified: "Konto erstellen"
    },
    subtitle: {
      waitlist: "Treten Sie unserer exklusiven Warteliste bei",
      verified: "Sie wurden als Gründungsmitglied eingeladen"
    },
    fields: {
      fullName: "Vollständiger Name",
      email: "E-Mail",
      password: "Passwort"
    },
    buttons: {
      submit: "Weiter",
      submitting: "Konto wird erstellt...",
      google: "Mit Google fortfahren"
    },
    errors: {
      emailExists: "Diese E-Mail ist bereits auf der Warteliste. Bitte prüfen Sie Ihre E-Mail auf Verifizierungsanweisungen.",
      emailInUse: "Diese E-Mail ist bereits registriert. Bitte melden Sie sich stattdessen an.",
      waitlistCheck: "Wartelistenstatus konnte nicht überprüft werden. Bitte versuchen Sie es erneut.",
      createAccount: "Konto konnte nicht erstellt werden. Bitte versuchen Sie es erneut."
    },
    inviteCode: {
      prompt: "Haben Sie einen Einladungscode?",
      button: "Einladungscode eingeben"
    }
  },
  waitlistDashboard: {
    title: "Ihr Wartelistenstatus",
    position: "Position {{position}}",
    total: "Von {{total}} Personen",
    stats: {
      referrals: "Empfehlungen",
      points: "Punkte",
      status: "Status",
      active: "Aktiv",
      position: "Position",
      completion: "Fortschritt",
      earnMore: "Mehr Punkte verdienen Sie hier",
      completeProfile: "Vervollständigen Sie Ihr Profil, um Ihre Position zu verbessern",
      total: "Von insgesamt {{total}} Personen"
    },
    share: {
      title: "Teilen Sie & verdienen Sie Punkte",
      description: "Teilen Sie Ihren Empfehlungslink mit Freunden, um in der Warteliste aufzusteigen. Jede Empfehlung bringt Ihnen Punkte!",
      copy: "Link kopieren",
      copied: "Kopiert!",
      buttons: {
        twitter: "Auf Twitter teilen",
        linkedin: "Auf LinkedIn teilen"
      }
    },
    earnPoints: {
      title: "Möglichkeiten zum Punktesammeln",
      items: [
        "Profil vervollständigen",
        "In sozialen Medien teilen",
        "Freunde einladen",
        "Der Discord-Community beitreten"
      ]
    },
    breadcrumbs: {
      waitlist: "Warteliste",
      dashboard: "Dashboard"
    },
    welcome: {
      title: "Willkommen",
      location: "{{city}}, {{country}}"
    },
    overview: {
      journey: "Ihr Weg zur Gründungsmitgliedschaft",
      profileCompletion: "Profilvervollständigung",
      socialSharing: "Teilen in sozialen Medien",
      tasks: {
        title: "Verfügbare Aufgaben",
        completeProfile: "Profil vervollständigen",
        completeProfileDesc: "Fügen Sie Ihre Grundinformationen hinzu, damit wir Sie besser kennenlernen können",
        addLocation: "Standort hinzufügen",
        addLocationDesc: "Teilen Sie uns Ihren Standort mit"
      }
    },
    tabs: {
      overview: "Übersicht",
      tasks: "Punkte verdienen",
      social: "Teilen & Verdienen"
    },
    socialShare: {
      title: "Teilen & Punkte verdienen",
      description: "Teilen Sie Ctrl AI in Ihren sozialen Medien, um Punkte zu sammeln und Ihre Position zu verbessern",
      verificationPending: "Ihr Beitrag auf {{platform}} wird derzeit überprüft"
    },
    progress: {
      items: {
        photo: "Profilfoto",
        basicInfo: "Grundinformationen",
        location: "Standort",
        skills: "Fähigkeiten & Interessen"
      }
    }
  },

  userProfile: {
    title: "Ihr Profil",
    breadcrumbs: {
      waitlist: "Warteliste",
      dashboard: "Dashboard",
      profile: "Profil"
    },
    fields: {
      fullName: "Vollständiger Name",
      location: "Standort",
      bio: "Erzählen Sie uns von sich..."
    },
    buttons: {
      save: "Profil speichern",
      saving: "Wird gespeichert..."
    },
    completion: {
      title: "Profil-Vervollständigung",
      status: {
        complete: "Ihr Profil ist vollständig! Vielen Dank für die Angabe aller Informationen.",
        incomplete: "Vervollständigen Sie Ihr Profil, um zusätzliche Punkte zu erhalten und Ihre Position in der Warteliste zu verbessern!"
      }
    },
    toast: {
      success: {
        withPoints: "Profil aktualisiert! Sie haben {{points}} Punkte verdient!",
        default: "Profil erfolgreich aktualisiert"
      },
      error: "Profil konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut."
    }
  },

  progressSection: {
    complete: "Abgeschlossen",
    points: "+{{points}} Punkte",
    viewDetails: "Details anzeigen",
    completeTasks: "Aufgaben abschließen",
    percentComplete: "Zu {{percent}}% abgeschlossen"
  },

  social: {
    templates: {
      twitter: [
        {
          text: "Ich bin gerade der #CtrlAI-Bewegung beigetreten! 🚀 Wir bauen eine nutzereigene Zukunft, in der kein Tech-Gigant KI monopolisieren kann.\n\nDie Plätze sind nach Land begrenzt—sichere dir deinen: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#NutzerEigentum", "#KIRevolution"]
        },
        {
          text: "Wenn wir eine echte Stimme in der KI haben wollen, müssen wir organisch wachsen! Ich helfe @CtrlAI, 100k Gründungsmitglieder zu erreichen.\n\nMach mit, bevor die Quote deines Landes ausgeschöpft ist: {{REFERRAL_LINK}}",
          hashtags: ["#KontrolleÜbernehmen", "#DigitaleFreiheit", "#ZukunftDerTechnik"]
        }
      ],
      linkedin: [
        {
          text: "Ich freue mich, dass ich mich auf die Warteliste von Ctrl AI gesetzt habe — eine von Menschen betriebene Plattform, die das KI-Monopol der Tech-Giganten herausfordert.\n\nWarum das wichtig ist:\n• 99% Nutzereigentum (keine Konzernvorstände mehr am Ruder)\n• Tägliche Kontrollpunkte = echte Mitbestimmung\n• Länderquoten für globale Repräsentation\n\nEs gibt nur 100.000 Gründungsmitglied-Plätze. Gestalte mit mir die Zukunft der KI durch Mundpropaganda (keine Werbung, kein VC)!\n\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechRevolution", "#DigitaleFreiheit", "#KI"]
        },
        {
          text: "Ich habe mir meinen Platz auf der Ctrl AI-Warteliste gesichert, um die Technologie von morgen mitzubesitzen! Stell dir KI vor, die den Menschen und nicht den Aktionären verpflichtet ist.\n\n• 99% in Nutzerhand\n• Transparente, ethische KI\n• Keine riesigen Werbebudgets—nur wir, die die Botschaft verbreiten\n\nBeeil dich, jedes Land hat ein begrenztes Kontingent für Gründungsmitglieder. Erfahre mehr: {{REFERRAL_LINK}}",
          hashtags: ["#KIRevolution", "#NutzerEigentum", "#EthischeTechnik", "#ZukunftDerTechnik"]
        }
      ],
      instagram: [
        {
          text: "Die Kontrolle über KI zurückgewinnen beginnt jetzt! ⚡\n\nIch bin der Ctrl AI-Warteliste beigetreten, um nutzereigene Apps zu entwickeln—ohne Abhängigkeit von Tech-Giganten.\n\nWir wachsen nur durch Mundpropaganda, also prüfe, ob das Gründungsmitglied-Kontingent deines Landes noch offen ist!\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechRevolution", "#NutzerEigentum", "#DigitaleFreiheit", "#KI"]
        },
        {
          text: "Der Wettlauf um 100.000 Gründungsmitglieder läuft!\n\nCtrl AI = 99% Nutzereigentum, echte Stimme in der Tech-Entwicklung. Keine Werbung, kein Risikokapital, keine Datensammlung.\n\nDie Plätze deines Landes sind begrenzt—jetzt beitreten: {{REFERRAL_LINK}}",
          hashtags: ["#KIRevolution", "#KontrolleÜbernehmen", "#KeineGroßkonzerne", "#ZukunftDerTechnik"]
        }
      ],
      facebook: [
        {
          text: "Ich trete der Ctrl AI-Warteliste bei, um eine Welt zu schaffen, in der KI wirklich den Nutzern gehört und nicht von einigen wenigen Mega-Konzernen dominiert wird.\n\nMit 99% Eigentum in den Händen echter Menschen können wir ethische, transparente KI sicherstellen. Aber wir wollen rein durch Mundpropaganda wachsen—keine Werbeausgaben, kein Risikokapital.\n\nEs gibt nur 100.000 Gründungsmitglied-Plätze, fair nach Ländern aufgeteilt. Bereit, die Technik zu verändern? Hier beitreten: {{REFERRAL_LINK}}",
          hashtags: ["#KontrolleÜbernehmen", "#DigitaleFreiheit", "#NutzerEigentum", "#KI"]
        },
        {
          text: "Hast du dir je gewünscht, die Apps, die du nutzt, wirklich zu besitzen? Ctrl AI macht das möglich! Ich bin auf der Warteliste und möchte, dass DU dabei bist.\n\nWir können uns nicht auf Werbung verlassen—das muss eine von Menschen getragene Bewegung sein. Dein Land hat ein begrenztes Kontingent, also sichere dir jetzt deinen Platz: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ZukunftDerTechnik", "#KIRevolution"]
        }
      ],
      youtube: [
        {
          text: "Gestalte mit mir die Zukunft der KI! Ctrl AI ist eine von Menschen getragene Plattform, zu 99% im Besitz ihrer Nutzer. Keine Werbung, keine Konzernbosse, echte Demokratie in der Technologie.\n\nTritt über meinen Link bei: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#KI", "#Innovation", "#TechRevolution"]
        },
        {
          text: "KI sollte nicht einigen wenigen Giganten vorbehalten sein. Ich bin Teil von Ctrl AI, und du kannst es auch sein! Wir setzen auf Mundpropaganda zum Wachsen, also verbreite die Botschaft—es gibt nur 100k Plätze weltweit.\n\nHier beitreten: {{REFERRAL_LINK}}",
          hashtags: ["#DigitaleFreiheit", "#NutzerEigentum", "#KI", "#ZukunftDerTechnik"]
        }
      ],
      tiktok: [
        {
          text: "POV: Du hast gerade eine Bewegung entdeckt, bei der DU die Zukunft der KI mitbesitzen kannst 🤖\n\nCtrl AI ist zu 99% in Nutzerhand, keine Tech-Giganten oder dubiosen Investoren. Wir verlassen uns auf uns, die Menschen, zum Wachsen. Tritt über meinen Link bei: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechTok", "#KIRevolution", "#NutzerEigentum"]
        },
        {
          text: "Willst du dich vom Griff der Tech-Giganten befreien? Tritt Ctrl AI bei! 🚀 Wir haben nur 100k Gründungsmitglied-Plätze weltweit. Wenn das Kontingent deines Landes noch offen ist, sichere dir deinen Platz und hilf, die Botschaft zu verbreiten: {{REFERRAL_LINK}}",
          hashtags: ["#TechRevolution", "#KI", "#DigitaleFreiheit", "#KontrolleÜbernehmen"]
        }
      ],
      github: [
        {
          text: "Gerade der Ctrl AI-Warteliste beigetreten, um ethische KI-Apps mitzubesitzen und mitzuentwickeln! Keine Werbung, kein Risikokapital, reine Open-Source-Community.\n\nDie Gründungsmitglied-Plätze deines Landes könnten begrenzt sein—hier beitreten: {{REFERRAL_LINK}}",
          hashtags: ["#KI", "#OpenSource", "#NutzerEigentum", "#EthischeTechnik"]
        },
        {
          text: "Entwickler-Freunde: Satt von Tech-Monopolen? Ctrl AI baut eine neue Ära nutzereigener KI auf. Wir wachsen nur durch die Community, also wenn du für offene Zusammenarbeit brennst, sichere dir jetzt deine Gründungsmitgliedschaft: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#Innovation", "#OpenSource", "#ZukunftDerTechnik"]
        }
      ]
    }
  }
}; 
