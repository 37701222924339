import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { auth, db, functions } from '@/config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { EmailSignupForm } from './EmailSignupForm';

export function VerificationSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [verificationData, setVerificationData] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  
  const verificationId = searchParams.get('verification_id');
  const sessionId = searchParams.get('session_id');
  const email = searchParams.get('email');

  // Verify the session and load verification data
  useEffect(() => {
    const verifySession = async () => {
      if (!verificationId || !sessionId) {
        navigate('/verification');
        return;
      }

      try {
        const verificationRef = doc(db, 'pendingVerifications', verificationId);
        const verificationDoc = await getDoc(verificationRef);

        if (!verificationDoc.exists()) {
          throw new Error('Verification not found');
        }

        const data = verificationDoc.data();
        if (data.status !== 'paid') {
          throw new Error('Payment not completed');
        }

        setVerificationData(data);
      } catch (error) {
        console.error('Verification error:', error);
        setError('Invalid or expired verification. Please try again.');
        navigate('/verification');
      } finally {
        setLoading(false);
      }
    };

    verifySession();
  }, [verificationId, sessionId, navigate]);

  const completeVerification = async (verificationId: string) => {
    try {
      if (!verificationId) {
        throw new Error('Verification ID is required');
      }

      const completeVerificationFn = httpsCallable(functions, 'completeVerification');
      await completeVerificationFn({ verificationId });
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Verification completion error:', error);
      throw new Error(error.message);
    }
  };

  // If user is already logged in and emails match, complete verification
  useEffect(() => {
    const autoComplete = async () => {
      if (!verificationId) {
        setError('Invalid verification ID');
        return;
      }

      if (profile && profile.email === verificationData?.email) {
        try {
          await completeVerification(verificationId);
          navigate('/dashboard');
        } catch (error) {
          setError('Failed to complete verification automatically');
        }
      }
    };

    if (verificationData) {
      autoComplete();
    }
  }, [verificationData, profile, verificationId, navigate]);

  const handleGoogleSignup = async () => {
    try {
      setLoading(true);
      const provider = new GoogleAuthProvider();
      // Ensure Google signup uses the verified email
      provider.setCustomParameters({
        login_hint: email || ''
      });
      
      const result = await signInWithPopup(auth, provider);
      
      // Verify email matches
      if (result.user.email !== verificationData.email) {
        throw new Error('Please use the same email used during verification');
      }
      
      await completeVerification(result.user.uid);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
        <Button onClick={() => navigate('/verification')}>Try Again</Button>
      </Alert>
    );
  }

  return (
    <Card className="max-w-md mx-auto mt-20">
      <CardHeader>
        <CardTitle>Complete Your Account</CardTitle>
        <p className="text-muted-foreground">
          Payment successful! Create your account to join the chain.
        </p>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Button
            onClick={handleGoogleSignup}
            className="w-full"
            variant="outline"
          >
            Continue with Google
          </Button>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Or
              </span>
            </div>
          </div>

          <EmailSignupForm 
            verificationData={verificationData}
            onComplete={completeVerification}
            onError={setError}
          />
        </div>
      </CardContent>
    </Card>
  );
} 