import { create } from 'zustand';
import { 
  doc, 
  onSnapshot, 
  query, 
  collection, 
  orderBy, 
  limit,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  getDocs,
  startAfter
} from 'firebase/firestore';
import { db } from '@/config/firebase';
import type { ChainLink } from '@/features/chain/types';

interface ChainState {
  chainLinks: ChainLink[];
  recentLinks: ChainLink[];
  loading: boolean;
  error: string | null;
  initialize: () => () => void;
  lastDoc: QueryDocumentSnapshot | null;
  loadMoreLinks: () => Promise<void>;
}

export const useChainStore = create<ChainState>((set, get) => ({
  chainLinks: [],
  recentLinks: [],
  loading: false,
  error: null,
  lastDoc: null,

  loadMoreLinks: async () => {
    const { lastDoc, recentLinks } = get();
    set({ loading: true });
    
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, 'chainLinks'),
          orderBy('timestamp', 'desc'),
          startAfter(lastDoc),
          limit(20)
        )
      );

      const newLinks = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          timestamp: data.timestamp,
          referralDeadline: data.referralDeadline,
        } as unknown as ChainLink;
      });

      set({
        recentLinks: [...recentLinks, ...newLinks],
        lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
        loading: false
      });
    } catch (error) {
      console.error('Error loading more links:', error);
      set({ loading: false });
    }
  },

  initialize: () => {
    const unsubscribers: (() => void)[] = [];

    // Get the latest stats
    const statsRef = doc(db, 'chainStats', 'latest');
    
    const unsubLatest = onSnapshot(statsRef, (statsDoc: DocumentSnapshot) => {
      if (statsDoc.exists()) {
        const data = statsDoc.data();
        const lastLinkNumber = data?.lastLinkNumber || 0;
        const startDoc = Math.floor(lastLinkNumber / 1000) * 1000;
        
        // Subscribe to all relevant documents
        for (let i = startDoc; i >= 0; i -= 1000) {
          const docId = `links_${i}_${i + 999}`;
          const linksRef = doc(db, 'chainLinks', docId);
          
          const unsubDoc = onSnapshot(linksRef, (snapshot: DocumentSnapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.data();
              const links = (data?.links || []) as ChainLink[];
              set(state => {
                // Create a map of existing links by chainLinkNumber for deduplication
                const existingLinksMap = new Map(
                  state.recentLinks.map(link => [link.chainLinkNumber, link])
                );
                
                // Add new links, overwriting existing ones if present
                links.forEach(link => {
                  existingLinksMap.set(link.chainLinkNumber, link);
                });

                // Convert back to array and sort
                const updatedLinks = Array.from(existingLinksMap.values())
                  .sort((a, b) => b.timestamp.toDate().getTime() - a.timestamp.toDate().getTime());

                return {
                  chainLinks: [
                    ...state.chainLinks.filter(link => 
                      link.chainLinkNumber < i || link.chainLinkNumber > i + 999
                    ),
                    ...links
                  ].sort((a, b) => b.chainLinkNumber - a.chainLinkNumber),
                  recentLinks: updatedLinks
                };
              });
            }
          });
          
          unsubscribers.push(unsubDoc);
        }
      }
      set({ loading: false });
    });

    unsubscribers.push(unsubLatest);

    return () => unsubscribers.forEach(unsub => unsub());
  }
}));

// Initialize the store
useChainStore.getState().initialize(); 