export const de = {

  hero: {
    title: "Wir können KI nicht vollständig kontrollieren – aber wir müssen es versuchen",
    subtitle: 
      "Schließe dich Ctrl AI an, einer gemeinnützigen Bewegung, in der jeder Mensch eine gleichberechtigte Stimme hat, um die Zukunft der KI mitzugestalten. Verifiziere deine Menschlichkeit mit 1 $ und werde Miteigentümer eines globalen Projekts für ethische und transparente KI.",
    chainCall: "Jede Stimme zählt. Füge deine zur Bewegung hinzu."
  },

  problem: {
    category: "Die Herausforderung",
    title: "Die Macht der KI liegt in den Händen weniger – was, wenn sie nicht das Beste für die Menschheit wählen?",
    subtitle: "Wir müssen sicherstellen, dass KI für die gesamte Menschheit ausgerichtet ist – nicht nur für die Mächtigen und Reichen.",
    citation:
      "Das nennen wir 'KI-Kolonialismus'. Und die einzige Möglichkeit, ihn zu stoppen, besteht darin, Milliarden gewöhnlicher Menschen in wahrem KI-Eigentum zu vereinen.",
    pointsTitle: "Warum du jetzt handeln musst:",
    points: [
      {
        headline: "Monopole der Tech-Giganten",
        text: "Eine Handvoll Unternehmen kontrolliert Daten, Ressourcen und Durchbrüche in der KI."
      },
      {
        headline: "Geringe Verantwortung",
        text: "Profitinteressen von Unternehmen können das Gemeinwohl überlagern."
      },
      {
        headline: "Gesellschaftlicher Umbruch",
        text: "Da KI immer mehr menschliche Jobs übernimmt, muss sich die Gesellschaft vorbereiten."
      }
    ],
    conclusion: "Entweder wir besitzen die KI der Zukunft gemeinsam – oder wir überlassen sie den Megakonzernen, die mit unseren Daten Profit machen."
  },

  opportunity: {
    title: "Unsere Lösung – Eine menschliche Bewegung",
    subtitle: "Jedes verifizierte Mitglied ist ein gleichberechtigter Partner – nicht mehr, nicht weniger.",
    benefitsTitle: "Warum das wichtig ist",
    benefits: [
      {
        headline: "Gemeinnützig & 100 % benutzereigen",
        text: "Dein 1 $ ist keine Gebühr – es ist der Beweis, dass du ein Mensch bist. Die Mitglieder entscheiden, wie diese Mittel genutzt werden."
      },
      {
        headline: "Offene Governance",
        text: "Jede Person hat genau eine Stimme – kein zusätzlicher Einfluss für frühere Beitritte."
      },
      {
        headline: "Echte Wirkung",
        text: "Indem wir uns vereinen, schaffen wir eine mächtige Koalition, die Big Tech und Regierungen respektieren müssen."
      }
    ]
  },

  howItWorks: {
    title: "So funktioniert es",
    points: [
      {
        title: "1. Verifiziere deine Menschlichkeit (1 $)",
        bullets: [
          "Ein einfacher Check, um Bots zu blockieren und Fairness zu gewährleisten.",
          "Entscheide, ob dein 1 $ in die Gemeinschaftskasse fließt oder Betriebskosten deckt."
        ]
      },
      {
        title: "2. Werde Miteigentümer",
        bullets: [
          "Eine Person, eine Stimme bei wichtigen Entscheidungen – unabhängig davon, wann du beitrittst."
        ]
      },
      {
        title: "3. Verbreite die Botschaft",
        bullets: [
          "Unsere Bewegung wächst nur durch echte Menschen, die sie weiterempfehlen – keine Werbung, keine Investoren."
        ]
      }
    ]
  },

  commitment: {
    title: "Unser Engagement",
    subtitle: "Eine Grundlage aus Vertrauen und Transparenz schaffen",
    points: [
      {
        headline: "Volle Transparenz",
        text: "Wir teilen unsere Finanzen, Entscheidungen und Fortschritte öffentlich."
      },
      {
        headline: "Für immer gemeinnützig",
        text: "Keine Unternehmensaktionäre – jeder hat einen Anteil."
      },
      {
        headline: "Ethische KI-Entwicklung",
        text: "Wir entwickeln und unterstützen KI, die das menschliche Wohl und das Gemeinwohl in den Vordergrund stellt."
      }
    ]
  },

  shareMessage: {
    title: "Hilf uns zu wachsen – Keine Werbung, keine Investoren, nur Menschen",
    subtitle: 
      "Ctrl AI lebt von echten menschlichen Stimmen, die unsere Botschaft verbreiten. Betrachte es als ein globales Pay-it-Forward – unsere gemeinsame Zukunft hängt von jeder Person ab, die sich anschließt.\n\nNachdem du beigetreten bist, lade bitte einen Freund ein.",
    cta: "Teile Ctrl AI"
  },

  cta: {
    title: "Bereit, die Zukunft der KI mitzugestalten?",
    subtitle: 
      "Verifiziere deine Menschlichkeit für 1 $ und stehe Seite an Seite mit anderen, die eine ethische, inklusive KI fordern. Gemeinsam können wir sicherstellen, dass KI für alle Menschen arbeitet.",
    buttons: {
      primary: "Jetzt beitreten (1 $)",
      secondary: "Mitgliederliste"
    }
  },
  aspiringFoundersPage: {
    hero: {
      category: "Erfinder & Innovatoren",
      title: "Baue mit Ctrl AI – Gestalte die Zukunft der Technologie",
      subtitle:
        "Hast du eine Vision für eine ethische, nutzerzentrierte KI-Anwendung oder ein Open-Source-Modell? Präsentiere deine Idee der Ctrl AI-Community und arbeite gemeinsam daran, sie zum Leben zu erwecken – ohne Werbung, Risikokapital oder versteckte Agenden. Alles unterliegt der kollektiven Zustimmung unserer Mitglieder.",
    },
  
    intro: {
      title: "Unsere Philosophie: Menschen vor Profite",
      subtitle:
        "Da Open-Source-KI-Modelle rasant mit Unternehmenslösungen aufholen, gibt es ein einzigartiges Zeitfenster für Entwickler, bessere und menschlichere Lösungen zu schaffen. Bei Ctrl AI glauben wir, dass Innovation gedeiht, wenn:\n\n• Sie von den Menschen finanziert wird, die sie tatsächlich nutzen werden.\n• Gründer nicht gezwungen sind, Profit auf Kosten der Nutzer zu maximieren.\n• Technologie offen, transparent und gemeinschaftlich bleibt.",
      pointsTitle: "Warum unter Ctrl AI bauen?",
      points: [
        {
          headline: "Community-getriebene Finanzierung",
          text: "Kein ausbeuterisches Risikokapital oder werbebasierte Einschränkungen. Unsere gemeinnützige, nutzergeführte Community kann dein Projekt finanzieren – wenn sie daran glaubt."
        },
        {
          headline: "Open-Source-Wachstum",
          text: "Nutze Open-Source-KI-Modelle, die mit den Lösungen der Konzerne konkurrieren. Entwickle sie weiter – gemeinsam mit unserer globalen Community."
        },
        {
          headline: "Ein neuer Gesellschaftsvertrag",
          text: "Mit Tausenden (später Millionen) von Miteigentümern hast du ein integriertes Publikum, das an deinem Projekt interessiert und beteiligt ist – und daran, dass es ethisch bleibt."
        }
      ],
      disclaimer:
        "Alle Details unten sind erste Konzepte und werden letztendlich durch die kollektive Stimme der Ctrl AI-Mitglieder geformt."
    },
  
    ownershipModels: {
      title: "Flexible Eigentumsstrukturen",
      subtitle:
        "Wir wissen, dass unterschiedliche Projekte unterschiedliche Bedürfnisse haben. Hier sind einige unserer ersten Ideen, wie Gründerteams und die Community Eigentum und Verantwortung teilen können.",
      models: [
        {
          headline: "Kleinere Projekte: Gründeranteil bis zu 5 %",
          text: "Gründer behalten einen kleinen Anteil, während der Rest der Community gehört. Die Community kann auch darüber abstimmen, ob Gründer ein monatliches Gehalt erhalten, damit sie sich voll auf den Aufbau konzentrieren können."
        },
        {
          headline: "Größere ambitionierte Projekte: Vollständig gemeinschaftseigen",
          text: "Wenn die Community genug Mittel für Entwicklung, Infrastruktur und Personal aufbringen kann, kann sie sich für 100 % kollektives Eigentum entscheiden – mit fairer Vergütung für Mitwirkende, aber ohne langfristiges Gründer-Eigentum."
        }
      ],
      note:
        "Die genaue Struktur des Eigentums jedes Projekts – und wie Mittel verteilt werden – wird durch offene Diskussionen und demokratische Abstimmungen entschieden, sobald wir eine kritische Masse an Mitgliedern erreicht haben."
    },
  
    pitchProcess: {
      category: "Pitch & Genehmigung",
      title: "So kannst du ein Projekt starten",
      subtitle:
        "Sobald unsere Community groß genug ist, um mehrere Initiativen zu unterstützen, werden wir Gründer einladen, ihre Ideen zu präsentieren. Die Community entscheidet dann gemeinsam, welche Projekte finanziert, entwickelt und priorisiert werden.",
      stepsTitle: "Erste Schritte (vorbehaltlich Community-Abstimmung)",
      steps: [
        {
          headline: "1. Präsentiere deine Vision",
          text: "Erstelle einen kurzen Vorschlag, der deine Idee, ihre potenziellen Auswirkungen und dein Team beschreibt."
        },
        {
          headline: "2. Offene Diskussion",
          text: "Alle Ctrl AI-Mitglieder können Fragen stellen, Verbesserungen vorschlagen und das Interesse der Community bewerten."
        },
        {
          headline: "3. Community-Abstimmung",
          text: "Wenn dein Projekt genügend Unterstützung erhält, bekommst du Finanzierung und Ressourcen, um es umzusetzen. Gründer können während der Entwicklung möglicherweise ein monatliches Gehalt erhalten – wenn die Community es genehmigt."
        },
        {
          headline: "4. Kontinuierliche Transparenz",
          text: "Regelmäßige Fortschrittsupdates, Open-Source-Code (soweit machbar) und eine Verpflichtung zum Wohl der Nutzer sind während der gesamten Entwicklung erforderlich."
        }
      ],
      conclusion:
        "Kein starres System, keine Bürokratie – nur eine gemeinschaftsgetriebene Plattform, in der die besten und ethischsten Ideen gedeihen können."
    },
  
    buildingInPublic: {
      category: "Offene Zusammenarbeit",
      title: "Baue öffentlich, wachse gemeinsam",
      subtitle:
        "Transparenz ist der Kern der DNA von Ctrl AI. Indem du deine Roadmap, Herausforderungen und Meilensteine mit den Miteigentümern teilst, lädst du echte Zusammenarbeit ein – und gewinnst dauerhafte Unterstützung.",
      pointsTitle: "Unsere Prinzipien für öffentliches Bauen",
      points: [
        {
          headline: "Offene Roadmaps",
          text: "Halte die Community über wichtige Funktionen, Zeitpläne und langfristige Ziele auf dem Laufenden."
        },
        {
          headline: "Gemeinsames Testen & Feedback",
          text: "Nutze eine globale Nutzerbasis für Beta-Tests, Fehlererkennung und Optimierung."
        },
        {
          headline: "Regelmäßige Fortschrittsberichte",
          text: "Teile Erfolge, Herausforderungen und kommende Aufgaben wöchentlich oder monatlich, um Vertrauen und Dynamik zu erhalten."
        }
      ],
      conclusion:
        "Dieser offene Ansatz hilft nicht nur, bessere Lösungen zu entwickeln – er stellt sicher, dass jeder den menschzentrierten Werten verpflichtet bleibt, für die wir stehen."
    },
  
    callToAction: {
      category: "Werde aktiv",
      title: "Bereit, deine Idee zu verwirklichen?",
      subtitle:
        "Wir stehen noch am Anfang einer globalen Bewegung. Sobald wir genügend Mitglieder haben, starten wir den Pitch-Prozess. Tritt jetzt Ctrl AI bei, vernetze dich mit Mitbesitzern und verfeinere deine Vision, damit du bereit bist, wenn es losgeht.",
      stepsTitle: "Nächste Schritte (für den Moment)",
      steps: [
        {
          headline: "1. Tritt Ctrl AI bei",
          text: "Werde für 1 $ verifiziertes Mitglied. Deine Stimme wird darüber entscheiden, welche Projekte finanziert und unterstützt werden."
        },
        {
          headline: "2. Engagiere dich in der Community",
          text: "Lerne potenzielle Kollaborateure und zukünftige Nutzer kennen. Besprich deine Idee informell, um frühzeitig Unterstützung aufzubauen."
        },
        {
          headline: "3. Bereite deinen Pitch vor",
          text: "Skizziere deine Idee – wie sie zu den Werten von Ctrl AI passt und welchen Finanzierungsbedarf du hast. Bleibe auf dem Laufenden über Pitch-Einreichungen."
        }
      ],
      conclusion:
        "Diese Frameworks sind nicht in Stein gemeißelt. Gemeinsam werden wir definieren, wie wir visionäre Gründer unterstützen – während wir Technologie wirklich nutzerzentriert halten."
    }
  },
  newSocialContractPage: {
    hero: {
      category: "Ein neuer Gesellschaftsvertrag für das KI-Zeitalter",
      title: "Schließe dich Ctrl AI an: Eine gemeinnützige, menschengetriebene Bewegung",
      subtitle:
        "Indem du deine Menschlichkeit für 1 $ verifizierst, wirst du Miteigentümer unserer globalen Initiative, um KI ethisch, transparent und inklusiv zu gestalten. Keine Aktionäre, keine profitorientierten Investoren – nur eine Person, eine Stimme.",
    },
  
    intro: {
      title: "Warum ein gemeinnütziges, nutzergeführtes Modell?",
      subtitle:
        "Angesichts der rasanten KI-Fortschritte müssen nicht Konzerne, sondern die Menschen selbst darüber entscheiden, wie Technologie entwickelt wird. Ctrl AI baut einen neuen Gesellschaftsvertrag – einen, der menschliches Wohlbefinden und Gemeinschaftsführung ins Zentrum stellt.",
      pointsTitle: "Grundprinzipien",
      points: [
        {
          headline: "Eine Person, eine Stimme",
          text: "Egal wann du beitrittst oder wie viel Geld du hast – deine Stimme zählt genauso viel wie die des Gründers."
        },
        {
          headline: "Keine Werbung, keine Investoren",
          text: "Wir finanzieren uns ausschließlich durch eine einmalige 1 $-Verifikation, um Bots auszuschließen und echtes menschliches Eigentum zu gewährleisten – ohne Unternehmensinteressen."
        },
        {
          headline: "Globale Inklusivität",
          text: "Jeder, überall kann beitreten. Unser Ziel ist es, Stimmen aus der ganzen Welt zu vereinen."
        }
      ],
      conclusion:
        "Wenn ganz normale Menschen KI besitzen, stellen wir sicher, dass sie durch gemeinsame Werte gesteuert wird – Fairness, Transparenz und Befähigung – und nicht nur durch Profit."
    },
  
    newOwnershipModel: {
      title: "Die Eigentumsregeln neu schreiben",
      subtitle:
        "Traditionelle Tech-Unternehmen sind Investoren und Werbeeinnahmen verpflichtet. Ctrl AI ist seinen Nutzern verpflichtet – denn die Nutzer sind die Eigentümer.",
      pointsTitle: "Was uns anders macht",
      points: [
        {
          headline: "100 % gemeinschaftlich geführt",
          text: "Wir sind eine gemeinnützige Organisation ohne externe Aktionäre. Alle Mitglieder bestimmen gemeinsam unsere Zukunft."
        },
        {
          headline: "Transparente Finanzierung & Ausgaben",
          text: "Dein 1 $ wird vollständig nachverfolgt. Unsere Finanzen sind öffentlich einsehbar, und überschüssige Mittel werden per Mitgliedsabstimmung verwendet."
        },
        {
          headline: "Geteilte Verantwortung",
          text: "Da wir die Plattform gemeinsam besitzen, teilen wir auch die Verantwortung dafür, dass sie ethisch, nützlich und im Interesse der Menschheit bleibt."
        }
      ],
      note:
        "Das ist mehr als ein Geschäftsmodell – es ist ein neuer Gesellschaftsvertrag, in dem Technologie allen gehört, die sie nutzen."
    },
  
    whyJoinNow: {
      title: "Warum jetzt Gründungsmitglied werden?",
      subtitle:
        "Wir stehen an einem Wendepunkt: KI entwickelt sich so schnell, dass eine Handvoll Konzerne sie dominieren und darüber entscheiden könnte, wie wir alle leben und arbeiten. Wenn du jetzt beitrittst, wirst du Mitgestalter einer anderen Zukunft.",
      points: [
        {
          headline: "Hilf, unsere Verfassung zu gestalten",
          text: "Als Teil der Gründungscommunity hilfst du, unsere Leitprinzipien festzulegen und die Führung verantwortlich zu halten."
        },
        {
          headline: "Direkte Einflussnahme",
          text: "Schlage wichtige Entscheidungen vor oder stimme darüber ab – von der Budgetverteilung bis hin zu den ersten KI-Tools, die wir entwickeln."
        },
        {
          headline: "Setze ein globales Beispiel",
          text: "Hilf zu beweisen, dass Technologie ethisch und transparent entwickelt werden kann – wenn Menschen, nicht Profite, im Mittelpunkt stehen."
        }
      ],
      conclusion:
        "Jetzt beizutreten bedeutet, an der Spitze einer menschengeführten KI-Revolution zu stehen – einer, die das Wohl aller über Unternehmensinteressen stellt."
    },
  
    howItWorks: {
      title: "So funktioniert der Beitritt",
      steps: [
        {
          title: "1. Verifiziere deine Menschlichkeit",
          text: "Leiste eine einmalige Zahlung von 1 $, um zu bestätigen, dass du ein echter Mensch bist. Keine weiteren Gebühren, keine Abonnements."
        },
        {
          title: "2. Werde Miteigentümer",
          text: "Deine Mitgliedschaft gibt dir eine gleichberechtigte Stimme bei jeder wichtigen Entscheidung – von unserer Verfassung bis zur Projektfinanzierung."
        },
        {
          title: "3. Verbreite die Botschaft",
          text: "Wir wachsen durch menschliche Verbindungen, nicht durch Werbung oder Investorengelder. Lade andere ein, damit unsere kollektive Stimme stärker wird."
        }
      ]
    },
  
    finalCall: {
      title: "Bereit, die Zukunft der KI neu zu schreiben?",
      subtitle:
        "Bringe deine Stimme, deine Ideen und deine Leidenschaft ein. Gemeinsam können wir sicherstellen, dass KI ein Werkzeug für die gesamte Menschheit bleibt – und nicht nur für eine Elite.",
      button: "Jetzt Ctrl AI beitreten"
    }
  },
  constitutionPage: {
    hero: {
      title: "Die Ctrl AI Verfassung (Entwurf)",
      preamble:
        "In einer Zeit, in der Technologie zunehmend die Zukunft der Menschheit gestaltet, wählen wir einen anderen Weg: Wir bauen nicht nur bessere Produkte, sondern arbeiten nach besseren Prinzipien.\n\nDies ist unser erster Entwurf – sobald wir eine kritische Masse an Mitgliedern weltweit erreichen, werden wir ihn gemeinsam durch eine Community-Abstimmung verfeinern."
    },
  
    principles: {
      title: "Unsere Kernprinzipien",
      intro:
        "Diese Grundwerte leiten Ctrl AI. Sie können sich weiterentwickeln, aber ihr Kern bleibt: Menschliches Wohl, Transparenz und gemeinschaftliche Kontrolle müssen in jeder KI-Entwicklung im Mittelpunkt stehen.\n\nSobald sie von der globalen Community ratifiziert wurden, sind diese Verpflichtungen für jedes Projekt unter Ctrl AI bindend:",
  
      principles: [
        {
          title: "Menschliches Wohl an erster Stelle",
          items: [
            "KI muss das tatsächliche Wohl des Menschen fördern – nicht Engagement-Metriken oder Suchtmechanismen.",
            "Null Toleranz für manipulative Designs oder psychologische Ausbeutung.",
            "Respektiere die Aufmerksamkeit der Nutzer als begrenzte, wertvolle Ressource.",
            "Fördere gesunde Nutzungsmuster und natürliche Pausen im Design.",
            "Setze auf sinnvolle Verbindungen statt auf süchtig machende Erlebnisse."
          ]
        },
        {
          title: "Absolute Datenrechte",
          items: [
            "Nutzer besitzen ihre Daten vollständig – kein Verkauf oder unautorisierte Weitergabe.",
            "Wo möglich, setzen wir auf lokale Datenverarbeitung.",
            "Starke Verschlüsselung ist unser Standard.",
            "Jeder Nutzer hat das Recht, seine Daten jederzeit vollständig löschen zu lassen."
          ]
        },
        {
          title: "Echte Gemeinschaftsmacht",
          items: [
            "100 % gemeinschaftliches Eigentum – keine Unternehmensaktionäre.",
            "Eine Person, eine Stimme bei allen wichtigen Entscheidungen (einschließlich des Gründers).",
            "Transparente Budgetierung und offene Governance-Prozesse.",
            "Globale Inklusivität – jeder kann beitreten mit einer einfachen 1 $-Verifikation."
          ]
        },
        {
          title: "Ethische KI-Grenzen",
          items: [
            "KI muss menschliche Entscheidungsfreiheit unterstützen – niemals untergraben oder ersetzen.",
            "Transparenz bei KI-Entscheidungen ist Pflicht.",
            "Kontinuierliche Überwachung und Minimierung von Bias.",
            "Immer eine klare menschliche Kontrolle über KI-Funktionen ermöglichen.",
            "Entwicklung auf Werkzeuge fokussieren, die Menschen befähigen, anstatt sie zu ersetzen."
          ]
        }
      ]
    },
  
    enforcement: {
      title: "Echte Durchsetzung, echte Verantwortung",
      content:
        "Diese Prinzipien sind nicht nur leere Versprechen – sie sind verbindliche Richtlinien mit klaren Mechanismen zur Kontrolle und Korrektur, falls wir ihnen nicht gerecht werden.\n\nUnsere Community hat das letzte Wort bei der Sicherstellung der Einhaltung.",
      measures: [
        {
          title: "Gemeinschaftliche Aufsicht",
          items: [
            "• Regelmäßige öffentliche Audits von Code, Finanzen und Prozessen",
            "• Echtzeit-Meldung potenzieller Verstöße",
            "• Direkte Feedback-Kanäle für Mitglieder",
            "• Unabhängiges Ethik-Überprüfungsgremium für neutrale Untersuchungen"
          ]
        },
        {
          title: "Klare Konsequenzen",
          items: [
            "• Sofortige Reaktion auf von Mitgliedern gemeldete Probleme",
            "• Gemeinschaftsgetriebene Lösungen und Korrekturmaßnahmen",
            "• Verpflichtende Maßnahmen zur Behebung von Verstößen",
            "• Transparente Berichterstattung darüber, wie jedes Problem gelöst wurde"
          ]
        },
        {
          title: "Ein lebendiges Dokument",
          items: [
            "• Jährliche Überprüfung zur Anpassung an neue Technologien oder gesellschaftliche Bedürfnisse",
            "• Kernprinzipien bleiben unveränderlich, außer durch eine Supermehrheit der Community",
            "• Demokratischer Änderungsprozess, sobald eine kritische Masse an Mitgliedern erreicht ist",
            "• Offene Einladung für Mitglieder, Vorschläge einzureichen"
          ]
        }
      ]
    },
  
    cta: {
      title: "Beteilige dich an der Gestaltung der KI-Zukunft",
      content:
        "Dieser Verfassungsentwurf ist nur der Anfang. Sobald wir eine vielfältige, globale Mitgliedschaft erreicht haben, werden wir diese Prinzipien gemeinsam finalisieren und ratifizieren.\n\nEgal, ob du Entwickler, Politiker oder alltäglicher Nutzer bist – deine Stimme hat hier das gleiche Gewicht.\n\nBist du bereit, mitzugestalten?",
      button: "Jetzt für 1 $ Ctrl AI beitreten"
    }
  },
  footer: {
    companyDescription: "Die Zukunft der KI mit gemeinschaftlichem Eigentum und ethischen Prinzipien gestalten.",
    navigation: "Navigation",
    legal: "Rechtliches",
    connect: "Sonstiges",
    privacy: "Datenschutzerklärung",
    terms: "Nutzungsbedingungen",
    cookies: "Cookie-Richtlinie",
    rights: "Alle Rechte vorbehalten."
  },

  nav: {
    chain: "Chain-Dashboard",
    founders: "Für Entwickler",
    ownership: "Neuer Gesellschaftsvertrag",
    constitution: "Verfassung",
    pricing: "Preise",
    dashboard: "Dashboard",
    myProfile: "Mein Profil",
    settings: "Einstellungen",
    home: "Startseite",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Als Gründer bewerben",
    about: "Über Ctrl AI",
    logout: "Abmelden",
    members: "Mitglieder",
  },

  auth: {
    login: {
      title: "Willkommen zurück",
      subtitle: "Setze deine Reise in die Zukunft fort",
      emailPlaceholder: "E-Mail eingeben",
      passwordPlaceholder: "Passwort eingeben",
      submitButton: "Anmelden",
      noAccount: "Noch kein Konto?",
      signupLink: "Als Gründer registrieren",
      error: "Ungültige E-Mail oder Passwort"
    },
    signup: {
      title: "Als Gründer beitreten",
      subtitle: "Beginne, die Zukunft der Technologie zu gestalten",
      namePlaceholder: "Vollständigen Namen eingeben",
      emailPlaceholder: "E-Mail eingeben",
      passwordPlaceholder: "Passwort wählen",
      submitButton: "Konto erstellen",
      hasAccount: "Bereits ein Konto?",
      loginLink: "Anmelden",
      error: "Fehler beim Erstellen des Kontos"
    },
    errors: {
      passwordLength: "Das Passwort muss mindestens 6 Zeichen lang sein",
      invalidEmail: "Bitte eine gültige E-Mail-Adresse eingeben",
      emailInUse: "Diese E-Mail wird bereits verwendet"
    },
    passwordRequirements: "Das Passwort muss mindestens 6 Zeichen lang sein",
    joinForm: {
      coverOperationalCosts: "Unsere Betriebskosten unterstützen (empfohlen) – Dein 1 $ hilft dabei, Betriebskosten zu decken, anstatt in die Gemeinschaftskasse zu fließen",
      processing: "Wird verarbeitet...",
      signUpWithEmail: "Mit E-Mail registrieren",
      headline: "Schließe dich der Bewegung an",
      continueWithGoogle: "Mit Google fortfahren",
      errors: {
        allFieldsRequired: "Alle Felder müssen ausgefüllt werden",
        acceptTerms: "Du musst die Bedingungen akzeptieren, um fortzufahren",
        passwordLength: "Das Passwort muss mindestens 6 Zeichen lang sein",
        paymentFailed: "Die Zahlung konnte nicht verarbeitet werden. Bitte versuche es erneut."
      },
      subtitle: "Erstelle dein Konto, um deine Menschlichkeit zu verifizieren",
      costAllocationTitle: "Wähle, wohin deine Verifikationsgebühr geht",
      operationalCosts: "Betriebskosten unterstützen",
      operationalCostsDetail: "Hilft, unsere Server- und Betriebskosten zu decken (0,66 $ nach Zahlungsgebühren).",
      treasuryCosts: "Unberührt lassen",
      treasuryCostsDetail: "Dein gesamter Beitrag (abzüglich Gebühren) fließt direkt in die Gemeinschaftskasse.",
      orDivider: "oder",
      email: "E-Mail",
      emailPlaceholder: "E-Mail-Adresse eingeben",
      fullName: "Vollständiger Name",
      fullNamePlaceholder: "Vollständigen Namen eingeben",
      password: "Passwort",
      passwordPlaceholder: "Passwort wählen (mind. 6 Zeichen)",
      alreadyHaveAccount: "Bereits ein Konto?",
      signIn: "Anmelden",
      termsAgreement: "Ich akzeptiere die",
      termsLink: "Nutzungsbedingungen"
    }
  },

  members: {
    title: "Werde Teil unserer wachsenden Bewegung",
    subtitle: "Wir stehen noch am Anfang – und genau das macht es spannend.\n\nTritt heute bei und gestalte unsere Gemeinschaft von Anfang an mit. Deine Stimme bestimmt die Zukunft der KI.",
    stats: {
      membersLabel: "Verifizierte Menschen",
      countriesLabel: "Vertretene Länder"
    },
    share: {
      title: "Hilf uns zu wachsen",
      subtitle: "Jede einzelne Weiterempfehlung kann die nächste wichtige Stimme hinzufügen.",
      twitterText: "Ich habe gerade @CtrlAI entdeckt – eine globale Bewegung echter Menschen, die die Zukunft der KI gestalten. Mach mit!",
      buttons: {
        twitter: "Auf Twitter teilen",
        facebook: "Auf Facebook teilen",
        copyLink: "Link kopieren",
        linkCopied: "Link wurde in die Zwischenablage kopiert!"
      }
    },
    shareInvite: {
      title: "Teile deinen Beitrag",
      bulletPoints: [
        "Jede Weiterempfehlung bringt uns einer echten demokratischen KI-Governance näher.",
        "Hilf mit, unsere globale Gemeinschaft zu vergrößern.",
        "Verbreite die Idee einer menschengeführten KI."
      ],
      twitterText: "Ich bin gerade @CtrlAI beigetreten, um die Zukunft der KI mitzugestalten. Sei dabei!",
      shareButtons: {
        twitter: "Auf Twitter teilen",
        facebook: "Auf Facebook teilen",
        copyLink: "Link kopieren",
        linkCopied: "Link wurde kopiert!"
      },
      founderNote: "Du bist Mitglied #{{memberNumber}} unserer Bewegung. Besuche dein Profil, um dein öffentliches Profil anzupassen."
    }
  },

  quotaMap: {
    stats: {
      totalQuota: "Gesamtquote weltweit",
      totalRegistered: "Gesamtzahl der Registrierungen",
      totalPopulation: "Gesamtbevölkerung",
      countryCount: "Vertretene Länder",
      fulfillment: "Erfüllungsgrad weltweit"
    },
    tooltip: {
      country: "Land",
      population: "Bevölkerung",
      quota: "Mitgliederquote",
      registered: "Registrierte Mitglieder",
      fulfillment: "Quote erfüllt"
    },
    legend: {
      title: "Erfüllungsgrad",
      low: "Gering",
      target: "Ziel",
      exceeded: "Überschritten"
    },
    controls: {
      reset: "Ansicht zurücksetzen",
      zoom: "Zoomen",
      pan: "Verschieben"
    },
    noData: "Für dieses Land sind keine Daten verfügbar.",
    loading: "Kartendaten werden geladen..."
  },

  founders: {
    title: "Werde Teil unserer wachsenden Bewegung",
  }
}
