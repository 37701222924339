import { useEffect, useState } from 'react';
import { useChainStore } from '@/stores/useChainStore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import { Link2, Link2Off, Clock } from 'lucide-react';
import type { ChainLink } from '@/features/chain/types';
import { CountryFlag } from '@/components/CountryFlag';
import { getISOCode } from '@/utils/countryUtils';
import { useUserStore } from '@/stores/useUserStore';
import { cn } from '@/lib/utils';
import Confetti from 'react-confetti';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Button } from '@/components/ui/button';

export function RecentJoins({ limit = 100, showLoadMore = true }) {
  const { t } = useTranslation();
  const { recentLinks, loading, loadMoreLinks } = useChainStore();
  const { profile } = useUserStore();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [previousLinksCount, setPreviousLinksCount] = useState(0);
  const [displayCount, setDisplayCount] = useState(limit);

  // Track new joins and trigger celebration
  useEffect(() => {
    if (recentLinks.length > previousLinksCount && previousLinksCount !== 0) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    }
    setPreviousLinksCount(recentLinks.length);
  }, [recentLinks.length, previousLinksCount]);

  // Get user's chain link number
  const userLinkNumber = profile?.chainData?.chainLinks ? 
    Object.values(profile.chainData.chainLinks)[0]?.chainLinkNumber : 
    null;

  // Sort links by timestamp descending (latest first)
  const sortedLinks = [...recentLinks].sort((a, b) => 
    b.timestamp.toDate().getTime() - a.timestamp.toDate().getTime()
  );

  const handleLoadMore = () => {
    loadMoreLinks();
    setDisplayCount(prev => prev + 20);
  };

  // Sort and slice the links
  const displayedLinks = sortedLinks.slice(0, displayCount);

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t('chain.recentJoins.title')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px] flex items-center justify-center">
            <div className="animate-pulse space-y-4 w-full">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="h-16 bg-muted rounded-lg" />
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      <Card className="max-w-3xl mx-auto">
        <CardHeader>
          <CardTitle>{t('chain.recentJoins.title')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {displayedLinks.map((link: ChainLink) => {
              const isUserLink = link.chainLinkNumber === userLinkNumber;
              const now = new Date();
              const referralDeadline = link.referralDeadline.toDate();
              const timeLeft = referralDeadline.getTime() - now.getTime();
              const canRefer = !link.hasReferred && timeLeft > 0;

              return (
                <div 
                  key={link.chainLinkNumber}
                  className={cn(
                    "grid grid-cols-[auto_1fr_auto_auto] items-center p-3 rounded-lg gap-4",
                    isUserLink ? "bg-primary/10 border border-primary/20" : "bg-muted/50"
                  )}
                >
                  {/* Link number */}
                  <div className="flex items-center justify-center min-w-[3rem]">
                    <span className="text-xl font-mono font-semibold">#{link.chainLinkNumber}</span>
                  </div>
                  
                  {/* Location and time info */}
                  <div className="flex flex-col min-w-0">
                    <div className="flex items-center gap-2 flex-wrap">
                      <div className="flex items-center gap-2">
                        <CountryFlag 
                          topologyId={getISOCode(link.country) || ''} 
                          className="w-5 h-5 rounded-sm inline-flex flex-shrink-0"
                        />
                        <span className="text-sm font-medium truncate mb-[1px]">
                          {link.city}, {link.country}
                        </span>
                      </div>
                      {isUserLink && (
                        <Badge variant="secondary" className="text-xs">
                          {t('chain.recentJoins.you')}
                        </Badge>
                      )}
                    </div>
                    
                    <div className="flex items-center gap-2 text-xs text-muted-foreground mt-1">
                      <span>{formatDistanceToNow(link.timestamp.toDate(), { addSuffix: true })}</span>
                      {canRefer && (
                        <>
                          <span>•</span>
                          <span className="flex items-center gap-1">
                            <Clock className="w-3 h-3" />
                            {t('chain.recentJoins.timeLeft')}: {formatDistanceToNow(referralDeadline, { addSuffix: true })}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  

                  {/* Referral status 
                  <div className="flex-shrink-0">
                    {link.hasReferred ? (
                      <Badge variant="outline" className="whitespace-nowrap">
                        {t('chain.recentJoins.referred', { number: link.referredLinkNumbers?.[0] })}
                      </Badge>
                    ) : canRefer ? (
                      <Badge variant="secondary" className="whitespace-nowrap">
                        {t('chain.recentJoins.notReferred')}
                      </Badge>
                    ) : null}
                  </div>
                  */}
                  
                  {/* Status icon */}
                  <div className="flex-shrink-0">
                    <Badge 
                      variant={link.hasReferred ? "success" : "secondary"} 
                      className="w-8 h-8 rounded-full p-0 flex items-center justify-center"
                    >
                      {link.hasReferred ? (
                        <Link2 className="w-4 h-4" />
                      ) : (
                        <Link2Off className="w-4 h-4" />
                      )}
                    </Badge>
                  </div>

                </div>
              );
            })}
          </div>
          
          {showLoadMore && displayedLinks.length < recentLinks.length && (
            <div className="mt-6 text-center">
              <Button 
                variant="outline" 
                onClick={handleLoadMore}
                disabled={loading}
              >
                {loading ? t('common.loading') : t('common.loadMore')}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}