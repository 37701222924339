export const ar = {
hero: {
  title: "OpenAI لم تعد مفتوحة.",
  subtitle: "هذه هي الحركة الوحيدة التي تضع البشرية أولاً.",
  chainCall: "التحكم للناس، وليس للشركات."
},

problem: {
  category: "الحقائق",
  title: "ChatGPT: +300 مليون مستخدم",
  subtitle: "+157 مليار دولار تقييم",
  subheadline: "0% ملكية المستخدمين"
},

problem2: {
  title: "نحن نبني Ctrl AI",
  subtitle: "99% مملوكة للمستخدمين. هذا يعني أنت."
},

opportunity: {
  points: {
    control: "تبدأ بـ 300 نقطة تحكم",
    bring: "أحضر شخصاً واحداً خلال 24 ساعة",
    fail: "الفشل = خسارة 200 نقطة للأبد"
  },
  note: "هذه المرة، أنت تمتلك حقاً ما تساعد في بنائه."
},

howItWorks: {
  title: "البديل الحقيقي",
  points: [
    {
      title: "المرحلة 1 / توحيد مليون إنسان",
      bullets: [
        "لا رأس مال مخاطر، لا تحكم للشركات",
        "قوة الشعب، حلقة تلو الأخرى",
        "كل مستخدم مالك حقيقي"
      ]
    },
    {
      title: "المرحلة 2 / تمويل 100 فريق",
      bullets: [
        "إعادة بناء التطبيقات التي نستخدمها يومياً، تلك التي تستخرج بياناتنا وتبيعها لأعلى مزايد",
        "إنشاء أدوات ذكاء اصطناعي نمتلكها حقاً",
        "تحكم ديمقراطي في الميزات",
        "بياناتك تبقى ملكاً لك"
      ]
    },
    {
      title: "المرحلة 3 / النمو إلى مليار إنسان",
      bullets: [
        "بديل حقيقي للذكاء الاصطناعي المؤسسي",
        "صوت عالمي في تطوير الذكاء الاصطناعي",
        "قوة حقيقية من خلال ملكية حقيقية"
      ]
    }
  ]
},

cta: {
  category: "الخيار",
  title: "مفتوح مقابل التحكم",
  subtitle: "أظهرت لنا OpenAI ما يحدث عندما يصبح 'المفتوح' مغلقاً. تضمن Ctrl AI ألا يحدث هذا مرة أخرى من خلال منح 99% من الملكية للمستخدمين منذ اليوم الأول.",
  subheadline: "هل ستأخذ التحكم؟",
  citation: "أنا رادو. أنشأت Ctrl AI لأنني شاهدت تحول OpenAI من منظمة غير ربحية إلى عملاق بقيمة 157 مليار دولار. هذه المرة، دعونا نبني شيئاً نمتلكه حقاً معاً.",
  buttons: {
    primary: "خذ التحكم ($1)",
    secondary: "ابق عاجزاً"
  },
  note: "$1 يثبت أنك إنسان. لا روبوتات، لا مستثمرين—فقط أناس متحدون."
},


  aspiringFounders: {
    intro: {
      category: "المؤسسون والمبدعون",
      title: "حقق رؤيتك تحت مظلة Ctrl AI",
      subtitle: "حول فكرتك في الذكاء الاصطناعي أو التطبيق إلى واقع بدعم من مجتمع عالمي. بدون استثمارات جشعة، وبدون مطاردة الإيرادات الإعلانية – فقط ابتكار هادف يتماشى تمامًا مع رفاهية المستخدم.",
      citation: "“لماذا تطارد رؤوس الأموال المغامرة بينما يمكنك الوصول إلى قاعدة مستخدمين شريكة منذ اليوم الأول؟”",
      pointsTitle: "لماذا تبني مع Ctrl AI؟",
      points: [
        {
          headline: "محور المستخدم، وليس الإعلانات",
          text: "انسى الخوارزميات المضللة – دستورنا يفرض الشفافية والفائدة الحقيقية."
        },
        {
          headline: "90٪ ملكية المجتمع",
          text: "احتفظ بما يصل إلى 10٪ لفريقك المؤسس بينما تبقى الحصة الكبرى مع من يدعمون المشروع حقًا."
        },
        {
          headline: "دعاة مدمجون",
          text: "منذ اليوم الأول، سيكون لديك آلاف السفراء الذين هم شركاء في الملكية، على استعداد لدعم منتجك ونشره."
        }
      ],
      conclusion: "إذا كنت متحمسًا للابتكار الأخلاقي والمفتوح، Ctrl AI توفر لك المنصة، التمويل، وقاعدة المستخدمين التي تحتاجها – في إطار يضع رفاهية الإنسان في المقام الأول."
    },

    pitchProcess: {
      category: "الطرح والتمويل",
      title: "طريقك إلى النجاح المدعوم من المجتمع",
      subtitle: "بمجرد وصول Ctrl AI إلى 100 ألف عضو مؤسس، يمكنك طرح مشروعك على مجتمعنا العالمي. إذا تم قبوله، ستحصل على تمويل شهري، وملاحظات نشطة من المستخدمين، وشبكة شركاء جاهزة لدعم نجاحك.",
      citation: "“احصل على ما يصل إلى $10k/شهريًا لكل مؤسس بمجرد أن تحصل فكرتك على موافقة المجتمع – بدون شروط سوى الالتزام بدستور Ctrl AI.”",
      pointsTitle: "كيف يعمل:",
      points: [
        {
          headline: "اقترح فكرتك",
          text: "قدم طرحًا قصيرًا يوضح رؤيتك، فريقك، وكيف ستلتزم بنموذج 90٪/10٪ (مع تخصيص 20٪ على الأقل لصالح Ctrl AI و70٪ لداعمي المشروع المباشرين)."
        },
        {
          headline: "تصويت المجتمع",
          text: "يقوم أعضاء Ctrl AI بمراجعة مشروعك والتصويت عليه. إذا وصلت إلى الحد المطلوب من الدعم، سيتم اعتمادك للحصول على التمويل."
        },
        {
          headline: "راتب شهري",
          text: "يمكن لكل مؤسس معتمد أن يحصل على $10k/شهريًا، مأخوذة من صناديق تمويل الأعضاء – حتى تتمكن من التركيز على البناء بدلاً من جمع التبرعات."
        }
      ],
      conclusion: "لا جولات استثمارية لا نهاية لها. لا حواجز مدفوعة. فقط دعم حقيقي مدعوم من المستخدمين. عندما ينمو مشروعك، ينمو شركاؤك معك."
    },

    buildingInPublic: {
      category: "البناء بشفافية",
      title: "شارك الإنجازات، النجاحات والدروس أسبوعيًا",
      subtitle: "تُبقي التحديثات المنتظمة داعميك وشركاء الملكية على اطلاع دائم – مما يعزز الشفافية والمساءلة وشعور الحماس الجماعي.",
      citation: "“عندما يكون للمجتمع مصلحة في المشروع، تصبح نجاحاتك وتحدياتك تجارب مشتركة حقًا.”",
      pointsTitle: "المبادئ الأساسية:",
      points: [
        {
          headline: "خرائط طريق مفتوحة",
          text: "انشر جدول تطويرك الزمني، الميزات الرئيسية، والأهداف طويلة المدى حتى يعرف الجميع ما هو قادم."
        },
        {
          headline: "اختبار المجتمع والتغذية الراجعة",
          text: "قم بدمج دورات التغذية الراجعة من المستخدمين مبكرًا وبشكل متكرر. يمكن لشركاء الملكية اكتشاف المشكلات، تحسين الميزات، وتعزيز نموك."
        },
        {
          headline: "تقارير تقدم شفافة",
          text: "شارك لقطات أسبوعية أو شهرية – تسلط الضوء على الإنجازات، التحديات، والخطوات التالية – للحفاظ على تفاعل المساهمين."
        }
      ],
      conclusion: "الأمر أكثر من مجرد تحديثات برمجية؛ إنها دعوة لمجتمعك لتشكيل رحلتك والاحتفاء بها. التفاعل الحقيقي يعزز الولاء – ويؤدي إلى منتجات أفضل."
    },

    callToAction: {
      category: "ابدأ الآن",
      title: "هل أنت مستعد لإطلاق مشروعك في مجال الذكاء الاصطناعي؟",
      subtitle: "انضم إلى مجتمع ملتزم بالابتكار الأخلاقي المملوك للمستخدمين. اطرح فكرتك، احصل على التمويل، وابدأ في البناء بدعم الآلاف – بدون قيود الشركات.",
      pointsTitle: "الخطوات التالية:",
      points: [
        {
          headline: "الوصول إلى 100 ألف عضو مؤسس",
          text: "بمجرد أن نحقق الموجة الأولى من العضوية، سيفتح نظام الطرح – لذا شارك Ctrl AI للوصول إلى هذا الهدف بسرعة."
        },
        {
          headline: "جهز طرحك",
          text: "ابدأ في صياغة فكرتك، واجمع فريقك (أو اعمل بمفردك)، وحدد كيف ستلتزم بنموذج الملكية 90٪/10٪."
        },
        {
          headline: "تفاعل مبكر ومتكرر",
          text: "انضم إلى منتديات المجتمع للحصول على تعليقات مبدئية حول فكرتك. كلما زادت الروابط التي تبنيها الآن، كان طرحك أسهل."
        }
      ],
      conclusion: "**Ctrl AI هي المكان الذي تتشكل فيه تطبيقات الغد التحويلية – مدعومة بمجتمع عالمي، وتمويل شفاف، وأخلاقيات تضع المستخدم أولاً.**"
    }
  },


  foundingMembers: {
    intro: {
      category: "انضم إلى الحركة",
      title: "كن عضوًا مؤسسًا في Ctrl AI",
      subtitle: "أمنح لنفسك مكانًا في ائتلاف عالمي يشكل مستقبل الذكاء الاصطناعي – دون تسليم كل السلطة إلى عدد قليل من شركات التكنولوجيا العملاقة.",
      citation: "الأعضاء المؤسسون هم شريان الحياة في Ctrl AI. إنهم يساهمون في صياغة الدستور، ويصوتون على القرارات الرئيسية، ويضعون الأساس لثورة ذكاء اصطناعي يقودها الناس.",
      pointsTitle: "لماذا تصبح عضوًا مؤسسًا؟",
      points: [
        {
          headline: "حوكمة مبكرة حصرية",
          text: "يحصل الأعضاء المؤسسون على نقاط تحكم يومية، مما يتيح لهم توجيه المشاريع الأساسية للذكاء الاصطناعي قبل أي شخص آخر."
        },
        {
          headline: "حصص عالمية",
          text: "نخصص المقاعد بناءً على حجم الدولة، مما يضمن سماع صوت كل أمة منذ البداية."
        },
        {
          headline: "بدون إعلانات أو استثمارات خارجية، مجتمع خالص",
          text: "تُمول رسوم عضويتك منصتنا – دون مساهمين من الشركات. إنها ديمقراطية حقيقية للذكاء الاصطناعي."
        }
      ],
      conclusion: "هل أنت مستعد للمساعدة في بناء مستقبل عادل ومملوك من قبل المستخدمين؟ انضم إلى قائمة الانتظار وابدأ في كسب مكانك كعضو مؤسس اليوم."
    },

    waitlistMechanics: {
      category: "آلية قائمة الانتظار",
      title: "كيف تعمل قائمة الانتظار",
      subtitle: "نؤمن ببناء حركة من المؤمنين الحقيقيين – وليس مجرد مشاهدين سلبيين. لهذا السبب ندعو أفضل المساهمين في قائمة الانتظار ليصبحوا أعضاء مؤسسين.",
      citation: "“اكسب مكانك من خلال المشاركة، التعاون، وإظهار التزامك الحقيقي برؤية Ctrl AI.”",
      pointsTitle: "كسب النقاط:",
      points: [
        {
          headline: "أكمل ملفك الشخصي",
          text: "قدم معلومات أساسية حتى نعرف من أنت وأي منطقة تمثلها."
        },
        {
          headline: "أحِل الأصدقاء وشارك على وسائل التواصل",
          text: "نوفر لك منشورات معدة مسبقًا يمكنك تعديلها، ثم لصق رابط الإثبات في لوحة التحكم الخاصة بك. يتحقق فريقنا يدويًا ويمنحك النقاط."
        },
        {
          headline: "تفاعل مع المجتمع",
          text: "قريبًا ستتمكن من المشاركة في الأسئلة والأجوبة، وإضافة التعليقات على المقترحات، أو المساعدة في الإجابة على أسئلة الأعضاء الجدد. كل إجراء هادف يمكن أن يعزز ترتيبك."
        }
      ],
      conclusion: "بمجرد وصولك إلى حد معين من النقاط أو ترتيبك بين أفضل المساهمين، سندعوك لتفعيل وضع العضو المؤسس الخاص بك."
    },

    callToAction: {
      category: "اتخذ الإجراء",
      title: "احجز مكانك وشكّل المستقبل",
      subtitle: "نقوم بمراجعة قائمة الانتظار بانتظام ونختار أعضاء مؤسسين جدد. أظهر لنا أنك ملتزم وساعد في نشر الرسالة لتسلق الرتب بشكل أسرع.",
      pointsTitle: "ماذا يحدث بعد اختيارك:",
      points: [
        {
          headline: "رسوم العضوية: $1/شهريًا (سنوي)",
          text: "ابدأ عضويتك الرسمية. هذه الرسوم الصغيرة تبقي Ctrl AI مستقلة – دون إعلانات أو قيود رأس المال المغامر."
        },
        {
          headline: "نقاط التحكم اليومية",
          text: "اكسب قوة الحوكمة لكل يوم من العضوية. قم بتوجيه كل شيء من ميزات التطبيق إلى المبادئ الأخلاقية."
        },
        {
          headline: "التصويت والمصادقة على الدستور",
          text: "ساعد في إتمام وتطبيق دستورنا الملزم. إذا انحرفنا يومًا ما، لديك الحق (والواجب) لإعادتنا إلى المسار الصحيح."
        }
      ],
      conclusion: "**دعونا نبني منصة ذكاء اصطناعي مملوكة حقًا من قبل مستخدميها.** انضم إلى قائمة الانتظار اليوم، تفاعل لكسب النقاط، وكن من أوائل من يتحكمون في الذكاء الاصطناعي."
    }
  },

  ownership: {
    intro: {
      category: "الملكية",
      title: "قوة الملكية الجماعية",
      subtitle: "Ctrl AI مملوكة بنسبة 99٪ للمستخدمين – مما يضمن عدم قدرة أي شركة، مستثمر، أو دولة على الهيمنة على منصتنا. من خلال توزيع الملكية على ملايين (وفي النهاية مليارات) الأعضاء، نخلق نظام ذكاء اصطناعي ديمقراطيًا حقًا.",
      citation: "“الملكية في Ctrl AI ليست مجرد كلمة – إنها حقك في توجيه مستقبلنا، تشكيل سياساتنا، والمشاركة في القيمة التي نخلقها معًا.”",
      pointsTitle: "لماذا هذا مهم:",
      points: [
        {
          headline: "الضوابط والتوازنات",
          text: "عندما يتم توزيع 99٪ من الملكية بين المستخدمين، لا يمكن لأي أقلية فرض قرارات تضر بالمجتمع."
        },
        {
          headline: "مساءلة حقيقية",
          text: "يمتلك المستخدمون سلطة الفيتو من خلال التصويت ويمكنهم المطالبة بالشفافية، مما يضمن التطوير الأخلاقي والعادل."
        },
        {
          headline: "استقرار طويل الأجل",
          text: "المنصات المدفوعة من المجتمع أكثر مرونة أمام تقلبات السوق وطمع المساهمين."
        }
      ],
      conclusion: "بصفتك عضوًا، لا تقتصر وظيفتك على استخدام Ctrl AI – بل تملكها وتوجهها أيضًا. هذه هي الطريقة التي نمنع بها الاستعمار الرقمي ونضمن أن يظل الذكاء الاصطناعي بقيادة البشر."
    },

    controlPoints: {
      category: "نقاط التحكم",
      title: "ملكية مستمرة وقوة الحوكمة",
      subtitle: "كل عضو نشط يكسب نقطة تحكم واحدة يوميًا – تتراكم باستمرار طالما بقيت مشتركًا. تمنحك هذه النقاط تأثيرًا مباشرًا على القرارات الرئيسية للمنصة.",
      citation: "“انضم مبكرًا وكن نشطًا: كلما طالت مدة عضويتك، زادت نقاط التحكم التي تراكمها، وزادت قوتك في تشكيل Ctrl AI.”",
      pointsTitle: "كيف يعمل:",
      points: [
        {
          headline: "التراكم اليومي",
          text: "تزداد نقاط التحكم الخاصة بك بمقدار نقطة واحدة لكل يوم تكون فيه عضويتك نشطة. لا يوجد حد أقصى – يكتسب الأعضاء القدامى تأثيرًا أكبر بشكل طبيعي."
        },
        {
          headline: "التصويت والمقترحات",
          text: "استخدم نقاط التحكم للتصويت على مقترحات الميزات الجديدة، المبادئ الأخلاقية، تخصيص الميزانية، والمزيد."
        },
        {
          headline: "التحويل الاختياري إلى رموز",
          text: "بعد عامين، يمكنك تحويل نقاط التحكم الخاصة بك إلى رموز – ولكن هذا اختياري تمامًا. يمكنك البقاء بعيدًا عن السلسلة إذا كنت تفضل البساطة."
        }
      ],
      conclusion: "تم تصميم نقاط التحكم للحوكمة أولاً، وليس للمضاربة. إنها تعكس التزامك المستمر وتمنحك صوتًا حقيقيًا في كيفية تطور Ctrl AI."
    },

    communityModel: {
      category: "نموذج يركز على المستخدم",
      title: "99٪ مملوكة للمجتمع",
      subtitle: "نقوم بتقسيم الملكية بطريقة تمنح القوة للمستخدمين دون أن تعيق التطوير. القرارات الكبيرة – بدءًا من إطلاق التطبيقات وحتى خصوصية البيانات – تبقى دائمًا في أيدي الأغلبية.",
      citation: "“تخيل مستقبلًا تكون فيه أدوات الذكاء الاصطناعي ملكًا لك حقًا، وليس لمنصات إعلانية أو شركات استثمارية.”",
      pointsTitle: "الميزات الرئيسية لنموذجنا:",
      points: [
        {
          headline: "مشاريع المؤسسين (90/10)",
          text: "تُبنى المشاريع الفرعية تحت مظلة Ctrl AI بحد أدنى 90٪ ملكية للمجتمع و10٪ للفريق المؤسس – مما يبقي مصالح المستخدمين في الصدارة."
        },
        {
          headline: "حوكمة مباشرة",
          text: "صوت على القرارات الرئيسية، واقترح التغييرات، وشكل مستقبل Ctrl AI."
        },
        {
          headline: "تمثيل عالمي",
          text: "تضمن حصص العضوية الموزعة حسب الدول عدم قدرة أي منطقة على تجاوز أصوات الآخرين. يتم توزيع الملكية على مستوى العالم."
        }
      ],
      conclusion: "من خلال وضع حد أقصى للحصص الفردية وتوزيع الباقي على ملايين المستخدمين، نضمن أن تظل Ctrl AI منصة عالمية بلا حدود ومركزة على المستخدم."
    },
  
    callToAction: {
      category: "اتخذ الإجراء",
      title: "امتلك مستقبلك مع Ctrl AI",
      subtitle: "لا تقبل بعالم يحتكر فيه الذكاء الاصطناعي. انضم إلى Ctrl AI، اكسب نقاط التحكم يوميًا، وحافظ على تطوير الذكاء الاصطناعي ليظل نزيهًا، مركزًا على الإنسان، وشفافًا.",
      pointsTitle: "الخطوات التالية:",
      points: [
        {
          headline: "انضم إلى قائمة الانتظار",
          text: "احجز مكانك في العضوية وابدأ في كسب نقاط التحكم بمجرد دعوتك."
        },
        {
          headline: "شارك واكسب نقاط الانتظار",
          text: "ارفع ترتيبك من خلال دعوة الأصدقاء ونشر الرسالة – ساعدنا في تحقيق أهداف العضوية العالمية بسرعة."
        },
        {
          headline: "شكل مستقبل الذكاء الاصطناعي",
          text: "بمجرد الانضمام، استخدم نقاط التحكم الخاصة بك للتصويت، واقتراح التغييرات، والحفاظ على وفاء منصتنا بمهمتها."
        }
      ],
      conclusion: "**الملكية هي القوة** – وفي Ctrl AI، تلك القوة ملك لك. اشترك، كن نشطًا، وساعدنا في إعادة كتابة قواعد ملكية الذكاء الاصطناعي للجميع."
    }
  },  

    constitutionPage: {
      hero: {
        title: "دستور Ctrl AI",
        preamble: "في عصر يتزايد فيه تأثير التكنولوجيا على مستقبل البشرية، اخترنا مسارًا مختلفًا. **ليس مجرد منتجات أفضل، بل مبادئ أفضل. ليس مجرد ابتكار، بل تقدم أخلاقي. ليس مجرد تفاعل المستخدم، بل ازدهار الإنسان.**"
      },
      principles: {
        title: "مبادئنا الملزمة",
        intro: "ليست هذه إرشادات أو اقتراحات – إنها التزامات ملزمة يجب على كل مشروع تحت مظلة Ctrl AI اتباعها، ويتم تطبيقها من خلال إشراف المجتمع والمراجعات المنتظمة:",
        principles: [
          {
            title: "الرفاهية البشرية أولاً",
            items: [
              "التصميم من أجل ازدهار الإنسان الحقيقي، وليس لمقاييس التفاعل",
              "عدم التسامح مع الأنماط المظلمة أو التلاعب النفسي",
              "التعامل مع انتباه المستخدم كموارد ثمينة يجب احترامها",
              "بناء نقاط توقف طبيعية وأنماط استخدام صحية",
              "إعطاء الأولوية للاتصال الهادف بدلاً من التفاعل الإدماني"
            ]
          },
          {
            title: "حقوق البيانات المطلقة",
            items: [
              "المستخدمون يمتلكون بياناتهم بالكامل – بلا استثناءات",
              "عدم بيع البيانات أو مشاركتها بدون إذن",
              "المعالجة المحلية أولًا كلما أمكن ذلك",
              "تشفير بدرجة عسكرية كمعيار",
              "الحق المضمون في النسيان – بشكل كامل ودائم"
            ]
          },
          {
            title: "قوة المجتمع الحقيقية",
            items: [
              "90٪ حد أدنى لملكية المجتمع – مضمون دستوريًا",
              "حوكمة ديمقراطية مباشرة على القرارات الكبرى",
              "تصويتات ملزمة من المجتمع بشأن اتجاه المنتج",
              "توزيع رمزي عادل رياضيًا",
              "حقوق متساوية بغض النظر عن حجم المساهمة"
            ]
          }
        ]
      },
      enforcement: {
        title: "تطبيق حقيقي، مساءلة حقيقية",
        content: "**هذه المبادئ ليست وعودًا – إنها التزامات ملزمة بعواقب حقيقية:**",
        measures: [
          {
            title: "إشراف المجتمع",
            items: [
              "• مراجعات علنية ربع سنوية",
              "• تقارير فورية عن الانتهاكات",
              "• أنظمة تغذية راجعة مباشرة من المجتمع",
              "• لجنة مراجعة أخلاقية مستقلة"
            ]
          },
          {
            title: "عواقب واضحة",
            items: [
              "• استجابة فورية للانتهاكات",
              "• تصحيحات يقودها المجتمع",
              "• معالجة إلزامية",
              "• تقارير علنية عن المساءلة"
            ]
          },
          {
            title: "وثيقة حية",
            items: [
              "• مراجعة سنوية من المجتمع",
              "• تحديثات متكيفة مع التكنولوجيا",
              "• مبادئ أساسية غير قابلة للتغيير",
              "• عملية تعديل ديمقراطية"
            ]
          }
        ]
      },
      cta: {
        title: "انضم إلى ثورة التكنولوجيا الأخلاقية",
        content: "**هذا ليس مجرد بيان مهمة لشركة تقنية أخرى. هذا دستور ملزم لبناء التكنولوجيا التي تخدم الإنسانية.** سواء كنت مؤسسًا مستعدًا للبناء بطريقة مختلفة أو مستخدمًا مستعدًا لامتلاك ما تستخدمه، يبدأ مستقبل التكنولوجيا الأخلاقية هنا.",
        button: "انضم كعضو مؤسس ($1/شهريًا)"
      }
    },

    footer: {
      companyDescription: "نبني مستقبل الذكاء الاصطناعي مع ملكية المجتمع والمبادئ الأخلاقية.",
      navigation: "التنقل",
      legal: "القانوني",
      connect: "اتصل بنا",
      privacy: "سياسة الخصوصية",
      terms: "شروط الخدمة",
      cookies: "سياسة الكوكيز",
      rights: "جميع الحقوق محفوظة."
    },

  
    nav: {
      community: "الأعضاء المؤسسون",
      founders: "المؤسسون",
      ownership: "الملكية",
      constitution: "الدستور",
      pricing: "التسعير",
      dashboard: "لوحة التحكم",
      myProfile: "ملفي الشخصي",
      settings: "الإعدادات",
      home: "الرئيسية",
      ctrlaiCom: "CtrlAI.com",
      ctrlaiAi: "CtrlAI.ai",
      apply: "التقديم كمؤسس",
      about: "حول Ctrl AI",
      logout: "تسجيل الخروج"
    },
  
    auth: {
      login: {
        title: "مرحبًا بعودتك",
        subtitle: "تابع رحلتك في بناء المستقبل",
        emailPlaceholder: "أدخل بريدك الإلكتروني",
        passwordPlaceholder: "أدخل كلمة المرور",
        submitButton: "تسجيل الدخول",
        noAccount: "ليس لديك حساب؟",
        signupLink: "سجل كمؤسس",
        error: "البريد الإلكتروني أو كلمة المرور غير صحيحة"
      },
      signup: {
        title: "انضم كمؤسس",
        subtitle: "ابدأ في بناء مستقبل التكنولوجيا",
        namePlaceholder: "أدخل اسمك الكامل",
        emailPlaceholder: "أدخل بريدك الإلكتروني",
        passwordPlaceholder: "اختر كلمة مرور",
        submitButton: "إنشاء حساب",
        hasAccount: "هل لديك حساب بالفعل؟",
        loginLink: "تسجيل الدخول",
        error: "حدث خطأ أثناء إنشاء الحساب"
      },
      errors: {
        passwordLength: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
        invalidEmail: "يرجى إدخال بريد إلكتروني صالح",
        emailInUse: "هذا البريد الإلكتروني مستخدم بالفعل"
      },
      passwordRequirements: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل"
    },
  
    pricing: {
      title: "انضم إلى الحركة",
      subtitle: "انضم إلى الحركة لبناء وامتلاك مستقبل التكنولوجيا",
      tiers: {
        founding: {
          title: "عضو مؤسس",
          price: "$1/شهريًا",
          description: "لأولئك الذين يرغبون في قيادة التغيير",
          features: [
            "1 نقطة تحكم يوميًا",
            "أولوية الوصول إلى المشاريع الجديدة",
            "شكّل مستقبل التكنولوجيا",
            "شارة العضو المؤسس الحصرية",
            "3 رموز دعوة للأصدقاء"
          ]
        }
      },
      footer: {
        quote: "في غضون عشرين عامًا، سيتساءل الناس كيف فاتتهم هذه الفرصة. لا تكن الشخص الذي يشرح لماذا انتظر."
      }
    },
  
    modals: {
      inviteCode: {
        title: "استرد رمز الدعوة الخاص بك",
        description: "أدخل رمز الدعوة الخاص بك لتأمين مكانك كعضو مؤسس. يمكن استخدام كل رمز مرة واحدة فقط.",
        placeholder: "أدخل رمز الدعوة",
        submit: "انضم كعضو مؤسس",
        invalidCode: "رمز الدعوة غير صالح أو منتهي الصلاحية. يرجى المحاولة مرة أخرى.",
        success: "مرحبًا بك في Ctrl AI! جارٍ إكمال تسجيلك...",
        validating: "جارٍ التحقق...",
        alreadyUsed: "تم استخدام هذا الرمز مسبقًا",
        expired: "هذا الرمز منتهي الصلاحية",
        validated: "تم التحقق من الرمز! جارٍ إعادة التوجيه إلى التسجيل..."
      },
      waitlist: {
        title: "انضم إلى قائمة الانتظار",
        description: "انضم إلى قائمة الانتظار الحصرية لدينا للحصول على وصول مبكر إلى Ctrl AI.",
        namePlaceholder: "اسمك الكامل",
        emailPlaceholder: "عنوان بريدك الإلكتروني",
        passwordPlaceholder: "إنشاء كلمة مرور",
        submit: "انضم إلى قائمة الانتظار",
        submitting: "جارٍ الانضمام...",
        error: "حدث خطأ ما. يرجى المحاولة مرة أخرى.",
        success: "مرحبًا بك! لقد انضممت إلى القائمة."
      }
    },
  
    waitlist: {
      status: {
        title: "حالة قائمة الانتظار الخاصة بك",
        totalInWaitlist: "من بين {{total}} شخص في الطابور",
        referralCode: "رمز الإحالة الخاص بك",
        copyLink: "نسخ الرابط",
        referralDescription: "شارك رمز الإحالة الخاص بك مع الأصدقاء للارتقاء في قائمة الانتظار. كل إحالة تمنحك 50 نقطة!",
        points: "تقدم النقاط"
      },
      actions: {
        twitter: "شارك على تويتر",
        linkedin: "شارك على لينكد إن",
        discord: "انضم إلى ديسكورد",
        profile: "أكمل ملفك الشخصي"
      }
    },
  
    onboarding: {
      title: "مرحبًا بك في Ctrl AI",
      subtitle: "دعنا نساعدك على بدء رحلتك بنجاح",
      points: "نقاط",
      continue: "متابعة",
      finish: "إكمال الإعداد",
      steps: {
        profile: {
          title: "أكمل ملفك الشخصي",
          description: "أضف صورة شخصية وسيرة ذاتية لمساعدة الآخرين على التعرف عليك."
        },
        social: {
          title: "شارك رحلتك",
          description: "شارك حماسك حول الانضمام إلى Ctrl AI مع شبكتك."
        },
        community: {
          title: "انضم إلى المجتمع",
          description: "تواصل مع الأعضاء الآخرين في مجتمعنا عبر ديسكورد."
        }
      },
  
    dashboard: {
      welcome: "مرحبًا بعودتك، {{name}}",
      membershipTiers: {
        founding: "عضو مؤسس",
        collective: "عضو جماعي"
      },
      tabs: {
        projects: "المشاريع",
        community: "المجتمع",
        rewards: "المكافآت"
      },
      community: {
        title: "نشاط المجتمع",
        discussions: {
          title: "المناقشات الأخيرة",
          empty: "لا توجد مناقشات حتى الآن",
          start: "ابدأ مناقشة"
        },
        activity: {
          title: "النشاط الأخير",
          empty: "لا يوجد نشاط حديث"
        },
        stats: {
          members: "الأعضاء النشطون",
          discussions: "المناقشات",
          contributions: "المساهمات"
        }
      },
      projects: {
        title: "المشاريع النشطة",
        empty: "لا توجد مشاريع نشطة",
        explore: "استكشاف المشاريع",
        create: "إنشاء مشروع"
      },
      rewards: {
        title: "مكافآتك",
        points: "نقاط التحكم",
        achievements: "الإنجازات",
        empty: "لا توجد مكافآت حتى الآن"
      },
      quickActions: {
        title: "إجراءات سريعة",
        createProject: "إنشاء مشروع",
        startDiscussion: "ابدأ مناقشة",
        updateProfile: "تحديث الملف الشخصي"
      }
    },
    global: {
      title: "شاهد قائمة الانتظار لدينا تنمو عالميًا",
      subtitle: "نمو المجتمع في الوقت الفعلي",
      content: "كل نقطة تمثل شخصًا يتنافس للحصول على مكان كعضو مؤسس. ليس لديك رمز دعوة بعد؟ انضم إلى قائمة الانتظار وحسّن ترتيبك من خلال إكمال التحديات.",
      stats: {
        totalMembers: "إجمالي الأعضاء",
        activeToday: "النشطون اليوم",
        averagePoints: "متوسط النقاط"
      },
      controls: {
        pause: "إيقاف مؤقت والتفاعل",
        resume: "استئناف الجولة",
        next: "القارة التالية"
      }
    },
  
    quotaMap: {
      stats: {
        totalQuota: "إجمالي الحصة العالمية",
        totalRegistered: "إجمالي المسجلين",
        totalPopulation: "إجمالي السكان",
        countryCount: "عدد الدول الممثلة",
        fulfillment: "الوفاء العالمي"
      },
      tooltip: {
        country: "الدولة",
        population: "عدد السكان",
        quota: "حصة الأعضاء",
        registered: "الأعضاء المسجلون",
        fulfillment: "الوفاء بالحصة"
      },
      legend: {
        title: "الوفاء بالحصة",
        low: "منخفض",
        target: "الهدف",
        exceeded: "تم تجاوزه"
      },
      controls: {
        reset: "إعادة تعيين العرض",
        zoom: "تكبير",
        pan: "تحريك"
      },
      noData: "لا تتوفر بيانات لهذه الدولة",
      loading: "جارٍ تحميل بيانات الخريطة..."
    }
  },
  signupForm: {
    title: {
      waitlist: "الانضمام لقائمة الانتظار",
      verified: "إنشاء حساب"
    },
    subtitle: {
      waitlist: "انضم إلى قائمة الانتظار الحصرية للحصول على وصول مبكر",
      verified: "تمت دعوتك لتكون عضواً مؤسساً"
    },
    fields: {
      fullName: "الاسم الكامل",
      email: "البريد الإلكتروني",
      password: "كلمة المرور"
    },
    buttons: {
      submit: "متابعة",
      submitting: "جارٍ إنشاء الحساب...",
      google: "المتابعة باستخدام جوجل"
    },
    errors: {
      emailExists: "هذا البريد الإلكتروني مسجل بالفعل في قائمة الانتظار. يرجى التحقق من بريدك الإلكتروني للحصول على تعليمات التحقق.",
      emailInUse: "هذا البريد الإلكتروني مسجل بالفعل. يرجى تسجيل الدخول.",
      waitlistCheck: "تعذر التحقق من حالة قائمة الانتظار. يرجى المحاولة مرة أخرى.",
      createAccount: "فشل إنشاء الحساب. يرجى المحاولة مرة أخرى."
    },
    inviteCode: {
      prompt: "هل لديك رمز دعوة؟",
      button: "إدخال رمز الدعوة"
    }
  },

  waitlistDashboard: {
    title: "حالة قائمة الانتظار",
    position: "المركز {{position}}",
    total: "من أصل {{total}} شخص",
    stats: {
      referrals: "الإحالات",
      points: "النقاط",
      status: "الحالة",
      active: "نشط",
      position: "المركز",
      completion: "الإكتمال",
      earnMore: "اكسب المزيد من النقاط",
      completeProfile: "أكمل ملفك الشخصي لتحسين مركزك",
      total: "من إجمالي {{total}} شخص"
    },
    share: {
      title: "شارك واكسب النقاط",
      description: "شارك رابط الإحالة مع أصدقائك للتقدم في قائمة الانتظار. كل إحالة تمنحك نقاطاً!",
      copy: "نسخ الرابط",
      copied: "تم النسخ!",
      buttons: {
        twitter: "المشاركة على تويتر",
        linkedin: "المشاركة على لينكد إن"
      }
    },
    earnPoints: {
      title: "طرق كسب النقاط",
      items: [
        "أكمل ملفك الشخصي",
        "شارك على وسائل التواصل الاجتماعي",
        "ادعُ الأصدقاء",
        "انضم إلى مجتمع ديسكورد"
      ]
    },
    breadcrumbs: {
      waitlist: "قائمة الانتظار",
      dashboard: "لوحة التحكم"
    },
    welcome: {
      title: "مرحباً",
      location: "{{city}}، {{country}}"
    },
    overview: {
      journey: "رحلتك نحو العضوية المؤسسة",
      profileCompletion: "اكتمال الملف الشخصي",
      socialSharing: "المشاركة الاجتماعية",
      tasks: {
        title: "المهام المتاحة",
        completeProfile: "أكمل ملفك الشخصي",
        completeProfileDesc: "أضف معلوماتك الأساسية لمساعدتنا في التعرف عليك بشكل أفضل",
        addLocation: "أضف موقعك",
        addLocationDesc: "أخبرنا عن موقعك"
      }
    },
    tabs: {
      overview: "نظرة عامة",
      tasks: "اكسب النقاط",
      social: "شارك واكسب"
    },
    socialShare: {
      title: "شارك واكسب النقاط",
      description: "شارك عن Ctrl AI على منصات التواصل الاجتماعي لكسب النقاط وتحسين مركزك",
      verificationPending: "تم إرسال مشاركتك على {{platform}} للتحقق"
    },
    progress: {
      items: {
        photo: "الصورة الشخصية",
        basicInfo: "المعلومات الأساسية",
        location: "الموقع",
        skills: "المهارات والاهتمامات"
      }
    }
  },
  progressSection: {
    complete: "مكتمل",
    points: "+{{points}} نقطة",
    viewDetails: "عرض التفاصيل",
    completeTasks: "إكمال المهام",
    percentComplete: "{{percent}}٪ مكتمل"
  },
  social: {
    templates: {
      twitter: [
        {
          text: "انضممت للتو إلى حركة #CtrlAI! 🚀 نبني مستقبلاً يملكه المستخدمون حيث لا يمكن لعمالقة التكنولوجيا احتكار الذكاء الاصطناعي.\n\nالمقاعد محدودة حسب البلد—احجز مقعدك الآن: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ملكية_المستخدمين", "#ثورة_الذكاء_الاصطناعي"]
        },
        {
          text: "إذا أردنا صوتاً حقيقياً في الذكاء الاصطناعي، علينا النمو عضوياً! أساعد @CtrlAI للوصول إلى 100 ألف عضو مؤسس.\n\nانضم الآن قبل نفاد حصة بلدك: {{REFERRAL_LINK}}",
          hashtags: ["#استعد_السيطرة", "#الحرية_الرقمية", "#مستقبل_التكنولوجيا"]
        }
      ],
      linkedin: [
        {
          text: "متحمس لمشاركة انضمامي لقائمة انتظار Ctrl AI — منصة يقودها الناس لتحدي احتكار عمالقة التكنولوجيا للذكاء الاصطناعي.\n\nلماذا هذا مهم:\n• 99% ملكية للمستخدمين (لا مزيد من تحكم مجالس الإدارة)\n• نقاط تحكم يومية = قوة حوكمة حقيقية\n• حصص قُطرية لضمان التمثيل العالمي\n\nفقط 100,000 مقعد للأعضاء المؤسسين. انضموا إلينا لإعادة تشكيل مستقبل الذكاء الاصطناعي من خلال الكلمة المنطوقة (بدون إعلانات أو رأس مال استثماري)!\n\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ثورة_تقنية", "#الحرية_الرقمية", "#الذكاء_الاصطناعي"]
        },
        {
          text: "حجزت مكاني في قائمة انتظار Ctrl AI للمشاركة في ملكية تكنولوجيا الغد! تخيل ذكاءً اصطناعياً مسؤولاً أمام الناس، وليس المساهمين.\n\n• 99% مملوك للمستخدمين\n• ذكاء اصطناعي شفاف وأخلاقي\n• لا ميزانيات إعلانية ضخمة—فقط نحن، ننشر الرسالة\n\nأسرع، كل دولة لديها عدد محدود من مقاعد الأعضاء المؤسسين. اعرف المزيد: {{REFERRAL_LINK}}",
          hashtags: ["#ثورة_الذكاء_الاصطناعي", "#ملكية_المستخدمين", "#تقنية_أخلاقية", "#مستقبل_التكنولوجيا"]
        }
      ],
      instagram: [
        {
          text: "استعادة السيطرة على الذكاء الاصطناعي تبدأ الآن! ⚡\n\nانضممت لقائمة انتظار Ctrl AI لبناء تطبيقات يملكها المستخدمون—بدون سيطرة عمالقة التكنولوجيا.\n\nننمو فقط من خلال الكلمة المنطوقة، تحقق من توفر حصة الأعضاء المؤسسين في بلدك!\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ثورة_تقنية", "#ملكية_المستخدمين", "#الحرية_الرقمية", "#الذكاء_الاصطناعي"]
        },
        {
          text: "السباق على 100,000 عضو مؤسس بدأ!\n\nCtrl AI = 99% ملكية للمستخدمين، صوت حقيقي في تطور التكنولوجيا. بدون إعلانات، بدون رأس مال استثماري، بدون جمع بيانات.\n\nالمقاعد في بلدك محدودة—انضم الآن: {{REFERRAL_LINK}}",
          hashtags: ["#ثورة_الذكاء_الاصطناعي", "#استعد_السيطرة", "#لا_للاحتكار_التقني", "#مستقبل_التكنولوجيا"]
        }
      ],
      facebook: [
        {
          text: "أنضم لقائمة انتظار Ctrl AI للمساعدة في خلق عالم يكون فيه الذكاء الاصطناعي مملوكاً حقاً للمستخدمين، وليس محتكراً من قبل شركات قليلة عملاقة.\n\nمع 99% من الملكية في أيدي الناس الحقيقيين، يمكننا ضمان ذكاء اصطناعي أخلاقي وشفاف. نهدف للنمو من خلال الكلمة المنطوقة فقط—بدون إنفاق على الإعلانات أو رأس مال استثماري.\n\nفقط 100,000 مقعد للأعضاء المؤسسين، موزعة حسب البلد للعدالة. مستعد لتغيير التكنولوجيا؟ انضم هنا: {{REFERRAL_LINK}}",
          hashtags: ["#استعد_السيطرة", "#الحرية_الرقمية", "#ملكية_المستخدمين", "#الذكاء_الاصطناعي"]
        },
        {
          text: "هل تمنيت يوماً أن تمتلك حقاً التطبيقات التي تستخدمها؟ Ctrl AI يجعل هذا حقيقة! أنا على قائمة الانتظار، وأريدك أن تنضم معي.\n\nلا يمكننا الاعتماد على الإعلانات—يجب أن تكون حركة يقودها الناس. بلدك لديه حصة محدودة، احجز مكانك الآن: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#مستقبل_التكنولوجيا", "#ثورة_الذكاء_الاصطناعي"]
        }
      ],
      youtube: [
        {
          text: "انضم إلي لتغيير طريقة بناء الذكاء الاصطناعي! Ctrl AI منصة يقودها الناس، 99% مملوكة لمستخدميها. بدون إعلانات، بدون سيطرة الشركات، ديمقراطية حقيقية في التكنولوجيا.\n\nانضم عبر رابطي: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#الذكاء_الاصطناعي", "#ابتكار", "#ثورة_تقنية"]
        },
        {
          text: "الذكاء الاصطناعي يجب ألا يكون حكراً على حفنة من العمالقة. أنا جزء من Ctrl AI، ويمكنك أن تكون كذلك! نعتمد على الكلمة المنطوقة للنمو، انشر الرسالة—فقط 100 ألف مقعد عالمياً.\n\nانضم هنا: {{REFERRAL_LINK}}",
          hashtags: ["#الحرية_الرقمية", "#ملكية_المستخدمين", "#الذكاء_الاصطناعي", "#مستقبل_التكنولوجيا"]
        }
      ],
      tiktok: [
        {
          text: "POV: اكتشفت للتو حركة يمكنك فيها المشاركة في ملكية مستقبل الذكاء الاصطناعي 🤖\n\nCtrl AI مملوكة 99% للمستخدمين، بدون عمالقة تكنولوجيا أو مستثمرين مشبوهين. نعتمد علينا، نحن الناس، للنمو. انضم عبر رابطي: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#تيك_تقني", "#ثورة_الذكاء_الاصطناعي", "#ملكية_المستخدمين"]
        },
        {
          text: "تريد التحرر من قبضة عمالقة التكنولوجيا؟ انضم إلى Ctrl AI! 🚀 لدينا فقط 100 ألف مقعد للأعضاء المؤسسين عالمياً. إذا كانت حصة بلدك ما زالت متاحة، احجز مكانك وساعد في نشر الرسالة: {{REFERRAL_LINK}}",
          hashtags: ["#ثورة_تقنية", "#الذكاء_الاصطناعي", "#الحرية_الرقمية", "#استعد_السيطرة"]
        }
      ],
      github: [
        {
          text: "انضممت للتو لقائمة انتظار Ctrl AI للمشاركة في ملكية وتطوير تطبيقات ذكاء اصطناعي أخلاقية! بدون إعلانات، بدون رأس مال استثماري، مجتمع مفتوح المصدر بالكامل.\n\nمقاعد الأعضاء المؤسسين في بلدك قد تكون محدودة—انضم هنا: {{REFERRAL_LINK}}",
          hashtags: ["#الذكاء_الاصطناعي", "#مفتوح_المصدر", "#ملكية_المستخدمين", "#تقنية_أخلاقية"]
        },
        {
          text: "أصدقاء المطورين: هل سئمتم من احتكارات التكنولوجيا؟ Ctrl AI يبني عصراً جديداً من الذكاء الاصطناعي المملوك للمستخدمين. ننمو فقط من خلال المجتمع، فإذا كنت متحمساً للتعاون المفتوح، احصل على عضويتك التأسيسية الآن: {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#ابتكار", "#مفتوح_المصدر", "#مستقبل_التكنولوجيا"]
        }
      ]
    }
  }
};
