import ReactCountryFlag from "react-country-flag";

interface CountryFlagProps {
  topologyId: string;
  className?: string;
}

export function CountryFlag({ topologyId, className = '' }: CountryFlagProps) {
  return (
    <span className={className}>
      <ReactCountryFlag
        countryCode={topologyId}
        svg
        style={{
          width: '1em',
          height: '1em',
        }}
      />
    </span>
  );
} 