import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';
import { ThemeToggle } from './ThemeToggle';

export const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const handleNavClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-white dark:bg-gray-950 border-t  -mt-8  border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-14 pb-8">
        {/* Main footer content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <Link to="/">
              <div 
                className="logo bg-contain bg-no-repeat bg-center h-8 w-24"
                aria-label="Ctrl AI Logo" 
              />
            </Link>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {t('footer.companyDescription')}
            </p>
            <div className="flex space-x-4 items-center">
              <LanguageSwitcher />
              <ThemeToggle />
            </div>
          </div>

          {/* Navigation */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{t('footer.navigation')}</h3>
            <ul className="space-y-2">
              <li>
                
              </li>
              <li>
                <Link 
                  to="/ownership" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  {t('nav.ownership')}
                </Link>
              </li>
              <li>
                <Link 
                  to="/founders" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  {t('nav.founders')}
                </Link>
              </li>
              <li>
                <Link 
                  to="/constitution" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  {t('nav.constitution')}
                </Link>
              </li>
              
            </ul>
          </div>

          {/* Legal */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {t('footer.legal')}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/privacy" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/terms" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link 
                  to="/cookies" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={handleNavClick}
                >
                  Cookie Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Connect */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{t('footer.connect')}</h3>
            <ul className="space-y-2">
            
              <li>
                <a 
                  href="https://twitter.com/CtrlAIcom" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a 
                  href="https://www.linkedin.com/in/rado-sukala/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom bar */}
        <div className="mt-12 pt-6 border-t border-gray-200 dark:border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              © {currentYear} Ctrl AI. {t('footer.rights')}
            </p>
            <div className="mt-4 md:mt-0">
              <a 
                href="https://ctrlai.com" 
                className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
              >
                CtrlAI.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}; 