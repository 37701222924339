export const en = {

  hero: {
    title: "OpenAI Isn't Open Anymore.",
    subtitle: "Here's the Only Movement That Puts Humanity First.",
    chainCall: "Control by People, Not Corporations."
},

problem: {
    category: "The Facts",
    title: "ChatGPT: 300M+ Users",
    subtitle: "$157B+ Valuation",
    subheadline: "0% User Ownership"
},

problem2: {
    title: "We're Building Ctrl AI",
    subtitle: "99% Owned by Users. That Means You."
},

opportunity: {
    points: {
        control: "You Start With 300 Control Points",
        bring: "Connect ONE Person in 24 Hours",
        fail: "Fail = Lose 200 Points Forever"
    },
    note: "This Time, You Actually Own What You Help Build."
},

howItWorks: {
    title: "The Real Alternative",
    points: [
        {
            title: "Phase 1 / Unite 1M Humans",
            bullets: [
                "No venture capital, no corporate control",
                "Pure people power, link by link",
                "Every user is an actual owner"
            ]
        },
        {
            title: "Phase 2 / Fund 100 Teams",
            bullets: [
              "Rebuild Apps we used daily. Apps that extract our data and sell it to the highest bidder",    
              "Build AI tools we actually own",
                "Democratic control of features",
                "Your data stays yours"
            ]
        },
        {
            title: "Phase 3 / Grow to 1B Humans",
            bullets: [
                "True alternative to corporate AI",
                "Global voice in AI development",
                "Real power through real ownership"
            ]
        }
    ]
},

cta: {
    category: "The Choice",
    title: "Open vs Control",
    subtitle: 
        "OpenAI showed us what happens when 'open' becomes closed. Ctrl AI ensures it won't happen again by giving 99% ownership to users from day one.",
    subheadline: "Will you take control?",
    citation: "I'm Rado. I built Ctrl AI because I watched OpenAI's transformation from non-profit to $157B giant. This time, let's build something we actually own together.",
    buttons: {
        primary: "Take Control ($1)",
        secondary: "Stay Powerless"
    },
    note: "$1 proves you're human. No bots, no VCs—just people united."
},


aspiringFounders: {
  intro: {
    category: "Zakladatelé & Tvůrci",
    title: "Budujte svou vizi pod Ctrl AI",
    subtitle:
      "Proměňte svůj nápad na AI nebo aplikaci v realitu s podporou globální komunity. Žádní draví investoři, žádné honění reklamních příjmů—jen smysluplná inovace v souladu s uživatelským blahem.",
    citation: "Proč honit rizikový kapitál, když můžete od prvního dne čerpat z vestavěné uživatelské základny spoluvlastníků?",
    pointsTitle: "Proč budovat s Ctrl AI?",
    points: [
      {
        headline: "Uživatelé na prvním místě, ne reklamy",
        text: "Zapomeňte na manipulativní algoritmy—naše ústava vyžaduje transparentnost, soukromí a skutečný užitek."
      },
      {
        headline: "95% vlastněno komunitou",
        text: "Ponechte si až 5 % pro svůj zakládající tým, zatímco drtivá většina zůstane těm, kteří váš projekt skutečně pohánějí."
      },
      {
        headline: "Vestavění ambasadoři",
        text: "Od prvního dne máte tisíce spoluvlastníků, kteří jsou připraveni sdílet a podporovat váš produkt."
      }
    ],
    conclusion:
      "Pokud chcete tvořit eticky a otevřeně, Ctrl AI vám nabízí platformu, financování a uživatelskou základnu—all pod rámcem, který klade důraz na lidské blaho."
  },

  pitchProcess: {
    category: "Pitch & Financování",
    title: "Vaše cesta k úspěchu podpořenému komunitou",
    subtitle:
      "Jakmile zajistíme našich prvních 1M zakládajících členů, můžete svůj projekt představit komunitě Ctrl AI. Pokud bude schválen, získáte měsíční financování, okamžitou zpětnou vazbu a vestavěné publikum, které chce vidět váš úspěch.",
    citation:
      "Zajistěte si až **$10k/měsíc na zakladatele**, jakmile váš nápad projde schválením komunity—bez podmínek kromě dodržování ústavy Ctrl AI.",
    pointsTitle: "Jak to funguje:",
    points: [
      {
        headline: "Navrhněte svůj nápad",
        text: "Odešlete krátký pitch s vaší vizí, týmem (pokud nějaký máte) a tím, jak budete dodržovat poměr 95%/5%—95 % pro komunitu, 5 % pro zakládající skupinu."
      },
      {
        headline: "Hlasování komunity",
        text: "Všichni členové Ctrl AI váš návrh posoudí a hlasují. Pokud dosáhnete požadovaného prahu podpory, máte zelenou k financování."
      },
      {
        headline: "Měsíční plat",
        text: "Schválení zakladatelé mohou každý získat až **$10k/měsíc** z komunitního fondu—takže se můžete soustředit na budování, ne na nekonečné shánění financí."
      }
    ],
    conclusion:
      "Žádné nekonečné pitchování investorům. Žádné paywally. Jen skutečná podpora poháněná uživateli. Jak váš projekt roste, roste i vaše komunita—a tato synergie pohání udržitelný růst."
  },

  buildingInPublic: {
    category: "Budování na veřejnosti",
    title: "Sdílejte milníky, úspěchy & ponaučení každý týden",
    subtitle:
      "Pravidelné, transparentní aktualizace podporují odpovědnost a kolektivní nadšení. Vaši spoluvlastníci investovali do vašeho úspěchu a chtějí vidět, jak se vám daří.",
    citation:
      "Když má komunita skutečný podíl na věci, vaše triumfy—a výzvy—se stávají hluboce sdílenými zkušenostmi.",
    pointsTitle: "Základní principy:",
    points: [
      {
        headline: "Otevřené roadmapy",
        text: "Zveřejněte svůj vývojový plán, hlavní funkce a dlouhodobé cíle, aby každý věděl, co přijde dál."
      },
      {
        headline: "Testování a zpětná vazba od komunity",
        text: "Zapojte uživatele brzy a často. Spoluvlastníci mohou odhalit problémy, vylepšit funkce a stát se vašimi největšími ambasadory."
      },
      {
        headline: "Transparentní zprávy o pokroku",
        text: "Poskytujte týdenní nebo měsíční přehledy—zdůrazňující úspěchy, překážky a nadcházející milníky—aby zůstali zainteresovaní."
      }
    ],
    conclusion:
      "Nejde jen o vydávání softwaru; je to pozvání pro celou komunitu, aby se podílela na každém kroku. Autentická angažovanost buduje loajalitu—a vede k lepším produktům."
  },

  callToAction: {
    category: "Začněte",
    title: "Připraveni spustit svůj AI projekt?",
    subtitle:
      "Připojte se ke komunitě zavázané k etické, uživatelsky vlastněné inovaci. Představte svůj nápad, zajistěte financování a budujte s podporou tisíců—bez firemních podmínek.",
    pointsTitle: "Další kroky:",
    points: [
      {
        headline: "Pomozte nám dosáhnout 1M zakládajících členů",
        text: "Pitchování projektů otevřeme po dosažení prvního 1M uživatelů. Čím rychleji tohoto milníku dosáhneme, tím dříve můžete spustit."
      },
      {
        headline: "Připravte svůj pitch",
        text: "Návrh konceptu, definování, jak zajistíte **95% komunitní vlastnictví**, a sestavení zakládajícího týmu."
      },
      {
        headline: "Zapojte se včas a často",
        text: "Začněte se spojovat s členy na našich fórech. Čím více důvěry vybudujete nyní, tím plynulejší bude váš pitch proces."
      }
    ],
    conclusion:
      "**Ctrl AI je místem, kde se rodí transformační aplikace zítřka—poháněné globální, transparentní, uživatelsky orientovanou vizí.**"
  }
},


    foundingMembers: {
      intro: {
        category: "Join the Movement",
        title: "Become a Founding Member of Ctrl AI",
        subtitle:
          "Secure your spot as one of the earliest pioneers shaping the future of AI—before Big Tech cements its monopoly.",
        citation:
          "Founding Members are the lifeblood of Ctrl AI. They ratify our Constitution, vote on major decisions, and set the tone for a people-powered AI revolution.",
        pointsTitle: "Why Become a Founding Member?",
        points: [
          {
            headline: "Exclusive Early Influence",
            text: "Be among the first 1M links in our Chain of Ownership—earning the highest Control Points and a permanent stake in ethical AI."
          },
          {
            headline: "Global Representation",
            text: "Country-based quotas ensure no single region dominates."
          },
          {
            headline: "No Ads, No VC—Just Community",
            text: "We operate on a $1 one-time verification—free from investor influence and commercial ads."
          }
        ],
        conclusion:
          "Ready to help build a fair, user-owned AI future? Claim your chain link, join the founders, and guide Ctrl AI from Day One."
      },
  
      callToAction: {
        category: "Take Action",
        title: "Claim Your Spot & Shape the Future",
        subtitle:
          "Joining is instant—no waitlist or hidden steps. Verify your humanity with $1, claim your chain link, and help lead the AI revolution.",
        pointsTitle: "Here's What Happens:",
        points: [
          {
            headline: "One-Time $1 Verification",
            text: "Prove you're a real person, protect our community from bots, and secure your unique chain link number."
          },
          {
            headline: "High-Level Governance Power",
            text: "Receive early-bird Control Points if you're among the first waves, giving you extra influence on key AI decisions."
          },
          {
            headline: "Constitution & Voting",
            text: "Help finalize our binding Constitution. If Ctrl AI ever drifts from its mission, you have the power to course-correct."
          }
        ],
        conclusion:
          "**Let's build an AI platform truly owned by its users.** Join now, secure your Founding Member status, and shape Ctrl AI's future from the inside."
      }
    },
  
    ownership: {
      intro: {
        category: "Ownership",
        title: "The Power of Collective Ownership",
        subtitle:
          "Ctrl AI is 99% user-owned—ensuring that no single corporation or investor can dominate our platform. By distributing ownership across millions, we create a genuinely democratic AI ecosystem.",
        citation:
          "Ownership in Ctrl AI isn't just a buzzword—it's your genuine right to steer our future, shape our policies, and share in the value we create together.",
        pointsTitle: "Why This Matters:",
        points: [
          {
            headline: "Checks & Balances",
            text: "When 99% of ownership is spread among users, no tiny elite can force decisions that harm the community."
          },
          {
            headline: "Real Accountability",
            text: "Users hold veto power through votes and can demand transparency, ensuring ethical and fair development."
          },
          {
            headline: "Long-Term Stability",
            text: "Community-driven platforms are more resilient to market whims and profit-hungry shareholders."
          }
        ],
        conclusion:
          "As a member, you don't just use Ctrl AI—you co-own and guide it. This prevents AI Colonialism and keeps power in the hands of everyday people."
      },
  
      controlPoints: {
        category: "Control Points",
        title: "Ownership & Governance at Your Fingertips",
        subtitle:
          "When you join Ctrl AI by claiming your chain link, you receive a block of Control Points based on your link wave. These points give you direct influence over major platform decisions.",
        citation:
          "Join early and secure higher Control Points—guaranteeing a bigger say in how Ctrl AI evolves.",
        pointsTitle: "Key Details:",
        points: [
          {
            headline: "Block Allocation by Waves",
            text: "First 1M links each get 100 Control Points. Later joiners still have a voice—just fewer points initially."
          },
          {
            headline: "Voting & Proposals",
            text: "Use Control Points to vote on new features, budget allocations, and ethical standards. You have real power in shaping the platform."
          },
          {
            headline: "Designed for Governance, Not Speculation",
            text: "No daily accrual or crypto hype cycles—just a straightforward wave-based allotment that underscores collaboration over profit."
          }
        ],
        conclusion:
          "Control Points are your key to real AI democracy—letting you participate in decisions that matter without turning Ctrl AI into a speculative playground."
      },
  
      communityModel: {
        category: "User-Centric Model",
        title: "99% Community-Owned",
        subtitle:
          "We distribute ownership so that everyday people, not corporations, hold the reins of AI's future. No single investor or ad-driven giant can hijack our roadmap.",
        citation:
          "Imagine a world where AI truly belongs to everyone, not monopolistic tech titans or venture capitalists.",
        pointsTitle: "Core Features of Our Model:",
        points: [
          {
            headline: "Founder Projects (95/5)",
            text: "In Phase 2, we select 100 teams to build sub-projects under Ctrl AI, but must allocate at least 95% ownership to the community—keeping user interests first."
          },
          {
            headline: "Community Votes on Everything",
            text: "From data privacy to new product ideas, no decision is locked in without member approval."
          },
          {
            headline: "Global Representation",
            text: "Country-based quotas ensure diverse voices. By capping individual shares, we keep Ctrl AI free from concentrated power."
          }
        ],
        conclusion:
          "With millions of co-owners around the globe, Ctrl AI remains accountable to people rather than quarterly profits or ad revenue."
      },
  
      callToAction: {
        category: "Take Action",
        title: "Own Your Future with Ctrl AI",
        subtitle:
          "Don't settle for a world where AI is monopolized by a few. Join Ctrl AI, claim your chain link, and help forge a transparent future for everyone.",
        pointsTitle: "Next Steps:",
        points: [
          {
            headline: "Claim Your Link",
            text: "Verify with $1, get your permanent chain number, and secure your ownership stake immediately—no waitlist."
          },
          {
            headline: "Invite Others",
            text: "A chain is stronger with each new link. Spread the word so we can collectively shape an ethical AI ecosystem."
          },
          {
            headline: "Shape Tomorrow's AI",
            text: "Once inside, use your Control Points to vote, propose changes, and ensure Ctrl AI remains truly people-powered."
          }
        ],
        conclusion:
          "**Ownership is power.** At Ctrl AI, that power belongs to you. Join now, stay involved, and help us rewrite the rules of AI for everyone."
      }
    },


      constitutionPage: {
        hero: {
          title: "The Ctrl AI Constitution",
          preamble:
            "In an age where technology increasingly shapes humanity's future, we choose a different path. **Not just better products, but better principles. Not just innovation, but ethical progress. Not just user engagement, but human flourishing.**"
        },
        principles: {
          title: "Our Binding Principles",
          intro:
            "These aren't guidelines or suggestions—they're binding commitments that every project under Ctrl AI must follow, enforced by community oversight and regular audits:",
          principles: [
            {
              title: "Human Well-being First",
              items: [
                "Design for genuine human flourishing, not engagement metrics",
                "Zero tolerance for dark patterns or psychological manipulation",
                "Treat user attention as a precious resource to be respected",
                "Build natural stopping points and healthy usage patterns",
                "Prioritize meaningful connection over addictive engagement"
              ]
            },
            {
              title: "Absolute Data Rights",
              items: [
                "Users own their data completely—no exceptions",
                "Zero data selling or unauthorized sharing",
                "Local-first processing wherever possible",
                "Military-grade encryption as standard",
                "Guaranteed right to be forgotten—completely and permanently"
              ]
            },
            {
              title: "Real Community Power",
              items: [
                "95% minimum community ownership—constitutionally guaranteed",
                "Direct democratic governance on major decisions",
                "Binding community votes on product direction",
                "Fair, transparent distribution of Control Points",
                "Equal rights regardless of contribution size"
              ]
            },
            {
              title: "Ethical AI Boundaries",
              items: [
                "AI serves human agency—never diminishes it",
                "Complete transparency in AI decision processes",
                "Continuous bias monitoring and mitigation",
                "Clear human override on all AI features",
                "AI development focused on human empowerment"
              ]
            },
            {
              title: "Team Well-being",
              items: [
                "Four-day work week as a constitutional right",
                "Comprehensive mental health support",
                "Funded continuous learning for all",
                "Zero crunch-time exploitation",
                "Equitable profit sharing",
                "Remote-first with optional collaboration",
                "Strict work-life boundaries",
                "Regular team connection events"
              ]
            },
            {
              title: "Education First",
              items: [
                "Built-in educational value in all products",
                "Age-appropriate design and safety",
                "Active digital literacy promotion",
                "Multi-stakeholder educational approach",
                "Zero exploitation of young users",
                "Ban on youth-targeted manipulation"
              ]
            },
            {
              title: "Radical Transparency",
              items: [
                "Weekly public development updates",
                "Complete financial transparency",
                "Direct communication about challenges",
                "Open-source by default",
                "Regular community engagement sessions"
              ]
            },
            {
              title: "Sustainable Development",
              items: [
                "Natural growth over artificial virality",
                "Community-driven expansion only",
                "Ban on exploitative marketing",
                "Environmental impact consideration",
                "Long-term sustainability over short-term gains"
              ]
            },
            {
              title: "Fair Revenue",
              items: [
                "Zero advertising-based revenue",
                "Transparent value-based pricing where applicable",
                "Clear cost-benefit communication",
                "Direct community profit sharing",
                "Cost-aligned or pay-what-you-can models"
              ]
            },
            {
              title: "Innovation With Purpose",
              items: [
                "Focus on real human needs",
                "Community-serving innovation only",
                "Open collaboration principles",
                "Technology in service of humanity",
                "Social impact consideration in all decisions"
              ]
            }
          ]
        },
        enforcement: {
          title: "Real Enforcement, Real Accountability",
          content:
            "**These principles aren't mere promises—they're binding commitments with actual consequences:**",
          measures: [
            {
              title: "Community Oversight",
              items: [
                "• Quarterly public audits",
                "• Real-time violation reporting",
                "• Direct community feedback systems",
                "• Independent ethical review board"
              ]
            },
            {
              title: "Clear Consequences",
              items: [
                "• Immediate violation response",
                "• Community-driven corrections",
                "• Mandatory remediation",
                "• Public accountability reporting"
              ]
            },
            {
              title: "Living Document",
              items: [
                "• Annual community review",
                "• Technology-adaptive updates",
                "• Immutable core principles",
                "• Democratic amendment process"
              ]
            }
          ]
        },
        cta: {
          title: "Join the Ethical Technology Revolution",
          content:
            "**This isn't just another tech company's mission statement. This is a binding constitution for building technology that serves humanity.** Whether you're a founder ready to create under these principles or a user ready to own what you use, the future of ethical technology starts here.",
          button: "Claim Your Chain Link ($1)"
        }
      },


      footer: {
        companyDescription: "Building the future of AI with community ownership and ethical principles.",
        navigation: "Navigation",
        legal: {
          title: "Legal",
          privacy: "Privacy Policy",
          terms: "Terms of Service",
          cookies: "Cookie Policy"
        },
        connect: "Other",
        privacy: "Privacy Policy",
        terms: "Terms of Service",
        cookies: "Cookie Policy",
        rights: "All rights reserved."
      },


      nav: {
        // Main Navigation
        chain: "Chain of Trust",
        community: "Community",
        founders: "Founders",
        ownership: "Ownership",
        constitution: "Constitution",

        // User Menu
        dashboard: "Dashboard",
        signOut: "Sign Out",
        login: "Login",
        getVerified: "Get Verified",

        // Dialog
        joinDialog: {
          title: "Join Ctrl AI"
        }
      },


      auth: {
        login: {
          title: "Welcome Back",
          continueWithGoogle: "Continue with Google",
          orDivider: "Or continue with email",
          email: "Email",
          password: "Password",
          signIn: "Sign In",
          processing: "Processing...",
          noAccount: "Don't have an account?",
          signUp: "Sign up",
          errors: {
            loginFailed: "Login failed. Please check your credentials."
          }
        },
        signup: {
          title: "Join the Chain",
          titleAdditional: "Get Another Chain Link",
          
          fullName: "Full Name",
          email: "Email",
          
          continue: "Continue to Payment",
          processing: "Processing...",
          
          existingLinks: "You already have {{count}} chain link",
          existingLinksPlural: "You already have {{count}} chain links",
          earliestLink: "Your earliest link is #{{number}}",
          
          errors: {
            required: "All fields are required",
            invalidEmail: "Invalid email format",
            checkoutFailed: "Failed to create checkout session",
            generic: "Failed to initiate checkout. Please try again."
          }
        },
        errors: {
          passwordLength: "Password must be at least 6 characters long",
          invalidEmail: "Please enter a valid email address",
          emailInUse: "This email is already in use"
        },
        passwordRequirements: "Password must be at least 6 characters long",
        
        joinForm: {
          // Buttons
          continueWithGoogle: "Continue with Google",
          signUpWithEmail: "Sign up with Email",
          processing: "Processing...",
          
          // Form Labels
          email: "Email",
          fullName: "Full Name",
          password: "Password",
          referralCode: "Referral Code",
          
          // Placeholders
          emailPlaceholder: "Enter your email",
          fullNamePlaceholder: "Enter your full name",
          passwordPlaceholder: "Create a password",
          referralCodePlaceholder: "Enter referral code (optional)",
          
          // Dividers and Links
          orDivider: "Or sign up with email",
          alreadyHaveAccount: "Already have an account?",
          signIn: "Sign in",
          termsAgreement: "I agree to the",
          termsLink: "Terms of Service",
          
          // Errors
          errors: {
            allFieldsRequired: "All fields are required",
            acceptTerms: "Please accept the Terms of Service",
            passwordLength: "Password must be at least 6 characters",
            paymentFailed: "Failed to initiate payment. Please try again."
          },
          referralCodeProvided: "Referred by"
        }
      },


      quotaMap: {
        stats: {
          totalQuota: "Total Global Quota",
          totalRegistered: "Total Registered",
          totalPopulation: "Total Population",
          countryCount: "Countries Represented",
          fulfillment: "Global Fulfillment"
        },
        tooltip: {
          country: "Country",
          population: "Population",
          quota: "Member Quota",
          registered: "Registered Members",
          fulfillment: "Quota Fulfillment"
        },
        legend: {
          title: "Quota Fulfillment",
          low: "Low",
          target: "Target",
          exceeded: "Exceeded"
        },
        controls: {
          reset: "Reset View",
          zoom: "Zoom",
          pan: "Pan"
        },
        noData: "No data available for this country",
        loading: "Loading map data..."
      },

      dashboard: {
        title: "Joined Now",
        subtitle: "Join the movement to control AI",
        timezone: "All times are shown in your local timezone",

        verification: {
          title: "Verify Your Account",
          subtitle: "Join the Chain of Trust by verifying your account with a small contribution of $1. This helps maintain the integrity of our community.",
        },

        chainStatus: {
          title: "Chain Status",
          currentLink: "Current Chain Link",
          latestLink: "Latest Chain Link",
          totalVerified: "Total Verified",
          recentJoins: "Recent Joins",
          yourStatus: "Your Status",
          verified: "Verified",
          notVerified: "Not Verified",
          controlPoints: "Control Points",
          pendingPoints: "Pending Points",
        },

        recentJoins: {
          title: "Recent Joins",
          empty: "No recent joins yet",
          timeAgo: "{{time}} ago",
          from: "from",
          showMore: "Show More",
          showLess: "Show Less",
          newJoin: "New Join! 🎉",
          latest: "Latest",
        },

        yourPosition: {
          title: "Your Position",
          notJoined: "Not Joined",
          yourLink: "Your Chain Link",
          moreLinks: "+{{count}} more links"
        },

        currentWave: {
          title: "Current Wave",
          ready: "Ready for new wave",
          streak: "{{count}} consecutive joins in wave {{wave}}"
        },
        timer: {
          title: "Next Link Deadline",
          complete: "Wave Complete",
          hurry: "Hurry! Time is running out",
          bonus: "+{{amount}} Control Points",
          bonusCondition: "if next link joins in time",
          newWave: "New Wave Available",
          newWaveBonus: "Start a new wave and earn 100 Control Points!"
        },
        bonusSystem: {
          title: "Bonus System",
          intro: "Earn bonus points through:",
          streakBonus: "Streak Bonuses (10, 50, 100 links)",
          earlyParticipation: "Early Participation",
          consistentActivity: "Consistent Activity"
        },

        // Join Dialog
        joinDialog: {
          title: "Join Ctrl AI",
          processing: "Processing...",
          joinButton: "Join Ctrl AI (+{{points}} CP)",
          startWave: "Start Wave {{number}}",
          firstJoinBonus: "Be the first to join and earn 100 Control Points!",
          activeWave: "Join active wave (+{{basePoints}} CP{{bonusPoints}})",
          bonusFormat: " +{{points}} bonus",
          newWaveBonus: "Be first in the new wave (+100 CP)"
        },

        // Wave Progress
        waveProgress: {
          title: "Wave Progress",
          complete: "Complete",
          progress: "{{current}}/{{total}}",
          bonusLevels: {
            bonus10: "Bonus 10",
            bonus50: "Bonus 50",
            bonus100: "Bonus 100",
            totalPoints: "Total: {{points}} CP",
            tooltips: {
              bonus10: "+20 Control Points bonus",
              bonus50: "+30 Control Points bonus (total 50 CP)",
              bonus100: "+50 Control Points bonus (total 100 CP)"
            }
          }
        },

        // Buttons and Actions
        actions: {
          claimNumber: "Claim Your Chain Number",
          startNewWave: "Start a new wave and earn 100 CP!",
          joinActiveWave: "Join the active wave"
        },

        // Status Messages
        status: {
          notJoined: "Not Joined",
          moreLinks: "+{{count}} more links",
          yourChainLink: "Your Chain Link",
          newWaveAvailable: "New Wave Available",
          joinMovement: "Join the movement to control AI development"
        },

        // Tooltips and Info
        tooltips: {
          bonusSystem: {
            title: "Wave Bonus System:",
            bonus10: "At 10 joins: +20 Control Points for all members",
            bonus50: "At 50 joins: Additional +30 Control Points (total 50 CP)",
            bonus100: "At 100 joins: Additional +50 Control Points (total 100 CP)",
            note: "All bonuses are awarded retroactively to every member in the wave!"
          }
        },

        showMore: "Show More",
        showLess: "Show Less"
      },

      profile: {
        // Page Sections
        title: "Profile",
        chainLinks: "Your Chain Links",
        controlPoints: "Control Points",
        
        // Actions
        editProfile: "Edit Profile",
        save: "Save",
        saving: "Saving...",
        cancel: "Cancel",
        setPrimary: "Set as Primary",
        
        // Labels
        fullName: "Full Name",
        primary: "Primary",
        wave: "Wave {{number}}",
        
        // Notifications
        updateSuccess: {
          title: "Profile Updated",
          description: "Your changes have been saved successfully."
        },
        updateError: {
          title: "Update Failed",
          description: "There was an error updating your profile."
        }
      },

      points: {
        // Page Header
        title: "Points History",
        
        // Status Messages
        loading: "Loading history...",
        noHistory: "No points history yet",
        
        // Points Display
        amount: "{{sign}}{{amount}} CP",
        wave: "Wave {{number}}",
        timeAgo: "{{time}} ago"
      },
 
 

  // Add new sections for our chain/dashboard features
  common: {
    processing: "Processing...",
    error: "Error",
    success: "Success",
    change: "Change",
    loading: "Loading...",
    loadMore: "Load More"
  },

  chain: {
    description: "Join the movement to Control AI",
    recentJoins: {
      title: "Recent Joins",
      homeTitle: "Join the Growing Chain",
      homeSubtitle: "See who's recently joined our community and become part of the movement. Reserve your low chain link number now! Demonstrate that you were here first!",
      viewAll: "View All Chain Members",
      empty: "No recent joins yet",
      timeAgo: "{{time}} ago",
      from: "from",
      showMore: "Show More",
      showLess: "Show Less",
      newJoin: "New Join! 🎉",
      latest: "Latest",
      you: "You",
      referred: "Referred #{{number}}",
      timeLeft: "Time left",
      referralDeadline: "Referral deadline",
      hasReferred: "Has referred",
      notReferred: "Not referred yet"
    },
    status: {
      title: "Your Chain Status",
      yourLink: "Your Chain Link",
      controlPoints: "Control Points",
      referralStatus: "Referral Status",
      referred: "Referred",
      expired: "Expired",
      referredLinks: "Referred Links",
      timeLeft: "Time Left",
      referralBonus: "+200 CP on successful referral",
      referralDeadline: "Referral Deadline",
      pendingPoints: "Pending Points",
      referralInstructions: "Share your link number to refer others",
      shareLink: "Share Link",
    },
    join: {
      button: "Join Chain ($1)",
    },
  },

  error: {
    verification: {
      failed: "Verification failed. Please try again.",
      alreadyVerified: "Account already verified.",
      paymentFailed: "Failed to initiate payment. Please try again."
    },
  },

  social: {
    share: {
      title: "Share",
      twitter: "Share on X",
      linkedin: "Share on LinkedIn",
      copyText: "Copy Text",
      copied: "Copied!",
      messages: {
        twitter: "🚀 I just joined Ctrl AI - a movement to put AI back in human hands! Join using my link {{url}} and let's build something we actually own together.",
        linkedin: "I've joined Ctrl AI - a movement to democratize AI development. We're building an alternative to corporate AI, owned by users. Join using my link {{url}} and be part of this change.",
        general: "Join Ctrl AI using my link {{url}} and help build AI that's owned by users, not corporations."
      }
    }
  },

}


