import { useEffect } from 'react';
import { useUserStore } from '@/stores/useUserStore';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';

// Define public paths that don't require authentication
const PUBLIC_PATHS = [
  '/',                    // Homepage
  '/about',               // About page
  '/contact',             // Contact page
  '/login',               // Auth pages
  '/signup',
  '/reset-password',
  '/onboarding',          // Onboarding flows
  '/community',           // Content pages
  '/founders',
  '/pricing',
  '/ownership',
  '/constitution',
  '/privacy',            // Legal pages
  '/terms',
  '/cookies',
  '/verification',       // Verification flows
  '/admin/login',        // Admin login
  '/chain'               // Chain page
];

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { user, initialized, initialize } = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!initialized) return;

    const currentPath = location.pathname;
    const isPublicPath = PUBLIC_PATHS.some(path => 
      currentPath === path || currentPath.startsWith(path + '/')
    );

    // Only redirect to login for protected routes when there's no user
    if (!user && !isPublicPath) {
      navigate('/login', { replace: true, state: { from: currentPath } });
    }

  }, [user, initialized, location.pathname, navigate]);

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return children;
} 