export const cs = {
  hero: {
    title: "Nemůžeme plně kontrolovat AI—ale musíme se o to pokusit",
    subtitle: 
      "Připojte se ke Ctrl AI, neziskové organizaci, kde každý člověk má rovný hlas při utváření budoucnosti AI. Ověřte že jste člověk za 1$ a staňte se spoluvlastníkem globálního úsilí o etické a transparentní směřování AI.",
    chainCall: "Každý hlas se počítá. Přidejte ten váš k hnutí."
  },

  problem: {
    category: "Výzva",
    title: "Síla AI je soustředěna v rukou několika málo lidí—co když nezvolí to nejlepší pro lidstvo?",
    subtitle: "Musíme zajistit, aby AI byla v souladu s celým lidstvem—nejen s těmi, kteří mají moc a peníze.",
    citation:
      "Tomu říkáme 'AI kolonialismus.' A jediný způsob, jak ho zastavit, je spojením miliard obyčejných lidí ve skutečném vlastnictví AI.",
    pointsTitle: "Proč musíte jednat nyní:",
    points: [
      {
        headline: "Monopoly velkých technologických firem",
        text: "Hrstka korporací drží data, zdroje a průlomy v AI."
      },
      {
        headline: "Omezená odpovědnost",
        text: "Korporátní ziskové motivy mohou zastínit veřejné blaho."
      },
      {
        headline: "Společenský převrat",
        text: "Jak AI překonává lidi ve více povoláních, společnost musí být připravena."
      }
    ],
    conclusion: "Buď budeme spoluvlastnit AI zítřka—nebo ji přenecháme mega-korporacím, které profitují z našich dat."
  },

  opportunity: {
    title: "Naše řešení—Lidské hnutí",
    subtitle: "Každý ověřený člen je rovnocenným partnerem—nic víc, nic míň.",
    benefitsTitle: "Proč je to důležité",
    benefits: [
      {
        headline: "Neziskové & 100% vlastněné uživateli",
        text: "Váš 1$ není poplatek—je to důkaz, že jste člověk. Členové rozhodují, jak budou tyto prostředky využity."
      },
      {
        headline: "Otevřená správa",
        text: "Každý člověk má přesně jeden hlas—žádná extra moc pro dřívější členy."
      },
      {
        headline: "Skutečný dopad",
        text: "Společně vytváříme silnou koalici, kterou musí velké technologické firmy a vlády respektovat."
      }
    ]
  },

  howItWorks: {
    title: "Jak to funguje",
    points: [
      {
        title: "1. Ověřte, že jste člověk (1$)",
        bullets: [
          "Jednoduchá kontrola pro blokování botů a zajištění spravedlnosti.",
          "Rozhodněte, zda váš 1$ půjde do komunitní pokladny nebo na pokrytí provozních nákladů."
        ]
      },
      {
        title: "2. Staňte se spoluvlastníkem",
        bullets: [
          "Jeden člověk, jeden hlas při klíčových rozhodnutích—nezáleží na tom, kdy se připojíte."
        ]
      },
      {
        title: "3. Šiřte informace dál",
        bullets: [
          "Naše hnutí roste pouze díky skutečným lidem, kteří o něm řeknou ostatním—žádné reklamy, žádní investoři."
        ]
      }
    ]
  },

  commitment: {
    title: "Náš závazek",
    subtitle: "Budujeme základy důvěry a transparentnosti",
    points: [
      {
        headline: "Úplná transparentnost",
        text: "Veřejně sdílíme naše finance, rozhodnutí a pokrok."
      },
      {
        headline: "Neziskové navždy",
        text: "Žádní korporátní akcionáři—každý má svůj podíl."
      },
      {
        headline: "Etický vývoj AI",
        text: "Vytváříme a podporujeme AI, která upřednostňuje lidské blaho a společenské dobro."
      }
    ]
  },

  shareMessage: {
    title: "Pomozte nám růst—Žádné reklamy, žádní investoři, jen lidé",
    subtitle: 
      "Ctrl AI prosperuje díky skutečným hlasům lidí sdílejícím naše poselství. Představte si to jako globální předávání dobra—naše společná budoucnost závisí na každém, kdo se připojí. Po připojení prosím pozvěte přítele.",
    cta: "Sdílet Ctrl AI"
  },

  cta: {
    title: "Připraveni utvářet budoucnost AI?",
    subtitle: 
      "Ověřte že jste člověk za 1$ a postavte se po boku ostatních, kteří požadují etickou a inkluzivní AI. Společně můžeme zajistit budoucnost, kde AI pracuje pro všechny.",
    buttons: {
      primary: "Připojit se (1$)",
      secondary: "Seznam členů"
    }
  },

  aspiringFoundersPage: {
    hero: {
      category: "Tvůrci & Inovátoři",
      title: "Budujte s Ctrl AI—Utvářejte budoucnost technologií",
      subtitle:
        "Máte vizi pro etickou, uživatelsky zaměřenou AI aplikaci nebo open-source model? Představte ji komunitě Ctrl AI a společně ji přiveďte k životu—bez reklam, investorů nebo skrytých záměrů. Vše podléhá kolektivnímu schválení našich členů.",
    },
  
    intro: {
      title: "Naše filozofie: Lidé před ziskem",
      subtitle:
        "Jak open-source AI modely rychle dohánějí korporátní řešení, otevírá se jedinečná příležitost pro tvůrce vytvářet lepší, více lidsky orientovaná řešení. V Ctrl AI věříme, že inovace prosperují, když:\n• Jsou kolektivně financovány lidmi, kteří je budou skutečně používat.\n• Zakladatelé nejsou nuceni honit zisk na úkor uživatelů.\n• Technologie zůstává otevřená, transparentní a řízená komunitou.",
      pointsTitle: "Proč budovat pod Ctrl AI?",
      points: [
        {
          headline: "Komunitní financování",
          text: "Žádný predátorský rizikový kapitál nebo omezení reklamou. Naše nezisková, uživatelsky vlastněná komunita může přímo financovat váš projekt, pokud v něj věří."
        },
        {
          headline: "Open Source momentum",
          text: "Využijte open-source AI modely, které drží krok s korporátními giganty. Vylepšujte je společně s našimi globálními členy."
        },
        {
          headline: "Nová společenská smlouva",
          text: "S tisíci (časem miliony) spoluvlastníků máte zaručené publikum, které má zájem na úspěchu vašeho projektu—a na zachování jeho etičnosti."
        }
      ],
      disclaimer:
        "Všechny níže uvedené detaily jsou počáteční koncepty a budou nakonec formovány kolektivním hlasem členů Ctrl AI."
    },
  
    ownershipModels: {
      title: "Flexibilní vlastnické struktury",
      subtitle:
        "Uznáváme, že různé projekty mají různé potřeby. Níže jsou naše počáteční nápady, jak mohou zakladatelské týmy a komunita sdílet vlastnictví a odpovědnosti.",
      models: [
        {
          headline: "Menší projekty: Podíl zakladatele až 5%",
          text: "Zakladatelé si ponechají malý podíl, zatímco zbytek vlastní komunita. Komunita může také hlasovat o poskytnutí měsíčního platu zakladatelům, což jim umožní soustředit se na budování na plný úvazek."
        },
        {
          headline: "Větší ambiciózní projekty: Plně vlastněné komunitou",
          text: "Pokud komunita dokáže získat dostatek prostředků na pokrytí vývoje, infrastruktury a personálu, může se rozhodnout pro 100% kolektivní vlastnictví—nabízející spravedlivou odměnu přispěvatelům bez dlouhodobého podílu zakladatelů."
        }
      ],
      note:
        "Přesná struktura vlastnictví každého projektu—a způsob rozdělení prostředků—bude určena otevřenou diskusí a demokratickým hlasováním, jakmile dosáhneme kritického množství členů."
    },
  
    pitchProcess: {
      category: "Prezentace & Schválení",
      title: "Jak můžete spustit projekt",
      subtitle:
        "Jakmile bude naše komunita dostatečně velká na podporu více iniciativ, pozveme zakladatele k prezentaci jejich nápadů. Komunita kolektivně rozhoduje, které projekty financovat, budovat a upřednostňovat.",
      stepsTitle: "Počáteční kroky (Podléhají ratifikaci komunitou)",
      steps: [
        {
          headline: "1. Představte svoji vizi",
          text: "Připravte stručný návrh popisující váš nápad, jeho potenciální dopad a případné členy týmu."
        },
        {
          headline: "2. Otevřená diskuse",
          text: "Všichni členové Ctrl AI se zapojí—kladou otázky, navrhují vylepšení a zjišťují zájem komunity."
        },
        {
          headline: "3. Hlasování komunity",
          text: "Pokud váš projekt získá dostatečnou podporu, obdržíte financování a zdroje pro pokračování. Zakladatelé mohou během vývoje pobírat měsíční plat, schválený členy."
        },
        {
          headline: "4. Průběžná transparentnost",
          text: "Během vývoje jsou vyžadovány pravidelné aktualizace postupu, open-source kód kde je to možné a závazek k blahu uživatelů."
        }
      ],
      conclusion:
        "Žádný univerzální přístup, žádná byrokracie—jen platforma poháněná lidmi, kde mohou prosperovat nejlepší a nejeetičtější nápady."
    }
  },

  newSocialContractPage: {
    hero: {
      category: "Nová společenská smlouva pro věk AI",
      title: "Připojte se ke Ctrl AI: Neziskové, lidmi řízené hnutí",
      subtitle:
        "Ověřením že jste člověk za 1$ se stanete spoluvlastníkem našeho globálního úsilí o udržení AI etické, transparentní a inkluzivní. Žádní akcionáři, žádní investoři hladoví po zisku—jen jeden člověk, jeden hlas.",
    },
  
    intro: {
      title: "Proč neziskový, uživatelsky vlastněný model?",
      subtitle:
        "Věříme, že tváří v tvář rychle se vyvíjejícím průlomům v AI musí o vývoji technologií rozhodovat lidé—ne velké korporace. Ctrl AI buduje novou společenskou smlouvu: takovou, která staví lidské blaho a komunitní správu do centra dění.",
      pointsTitle: "Klíčové principy",
      points: [
        {
          headline: "Jeden člověk, jeden hlas",
          text: "Bez ohledu na to, kdy se připojíte nebo kolik máte peněz, váš hlas má stejnou váhu—stejně jako hlas zakladatele."
        },
        {
          headline: "Žádné reklamy, žádní investoři",
          text: "Spoléháme na jednorázové ověření za 1$, abychom udrželi boty mimo a zajistili skutečné lidské vlastnictví, bez korporátních agend."
        },
        {
          headline: "Globální inkluze",
          text: "Kdokoli, odkudkoli se může připojit. Naším cílem je spojit hlasy ze všech koutů světa."
        }
      ],
      conclusion:
        "Když běžní lidé spoluvlastní AI, zajišťujeme, že je řízena sdílenými hodnotami—férovostí, transparentností a posílením—ne jen ziskem."
    },
  
    newOwnershipModel: {
      title: "Přepisujeme pravidla vlastnictví",
      subtitle:
        "Tradiční technologické společnosti se zodpovídají investorům a příjmům z reklamy. Ctrl AI se zodpovídá svým uživatelům—protože uživatelé jsou vlastníci.",
      pointsTitle: "Čím se lišíme",
      points: [
        {
          headline: "100% vedeno komunitou",
          text: "Jsme nezisková organizace bez externích akcionářů. Všichni členové společně utvářejí náš plán a mají konečné slovo."
        },
        {
          headline: "Transparentní financování & výdaje",
          text: "Váš příspěvek 1$ je plně vyúčtován. Naše finance jsou veřejně viditelné a o případných přebytcích rozhodují členové hlasováním."
        },
        {
          headline: "Sdílená odpovědnost",
          text: "Protože platformu spoluvlastníme, sdílíme také odpovědnost za to, aby byla etická, užitečná a v souladu s potřebami lidstva."
        }
      ],
      note:
        "Toto je víc než obchodní model—je to nová společenská smlouva, kde se technologie zodpovídá všem, kdo ji používají."
    },
  
    whyJoinNow: {
      title: "Proč se stát zakládajícím členem?",
      subtitle:
        "Jsme na zlomovém bodě: AI se vyvíjí tak rychle, že by ji mohla ovládnout hrstka korporací—a rozhodnout, jak všichni budeme žít a pracovat. Připojením se nyní se stanete jedním z prvních spolutvůrců jiné budoucnosti.",
      points: [
        {
          headline: "Utvářejte naši ústavu",
          text: "Jako součást zakládající komunity pomůžete finalizovat naše hlavní principy a udržovat vedení odpovědným."
        },
        {
          headline: "Přímý vliv",
          text: "Navrhujte nebo hlasujte o kritických rozhodnutích, od rozdělení rozpočtu po to, které AI nástroje budeme stavět jako první."
        },
        {
          headline: "Nastavte globální příklad",
          text: "Pomozte dokázat, že technologie lze vyvíjet eticky a transparentně, když jsou na prvním místě lidé, ne zisky."
        }
      ],
      conclusion:
        "Připojit se nyní znamená stát v čele revoluce AI řízené lidmi—takové, která upřednostňuje kolektivní blaho před korporátními zájmy."
    },
  
    howItWorks: {
      title: "Připojení je jednoduché",
      steps: [
        {
          title: "1. Ověřte že jste člověk",
          text: "Přispějte jednorázovým poplatkem 1$ pro potvrzení, že jste skutečná osoba. Žádné další poplatky—žádné předplatné."
        },
        {
          title: "2. Staňte se spoluvlastníkem",
          text: "Vaše členství vám dává rovný hlas při každém důležitém rozhodnutí, od naší ústavy po financování projektů."
        },
        {
          title: "3. Šiřte informace",
          text: "Rosteme díky lidským spojením, ne reklamám nebo penězům investorů. Pozvěte ostatní, aby náš kolektivní hlas zesílil."
        }
      ]
    },
  
    finalCall: {
      title: "Připraveni pomoci přepsat budoucnost AI?",
      subtitle:
        "Přidejte svůj hlas, své nápady a své nadšení. Společně můžeme zajistit, že AI zůstane nástrojem pro celé lidstvo—ne jen pro elitu.",
      button: "Připojit se ke Ctrl AI"
    }
  },

  buildingInPublic: {
    category: "Otevřená spolupráce",
    title: "Budujte veřejně, růstěte společně",
    subtitle:
      "Transparentnost je základem DNA Ctrl AI. Sdílením vašeho plánu, výzev a milníků se spoluvlastníky zvete ke skutečné spolupráci—a získáváte trvalou podporu.",
    pointsTitle: "Naše principy veřejného budování",
    points: [
      {
        headline: "Otevřené plány",
        text: "Informujte komunitu o klíčových funkcích, časových plánech a dlouhodobých cílech."
      },
      {
        headline: "Kolektivní testování & zpětná vazba",
        text: "Využijte globální uživatelskou základnu pro beta testování, hledání chyb a vylepšování."
      },
      {
        headline: "Časté zprávy o pokroku",
        text: "Sdílejte úspěchy, překážky a nadcházející úkoly týdně nebo měsíčně pro udržení důvěry a momentum."
      }
    ],
    conclusion:
      "Tento otevřený přístup nejen pomáhá vytvářet lepší řešení—udržuje také všechny odpovědné vůči lidským hodnotám, za kterými stojíme."
  },

  callToAction: {
    category: "Zapojte se",
    title: "Připraveni oživit svůj nápad?",
    subtitle:
      "Jsme v počátečních fázích budování globálního hnutí. Jakmile dosáhneme dostatečného počtu členů, spustíme proces prezentací. Připojte se ke Ctrl AI nyní, začněte se spojovat s ostatními spoluvlastníky a vylepšujte svoji vizi, abyste byli připraveni, až přijde čas.",
    stepsTitle: "Další kroky (Pro teď)",
    steps: [
      {
        headline: "1. Připojte se ke Ctrl AI",
        text: "Staňte se ověřeným členem za 1$. Váš hlas a názor budou utvářet, jak vybíráme a financujeme nové projekty."
      },
      {
        headline: "2. Zapojte se do komunity",
        text: "Poznejte potenciální spolupracovníky a budoucí uživatele. Diskutujte o svém konceptu neformálně pro získání včasné podpory."
      },
      {
        headline: "3. Připravte svoji prezentaci",
        text: "Načrtněte svůj nápad, včetně toho, jak se shoduje s hodnotami Ctrl AI a jaké jsou případné rozpočtové potřeby. Sledujte oznámení o možnosti podávání prezentací."
      }
    ],
    conclusion:
      "**Tyto rámce nejsou vytesány do kamene.** Společně vylepšíme způsob, jak podporujeme vizionářské zakladatele a zároveň udržujeme technologii skutečně zaměřenou na uživatele."
  },

  members: {
    title: "Připojte se k našemu rostoucímu hnutí",
    subtitle: "Teprve začínáme a právě to je vzrušující. Připojte se dnes a pomozte utvářet naši komunitu od základů a dejte zaznít svému hlasu v budoucnosti AI.",
    stats: {
      membersLabel: "Ověřených lidí",
      countriesLabel: "Zastoupených zemí"
    },
    share: {
      title: "Pomozte rozšířit náš dopad",
      subtitle: "Stačí jedno sdílení k přivedení dalšího klíčového hlasu.",
      twitterText: "Právě jsem objevil/a @CtrlAI, globální hnutí skutečných lidí utvářejících budoucnost AI. Připojte se k nám!",
      buttons: {
        twitter: "Sdílet na Twitteru",
        facebook: "Sdílet na Facebooku",
        copyLink: "Kopírovat odkaz",
        linkCopied: "Odkaz zkopírován!"
      }
    },
    shareInvite: {
      title: "Sdílejte svůj vliv",
      bulletPoints: [
        "Každé sdílení nás přibližuje ke skutečně demokratické správě AI",
        "Pomozte rozšířit naši globální komunitu",
        "Šiřte povědomí o AI pro lidstvo"
      ],
      twitterText: "Právě jsem se připojil/a k @CtrlAI, abych pomohl/a utvářet budoucnost AI. Připojte se ke mně!",
      shareButtons: {
        twitter: "Sdílet na Twitteru",
        facebook: "Sdílet na Facebooku",
        copyLink: "Kopírovat odkaz",
        linkCopied: "Zkopírováno!"
      },
      founderNote: "Jste člen #{{memberNumber}} našeho hnutí. Navštivte svůj profil pro přizpůsobení vaší veřejné prezentace."
    }
  },

  constitutionPage: {
    hero: {
      title: "Ústava Ctrl AI (Návrh)",
      preamble:
        "V době, kdy technologie stále více utváří budoucnost lidstva, volíme jinou cestu: nejen budování lepších produktů, ale budování s lepšími principy. Toto je náš první návrh—jakmile dosáhneme kritického množství členů po celém světě, společně jej vylepšíme hlasováním komunity."
    },

    principles: {
      title: "Naše základní závazky",
      intro:
        "Toto jsou základní hodnoty, které vedou Ctrl AI. I když se mohou vyvíjet, podstata zůstává: upřednostnit lidské blaho, transparentnost a komunitní dohled při veškerém vývoji AI. Jakmile budou ratifikovány globálním členstvem, tyto závazky se stanou závaznými pro každý projekt pod Ctrl AI:",

      principles: [
        {
          title: "Nejprve lidské blaho",
          items: [
            "Design pro skutečný lidský rozvoj, ne pro obsesivní metriky zapojení",
            "Nulová tolerance k manipulativnímu designu nebo psychologickému vykořisťování",
            "Respektování pozornosti uživatelů jako omezeného, vzácného zdroje",
            "Podpora zdravých vzorců používání a přirozených bodů zastavení",
            "Podpora smysluplných spojení namísto návykových zážitků"
          ]
        }
      ]
    },

    enforcement: {
      title: "Skutečné vymáhání, skutečná odpovědnost",
      content:
        "Tyto principy nejsou jen vznešené ideály; jsou to vodící pravidla s mechanismy pro nápravu, pokud selžeme. Naše komunita má konečné slovo v udržování odpovědnosti Ctrl AI.",
      measures: [
        {
          title: "Komunitní dohled",
          items: [
            "• Plánované veřejné audity kódu, financí a procesů",
            "• Okamžité hlášení potenciálních porušení",
            "• Přímé kanály pro zpětnou vazbu členů",
            "• Nezávislá etická komise pro neutrální vyšetřování"
          ]
        },
        {
          title: "Jasné důsledky",
          items: [
            "• Rychlá reakce na problémy vznesené členy",
            "• Řešení a nápravy řízené komunitou",
            "• Povinné nápravné kroky při porušení",
            "• Transparentní reporting o řešení každého porušení"
          ]
        },
        {
          title: "Živý dokument",
          items: [
            "• Roční revize pro přizpůsobení novým technologiím nebo potřebám",
            "• Základní principy zůstávají neměnné kromě supermajority komunity",
            "• Demokratický proces změn po dosažení kritické masy",
            "• Otevřené výzvy pro návrhy členů"
          ]
        }
      ]
    },

    cta: {
      title: "Připojte se k nám při psaní budoucnosti AI",
      content:
        "Tento návrh Ústavy je jen začátek. Jakmile budeme mít různorodé, globální členství, naše komunita společně finalizuje a ratifikuje tyto principy. Ať jste vývojář, tvůrce politik nebo běžný uživatel, váš hlas má zde stejnou sílu. Jste připraveni se zapojit?",
      button: "Připojte se ke Ctrl AI za 1$"
    }
  },
  
  quotaMap: {
    stats: {
      totalQuota: "Celková globální kvóta",
      totalRegistered: "Celkem registrováno",
      totalPopulation: "Celková populace",
      countryCount: "Zastoupené země",
      fulfillment: "Globální naplnění"
    },
    tooltip: {
      country: "Země",
      population: "Populace",
      quota: "Členská kvóta",
      registered: "Registrovaní členové",
      fulfillment: "Naplnění kvóty"
    },
    legend: {
      title: "Naplnění kvóty",
      low: "Nízké",
      target: "Cílové",
      exceeded: "Překročené"
    },
    controls: {
      reset: "Obnovit pohled",
      zoom: "Přiblížení",
      pan: "Posouvání"
    },
    noData: "Pro tuto zemi nejsou dostupná data",
    loading: "Načítání dat mapy..."
  },

  auth: {
    login: {
      title: "Vítejte zpět",
      subtitle: "Pokračujte ve své cestě budování budoucnosti",
      emailPlaceholder: "Zadejte svůj email",
      passwordPlaceholder: "Zadejte své heslo",
      submitButton: "Přihlásit se",
      noAccount: "Nemáte účet?",
      signupLink: "Zaregistrujte se jako zakladatel",
      error: "Neplatný email nebo heslo"
    },
    signup: {
      title: "Připojte se jako zakladatel",
      subtitle: "Začněte budovat budoucnost technologií",
      namePlaceholder: "Zadejte své celé jméno",
      emailPlaceholder: "Zadejte svůj email",
      passwordPlaceholder: "Zvolte si heslo",
      submitButton: "Vytvořit účet",
      hasAccount: "Již máte účet?",
      loginLink: "Přihlásit se",
      error: "Chyba při vytváření účtu"
    },
    errors: {
      passwordLength: "Heslo musí mít alespoň 6 znaků",
      invalidEmail: "Zadejte prosím platnou emailovou adresu",
      emailInUse: "Tento email je již používán"
    },
    passwordRequirements: "Heslo musí mít alespoň 6 znaků",
    joinForm: {
      coverOperationalCosts: "Podpořte naše operace (doporučeno) - Váš 1$ pomůže pokrýt provozní náklady místo toho, aby šel do pokladny",
      processing: "Zpracovává se...",
      signUpWithEmail: "Registrace emailem",
      headline: "Připojte se k hnutí",
      continueWithGoogle: "Pokračovat přes Google",
      errors: {
        allFieldsRequired: "Všechna pole jsou povinná",
        acceptTerms: "Musíte přijmout podmínky pro pokračování",
        passwordLength: "Heslo musí mít alespoň 6 znaků",
        paymentFailed: "Proces platby selhal. Zkuste to prosím znovu."
      },
      subtitle: "Vytvořte si účet pro ověření že jste člověk",
      costAllocationTitle: "Vyberte, kam půjde váš ověřovací poplatek",
      operationalCosts: "Podpořit provoz",
      operationalCostsDetail: "Pomozte pokrýt naše výpočetní a provozní náklady (0,66$ po odečtení poplatků za zpracování platby)",
      treasuryCosts: "Nechat nedotčené",
      treasuryCostsDetail: "Nedotkneme se ani haléře! Celý váš příspěvek (minus poplatky za platbu) jde přímo do pokladny hnutí",
      orDivider: "nebo",
      email: "Email",
      emailPlaceholder: "Zadejte svou emailovou adresu",
      fullName: "Celé jméno",
      fullNamePlaceholder: "Zadejte své celé jméno",
      password: "Heslo",
      passwordPlaceholder: "Zvolte si heslo (min. 6 znaků)",
      alreadyHaveAccount: "Již máte účet?",
      signIn: "Přihlásit se",
      termsAgreement: "Souhlasím s",
      termsLink: "Podmínkami služby"
    }
  },

  footer: {
    companyDescription: "Budujeme budoucnost AI s komunitním vlastnictvím a etickými principy.",
    navigation: "Navigace",
    legal: "Právní",
    connect: "Ostatní",
    privacy: "Zásady ochrany soukromí",
    terms: "Podmínky služby",
    cookies: "Zásady používání cookies",
    rights: "Všechna práva vyhrazena."
  },

  nav: {
    chain: "Chain Dashboard",
    founders: "Pro vývojáře",
    ownership: "Nová společenská smlouva",
    constitution: "Ústava",
    pricing: "Ceny",
    dashboard: "Dashboard",
    myProfile: "Můj profil",
    settings: "Nastavení",
    home: "Domů",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Přihlásit se jako zakladatel",
    about: "O Ctrl AI",
    logout: "Odhlásit se",
    members: "Členové",
  },

  
}; 