export const fr = {

  hero: {
    title: "OpenAI N'est Plus Ouvert.",
    subtitle: "Voici le Seul Mouvement Qui Met l'Humanité en Premier.",
    chainCall: "Contrôle par les Gens, Pas par les Entreprises."
  },

  problem: {
    category: "Les Faits",
    title: "ChatGPT : 300M+ Utilisateurs",
    subtitle: "157B$+ Valorisation",
    subheadline: "0% Propriété des Utilisateurs"
  },

  problem2: {
    title: "Nous Construisons Ctrl AI",
    subtitle: "99% Détenu par les Utilisateurs. Cela Signifie Vous."
  },

  opportunity: {
    points: {
      control: "Vous Commencez avec 300 Points de Contrôle",
      bring: "Connectez UNE Personne en 24 Heures",
      fail: "Échec = Perte de 200 Points à Jamais"
    },
    note: "Cette Fois, Vous Possédez Vraiment Ce Que Vous Aidez à Construire."
  },

  howItWorks: {
    title: "La Véritable Alternative",
    points: [
      {
        title: "Phase 1 / Unir 1M d'Humains",
        bullets: [
          "Pas de capital-risque, pas de contrôle d'entreprise",
          "Pouvoir au peuple, maillon par maillon",
          "Chaque utilisateur est un véritable propriétaire"
        ]
      },
      {
        title: "Phase 2 / Financer 100 Équipes",
        bullets: [
          "Reconstruire les applications que nous utilisons quotidiennement. Applications qui extraient nos données et les vendent au plus offrant",
          "Construire des outils d'IA que nous possédons vraiment",
          "Contrôle démocratique des fonctionnalités",
          "Vos données restent les vôtres"
        ]
      },
      {
        title: "Phase 3 / Croître à 1B d'Humains",
        bullets: [
          "Véritable alternative à l'IA des entreprises",
          "Voix mondiale dans le développement de l'IA",
          "Pouvoir réel par la propriété réelle"
        ]
      }
    ]
  },

  cta: {
    category: "Le Choix",
    title: "Ouverture vs Contrôle",
    subtitle: "OpenAI nous a montré ce qui arrive quand 'ouvert' devient fermé. Ctrl AI garantit que cela ne se reproduira pas en donnant 99% de la propriété aux utilisateurs dès le premier jour.",
    subheadline: "Prendrez-vous le contrôle ?",
    citation: "Je suis Rado. J'ai créé Ctrl AI parce que j'ai vu la transformation d'OpenAI, d'une organisation à but non lucratif à un géant de 157 milliards de dollars. Cette fois, construisons quelque chose que nous possédons vraiment ensemble.",
    buttons: {
      primary: "Prenez le Contrôle (1€)",
      secondary: "Rester sans pouvoir"
    },
    note: "1€ prouve que vous êtes humain. Pas de bots, pas d'investisseurs—juste des personnes unies."
  },


  aspiringFounders: {
    intro: {
      category: "Fondateurs & Créateurs",
      title: "Construisez votre vision avec Ctrl IA",
      subtitle: "Transformez votre idée d'application ou d'IA en réalité avec le soutien d'une communauté mondiale. Pas de capital-risque prédateur, pas de course aux revenus publicitaires—juste une innovation ciblée, pleinement alignée sur le bien-être des utilisateurs.",
      citation: "« Pourquoi chercher des capitaux-risqueurs quand vous pouvez accéder à une base d'utilisateurs copropriétaires dès le premier jour ? »",
      pointsTitle: "Pourquoi construire avec Ctrl IA ?",
      points: [
        {
          headline: "Centré sur l'utilisateur, pas sur la publicité",
          text: "Oubliez les algorithmes manipulateurs—notre Constitution impose la transparence et une réelle utilité.",
        },
        {
          headline: "90 % de propriété communautaire",
          text: "Conservez jusqu'à 10 % pour votre équipe fondatrice tandis que la majorité des parts reste entre les mains de ceux qui soutiennent véritablement le projet.",
        },
        {
          headline: "Évangélistes intégrés",
          text: "Dès le premier jour, vous bénéficiez de milliers d'ambassadeurs copropriétaires, prêts à partager et à soutenir votre produit.",
        }
      ],
      conclusion: "Si vous êtes motivé à créer de manière éthique et ouverte, Ctrl IA vous offre la plateforme, le financement et la base d'utilisateurs nécessaires—sous un cadre qui priorise le bien-être humain.",
    },

    pitchProcess: {
      category: "Présentation & Financement",
      title: "Votre chemin vers le succès soutenu par la communauté",
      subtitle: "Une fois que les 100 000 premiers membres fondateurs de Ctrl IA seront réunis, vous pourrez présenter votre projet à notre communauté mondiale. Si votre projet est approuvé, vous recevrez un financement mensuel, des retours actifs des utilisateurs et un réseau de partenaires prêt à vous voir réussir.",
      citation: "« Obtenez jusqu'à 10 000 $/mois par fondateur une fois que votre idée est approuvée par la communauté—sans conditions autres que le respect de la Constitution de Ctrl IA. »",
      pointsTitle: "Comment ça fonctionne :",
      points: [
        {
          headline: "Proposez votre idée",
          text: "Soumettez un pitch détaillant votre vision, votre équipe et comment vous respecterez le modèle 90 %/10 % (avec au moins 20 % revenant au noyau de Ctrl IA et 70 % aux soutiens directs).",
        },
        {
          headline: "Vote de la communauté",
          text: "Les membres de Ctrl IA examinent et votent sur votre projet. Si vous atteignez le seuil de soutien requis, vous êtes approuvé pour un financement.",
        },
        {
          headline: "Salaire mensuel",
          text: "Chaque fondateur approuvé peut recevoir 10 000 $/mois, financé par les cotisations des membres—vous pouvez ainsi vous concentrer sur la création plutôt que sur la recherche de fonds.",
        }
      ],
      conclusion: "Pas de levées de fonds interminables. Pas de murs payants. Juste un soutien tangible et alimenté par les utilisateurs. Lorsque votre projet grandit, vos copropriétaires grandissent avec vous.",
    },

    buildingInPublic: {
      category: "Construire en public",
      title: "Partagez vos étapes, succès et leçons chaque semaine",
      subtitle: "Des mises à jour régulières maintiennent vos soutiens et copropriétaires informés—faisant naître transparence, responsabilité et un sentiment d'excitation collective.",
      citation: "« Lorsque la communauté a sa part du jeu, les succès—et les défis—de votre projet deviennent des expériences véritablement partagées. »",
      pointsTitle: "Principes fondamentaux :",
      points: [
        {
          headline: "Feuilles de route ouvertes",
          text: "Publiez votre calendrier de développement, les principales fonctionnalités et vos objectifs à long terme pour que chacun sache ce qui est à venir.",
        },
        {
          headline: "Tests & retours de la communauté",
          text: "Intégrez des boucles de retour d'information des utilisateurs dès le début et fréquemment. Vos copropriétaires peuvent détecter les problèmes, affiner les fonctionnalités et soutenir votre croissance.",
        },
        {
          headline: "Rapports de progrès transparents",
          text: "Partagez des instantanés hebdomadaires ou mensuels—mettant en avant les réussites, les défis et les prochaines étapes—pour maintenir l'engagement des parties prenantes.",
        }
      ],
      conclusion: "C'est plus qu'une simple mise à jour logicielle ; c'est une invitation pour votre communauté à façonner et célébrer votre parcours. Un engagement authentique favorise la fidélité—et de meilleurs produits.",
    },

    callToAction: {
      category: "Commencez",
      title: "Prêt à lancer votre projet d'IA ?",
      subtitle: "Rejoignez une communauté engagée pour une innovation éthique et orientée utilisateur. Proposez votre idée, obtenez un financement et développez avec le soutien de milliers de personnes—sans contraintes corporatives.",
      pointsTitle: "Prochaines étapes :",
      points: [
        {
          headline: "Atteindre 100 000 membres fondateurs",
          text: "Une fois que nous aurons atteint notre premier objectif d'adhésion, la plateforme de pitch s'ouvrira—alors partagez Ctrl IA pour accélérer ce processus.",
        },
        {
          headline: "Préparez votre pitch",
          text: "Commencez à rédiger votre concept, rassemblez votre équipe (ou travaillez en solo), et finalisez comment vous respecterez la structure de propriété 90 %/10 %.",
        },
        {
          headline: "Engagez-vous tôt et souvent",
          text: "Rejoignez nos forums communautaires pour obtenir des retours initiaux sur votre idée. Plus vous établissez de connexions maintenant, plus votre processus de présentation sera fluide.",
        }
      ],
      conclusion: "**Ctrl IA est l'endroit où les applications transformatrices de demain prennent forme—soutenues par une communauté mondiale, un financement transparent et une éthique orientée utilisateur.**",
    }
  },


  foundingMembers: {
    intro: {
      category: "Rejoignez le mouvement",
      title: "Devenez membre fondateur de Ctrl IA",
      subtitle: "Assurez votre place dans une coalition mondiale qui façonne l'avenir de l'IA—sans donner tout le pouvoir à une poignée de géants de la technologie.",
      citation: "Les membres fondateurs sont l'âme de Ctrl IA. Ils définissent notre Constitution, votent sur les décisions majeures et donnent le ton à une révolution de l'IA portée par le peuple.",
      pointsTitle: "Pourquoi devenir membre fondateur ?",
      points: [
        {
          headline: "Gouvernance exclusive anticipée",
          text: "Les membres fondateurs gagnent des Points de Contrôle quotidiens, leur permettant d'influencer les projets clés d'IA avant tout le monde.",
        },
        {
          headline: "Quotas mondiaux",
          text: "Nous attribuons des places en fonction de la taille des pays, garantissant que la voix de chaque nation soit entendue dès le premier jour.",
        },
        {
          headline: "Pas de publicités, pas de VC, pure communauté",
          text: "Vos frais d'adhésion financent notre plateforme—pas d'actionnaires d'entreprises. C'est une vraie démocratie pour l'IA.",
        }
      ],
      conclusion: "Prêt à contribuer à un avenir équitable et à une IA appartenant aux utilisateurs ? Rejoignez notre liste d'attente et commencez à gagner votre place de membre fondateur dès aujourd'hui.",
    },

    waitlistMechanics: {
      category: "Liste d'attente et points",
      title: "Comment fonctionne la liste d'attente",
      subtitle: "Nous croyons en la création d'un mouvement de véritables participants, pas de spectateurs passifs. C'est pourquoi nous invitons les principaux contributeurs de la liste d'attente à devenir membres fondateurs.",
      citation: "« Gagnez votre place en partageant, collaborant et montrant un engagement sincère envers la vision de Ctrl IA. »",
      pointsTitle: "Gagner des points :",
      points: [
        {
          headline: "Complétez votre profil",
          text: "Fournissez des informations de base afin que nous sachions qui vous êtes et quelle région vous représentez.",
        },
        {
          headline: "Parrainez des amis et partagez sur les réseaux sociaux",
          text: "Nous proposons des publications pré-rédigées que vous pouvez adapter, puis collez le lien de preuve dans votre tableau de bord. Notre équipe vérifie manuellement et attribue des points.",
        },
        {
          headline: "Participez à la communauté",
          text: "Bientôt, vous pourrez participer à des questions-réponses, ajouter des retours sur les propositions ou aider à répondre aux questions des nouveaux membres. Chaque action significative peut améliorer votre classement.",
        }
      ],
      conclusion: "Une fois que vous atteignez un certain seuil de points ou que vous figurez parmi les meilleurs contributeurs, nous vous inviterons à activer votre statut de membre fondateur.",
    },

    callToAction: {
      category: "Passez à l'action",
      title: "Réclamez votre place et façonnez l'avenir",
      subtitle: "Nous examinons régulièrement la liste d'attente et sélectionnons de nouveaux membres fondateurs. Montrez-nous votre engagement et aidez à diffuser le message pour grimper plus rapidement dans le classement.",
      pointsTitle: "Ce qui se passe après votre sélection :",
      points: [
        {
          headline: "Frais d'adhésion : 1 $",
          text: "Commencez votre adhésion officielle. Ce petit frais maintient Ctrl IA indépendant—sans publicités ni capital-risque.",
        },
        {
          headline: "Points de Contrôle quotidiens",
          text: "Gagnez du pouvoir de gouvernance chaque jour d'adhésion. Influencez tout, des fonctionnalités d'applications aux lignes directrices éthiques.",
        },
        {
          headline: "Vote & ratification de la Constitution",
          text: "Aidez à finaliser et appliquer notre Constitution contraignante. Si nous dévions un jour, vous avez le droit (et la responsabilité) de nous ramener sur la bonne voie.",
        }
      ],
      conclusion: "**Construisons ensemble une plateforme d'IA véritablement détenue par ses utilisateurs.** Inscrivez-vous sur la liste d'attente, engagez-vous pour gagner des points et devenez l'un des premiers à prendre le contrôle de l'IA.",
    }
  },

  ownership: {
    intro: {
      category: "Propriété",
      title: "La puissance de la propriété collective",
      subtitle: "Ctrl IA est détenu à 99 % par ses utilisateurs—garantissant qu'aucune entreprise, investisseur ou nation ne puisse dominer notre plateforme. En répartissant la propriété entre des millions (et finalement des milliards) de membres, nous créons un véritable écosystème d'IA démocratique.",
      citation: "« La propriété dans Ctrl IA n'est pas un simple mot à la mode—c'est votre droit d'influencer notre avenir, de définir nos politiques et de partager la valeur que nous créons ensemble. »",
      pointsTitle: "Pourquoi c'est important :",
      points: [
        {
          headline: "Freins & contrepoids",
          text: "Lorsque 99 % de la propriété est répartie entre les utilisateurs, aucune minorité ne peut imposer des décisions nuisibles à la communauté.",
        },
        {
          headline: "Responsabilité réelle",
          text: "Les utilisateurs disposent d'un droit de veto via des votes et peuvent exiger de la transparence, garantissant un développement éthique et équitable.",
        },
        {
          headline: "Stabilité à long terme",
          text: "Les plateformes dirigées par la communauté sont plus résilientes face aux aléas du marché et aux actionnaires avides de profits.",
        }
      ],
      conclusion: "En tant que membre, vous n'utilisez pas seulement Ctrl IA—vous en êtes propriétaire et gardien. C'est ainsi que nous empêchons le colonialisme de l'IA et garantissons que l'IA reste contrôlée par le peuple.",
    },
  
    controlPoints: {
      category: "Points de Contrôle",
      title: "Propriété continue & pouvoir de gouvernance",
      subtitle: "Chaque membre actif gagne 1 Point de Contrôle par jour—s'accumulant continuellement tant que vous restez abonné. Ces Points de Contrôle vous donnent une influence directe sur les décisions majeures de la plateforme.",
      citation: "« Rejoignez tôt et restez actif : plus longtemps vous êtes membre, plus vous accumulez de Points de Contrôle, et plus vous avez de pouvoir pour façonner Ctrl IA. »",
      pointsTitle: "Comment ça fonctionne :",
      points: [
        {
          headline: "Accumulation quotidienne",
          text: "Vos Points de Contrôle augmentent de 1 chaque jour où votre adhésion est active. Il n'y a pas de plafond—les membres de longue date obtiennent naturellement plus d'influence.",
        },
        {
          headline: "Votes & propositions",
          text: "Utilisez vos Points de Contrôle pour voter sur de nouvelles propositions de fonctionnalités, des lignes directrices éthiques, des allocations budgétaires, et bien plus encore.",
        },
        {
          headline: "Conversion optionnelle en chaîne",
          text: "Après 2 ans, vous pouvez convertir vos Points de Contrôle en jetons—mais cela reste entièrement optionnel. Restez hors chaîne si vous préférez la simplicité.",
        }
      ],
      conclusion: "Les Points de Contrôle sont conçus avant tout pour la gouvernance, pas pour la spéculation. Ils reflètent votre engagement continu et vous donnent une véritable voix dans l'évolution de Ctrl IA.",
    },
  
    communityModel: {
      category: "Modèle centré sur les utilisateurs",
      title: "99 % détenu par la communauté",
      subtitle: "Nous répartissons la propriété de manière à renforcer les utilisateurs sans entraver le développement. Les grandes décisions—des lancements d'applications à la protection des données—restent toujours entre les mains de la majorité.",
      citation: "« Imaginez un futur où vos outils d'IA vous appartiennent réellement, et non à des conglomérats axés sur la publicité ou à des capital-risqueurs. »",
      pointsTitle: "Caractéristiques clés de notre modèle :",
      points: [
        {
          headline: "Projets des fondateurs (90/10)",
          text: "Les sous-projets sont développés sous Ctrl IA avec un minimum de 90 % de propriété communautaire et 10 % pour l'équipe fondatrice—maintenant ainsi les intérêts des utilisateurs au cœur des priorités.",
        },
        {
          headline: "Gouvernance directe",
          text: "Votez sur les décisions majeures, proposez des changements et façonnez l'avenir de Ctrl IA.",
        },
        {
          headline: "Représentation mondiale",
          text: "Des quotas d'adhésion par pays garantissent qu'aucune région ne puisse dominer les votes des autres. La propriété est répartie dans le monde entier.",
        }
      ],
      conclusion: "En limitant les parts individuelles et en répartissant le reste entre des millions d'utilisateurs, nous garantissons que Ctrl IA reste un écosystème global, sans frontières et axé sur les utilisateurs.",
    },
  
    callToAction: {
      category: "Passez à l'action",
      title: "Prenez en main votre avenir avec Ctrl IA",
      subtitle: "Ne vous contentez pas d'un monde où l'IA est monopolisée. Rejoignez Ctrl IA, gagnez des Points de Contrôle chaque jour et maintenez le développement de l'IA honnête, centré sur l'humain et transparent.",
      pointsTitle: "Prochaines étapes :",
      points: [
        {
          headline: "Rejoignez la liste d'attente",
          text: "Réservez votre place en tant que membre et commencez à accumuler des Points de Contrôle dès que vous serez invité.",
        },
        {
          headline: "Partagez & gagnez des points sur la liste d'attente",
          text: "Améliorez votre classement en parrainant des amis et en partageant le message—aidez-nous à atteindre nos objectifs mondiaux d'adhésion plus rapidement.",
        },
        {
          headline: "Façonnez l'IA de demain",
          text: "Une fois à l'intérieur, utilisez vos Points de Contrôle accumulés pour voter, proposer des changements et maintenir notre plateforme fidèle à sa mission.",
        }
      ],
      conclusion: "**La propriété, c'est le pouvoir**—et chez Ctrl IA, ce pouvoir vous appartient. Inscrivez-vous, restez actif et aidez-nous à réécrire les règles de la propriété de l'IA pour tous.",
    }
  },


  constitutionPage: {
    hero: {
      title: "La Constitution de Ctrl IA",
      preamble: "À une époque où la technologie façonne de plus en plus l'avenir de l'humanité, nous choisissons une voie différente. **Pas seulement de meilleurs produits, mais de meilleurs principes. Pas seulement l'innovation, mais le progrès éthique. Pas seulement l'engagement des utilisateurs, mais l'épanouissement humain.**",
    },
    principles: {
      title: "Nos principes contraignants",
      intro: "Ce ne sont pas des lignes directrices ou des suggestions—ce sont des engagements contraignants que chaque projet sous Ctrl IA doit respecter, appliqués par une supervision communautaire et des audits réguliers :",
      principles: [
        {
          title: "Le bien-être humain avant tout",
          items: [
            "Concevoir pour un véritable épanouissement humain, pas pour des métriques d'engagement",
            "Tolérance zéro pour les dark patterns ou la manipulation psychologique",
            "Traiter l'attention des utilisateurs comme une ressource précieuse à respecter",
            "Créer des points d'arrêt naturels et des modèles d'utilisation sains",
            "Prioriser les connexions significatives plutôt que l'engagement addictif",
          ],
        },
        {
          title: "Droits absolus sur les données",
          items: [
            "Les utilisateurs possèdent totalement leurs données—sans exception",
            "Zéro vente ou partage non autorisé de données",
            "Traitement local par défaut chaque fois que possible",
            "Cryptage de niveau militaire standard",
            "Droit garanti à l'oubli—complet et permanent",
          ],
        },
        {
          title: "Pouvoir réel de la communauté",
          items: [
            "90 % minimum de propriété communautaire—garanti par la Constitution",
            "Gouvernance démocratique directe sur les décisions majeures",
            "Votes contraignants de la communauté sur l'orientation des produits",
            "Distribution équitable des jetons selon des règles mathématiques",
            "Égalité des droits quel que soit le niveau de contribution",
          ],
        },
        {
          title: "Limites éthiques de l'IA",
          items: [
            "L'IA sert l'agence humaine—elle ne la réduit jamais",
            "Transparence complète des processus décisionnels de l'IA",
            "Surveillance continue des biais et élimination de ceux-ci",
            "Possibilité d'intervention humaine claire sur toutes les fonctionnalités de l'IA",
            "Développement de l'IA axé sur l'autonomisation humaine",
          ],
        },
        {
          title: "Bien-être de l'équipe",
          items: [
            "Semaine de travail de quatre jours comme droit constitutionnel",
            "Soutien complet à la santé mentale",
            "Apprentissage continu financé pour tous",
            "Aucune exploitation en période de pointe",
            "Partage équitable des bénéfices",
            "Priorité au télétravail avec collaboration optionnelle",
            "Limites strictes entre vie professionnelle et personnelle",
            "Événements réguliers de connexion d'équipe",
          ],
        },
        {
          title: "Éducation avant tout",
          items: [
            "Valeur éducative intégrée dans tous les produits",
            "Conception et sécurité adaptées à l'âge",
            "Promotion active de la littératie numérique",
            "Approche éducative multi-parties prenantes",
            "Aucune exploitation des jeunes utilisateurs",
            "Interdiction de la manipulation ciblée des jeunes",
          ],
        },
        {
          title: "Transparence radicale",
          items: [
            "Mises à jour publiques hebdomadaires du développement",
            "Transparence financière complète",
            "Communication directe sur les défis",
            "Open source par défaut",
            "Sessions régulières d'engagement communautaire",
          ],
        },
        {
          title: "Développement durable",
          items: [
            "Croissance naturelle plutôt que viralité artificielle",
            "Expansion uniquement menée par la communauté",
            "Interdiction du marketing exploitant",
            "Considération de l'impact environnemental",
            "Soutien à long terme plutôt que gains à court terme",
          ],
        },
        {
          title: "Revenus équitables",
          items: [
            "Aucun revenu basé sur la publicité",
            "Tarification transparente basée sur la valeur",
            "Communication claire des coûts et avantages",
            "Partage direct des bénéfices avec la communauté",
            "Tarification alignée sur les coûts uniquement",
          ],
        },
        {
          title: "Innovation avec un but",
          items: [
            "Concentration sur les besoins humains réels",
            "Innovation au service de la communauté uniquement",
            "Principes de collaboration ouverte",
            "Technologie au service de l'humanité",
            "Considération de l'impact social dans toutes les décisions",
          ],
        },
      ],
    },
    enforcement: {
      title: "Application réelle, responsabilité réelle",
      content: "**Ces principes ne sont pas des promesses—ce sont des engagements contraignants avec de réelles conséquences :**",
      measures: [
        {
          title: "Supervision communautaire",
          items: [
            "• Audits publics trimestriels",
            "• Signalement des violations en temps réel",
            "• Systèmes de retour d'information directs",
            "• Comité d'examen éthique indépendant",
          ],
        },
        {
          title: "Conséquences claires",
          items: [
            "• Réponse immédiate aux violations",
            "• Corrections menées par la communauté",
            "• Remédiation obligatoire",
            "• Rapport public de responsabilité",
          ],
        },
        {
          title: "Document évolutif",
          items: [
            "• Révision annuelle par la communauté",
            "• Mises à jour adaptées à la technologie",
            "• Principes fondamentaux immuables",
            "• Processus d'amendement démocratique",
          ],
        },
      ],
    },
    cta: {
      title: "Rejoignez la révolution technologique éthique",
      content: "**Ce n'est pas juste la déclaration de mission d'une autre entreprise tech. C'est une constitution contraignante pour construire une technologie au service de l'humanité.** Que vous soyez un fondateur prêt à créer autrement ou un utilisateur prêt à posséder ce que vous utilisez, l'avenir de la technologie éthique commence ici.",
      button: "Rejoindre les membres fondateurs (1 $)",
    },
  },


  footer: {
    companyDescription: "Construire l'avenir de l'IA avec une propriété communautaire et des principes éthiques.",
    navigation: "Navigation",
    legal: "Légal",
    connect: "Autres",
    privacy: "Politique de confidentialité",
    terms: "Conditions d'utilisation",
    cookies: "Politique relative aux cookies",
    rights: "Tous droits réservés.",
  },

  nav: {
    community: "Membres fondateurs",
    founders: "Fondateurs",
    ownership: "Propriété",
    constitution: "Constitution",
    pricing: "Tarification",
    dashboard: "Tableau de bord",
    myProfile: "Mon profil",
    settings: "Paramètres",
    home: "Accueil",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Postuler en tant que fondateur",
    about: "À propos de Ctrl IA",
    logout: "Déconnexion",
  },

  auth: {
    login: {
      title: "Bon retour",
      subtitle: "Continuez votre parcours dans la construction de l'avenir",
      emailPlaceholder: "Entrez votre email",
      passwordPlaceholder: "Entrez votre mot de passe",
      submitButton: "Se connecter",
      noAccount: "Vous n'avez pas de compte ?",
      signupLink: "Inscrivez-vous en tant que fondateur",
      error: "Email ou mot de passe invalide",
    },
    signup: {
      title: "Inscrivez-vous en tant que fondateur",
      subtitle: "Commencez à construire l'avenir de la technologie",
      namePlaceholder: "Entrez votre nom complet",
      emailPlaceholder: "Entrez votre email",
      passwordPlaceholder: "Choisissez un mot de passe",
      submitButton: "Créer un compte",
      hasAccount: "Vous avez déjà un compte ?",
      loginLink: "Connectez-vous",
      error: "Erreur lors de la création du compte",
    },
    errors: {
      passwordLength: "Le mot de passe doit comporter au moins 6 caractères",
      invalidEmail: "Veuillez entrer une adresse email valide",
      emailInUse: "Cet email est déjà utilisé",
    },
    passwordRequirements: "Le mot de passe doit comporter au moins 6 caractères",
  },


  pricing: {
    title: "Rejoignez le mouvement",
    subtitle: "Rejoignez le mouvement pour construire et posséder l'avenir de la technologie",
    tiers: {
      founding: {
        title: "Membre fondateur",
        price: "1 $",
        description: "Pour ceux qui veulent changer les choses",
        features: [
          "1 Point de Contrôle quotidien",
          "Accès prioritaire aux nouveaux projets",
          "Façonnez l'avenir de la technologie",
          "Badge exclusif de membre fondateur",
          "3 codes d'invitation pour vos amis",
        ],
      },
    },
    footer: {
      quote: "Dans vingt ans, les gens se demanderont comment on a pu manquer cette opportunité. Ne soyez pas celui qui explique pourquoi vous avez attendu.",
    },
  },

  modals: {
    inviteCode: {
      title: "Utilisez votre code d'invitation",
      description: "Entrez votre code d'invitation pour garantir votre place de membre fondateur. Chaque code ne peut être utilisé qu'une seule fois.",
      placeholder: "Entrez le code d'invitation",
      submit: "Rejoindre en tant que membre fondateur",
      invalidCode: "Code d'invitation invalide ou expiré. Veuillez réessayer.",
      success: "Bienvenue chez Ctrl IA ! Finalisation de votre inscription...",
      validating: "Validation en cours...",
      alreadyUsed: "Ce code a déjà été utilisé",
      expired: "Ce code a expiré",
      validated: "Code validé ! Redirection vers l'inscription...",
    },
    waitlist: {
      title: "Rejoignez la liste d'attente",
      description: "Rejoignez notre liste d'attente exclusive pour accéder en avant-première à Ctrl IA.",
      namePlaceholder: "Votre nom complet",
      emailPlaceholder: "Votre adresse email",
      passwordPlaceholder: "Créez un mot de passe",
      submit: "Rejoindre la liste d'attente",
      submitting: "Inscription en cours...",
      error: "Une erreur est survenue. Veuillez réessayer.",
      success: "Bienvenue ! Vous êtes sur la liste.",
    },
  },

  waitlist: {
    status: {
      title: "Votre statut dans la liste d'attente",
      totalInWaitlist: "Sur un total de {{total}} personnes en file d'attente",
      referralCode: "Votre code de parrainage",
      copyLink: "Copier le lien",
      referralDescription: "Partagez votre code de parrainage avec vos amis pour monter dans la liste d'attente. Chaque parrainage vous rapporte 50 points !",
      points: "Progression des points",
    },
    actions: {
      twitter: "Partager sur Twitter",
      linkedin: "Partager sur LinkedIn",
      discord: "Rejoindre Discord",
      profile: "Compléter le profil",
    },
  },
  onboarding: {
    title: "Bienvenue chez Ctrl IA",
    subtitle: "Préparons ensemble votre réussite",
    points: "points",
    continue: "Continuer",
    finish: "Terminer la configuration",
    steps: {
      profile: {
        title: "Complétez votre profil",
        description: "Ajoutez une photo de profil et une biographie pour que les autres puissent mieux vous connaître.",
      },
      social: {
        title: "Partagez votre parcours",
        description: "Partagez votre enthousiasme à propos de votre adhésion à Ctrl IA avec votre réseau.",
      },
      community: {
        title: "Rejoignez la communauté",
        description: "Connectez-vous avec d'autres membres dans notre communauté Discord.",
      },
    },
  },

  dashboard: {
    welcome: "Bon retour, {{name}}",
    membershipTiers: {
      founding: "Membre fondateur",
      collective: "Membre collectif",
    },
    tabs: {
      projects: "Projets",
      community: "Communauté",
      rewards: "Récompenses",
    },
    community: {
      title: "Activité de la communauté",
      discussions: {
        title: "Discussions récentes",
        empty: "Aucune discussion pour le moment",
        start: "Lancer une discussion",
      },
      activity: {
        title: "Activité récente",
        empty: "Aucune activité récente",
      },
      stats: {
        members: "Membres actifs",
        discussions: "Discussions",
        contributions: "Contributions",
      },
    },
    projects: {
      title: "Projets actifs",
      empty: "Aucun projet actif",
      explore: "Explorer les projets",
      create: "Créer un projet",
    },
    rewards: {
      title: "Vos récompenses",
      points: "Points de Contrôle",
      achievements: "Réalisations",
      empty: "Aucune récompense pour le moment",
    },
    quickActions: {
      title: "Actions rapides",
      createProject: "Créer un projet",
      startDiscussion: "Lancer une discussion",
      updateProfile: "Mettre à jour le profil",
    },
  },

  global: {
    title: "Regardez notre liste d'attente grandir à l'échelle mondiale",
    subtitle: "Croissance de la communauté en temps réel",
    content: "Chaque point représente une personne qui concourt pour une place de membre fondateur. Vous n'avez pas encore de code d'invitation ? Rejoignez la liste d'attente et améliorez votre classement en accomplissant des défis.",
    stats: {
      totalMembers: "Nombre total de membres",
      activeToday: "Actifs aujourd'hui",
      averagePoints: "Points moyens",
    },
    controls: {
      pause: "Pause & Interaction",
      resume: "Reprendre la visite",
      next: "Continent suivant",
    },
  }, 

  quotaMap: {
    stats: {
      totalQuota: "Quota global total",
      totalRegistered: "Nombre total d'inscrits",
      totalPopulation: "Population totale",
      countryCount: "Pays représentés",
      fulfillment: "Réalisation globale",
    },
    tooltip: {
      country: "Pays",
      population: "Population",
      quota: "Quota de membres",
      registered: "Membres inscrits",
      fulfillment: "Réalisation du quota",
    },
    legend: {
      title: "Réalisation du quota",
      low: "Faible",
      target: "Objectif",
      exceeded: "Dépassé",
    },
    controls: {
      reset: "Réinitialiser la vue",
      zoom: "Zoom",
      pan: "Déplacer",
    },
    noData: "Aucune donnée disponible pour ce pays",
    loading: "Chargement des données de la carte...",
  },
  signupForm: {
    title: {
      waitlist: "Rejoindre la liste d'attente",
      verified: "Créer un compte"
    },
    subtitle: {
      waitlist: "Rejoignez notre liste d'attente exclusive",
      verified: "Vous avez été invité en tant que membre fondateur"
    },
    fields: {
      fullName: "Nom complet",
      email: "E-mail",
      password: "Mot de passe"
    },
    buttons: {
      submit: "Continuer",
      submitting: "Création du compte...",
      google: "Continuer avec Google"
    },
    errors: {
      emailExists: "Cet e-mail est déjà sur la liste d'attente. Veuillez vérifier votre e-mail pour les instructions de vérification.",
      emailInUse: "Cet e-mail est déjà enregistré. Veuillez vous connecter.",
      waitlistCheck: "Impossible de vérifier le statut de la liste d'attente. Veuillez réessayer.",
      createAccount: "Impossible de créer le compte. Veuillez réessayer."
    },
    inviteCode: {
      prompt: "Vous avez un code d'invitation ?",
      button: "Entrer le code d'invitation"
    }
  },
  waitlistDashboard: {
    title: "État de votre Liste d'Attente",
    position: "Position {{position}}",
    total: "Sur {{total}} personnes",
    stats: {
      referrals: "Parrainages",
      points: "Points",
      status: "Statut",
      active: "Actif",
      position: "Position",
      completion: "Progression",
      earnMore: "Gagnez plus de points",
      completeProfile: "Complétez votre profil pour améliorer votre position",
      total: "Sur {{total}} personnes au total"
    },
    breadcrumbs: {
      waitlist: "Liste d'Attente",
      dashboard: "Tableau de Bord"
    },
    welcome: {
      title: "Bienvenue",
      location: "{{city}}, {{country}}"
    },
    overview: {
      journey: "Votre Parcours vers le Statut de Membre Fondateur",
      profileCompletion: "Complétion du Profil",
      socialSharing: "Partage Social",
      tasks: {
        title: "Tâches Disponibles",
        completeProfile: "Complétez votre Profil",
        completeProfileDesc: "Ajoutez vos informations de base pour nous aider à mieux vous connaître",
        addLocation: "Ajoutez votre Localisation",
        addLocationDesc: "Indiquez-nous où vous êtes situé"
      }
    },
    tabs: {
      overview: "Aperçu",
      tasks: "Gagner des Points",
      social: "Partager & Gagner"
    },
    socialShare: {
      title: "Partagez et Gagnez des Points",
      description: "Partagez Ctrl AI sur vos réseaux sociaux pour gagner des points et améliorer votre position",
      verificationPending: "Votre partage sur {{platform}} a été soumis pour vérification"
    },
    progress: {
      items: {
        photo: "Photo de Profil",
        basicInfo: "Informations de Base",
        location: "Localisation",
        skills: "Compétences et Intérêts"
      }
    }
  },

  userProfile: {
    title: "Votre Profil",
    breadcrumbs: {
      waitlist: "Liste d'attente",
      dashboard: "Tableau de bord",
      profile: "Profil"
    },
    fields: {
      fullName: "Nom complet",
      location: "Localisation",
      bio: "Parlez-nous de vous..."
    },
    buttons: {
      save: "Enregistrer le profil",
      saving: "Enregistrement..."
    },
    completion: {
      title: "Complétion du profil",
      status: {
        complete: "Votre profil est complet ! Merci d'avoir fourni toutes les informations.",
        incomplete: "Complétez votre profil pour gagner des points supplémentaires et améliorer votre position dans la liste d'attente !"
      }
    },
    toast: {
      success: {
        withPoints: "Profil mis à jour ! Vous avez gagné {{points}} points !",
        default: "Profil mis à jour avec succès"
      },
      error: "Impossible de mettre à jour le profil. Veuillez réessayer."
    }
  },

  progressSection: {
    complete: "Terminé",
    points: "+{{points}} points",
    viewDetails: "Voir les Détails",
    completeTasks: "Compléter les Tâches",
    percentComplete: "{{percent}}% Terminé"
  },

  social: {
    templates: {
      twitter: [
        {
          text: "Je viens de rejoindre le mouvement #CtrlAI ! 🚀 Construisons un futur appartenant aux utilisateurs, où aucun géant tech ne peut monopoliser l'IA.\n\nLes places sont limitées par pays—réservez la vôtre : {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#PropriétéUtilisateur", "#RévolutionIA"]
        },
        {
          text: "Pour avoir une vraie voix dans l'IA, nous devons grandir organiquement ! J'aide @CtrlAI à atteindre 100k Membres Fondateurs.\n\nRejoignez-moi avant que le quota de votre pays ne soit épuisé : {{REFERRAL_LINK}}",
          hashtags: ["#PrendreLeControle", "#LibertéNumérique", "#FuturDeLaTech"]
        }
      ],
      linkedin: [
        {
          text: "Ravi(e) de partager que j'ai rejoint la liste d'attente de Ctrl AI — une plateforme dirigée par les utilisateurs qui défie le monopole de l'IA des géants tech.\n\nPourquoi c'est important :\n• 99% appartient aux utilisateurs (plus de conseil d'administration qui décide)\n• Points de Contrôle quotidiens = pouvoir de gouvernance réel\n• Quotas par pays pour assurer une représentation mondiale\n\nSeules 100 000 places de Membres Fondateurs existent. Rejoignez-moi pour remodeler l'avenir de l'IA par le bouche-à-oreille (pas de pub, pas de VC) !\n\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#RévolutionTech", "#LibertéNumérique", "#IA"]
        },
        {
          text: "J'ai sécurisé ma place sur la liste d'attente Ctrl AI pour co-posséder la technologie de demain ! Imaginez une IA responsable envers les gens, pas les actionnaires.\n\n• 99% appartenant aux utilisateurs\n• IA éthique et transparente\n• Pas de budgets pub massifs—juste nous, partageant le message\n\nDépêchez-vous, chaque pays a une limite de places pour les Membres Fondateurs. En savoir plus : {{REFERRAL_LINK}}",
          hashtags: ["#RévolutionIA", "#PropriétéUtilisateur", "#TechÉthique", "#FuturDeLaTech"]
        }
      ],
      instagram: [
        {
          text: "Reprendre le contrôle de l'IA commence maintenant ! ⚡\n\nJ'ai rejoint la liste d'attente de Ctrl AI pour construire des apps appartenant aux utilisateurs—sans l'emprise des géants tech.\n\nNous grandissons uniquement par le bouche-à-oreille, alors vérifiez si le quota de Membres Fondateurs de votre pays est encore ouvert !\n{{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#RévolutionTech", "#PropriétéUtilisateur", "#LibertéNumérique", "#IA"]
        },
        {
          text: "La course aux 100 000 Membres Fondateurs est lancée !\n\nCtrl AI = 99% propriété utilisateur, vraie voix dans l'évolution tech. Pas de pub, pas de VC, pas de collecte de données.\n\nLes places de votre pays sont limitées—rejoignez maintenant : {{REFERRAL_LINK}}",
          hashtags: ["#RévolutionIA", "#PrendreLeControle", "#PlusDeGéantsTech", "#FuturDeLaTech"]
        }
      ],
      facebook: [
        {
          text: "Je rejoins la liste d'attente de Ctrl AI pour créer un monde où l'IA appartient vraiment aux utilisateurs, pas dominée par quelques méga-corporations.\n\nAvec 99% de propriété entre les mains des vrais gens, nous pouvons assurer une IA éthique et transparente. Mais nous visons une croissance purement par bouche-à-oreille—pas de dépenses pub, pas de VC.\n\nSeules 100 000 places de Membres Fondateurs existent, réparties par pays pour plus d'équité. Prêt à transformer la tech ? Rejoignez-nous ici : {{REFERRAL_LINK}}",
          hashtags: ["#PrendreLeControle", "#LibertéNumérique", "#PropriétéUtilisateur", "#IA"]
        },
        {
          text: "Vous avez déjà souhaité posséder réellement les apps que vous utilisez ? Ctrl AI rend ça possible ! Je suis sur la liste d'attente, et je veux que VOUS me rejoigniez.\n\nNous ne pouvons pas compter sur la pub—ce doit être un mouvement porté par les gens. Votre pays a un quota limité, alors sécurisez votre place maintenant : {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#FuturDeLaTech", "#RévolutionIA"]
        }
      ],
      youtube: [
        {
          text: "Rejoignez-moi pour changer la façon dont l'IA est construite ! Ctrl AI est une plateforme dirigée par les utilisateurs, possédée à 99% par ses utilisateurs. Pas de pub, pas de patrons d'entreprise, juste une vraie démocratie dans la tech.\n\nRejoignez via mon lien : {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#IA", "#Innovation", "#RévolutionTech"]
        },
        {
          text: "L'IA ne devrait pas être réservée à une poignée de géants. Je fais partie de Ctrl AI, et vous pouvez l'être aussi ! Nous comptons sur le bouche-à-oreille pour grandir, alors partagez le message—seules 100k places existent mondialement.\n\nRejoignez ici : {{REFERRAL_LINK}}",
          hashtags: ["#LibertéNumérique", "#PropriétéUtilisateur", "#IA", "#FuturDeLaTech"]
        }
      ],
      tiktok: [
        {
          text: "POV : Vous venez de découvrir un mouvement où VOUS pouvez co-posséder le futur de l'IA 🤖\n\nCtrl AI est possédée à 99% par les utilisateurs, pas de géants tech ni d'investisseurs douteux. Nous comptons sur nous, les gens, pour grandir. Rejoignez via mon lien : {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#TechTok", "#RévolutionIA", "#PropriétéUtilisateur"]
        },
        {
          text: "Envie de vous libérer de l'emprise des géants tech ? Rejoignez Ctrl AI ! 🚀 Nous n'avons que 100k places de Membres Fondateurs globalement. Si le quota de votre pays est encore ouvert, réclamez votre place et aidez à partager le message : {{REFERRAL_LINK}}",
          hashtags: ["#RévolutionTech", "#IA", "#LibertéNumérique", "#PrendreLeControle"]
        }
      ],
      github: [
        {
          text: "Je viens de rejoindre la liste d'attente de Ctrl AI pour co-posséder et co-développer des apps IA éthiques ! Pas de pub, pas de VC, que de l'open-source communautaire.\n\nLes places de Membres Fondateurs de votre pays pourraient être limitées—rejoignez ici : {{REFERRAL_LINK}}",
          hashtags: ["#IA", "#OpenSource", "#PropriétéUtilisateur", "#TechÉthique"]
        },
        {
          text: "Amis développeurs : Fatigués des monopoles tech ? Ctrl AI construit une nouvelle ère d'IA appartenant aux utilisateurs. Nous grandissons uniquement par la communauté, alors si vous êtes passionnés par la collaboration ouverte, sécurisez votre adhésion de Membre Fondateur maintenant : {{REFERRAL_LINK}}",
          hashtags: ["#CtrlAI", "#Innovation", "#OpenSource", "#FuturDeLaTech"]
        }
      ]
    }
  }
}; 