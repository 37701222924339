import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { useUserStore } from '@/stores/useUserStore';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface PointsEntry {
  id: string;
  amount: number;
  reason: string;
  timestamp: Date;
  waveNumber: number;
}

export function PointsHistoryPage() {
  const { profile } = useUserStore();
  const [history, setHistory] = useState<PointsEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchHistory = async () => {
      if (!profile) return;

      try {
        const pointsQuery = query(
          collection(db, 'pointsHistory'),
          where('userId', '==', profile.id),
          orderBy('timestamp', 'desc'),
          limit(50)
        );

        const snapshot = await getDocs(pointsQuery);
        const entries = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate()
        })) as PointsEntry[];

        setHistory(entries);
      } catch (error) {
        console.error('Error fetching points history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [profile]);

  return (
    <div className="container mx-auto p-4 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>{t('points.title')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {loading ? (
              <div className="text-center text-muted-foreground">
                {t('points.loading')}
              </div>
            ) : history.length === 0 ? (
              <div className="text-center text-muted-foreground">
                {t('points.noHistory')}
              </div>
            ) : (
              history.map((entry, index) => (
                <motion.div
                  key={entry.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="flex items-center justify-between p-4 rounded-lg bg-muted/50"
                >
                  <div className="space-y-1">
                    <div className="font-medium">
                      {entry.reason}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {formatDistanceToNow(entry.timestamp, { addSuffix: true })}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Badge 
                      variant={entry.amount > 0 ? "default" : "secondary"}
                      className="font-mono"
                    >
                      {t('points.amount', {
                        sign: entry.amount > 0 ? '+' : '',
                        amount: entry.amount
                      })}
                    </Badge>
                    {entry.waveNumber && (
                      <Badge variant="outline" className="text-xs">
                        {t('points.wave', { number: entry.waveNumber })}
                      </Badge>
                    )}
                  </div>
                </motion.div>
              ))
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 