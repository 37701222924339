import { lazy, Suspense } from 'react';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { Navigate, Outlet } from 'react-router-dom';
import { ScrollToTop } from '@/components/ScrollToTop';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AdminLogin } from '@/features/admin/components/AdminLogin';
import { AdminDashboard } from '@/features/admin/components/AdminDashboard';
import { AdminRoute } from '@/components/AdminRoute';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import DashboardPage from '@/pages/DashboardPage';

// Import verification components
import { VerificationForm } from '@/features/verification/components/VerificationForm';
import { VerificationSuccess } from '@/features/verification/components/VerificationSuccess';
import { VerificationCancel } from '@/features/verification/components/VerificationCancel';
import { LoginForm } from '@/features/auth/components/LoginForm';
import { VerificationRecovery } from '@/features/verification/components/VerificationRecovery';
import ChainPage from './pages/ChainPage';
import { ProfilePage } from '@/pages/ProfilePage';
import { PointsHistoryPage } from '@/pages/PointsHistoryPage';
import { ReferralRedirect } from '@/features/referral/components/ReferralRedirect';

// Lazy load pages
const HomePage = lazy(() => import('@/pages/HomePage'));
const CommunityPage = lazy(() => import('@/pages/CommunityPage'));
const FoundersPage = lazy(() => import('@/pages/FoundersPage'));
const OwnershipPage = lazy(() => import('@/pages/OwnershipPage'));
const ConstitutionPage = lazy(() => import('@/pages/ConstitutionPage'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('@/pages/TermsOfService'));
const CookiePolicy = lazy(() => import('@/pages/CookiePolicy'));

const withSuspense = (Component: React.ComponentType) => (
  <ErrorBoundary>
    <Suspense fallback={<LoadingSpinner />}>
      <ScrollToTop />
      <Component />
    </Suspense>
  </ErrorBoundary>
);

export const routes = [
  // Public routes (accessible to all) - NO protection wrapper
  {
    path: '/',
    element: withSuspense(HomePage)
  },
  {
    path: '/r/:referralCode',
    element: <ReferralRedirect />
  },
  {
    path: '/privacy',
    element: withSuspense(PrivacyPolicy)
  },
  {
    path: '/terms',
    element: withSuspense(TermsOfService)
  },
  {
    path: '/cookies',
    element: withSuspense(CookiePolicy)
  },
  {
    path: '/login',
    element: <LoginForm />
  },
  {
    path: '/chain',
    element: <ChainPage />
  },
  {
    path: '/verification/*',
    children: [
      {
        path: '',
        element: <VerificationForm />
      },
      {
        path: 'success',
        element: <VerificationSuccess />
      },
      {
        path: 'cancel',
        element: <VerificationCancel />
      },
      {
        path: 'recover',
        element: <VerificationRecovery />
      }
    ]
  },

  // Content pages - These should also be public
  {
    path: '/community',
    element: withSuspense(CommunityPage)
  },
  {
    path: '/founders',
    element: withSuspense(FoundersPage)
  },
  {
    path: '/ownership',
    element: withSuspense(OwnershipPage)
  },
  {
    path: '/constitution',
    element: withSuspense(ConstitutionPage)
  },

  // Protected routes - Only these should have ProtectedRoute wrapper
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    )
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute>
        <ProfilePage />
      </ProtectedRoute>
    )
  },
  {
    path: '/profile/history',
    element: (
      <ProtectedRoute>
        <PointsHistoryPage />
      </ProtectedRoute>
    )
  },

  // Admin routes
  {
    path: '/admin/*',
    element: (
      <div className="flex flex-col min-h-screen">
        <ScrollToTop />
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
    ),
    children: [
      {
        path: 'login',
        element: <AdminLogin />
      },
      {
        path: 'dashboard',
        element: (
          <AdminRoute>
            <AdminDashboard />
          </AdminRoute>
        )
      }
    ]
  },

  // Catch-all route
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];