import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { UserPublicProfile } from '@/types/user';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Linkedin, Twitter, Globe } from 'lucide-react';
import { LoadingSpinner } from '@/components/LoadingSpinner';

export function ProfilePage() {
  const { userId } = useParams();
  const [profile, setProfile] = useState<UserPublicProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!userId) return;
      
      try {
        setLoading(true);
        const profileDoc = await getDoc(doc(db, 'publicProfiles', userId));
        
        if (profileDoc.exists()) {
          setProfile(profileDoc.data() as UserPublicProfile);
        } else {
          setError(new Error('Profile not found'));
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError(error instanceof Error ? error : new Error('Failed to fetch profile'));
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-center text-red-500">{error.message}</div>;
  if (!profile) return null;

  return (
    <div className="max-w-2xl mx-auto px-4 py-32">
      <Card className="p-8">
        <div className="flex items-start gap-6">
          {profile.hasProfilePicture && (
            <img
              src={`/profile-photos/${profile.userId}/thumbnail.jpg`}
              alt={profile.displayName}
              className="w-24 h-24 rounded-full object-cover"
            />
          )}
          <div className="flex-1">
            <h1 className="text-3xl font-bold mb-2">{profile.displayName}</h1>
            <p className="text-muted-foreground">
              {t('profile.memberNumber', { number: profile.memberNumber })}
            </p>
            {(profile.displayCity || profile.displayCountry) && (
              <p className="text-muted-foreground mt-1">
                {[profile.displayCity, profile.displayCountry].filter(Boolean).join(', ')}
              </p>
            )}
          </div>
        </div>

        {profile.bio && (
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-2">{t('profile.about')}</h2>
            <p className="text-muted-foreground whitespace-pre-wrap">{profile.bio}</p>
          </div>
        )}

        {profile.links && Object.values(profile.links).some(Boolean) && (
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">{t('profile.links')}</h2>
            <div className="flex flex-wrap gap-3">
              {profile.links.linkedin && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => window.open(profile.links?.linkedin, '_blank')}
                >
                  <Linkedin className="w-4 h-4 mr-2" />
                  LinkedIn
                </Button>
              )}
              {profile.links.twitter && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => window.open(profile.links?.twitter, '_blank')}
                >
                  <Twitter className="w-4 h-4 mr-2" />
                  X (Twitter)
                </Button>
              )}
              {profile.links.website && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => window.open(profile.links?.website, '_blank')}
                >
                  <Globe className="w-4 h-4 mr-2" />
                  Website
                </Button>
              )}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
} 