import { lazy, Suspense } from 'react';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { Navigate, Outlet } from 'react-router-dom';
import { ScrollToTop } from '@/components/ScrollToTop';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AdminLogin } from '@/features/admin/components/AdminLogin';
import { AdminDashboard } from '@/features/admin/components/AdminDashboard';
import { AdminRoute } from '@/components/AdminRoute';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { JoinForm } from '@/features/dashboard/components/JoinForm';
import { MembersPage } from '@/pages/MembersPage';

import { LoginForm } from '@/features/auth/components/LoginForm';

// Lazy load pages
const HomePage = lazy(() => import('@/pages/HomePage'));
const FoundersPage = lazy(() => import('@/pages/FoundersPage'));
const OwnershipPage = lazy(() => import('@/pages/OwnershipPage'));
const ConstitutionPage = lazy(() => import('@/pages/ConstitutionPage'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('@/pages/TermsOfService'));
const CookiePolicy = lazy(() => import('@/pages/CookiePolicy'));

const withSuspense = (Component: React.ComponentType) => (
  <ErrorBoundary>
    <Suspense fallback={<LoadingSpinner />}>
      <ScrollToTop />
      <Component />
    </Suspense>
  </ErrorBoundary>
);

export const routes = [
  // Public routes (accessible to all) - NO protection wrapper
  {
    path: '/',
    element: withSuspense(HomePage)
  },
  {
    path: '/signup',
    element: <JoinForm />
  },
  {
    path: '/privacy',
    element: withSuspense(PrivacyPolicy)
  },
  {
    path: '/terms',
    element: withSuspense(TermsOfService)
  },
  {
    path: '/cookies',
    element: withSuspense(CookiePolicy)
  },
  {
    path: '/login',
    element: <LoginForm />
  },

 

  // Content pages - These should also be public
  {
    path: '/founders',
    element: withSuspense(FoundersPage)
  },
  {
    path: '/ownership',
    element: withSuspense(OwnershipPage)
  },
  {
    path: '/constitution',
    element: withSuspense(ConstitutionPage)
  },

  {
    path: '/members',
    element: withSuspense(MembersPage)
  },


  // Admin routes
  {
    path: '/admin/*',
    element: (
      <div className="flex flex-col min-h-screen">
        <ScrollToTop />
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
    ),
    children: [
      {
        path: 'login',
        element: <AdminLogin />
      },
      {
        path: 'dashboard',
        element: (
          <AdminRoute>
            <AdminDashboard />
          </AdminRoute>
        )
      }
    ]
  },

  // Catch-all route
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];