import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useStripePayment } from '@/hooks/useStripePayment';

export function VerificationForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { handleVerificationPayment, loading } = useStripePayment();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Please enter a valid email address');
      }

      await handleVerificationPayment(email);
    } catch (error: any) {
      console.error('Verification error:', error);
      setError(error.message || 'Failed to start verification process. Please try again.');
    }
  };

  return (
    <div className="w-full px-4 md:px-8 py-24">
      <Card className="mx-auto max-w-md w-full">
        <CardHeader>
          <CardTitle>Join the Chain</CardTitle>
          <p className="text-muted-foreground">
            Enter your email to verify and claim your chain number
          </p>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Continue to Verification ($1)'}
            </Button>

            <p className="text-sm text-center text-muted-foreground">
              One-time $1 verification fee to join the chain
            </p>
          </form>
        </CardContent>
      </Card>
    </div>
  );
} 