import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Share2, Twitter, Linkedin, Copy, Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface SocialShareProps {
  referralNumber: number;
  variant?: "default" | "outline" | "secondary";
  size?: "default" | "sm" | "lg";
  fullWidth?: boolean;
}

export function SocialShare({ 
  referralNumber, 
  variant = "outline",
  size = "sm",
  fullWidth = false 
}: SocialShareProps) {
  const { t } = useTranslation();
  const [copiedType, setCopiedType] = useState<string | null>(null);

  const baseUrl = 'https://ctrlai.com';
  const referralUrl = `${baseUrl}/r/${referralNumber}`;

  const getShareText = (type: 'twitter' | 'linkedin' | 'general') => {
    return t(`social.share.messages.${type}`, { 
      number: referralNumber,
      url: referralUrl 
    });
  };

  const handleShare = (type: 'twitter' | 'linkedin' | 'general') => {
    const text = getShareText(type);
    
    if (type === 'twitter') {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`, '_blank');
    } else if (type === 'linkedin') {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('https://ctrlai.com')}&summary=${encodeURIComponent(text)}`, '_blank');
    } else {
      navigator.clipboard.writeText(text);
      setCopiedType(type);
      setTimeout(() => setCopiedType(null), 2000);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant={variant}
          size={size}
          className={fullWidth ? "w-full" : ""}
        >
          <Share2 className="w-4 h-4 mr-2" />
          {t('social.share.title')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuItem onClick={() => handleShare('twitter')}>
          <Twitter className="w-4 h-4 mr-2" />
          {t('social.share.twitter')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleShare('linkedin')}>
          <Linkedin className="w-4 h-4 mr-2" />
          {t('social.share.linkedin')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleShare('general')}>
          {copiedType === 'general' ? (
            <Check className="w-4 h-4 mr-2" />
          ) : (
            <Copy className="w-4 h-4 mr-2" />
          )}
          {copiedType === 'general' ? t('social.share.copied') : t('social.share.copyText')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
} 