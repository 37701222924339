export const es = {

  hero: {
    title: "No podemos controlar completamente la IA, pero debemos intentarlo",
    subtitle: 
      "Únete a Ctrl AI, un movimiento sin fines de lucro donde cada persona tiene un voto igual en la configuración del futuro de la IA. Verifica tu humanidad con $1 y conviértete en copropietario de un esfuerzo global para guiar la IA de manera ética y transparente.",
    chainCall: "Cada voz cuenta. Agrega la tuya al movimiento."
  },

  problem: {
    category: "El Desafío",
    title: "El poder de la IA está concentrado en manos de unos pocos—¿y si no eligen lo mejor para la humanidad?",
    subtitle: "Debemos asegurarnos de que la IA esté alineada con toda la humanidad, no solo con aquellos que tienen poder y dinero.",
    citation:
      "Esto es lo que llamamos 'Colonialismo de la IA'. Y la única forma de detenerlo es uniendo a miles de millones de personas comunes en una verdadera propiedad de la IA.",
    pointsTitle: "Por qué debes actuar ahora:",
    points: [
      {
        headline: "Monopolios de las Grandes Tecnológicas",
        text: "Un puñado de corporaciones posee los datos, los recursos y los avances en IA."
      },
      {
        headline: "Responsabilidad limitada",
        text: "Los motivos de lucro corporativo pueden eclipsar el bienestar público."
      },
      {
        headline: "Convulsión social",
        text: "A medida que la IA supere a los humanos en más empleos, la sociedad debe estar preparada."
      }
    ],
    conclusion: "O co-poseemos la IA del futuro—o la dejamos en manos de las mega-corporaciones que lucran con nuestros datos."
    },

    opportunity: {
    title: "Nuestra solución—Un movimiento humano",
    subtitle: "Cada miembro verificado es un socio en igualdad de condiciones—ni más, ni menos.",
    benefitsTitle: "Por qué esto es importante",
    benefits: [
      {
        headline: "Sin fines de lucro y 100% propiedad de los usuarios",
        text: "Tu $1 no es una tarifa—es una prueba de que eres humano. Los miembros deciden cómo se usan estos fondos."
      },
      {
        headline: "Gobernanza abierta",
        text: "Cada persona tiene exactamente un voto—sin poder extra para los primeros en unirse."
      },
      {
        headline: "Impacto real",
        text: "Al unirnos, creamos una coalición poderosa que las grandes tecnológicas y los gobiernos deben respetar."
      }
    ]
},

howItWorks: {
  title: "Cómo Funciona",
  points: [
    {
      title: "1. Verifica que eres humano ($1)",
      bullets: [
        "Una verificación simple para bloquear bots y garantizar la equidad.",
        "Decide si tu $1 va al tesoro comunitario o cubre costos operativos."
      ]
    },
    {
      title: "2. Conviértete en copropietario",
      bullets: [
        "Una persona, un voto en decisiones clave—sin importar cuándo te unas."
      ]
    },
    {
      title: "3. Difunde el mensaje",
      bullets: [
        "Nuestro movimiento crece solo con humanos reales contándoselo a otros—sin anuncios, sin inversores."
      ]
    }
  ]
},

commitment: {
  title: "Nuestro Compromiso",
  subtitle: "Construyendo una base de confianza y transparencia",
  points: [
    {
      headline: "Transparencia total",
      text: "Compartimos públicamente nuestras finanzas, decisiones y progreso."
    },
    {
      headline: "Sin fines de lucro para siempre",
      text: "Sin accionistas corporativos—todos tienen una participación."
    },
    {
      headline: "Desarrollo ético de la IA",
      text: "Construimos y apoyamos una IA que prioriza el bienestar humano y el bien social."
    }
  ]
},

shareMessage: {
  title: "Ayúdanos a crecer—Sin anuncios, sin inversores, solo personas",
  subtitle: 
  "Ctrl AI prospera gracias a las voces reales de humanos compartiendo nuestro mensaje. Piénsalo como un acto global de solidaridad—nuestro futuro colectivo depende de cada persona que se una. Después de unirte, invita a un amigo.",
cta: "Comparte Ctrl AI"
},

cta: {
title: "¿Listo para dar forma al futuro de la IA?",
subtitle: 
  "Verifica tu humanidad por $1 y únete a otros que exigen una IA ética e inclusiva. Juntos, podemos asegurar un futuro donde la IA funcione para todos.",
buttons: {
  primary: "Únete ahora ($1)",
  secondary: "Lista de miembros"
}
},

aspiringFoundersPage: {
hero: {
  category: "Creadores e Innovadores",
  title: "Construye con Ctrl AI—Da forma al futuro de la tecnología",
  subtitle:
    "¿Tienes una visión para una aplicación de IA ética y centrada en los usuarios o un modelo de código abierto? Preséntala a la comunidad de Ctrl AI y trabaja en conjunto para hacerla realidad—sin anuncios, inversores de capital de riesgo ni agendas ocultas. Todo sujeto a la aprobación colectiva de nuestros miembros.",
},

intro: {
  title: "Nuestra Filosofía: Personas sobre beneficios",
  subtitle:
    "A medida que los modelos de IA de código abierto alcanzan rápidamente a las ofertas corporativas, hay una oportunidad única para que los creadores desarrollen soluciones mejores y más centradas en los humanos. En Ctrl AI, creemos que la innovación prospera cuando:\\n• Está financiada colectivamente por las personas que realmente la usarán.\\n• Los fundadores no están obligados a perseguir ganancias a costa de los usuarios.\\n• La tecnología sigue siendo abierta, transparente y dirigida por la comunidad.",
  pointsTitle: "¿Por qué construir bajo Ctrl AI?",
  points: [
    {
      headline: "Financiación impulsada por la comunidad",
      text: "Sin capital de riesgo depredador ni restricciones impulsadas por anuncios. Nuestra comunidad sin fines de lucro y propiedad de los usuarios puede financiar directamente tu proyecto si creen en él."
    },
    {
      headline: "Impulso de código abierto",
      text: "Aprovecha modelos de IA de código abierto que compiten con los gigantes corporativos. Mejóralos junto con nuestra membresía global."
    },
    {
      headline: "Un nuevo contrato social",
      text: "Con miles (y eventualmente millones) de copropietarios, tienes una audiencia integrada comprometida con el éxito de tu proyecto—y con mantenerlo ético."
    }
  ],
  disclaimer:
    "Todos los detalles a continuación son conceptos iniciales y, en última instancia, serán moldeados por la voz colectiva de los miembros de Ctrl AI."
},

ownershipModels: {
  title: "Estructuras de propiedad flexibles",
  subtitle:
    "Reconocemos que diferentes proyectos tienen diferentes necesidades. A continuación, presentamos nuestras ideas iniciales sobre cómo los equipos fundadores y la comunidad pueden compartir la propiedad y las responsabilidades.",
    models: [
      {
        headline: "Proyectos pequeños: Participación del fundador de hasta un 5%",
        text: "Los fundadores retienen una pequeña participación mientras que el resto es propiedad de la comunidad. La comunidad también puede votar para proporcionar a los fundadores un salario mensual, permitiéndoles enfocarse en la construcción a tiempo completo."
      },
      {
        headline: "Proyectos grandes y ambiciosos: Propiedad totalmente comunitaria",
        text: "Si la comunidad puede recaudar suficientes fondos para cubrir el desarrollo, la infraestructura y el personal, puede optar por una propiedad 100% colectiva—ofreciendo una compensación justa para los contribuyentes sin una participación a largo plazo para los fundadores."
      }
    ],
    note:
      "La estructura de propiedad de cada proyecto—y cómo se asignan los fondos—se determinará a través de discusiones abiertas y una votación democrática una vez que alcancemos una masa crítica de miembros."
  },

  pitchProcess: {
    category: "Propuesta y Aprobación",
    title: "Cómo puedes lanzar un proyecto",
    subtitle:
      "Una vez que nuestra comunidad sea lo suficientemente grande como para respaldar múltiples iniciativas, invitaremos a los fundadores a presentar sus ideas. La comunidad decidirá colectivamente qué proyectos financiar, construir y priorizar.",
    stepsTitle: "Primeros pasos (Sujeto a ratificación de la comunidad)",
    steps: [
      {
        headline: "1. Presenta tu visión",
        text: "Prepara una breve propuesta describiendo tu idea, su impacto potencial y cualquier miembro del equipo involucrado."
      },
      {
        headline: "2. Discusión abierta",
        text: "Todos los miembros de Ctrl AI pueden participar—haciendo preguntas, sugiriendo mejoras y evaluando el interés de la comunidad."
      },
      {
        headline: "3. Votación comunitaria",
        text: "Si tu proyecto recibe suficiente apoyo, obtendrás financiamiento y recursos para avanzar. Los fundadores pueden recibir un salario mensual durante el desarrollo, según lo aprueben los miembros."
      },
      {
        headline: "4. Transparencia continua",
        text: "Se requieren actualizaciones periódicas del progreso, código abierto cuando sea posible y un compromiso con el bienestar del usuario durante todo el desarrollo."
      }
    ],
    conclusion:
      "No hay un modelo único, ni burocracia—solo una plataforma impulsada por la gente donde las mejores y más éticas ideas pueden prosperar."
  },

  buildingInPublic: {
    category: "Colaboración Abierta",
    title: "Construye en Público, Crece Juntos",
    subtitle:
      "La transparencia es el núcleo del ADN de Ctrl AI. Al compartir tu hoja de ruta, desafíos y logros con los copropietarios, invitas a una colaboración genuina—y ganas un apoyo duradero.",
    pointsTitle: "Nuestros Principios de Construcción en Público",
    points: [
      {
          headline: "Hojas de ruta abiertas",
          text: "Mantén a la comunidad informada sobre características clave, plazos y objetivos a largo plazo."
        },
        {
          headline: "Pruebas y retroalimentación colectiva",
          text: "Aprovecha una base de usuarios global para pruebas beta, detección de errores y mejoras."
        },
        {
          headline: "Informes de progreso frecuentes",
          text: "Comparte logros, desafíos y próximas tareas semanal o mensualmente para mantener la confianza y el impulso."
        }
      ],
      conclusion:
        "Este enfoque abierto no solo ayuda a crear mejores soluciones—también garantiza que todos se mantengan fieles a los valores humanos que defendemos."
    },

    callToAction: {
      category: "Involúcrate",
      title: "¿Listo para hacer realidad tu idea?",
      subtitle:
        "Estamos en las primeras etapas de construcción de un movimiento global. Una vez que alcancemos suficientes miembros, iniciaremos el proceso de presentación de proyectos. Únete a Ctrl AI ahora, comienza a conectar con otros copropietarios y perfecciona tu visión para estar listo cuando llegue el momento.",
      stepsTitle: "Próximos Pasos (Por Ahora)",
      steps: [
        {
          headline: "1. Únete a Ctrl AI",
          text: "Conviértete en miembro verificado por $1. Tu voto y tu voz definirán cómo seleccionamos y financiamos nuevos proyectos."
        },
        {
          headline: "2. Conéctate con la Comunidad",
          text: "Conoce a colaboradores potenciales y futuros usuarios. Discute tu concepto de manera informal para ganar apoyo desde el principio."
        },
        {
          headline: "3. Prepara tu propuesta",
          text: "Define tu idea, cómo se alinea con los valores de Ctrl AI y cualquier necesidad de presupuesto. Mantente atento a la apertura de postulaciones."
        }
      ],
      conclusion:
        "**Estos marcos no están escritos en piedra.** Juntos, refinaremos cómo apoyamos a los fundadores visionarios mientras mantenemos la tecnología verdaderamente centrada en los usuarios."
    }
  },

  newSocialContractPage: {
    hero: {
      category: "Un Nuevo Contrato Social para la Era de la IA",
      title: "Únete a Ctrl AI: Un Movimiento Sin Fines de Lucro, Impulsado por Personas",
      subtitle:
        "Al verificar tu humanidad con $1, te conviertes en copropietario de nuestro esfuerzo global para mantener la IA ética, transparente e inclusiva. Sin accionistas, sin inversores hambrientos de ganancias—solo una persona, un voto."
    },

    intro: {
      title: "¿Por qué un modelo sin fines de lucro y propiedad de los usuarios?",
      subtitle:
        "Creemos que, ante los avances acelerados de la IA, las personas—y no las grandes corporaciones—deben decidir cómo evoluciona la tecnología. Ctrl AI está construyendo un nuevo contrato social: uno que pone el bienestar humano y la gobernanza comunitaria en el centro.",
      pointsTitle: "Principios Claves",
      points: [
        {
          headline: "Una persona, un voto",
          text: "Independientemente de cuándo te unas o cuánto dinero tengas, tu voz cuenta por igual—al igual que la del fundador."
        },
        {
          headline: "Sin anuncios, sin inversores",
          text: "Nos basamos en una verificación única de $1 para mantener alejados a los bots y garantizar una verdadera propiedad humana, libre de agendas corporativas."
        },
        {
          headline: "Inclusión global",
          text: "Cualquier persona, en cualquier lugar, puede unirse. Nuestro objetivo es reunir voces de todos los rincones del mundo."
        }
      ],
      conclusion:
        "Cuando las personas comunes co-propietarias de la IA, aseguramos que se guíe por valores compartidos—equidad, transparencia y empoderamiento—y no solo por el lucro."
    },

    newOwnershipModel: {
      title: "Reescribiendo las Reglas de la Propiedad",
      subtitle:
        "Las empresas tecnológicas tradicionales responden a inversores y a ingresos por publicidad. Ctrl AI responde a sus usuarios—porque los usuarios son los propietarios.",
      pointsTitle: "Cómo Somos Diferentes",
      points: [
        {
          headline: "100% liderado por la comunidad",
          text: "Somos una organización sin fines de lucro sin accionistas externos. Todos los miembros dan forma a nuestra hoja de ruta y tienen la última palabra."
        },
        {
          headline: "Financiamiento y gastos transparentes",
          text: "Tu contribución de $1 está completamente registrada. Nuestras finanzas son públicas y cualquier excedente se asigna mediante votación de los miembros."
        },
        {
          headline: "Responsabilidad compartida",
          text: "Como co-propietarios de la plataforma, también compartimos la responsabilidad de hacerla ética, útil y alineada con las necesidades de la humanidad."
        }
      ],
      note:
        "Esto es más que un modelo de negocio—es un nuevo contrato social donde la tecnología rinde cuentas a todos los que la usan."
    },

    whyJoinNow: {
      title: "¿Por qué convertirse en miembro fundador?",
      subtitle:
        "Estamos en un punto de inflexión: la IA avanza tan rápido que un puñado de corporaciones podría dominarla—y decidir cómo vivimos y trabajamos todos. Al unirte ahora, te conviertes en un co-arquitecto temprano de un futuro diferente.",
        points: [
          {
            headline: "Da forma a nuestra constitución",
            text: "Como parte de la comunidad fundadora, ayudarás a definir nuestros principios rectores y a garantizar la responsabilidad del liderazgo."
          },
          {
            headline: "Influencia directa",
            text: "Propón o vota en decisiones críticas, desde asignaciones presupuestarias hasta qué herramientas de IA construiremos primero."
          },
          {
            headline: "Establece un ejemplo global",
            text: "Ayuda a demostrar que la tecnología puede desarrollarse de manera ética y transparente cuando las personas, y no los beneficios, están al mando."
          }
        ],
        conclusion:
          "Unirte ahora significa estar en la vanguardia de una revolución de IA impulsada por la gente—una que prioriza el bienestar colectivo sobre los intereses corporativos."
      },
  
      howItWorks: {
        title: "Unirse es simple",
        steps: [
          {
            title: "1. Verifica tu humanidad",
            text: "Contribuye con una única tarifa de $1 para confirmar que eres una persona real. Sin cargos adicionales—sin suscripciones."
          },
          {
            title: "2. Conviértete en copropietario",
            text: "Tu membresía te otorga un voto igual en cada decisión importante, desde nuestra constitución hasta la financiación de proyectos."
          },
          {
            title: "3. Difunde el mensaje",
            text: "Crecemos a través de conexiones humanas, no con anuncios o dinero de inversores. Invita a otros para que nuestra voz colectiva se fortalezca."
          }
        ]
      },
  
      finalCall: {
        title: "¿Listo para ayudar a reescribir el futuro de la IA?",
        subtitle:
          "Agrega tu voz, tus ideas y tu pasión. Juntos, podemos asegurar que la IA siga siendo una herramienta para toda la humanidad—y no solo para la élite.",
        button: "Únete a Ctrl AI"
      }
    },
  
    constitutionPage: {
      hero: {
        title: "La Constitución de Ctrl AI (Borrador)",
        preamble:
          "En una era donde la tecnología moldea cada vez más el futuro de la humanidad, elegimos un camino diferente: no solo construir mejores productos, sino hacerlo con mejores principios. Este es nuestro primer borrador—una vez que alcancemos una masa crítica de miembros en todo el mundo, lo refinaremos juntos mediante una votación comunitaria."
      },
      principles: {
        title: "Nuestros Compromisos Fundamentales",
        intro:
          "Estos son los valores fundamentales que guían a Ctrl AI. Aunque pueden evolucionar, su esencia permanece: priorizar el bienestar humano, la transparencia y la supervisión comunitaria en todo desarrollo de IA. Una vez ratificados por la membresía global, estos compromisos serán obligatorios para cada proyecto dentro de Ctrl AI:",
  
        principles: [
          {
            title: "El bienestar humano primero",
            items: [
              "Diseñar para el florecimiento genuino de las personas, no para métricas de compromiso obsesivas",
              "Tolerancia cero con el diseño manipulador o la explotación psicológica",
              "Respetar la atención del usuario como un recurso limitado y valioso",
              "Fomentar patrones de uso saludables y puntos de detención naturales",
              "Priorizar conexiones significativas sobre experiencias adictivas"
            ]
          },
          {
            title: "Derechos absolutos sobre los datos",
            items: [
              "Los usuarios son dueños absolutos de sus datos—sin venta ni compartición no autorizada",
              "Usar procesamiento de datos local siempre que sea posible",
              "Emplear cifrado sólido como estándar predeterminado",
              "Garantizar el derecho a ser completamente olvidado en cualquier momento"
            ]
          },
          {
            title: "Poder real para la comunidad",
            items: [
              "100% propiedad comunitaria—sin accionistas corporativos",
              "Una persona, un voto en decisiones clave (incluido el fundador)",
              "Presupuesto transparente y procesos de gobernanza abiertos",
              "Inclusión global—todos son bienvenidos con una simple verificación de humanidad de $1"
            ]
          },
          {
            title: "Límites éticos de la IA",
            items: [
              "Toda IA debe servir a la agencia humana—nunca subvertirla ni reemplazarla",
              "Mantener transparencia en la lógica y los procesos de decisión de la IA",
              "Monitorear y mitigar continuamente los sesgos",
              "Siempre permitir una clara intervención humana en las funciones de IA",
              "Enfocar el desarrollo en herramientas que potencien en lugar de reemplazar el potencial humano"
            ]
          },
          {
            title: "Bienestar del equipo",
            items: [
              "Brindar apoyo integral de salud mental a todos los contribuyentes",
              "Fomentar una carga de trabajo equilibrada—sin períodos de 'crunch' crónicos",
              "Compensación justa y transparente para el personal dedicado",
              "Amigable con el trabajo remoto, con espacios opcionales de colaboración",
              "Eventos regulares de conexión para el equipo y la comunidad"
            ]
          },
          {
            title: "La educación primero",
            items: [
              "Incorporar valor educativo en todos los productos siempre que sea posible",
              "Garantizar un diseño apropiado para la edad, especialmente para usuarios jóvenes",
              "Promover la alfabetización digital y el uso responsable de la tecnología",
              "Prohibir prácticas manipulativas que exploten a los jóvenes"
            ]
          },
          {
            title: "Transparencia radical",
            items: [
              "Actualizaciones públicas frecuentes sobre el progreso de la plataforma y las finanzas",
              "Código abierto por defecto para fomentar la confianza y la participación de la comunidad",
              "Comunicación directa sobre desafíos, contratiempos y cambios",
              "Realizar sesiones periódicas de preguntas y respuestas o reuniones con la comunidad"
            ]
          },
          {
            title: "Desarrollo sostenible",
            items: [
              "Crecimiento orgánico a través de conexiones humanas reales, no marketing agresivo",
              "Evaluar el impacto ambiental de todas las decisiones",
              "Priorizar la resiliencia a largo plazo sobre las ganancias a corto plazo",
              "Evitar tácticas explotadoras o engañosas para aumentar la base de usuarios"
            ]
          },
          {
            title: "Ingresos justos",
            items: [
              "Sin modelos de ingresos basados en anuncios que incentiven la explotación de datos",
              "Ofrecer precios transparentes o donaciones cuando surjan costos",
              "Garantizar una comunicación clara de costos y beneficios para los usuarios",
              "Cualquier ingreso se destina directamente a iniciativas comunitarias o mejoras en la plataforma"
            ]
          },
          {
            title: "Innovación con propósito",
            items: [
              "Enfocarse en necesidades reales de las personas y la sociedad",
              "Fomentar la colaboración abierta y el conocimiento compartido",
              "Aplicar la tecnología al servicio de la dignidad y el empoderamiento humano",
              "Priorizar el impacto social y el beneficio comunitario en cada decisión"
            ]
          }
        ],

        enforcement: {
          title: "Aplicación real, responsabilidad real",
          content:
            "Estos principios no son solo ideales elevados; son reglas orientadoras con mecanismos de corrección si nos desviamos. Nuestra comunidad tiene la última palabra para garantizar que Ctrl AI rinda cuentas.",
    
          measures: [
            {
              title: "Supervisión comunitaria",
              items: [
                "• Auditorías públicas programadas de código, finanzas y procesos",
                "• Informes en tiempo real sobre posibles violaciones",
                "• Canales directos de retroalimentación para los miembros",
                "• Consejo independiente de revisión ética para investigaciones neutrales"
              ]
            },
            {
              title: "Consecuencias claras",
              items: [
                "• Respuesta rápida a problemas planteados por los miembros",
                "• Soluciones impulsadas por la comunidad y correcciones de rumbo",
                "• Pasos obligatorios de remediación cuando ocurran infracciones",
                "• Informes transparentes sobre cómo se resuelve cada violación"
              ]
            },
            {
              title: "Documento vivo",
              items: [
                "• Revisión anual para adaptarse a nuevas tecnologías o necesidades",
                "• Los principios fundamentales son inmutables, excepto por supermayoría comunitaria",
                "• Proceso democrático de enmienda una vez que se alcance una masa crítica",
                "• Invitaciones abiertas para sugerencias de los miembros"
              ]
            }
          ]
        },
    
        cta: {
          title: "Únete a nosotros para escribir el futuro de la IA",
          content:
            "Este borrador de la Constitución es solo el comienzo. Una vez que tengamos una membresía diversa y global, nuestra comunidad finalizará y ratificará estos principios juntos. Ya seas desarrollador, legislador o usuario común, tu voz tiene el mismo poder aquí. ¿Listo para participar?",
          button: "Únete a Ctrl AI por $1"
        }
      },
    
      footer: {
        companyDescription: "Construyendo el futuro de la IA con propiedad comunitaria y principios éticos.",
        navigation: "Navegación",
        legal: "Legal",
        connect: "Otros",
        privacy: "Política de Privacidad",
        terms: "Términos de Servicio",
        cookies: "Política de Cookies",
        rights: "Todos los derechos reservados."
      },
    
      nav: {
        chain: "Panel de la Cadena",
        founders: "Para Creadores",
        ownership: "Nuevo Contrato Social",
        constitution: "Constitución",
        pricing: "Precios",
        dashboard: "Panel de Control",
        myProfile: "Mi Perfil",
        settings: "Configuración",
        home: "Inicio",
        ctrlaiCom: "CtrlAI.com",
        ctrlaiAi: "CtrlAI.ai",
        apply: "Solicitar como Fundador",
        about: "Sobre Ctrl AI",
        logout: "Cerrar sesión",
        members: "Miembros",
      },
    
      auth: {
        login: {
          title: "Bienvenido de nuevo",
          subtitle: "Continúa tu viaje construyendo el futuro",
          emailPlaceholder: "Ingresa tu correo electrónico",
          passwordPlaceholder: "Ingresa tu contraseña",
          submitButton: "Iniciar sesión",
          noAccount: "¿No tienes una cuenta?",
          signupLink: "Regístrate como Fundador",
          error: "Correo electrónico o contraseña inválidos"
        },
        signup: {
          title: "Únete como Fundador",
          subtitle: "Comienza a construir el futuro de la tecnología",
          namePlaceholder: "Ingresa tu nombre completo",
          emailPlaceholder: "Ingresa tu correo electrónico",
          passwordPlaceholder: "Elige una contraseña",
          submitButton: "Crear Cuenta",
          hasAccount: "¿Ya tienes una cuenta?",
          loginLink: "Iniciar sesión",
          error: "Error al crear la cuenta"
        },
        errors: {
          passwordLength: "La contraseña debe tener al menos 6 caracteres",
          invalidEmail: "Por favor, ingresa una dirección de correo válida",
          emailInUse: "Este correo electrónico ya está en uso"
        }
      },

    passwordRequirements: "La contraseña debe tener al menos 6 caracteres",
    joinForm: {
      coverOperationalCosts: "Apoya nuestras operaciones (recomendado) - Tu $1 ayudará a cubrir los costos operativos en lugar de ir al tesoro",
      processing: "Procesando...",
      signUpWithEmail: "Regístrate con Correo Electrónico",
      headline: "Únete al Movimiento",
      continueWithGoogle: "Continuar con Google",
      errors: {
        allFieldsRequired: "Todos los campos son obligatorios",
        acceptTerms: "Debes aceptar los términos para continuar",
        passwordLength: "La contraseña debe tener al menos 6 caracteres",
        paymentFailed: "El proceso de pago ha fallado. Por favor, inténtalo de nuevo."
      },
      subtitle: "Crea tu cuenta para verificar tu humanidad",
      costAllocationTitle: "Elige a dónde va tu tarifa de verificación",
      operationalCosts: "Apoyar Operaciones",
      operationalCostsDetail: "Ayuda a cubrir nuestros costos de computación y operativos ($0.66 después de tarifas de procesamiento de pago)",
      treasuryCosts: "Déjalo Intacto",
      treasuryCostsDetail: "¡No tocaremos ni un centavo! Tu contribución completa (menos las tarifas de pago) irá directamente al Tesoro del Movimiento",
      orDivider: "o",
      email: "Correo Electrónico",
      emailPlaceholder: "Ingresa tu dirección de correo electrónico",
      fullName: "Nombre Completo",
      fullNamePlaceholder: "Ingresa tu nombre completo",
      password: "Contraseña",
      passwordPlaceholder: "Elige una contraseña (mín. 6 caracteres)",
      alreadyHaveAccount: "¿Ya tienes una cuenta?",
      signIn: "Iniciar sesión",
      termsAgreement: "Acepto los",
      termsLink: "Términos de Servicio"
    }
  },

  members: {
    title: "Únete a Nuestro Creciente Movimiento",
    subtitle: "Apenas estamos comenzando, y eso es lo que lo hace emocionante. Únete hoy para ayudar a construir nuestra comunidad desde cero y haz que tu voz sea escuchada en el futuro de la IA.",
    stats: {
      membersLabel: "Humanos Verificados",
      countriesLabel: "Países Representados"
    },
    share: {
      title: "Ayuda a Expandir Nuestro Impacto",
      subtitle: "Solo se necesita una compartición para atraer la próxima voz crucial.",
      twitterText: "Acabo de descubrir @CtrlAI, un movimiento global de humanos reales que dan forma al futuro de la IA. ¡Únete a nosotros!",
      buttons: {
        twitter: "Compartir en Twitter",
        facebook: "Compartir en Facebook",
        copyLink: "Copiar Enlace",
        linkCopied: "¡Enlace copiado al portapapeles!"

    }
  }
},
shareInvite: {
  title: "Comparte Tu Impacto",
  bulletPoints: [
    "Cada vez que compartes nos acercamos a una verdadera gobernanza democrática de la IA",
    "Ayuda a hacer crecer nuestra comunidad global",
    "Difunde el mensaje sobre la IA para la humanidad"
  ],
  twitterText: "Acabo de unirme a @CtrlAI para ayudar a dar forma al futuro de la IA. ¡Únete a mí!",
  shareButtons: {
    twitter: "Compartir en Twitter",
    facebook: "Compartir en Facebook",
    copyLink: "Copiar Enlace",
    linkCopied: "¡Enlace copiado!"
  },
  founderNote: "Eres el miembro #{{memberNumber}} de nuestro movimiento. Visita tu perfil para personalizar tu presencia pública."
},

quotaMap: {
stats: {
  totalQuota: "Cuota Global Total",
  totalRegistered: "Total Registrado",
  totalPopulation: "Población Total",
  countryCount: "Países Representados",
  fulfillment: "Cumplimiento Global"
},
tooltip: {
  country: "País",
  population: "Población",
  quota: "Cuota de Miembros",
  registered: "Miembros Registrados",
  fulfillment: "Cumplimiento de Cuota"
},
legend: {
  title: "Cumplimiento de Cuota",
  low: "Bajo",
  target: "Objetivo",
  exceeded: "Excedido"
},
controls: {
  reset: "Restablecer Vista",
  zoom: "Zoom",
  pan: "Desplazar"
},
noData: "No hay datos disponibles para este país",
loading: "Cargando datos del mapa..."


}
}
