import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import getUnicodeFlag from 'country-flag-icons/unicode';

// Split English into US and UK variants
const LANGUAGES = [
  { code: 'en-US', label: 'English (US)', path: '', countryCode: 'US' },
  { code: 'en-GB', label: 'English (UK)', path: '', countryCode: 'GB' },
  { code: 'es', label: 'Español', path: 'es', countryCode: 'ES' },
  { code: 'de', label: 'Deutsch', path: 'de', countryCode: 'DE' },
  { code: 'fr', label: 'Français', path: 'fr', countryCode: 'FR' },
  { code: 'pt', label: 'Português', path: 'pt', countryCode: 'PT' },
  { code: 'zhHans', label: '简体中文', path: 'zh', countryCode: 'CN' },
  { code: 'ja', label: '日本語', path: 'ja', countryCode: 'JP' },
  { code: 'ar', label: 'العربية', path: 'ar', countryCode: 'SA' },
  { code: 'cs', label: 'Čeština', path: 'cs', countryCode: 'CZ' }
];

// Map country codes to language codes
const COUNTRY_TO_LANGUAGE: { [key: string]: string } = {
  US: 'en-US',
  GB: 'en-GB',
  ES: 'es',
  DE: 'de',
  FR: 'fr',
  PT: 'pt',
  CN: 'zhHans',
  JP: 'ja',
  SA: 'ar',
  CZ: 'cs',
  // Add more mappings as needed
};

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const detectAndSetLanguage = async () => {
      try {
        // Only detect if language isn't already set
        if (!localStorage.getItem('i18nextLng')) {
          // Get user's country from IP
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          const userCountry = data.country_code;
          
          // Get browser's preferred language
          const browserLang = navigator.language;
          
          // Determine which language to use
          let languageToUse = 'en-US'; // default

          // If user is from an English-speaking country, use country-specific English
          if (userCountry === 'US') {
            languageToUse = 'en-US';
          } else if (userCountry === 'GB') {
            languageToUse = 'en-GB';
          } else {
            // For other countries, check if we have their language
            const countryLanguage = COUNTRY_TO_LANGUAGE[userCountry];
            if (countryLanguage && LANGUAGES.some(lang => lang.code === countryLanguage)) {
              languageToUse = countryLanguage;
            }
          }

          // Set the language
          i18n.changeLanguage(languageToUse);
        }
      } catch (error) {
        console.error('Error detecting location:', error);
        // Fallback to browser language or default
        const browserLang = navigator.language.startsWith('en') 
          ? 'en-US' 
          : navigator.language;
        i18n.changeLanguage(browserLang);
      }
    };

    detectAndSetLanguage();
  }, [i18n]);

  const currentLanguage = LANGUAGES.find(lang => {
    // Handle both full and partial language code matches
    if (lang.code === i18n.language) return true;
    if (i18n.language.startsWith('en') && (lang.code === 'en-US' || lang.code === 'en-GB')) {
      return lang.code === (i18n.language === 'en' ? 'en-US' : i18n.language);
    }
    return false;
  }) || LANGUAGES[0];

  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="icon" 
          className="h-9 w-9 rounded-xl flex items-center justify-center"
        >
          <span className="text-lg leading-none">
            {getUnicodeFlag(currentLanguage.countryCode)}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {LANGUAGES.map(({ code, label, countryCode }) => (
          <DropdownMenuItem
            key={code}
            onClick={() => handleLanguageChange(code)}
            className={`cursor-pointer flex items-center gap-2 ${
              i18n.language === code ? 'font-bold' : ''
            }`}
          >
            <span className="text-lg leading-none">
              {getUnicodeFlag(countryCode)}
            </span>
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}; 