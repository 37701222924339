import { useState } from 'react';
import { motion } from 'framer-motion';
import { useUserStore } from '@/stores/useUserStore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useToast } from '@/components/ui/use-toast';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { useTranslation } from 'react-i18next';

export function ProfilePage() {
  const { profile, refreshProfile } = useUserStore();
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [fullName, setFullName] = useState(profile?.fullName || '');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleUpdateProfile = async () => {
    if (!profile) return;
    
    try {
      setLoading(true);
      await updateDoc(doc(db, 'users', profile.id), {
        fullName
      });
      await refreshProfile();
      setIsEditing(false);
      toast({
        title: t('profile.updateSuccess.title'),
        description: t('profile.updateSuccess.description')
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: t('profile.updateError.title'),
        description: t('profile.updateError.description'),
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 space-y-6">
      {/* Profile Header */}
      <Card>
        <CardHeader>
          <CardTitle>{t('profile.title')}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center gap-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={profile?.avatar?.url} />
              <AvatarFallback>{profile?.fullName?.[0]}</AvatarFallback>
            </Avatar>
            <div className="space-y-2">
              {isEditing ? (
                <div className="space-y-2">
                  <Input
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder={t('profile.fullName')}
                  />
                  <div className="space-x-2">
                    <Button 
                      onClick={handleUpdateProfile}
                      disabled={loading}
                    >
                      {loading ? t('profile.saving') : t('profile.save')}
                    </Button>
                    <Button 
                      variant="outline" 
                      onClick={() => {
                        setIsEditing(false);
                        setFullName(profile?.fullName || '');
                      }}
                    >
                      {t('profile.cancel')}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl font-bold">{profile?.fullName}</h2>
                  <Button 
                    variant="outline" 
                    onClick={() => setIsEditing(true)}
                  >
                    {t('profile.editProfile')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Chain Links */}
      <Card>
        <CardHeader>
          <CardTitle>{t('profile.chainLinks')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {profile?.chainVerifications.map((verification) => (
              <motion.div
                key={verification.chainLinkNumber}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex items-center justify-between p-4 rounded-lg bg-muted/50"
              >
                <div className="space-y-1">
                  <div className="text-lg font-semibold">
                    #{verification.chainLinkNumber}
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {t('profile.wave', { number: verification.waveNumber })}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {verification.chainLinkNumber === profile.primaryChainLinkNumber ? (
                    <Badge variant="default">{t('profile.primary')}</Badge>
                  ) : (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => useUserStore.getState().setPrimaryChainNumber(verification.chainLinkNumber)}
                    >
                      {t('profile.setPrimary')}
                    </Button>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Control Points Summary */}
      <Card>
        <CardHeader>
          <CardTitle>{t('profile.controlPoints')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-3xl font-bold text-primary">
            {t('points.amount', {
              sign: '',
              amount: profile?.controlPoints || 0
            })}
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 