import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useToast } from '@/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

interface PaymentMetadata {
  userId: string;
  email: string;
  fullName: string;
  referralCode?: string;
  type: 'verification';
  existingLinkNumber?: number; // For users who already have a link
}

export function useStripePayment() {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const { t } = useTranslation();

  const handleVerificationPayment = async (email: string, metadata: Omit<PaymentMetadata, 'type'>) => {
    try {
      setLoading(true);
      
      const functionUrl = `${import.meta.env.VITE_FIREBASE_FUNCTIONS_URL}/createCheckout`;
      
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email,
          metadata: {
            ...metadata,
            type: 'verification'
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
        console.error('Server response:', errorData);
        throw new Error(errorData.error || `Failed to create checkout session: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.sessionId) {
        throw new Error('No session ID in response');
      }

      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error('Stripe failed to load');
      }

      const { error } = await stripe.redirectToCheckout({ 
        sessionId: data.sessionId 
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Payment Error:', error);
      toast({
        variant: "destructive",
        title: t('error.verification.paymentFailed'),
        description: error instanceof Error ? error.message : 'Unknown error occurred'
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { handleVerificationPayment, loading };
} 