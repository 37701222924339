import { create } from 'zustand';
import { User } from 'firebase/auth';
import { auth, db } from '@/config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/config/firebase';
import type { UserProfile } from '@/features/chain/types';

interface UserState {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  initialized: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: UserProfile | null) => void;
  setLoading: (loading: boolean) => void;
  initialize: () => void;
  refreshProfile: () => Promise<void>;
}

let authInitialized = false;

async function fetchUserProfile(uid: string): Promise<UserProfile | null> {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      return {
        id: uid,
        email: data.email || '',
        fullName: data.fullName || '',
        verified: data.verified || false,
        controlPoints: data.controlPoints || 0,
        chainData: data.chainData || {
          chainLinks: {}
        },
        stripeCustomerId: data.stripeCustomerId,
        createdAt: data.createdAt?.toDate(),
        updatedAt: data.updatedAt?.toDate(),
        city: data.city,
        country: data.country
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
}

export const useUserStore = create<UserState>((set, get) => ({
  user: null,
  profile: null,
  loading: true,
  initialized: false,

  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (loading) => set({ loading }),

  refreshProfile: async () => {
    const { user } = get();
    if (user?.uid) {
      try {
        set({ loading: true });
        const profile = await fetchUserProfile(user.uid);
        set({ profile, loading: false });
      } catch (error) {
        console.error('Error refreshing profile:', error);
        set({ loading: false });
      }
    }
  },

  initialize: () => {
    if (authInitialized) return;
    authInitialized = true;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      set({ user, loading: true });

      if (user) {
        try {
          let profile = await fetchUserProfile(user.uid);
          
          // If no profile exists, try to create it
          if (!profile) {
            const createUserProfile = httpsCallable(functions, 'createUserProfile');
            await createUserProfile({
              email: user.email,
              fullName: user.displayName
            });
            // Fetch the newly created profile
            profile = await fetchUserProfile(user.uid);
          }

          set({ 
            profile,
            loading: false,
            initialized: true
          });
        } catch (error) {
          console.error('Error initializing user profile:', error);
          set({ 
            profile: null,
            loading: false,
            initialized: true
          });
        }
      } else {
        set({ 
          profile: null,
          loading: false,
          initialized: true
        });
      }
    });

    return () => unsubscribe();
  }
}));

// Initialize the store
useUserStore.getState().initialize(); 