import { Timestamp, FieldValue } from 'firebase/firestore';

// Type for dates that can be either Date or Firestore FieldValue
export type FirestoreDate = Date | Timestamp | FieldValue;

export enum UserRole {
  ADMIN = 'admin',
  FOUNDING_MEMBER = 'founding_member',
  COLLECTIVE_MEMBER = 'collective_member',
  WAITLIST = 'waitlist',
  NONE = 'none'
}

export type UserStatus = 'active' | 'pending' | 'invited' | 'waitlist';
export enum MembershipStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  CANCELLED = 'cancelled'
}
export enum MembershipTier {
  FOUNDING = 'founding',
  COLLECTIVE = 'collective',
  WAITLIST = 'waitlist',
  NONE = 'none'
}

// Client-side user profile (after conversion)
export interface UserProfile {
  id: string;
  email: string;
  fullName: string;
  role: UserRole;
  membershipTier: MembershipTier;
  membershipStatus: MembershipStatus;
  membershipStartDate: Date | null;
  waitlistJoinDate: Date | null;
  createdAt: Date;
  controlPoints: number;
  projectsContributed: number;
  onboardingComplete: boolean;
  referralCode?: string;
  location?: {
    city?: string;
    country?: string;
  };
  interests?: string[];
  skills?: string[];
  avatar?: {
    url?: string;
    thumbnail?: string;
  };
}

// Firestore user profile (before conversion)
export interface FirestoreUserProfile extends Omit<UserProfile, 'id' | 'createdAt' | 'membershipStartDate' | 'waitlistJoinDate'> {
  createdAt: FirestoreDate;
  membershipStartDate: FirestoreDate | null;
  waitlistJoinDate: FirestoreDate | null;
  referralCode?: string;
}

// For creating a new user profile
export type CreateUserProfileData = Omit<FirestoreUserProfile, 'id'>;

export interface WaitlistEntry {
  userId: string;
  email: string;
  fullName: string;
  joinDate: Timestamp | FieldValue;
  status: 'pending' | 'approved' | 'rejected';
  referredBy?: string | null;
  referralCount: number;
}

// Utility function to convert Timestamp to Date
export const toDate = (timestamp: Timestamp | FieldValue | undefined): Date | undefined => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  }
  return undefined;
};

export interface UserPublicProfile {
  userId: string;
  memberNumber: number;
  displayName: string;
  displayCity?: string;
  displayCountry?: string;
  bio?: string;
  links?: {
    linkedin?: string;
    twitter?: string;
    website?: string;
  };
  hasProfilePicture: boolean;
  updatedAt: Date;
}

export interface UserProfileFormData {
  displayName: string;
  displayCity?: string;
  displayCountry?: string;
  bio?: string;
  links?: {
    linkedin?: string;
    twitter?: string;
    website?: string;
  };
} 