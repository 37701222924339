import { countryCodeMap } from '@/utils/countryUtils'
import getUnicodeFlag from 'country-flag-icons/unicode'

interface CountryFlagProps {
  countryId: string;
  className?: string;
}

export function CountryFlag({ countryId, className = '' }: CountryFlagProps) {
  const isoCode = countryCodeMap[countryId];
  if (!isoCode) return null;
  
  return (
    <span className={className} title={isoCode}>
      {getUnicodeFlag(isoCode)}
    </span>
  );
} 