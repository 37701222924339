import { useState, useEffect } from 'react';
import { useUserStore } from '@/stores/useUserStore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

interface PendingAction {
  id: string;
  userId: string;
  type: string;
  link: string;
  createdAt: Date;
  userName: string;
}

export function AdminDashboard() {
  const { user } = useUserStore();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [pendingActions, setPendingActions] = useState<PendingAction[]>([]);
  const [updatingRanks, setUpdatingRanks] = useState(false);

  useEffect(() => {
    checkAdminStatus();
    if (isAdmin) {
      loadPendingActions();
    }
  }, [user, isAdmin]);

  const checkAdminStatus = async () => {
    if (!user) {
      return;
    }
    
    const adminDoc = await getDocs(
      query(collection(db, 'adminUsers'), where('userId', '==', user.uid))
    );
    
    const isAdminUser = !adminDoc.empty;
    setIsAdmin(isAdminUser);
    setLoading(false);
  };

  const loadPendingActions = async () => {
    try {
      
      const waitlistSnapshot = await getDocs(collection(db, 'waitlist'));
      
      const actions: PendingAction[] = [];
      
      for (const waitlistDoc of waitlistSnapshot.docs) {
        const waitlistData = waitlistDoc.data();
        
        const actionsQuery = query(
          collection(db, `waitlist/${waitlistDoc.id}/actions`),
          where('verified', '==', false)
        );
        
        const actionsSnap = await getDocs(actionsQuery);
        
        actionsSnap.docs.forEach(actionDoc => {
          const data = actionDoc.data();
          
          let createdAt: Date;
          if (data.timestamp?.toDate) {
            createdAt = data.timestamp.toDate();
          } else if (typeof data.timestamp === 'string') {
            createdAt = new Date(data.timestamp);
          } else {
            createdAt = new Date();
          }
          
          actions.push({
            id: actionDoc.id,
            userId: waitlistDoc.id,
            type: data.type,
            link: data.link || '',
            createdAt,
            userName: waitlistData.name || 'Unknown'
          });
        });
      }
      
      setPendingActions(actions);
    } catch (error) {
      console.error('Error loading pending actions:', error);
      setError('Failed to load pending actions');
    }
  };

  const handleVerifyAction = async (actionId: string, userId: string, approved: boolean) => {
    try {
      const functions = getFunctions();
      const verifySocialAction = httpsCallable(functions, 'verifySocialAction');
      
      await verifySocialAction({ 
        actionId, 
        userId,
        verified: approved 
      });
      
      await loadPendingActions(); // Refresh the list
      setSuccess(`Action ${approved ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      console.error('Error verifying action:', error);
      setError('Failed to verify action');
    }
  };

  const handleUpdateRankings = async () => {
    try {
      setUpdatingRanks(true);
      const functions = getFunctions();
      const triggerUpdate = httpsCallable(functions, 'triggerRankingUpdate');
      
      const result = await triggerUpdate();
      
      setSuccess('Rankings updated successfully');
    } catch (error: any) {
      console.error('Error updating rankings:', error);
      setError(error.message || 'Failed to update rankings');
    } finally {
      setUpdatingRanks(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!isAdmin) return <div>Access denied</div>;

  return (
    <div className="container mx-auto p-6">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Admin Dashboard</CardTitle>
        </CardHeader>
        <CardContent>
          <Button 
            onClick={handleUpdateRankings}
            disabled={updatingRanks}
            className="mb-4"
          >
            {updatingRanks ? 'Updating Rankings...' : 'Update Waitlist Rankings'}
          </Button>

          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="mb-4">
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Pending Social Actions</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Link</TableHead>
                <TableHead>Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pendingActions.map((action) => (
                <TableRow key={action.id}>
                  <TableCell>{action.userName}</TableCell>
                  <TableCell>{action.type}</TableCell>
                  <TableCell>
                    <a href={action.link} target="_blank" rel="noopener noreferrer">
                      {action.link}
                    </a>
                  </TableCell>
                  <TableCell>
                    {action.createdAt.toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outline"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleVerifyAction(action.id, action.userId, true)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => handleVerifyAction(action.id, action.userId, false)}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
} 